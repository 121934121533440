import React, { ChangeEvent, useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { PaginationController } from '../../../ui/pagination_controller/pagination_controller';
import { PrimaryTypography } from '../../../ui/typography/typography';
import { getJobList } from '../../../utils/api_service/job_api';
import { ContextBasedGeneratorJob, TopicBasedGeneratorJob } from '../../../utils/types/content_manager_types';
import { SelectOptions } from '../../../utils/types/react_select_types';
import commonStyles from '../questions.module.css';
import styles from './content_manager.module.css';
import { DocumentTriage } from './document_triage/document_triage';
import { ContextSearch } from './search_bar';

export const ContentManager = () => {
  const [jobList, setJobList] = useState<(ContextBasedGeneratorJob | TopicBasedGeneratorJob)[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const generateTypeList: SelectOptions[] = [
    {
      value: 'context',
      label: 'Context',
    },
    {
      value: 'topic',
      label: 'Topic',
    },
  ];

  const [generateType, setGenerateType] = useState<SelectOptions | null>(null);

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm, generateType]);

  useEffect(() => {
    setLoading(true);
    const debounceSearchTerm = setTimeout(() => {
      const fetchJobList = async () => {
        try {
          let generateTypeValue = null;
          if (generateType?.value !== 'None') {
            generateTypeValue = generateType?.value;
          }
          const response = await getJobList(pageNumber, 10, searchTerm, generateTypeValue);
          const totalPages = Math.ceil(response.totalJobs / 10);
          setJobList(response.jobs);
          setTotalPages(totalPages);
        } catch (error) {
          toast.error('Error Fetching Jobs ' + error, { position: 'bottom-center' });
          setJobList([]);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      };
      fetchJobList();
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchTerm, generateType, pageNumber]);

  const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange = (selectedValue: SingleValue<SelectOptions>) => {
    setGenerateType(selectedValue);
  };

  return (
    <div
        className={`${commonStyles.container}`}
    >
      <div
          className={`${styles.header}`}
      >
        <PrimaryTypography.FiveXLarge
            fontWeight='bold'
            keepDefaultMargins={true}
        >
          Content Manager
        </PrimaryTypography.FiveXLarge>
      </div>
      <div
          className={`${commonStyles.contentContainer}`}
      >
        <div className='flex gap-6 items-center pb-5'>
          <div className='w-3/4'>
            <ContextSearch
                searchTerm={searchTerm}
                handleChange={handleSearchTermChange}
            />
          </div>
          <div className='w-1/4'>
            <Select
                placeholder='Select Generation Type'
                name='generationType'
                options={generateTypeList}
                onChange={handleSelectChange}
                isClearable={true}
            />
          </div>
        </div>
        { /* TODO: implement search bar */ }
        <DocumentTriage
            loading={loading}
            documents={jobList}
            setDocuments={setJobList}
        />
        <div className='py-4'>
          <PaginationController
              pageNumber={pageNumber}
              maxPageNumber={totalPages}
              navigateNextPage={() => setPageNumber((pageNumber) => pageNumber + 1)}
              navigatePreviousPage={() => setPageNumber((pageNumber) => pageNumber - 1)}
          />
        </div>
      </div>
    </div>
  );
};
