import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import loader from '../../../assets/images/graphics/loader.gif';
import { isUserVerified } from '../../../utils/api_service/user_api';
import styles from '../verify_email.module.css';
import { FailureNotice } from './notices/failure_notice';
import { SuccessNotice } from './notices/success_notice';

type RequestStatus = 'Success' | 'Failed' | 'Pending';

export const ConfirmNotice = () => {
  const history = useHistory();
  const { token } = useParams<{token: string}>();
  const [status, setStatus] = useState<RequestStatus>('Pending');

  const navigateToSignIn = () => history.push('/login');

  useEffect(() => {
    isUserVerified(token)
      .then(response => {
        if (response.ok) {
          setStatus('Success');
        } else {
          setStatus('Failed');
        }
      })
      .catch(error => {
        if (error) {
          setStatus('Failed');
        }
      });
  }, [token]);

  const Notice = () => {
    if (status === 'Pending') {
      return (
        <img
            src={loader}
            alt='Pending'
            width='500px'
        />
      );
    } else if (status === 'Success') {
      return <SuccessNotice navigateToSignIn={navigateToSignIn}/>;
    } else {
      return <FailureNotice/>;
    }
  };

  return (
    <div
        className={styles.pageContainer}
    >
      <Notice/>
    </div>
  );
};
