import classNames from 'classnames';
import React from 'react';
import { ChevronLeft, ChevronRight } from '../icons/icons';
import { SecondaryTypography } from '../typography/typography';
import styles from './pagination_controller.module.css';

type PaginationControllerProps = {
  pageNumber: number,
  maxPageNumber: number,
  navigateNextPage: () => void,
  navigatePreviousPage: () => void,
}

export const PaginationController = ({
  pageNumber,
  maxPageNumber,
  navigateNextPage,
  navigatePreviousPage,
}: PaginationControllerProps) => (
  <div
      className={styles.controllerContainer}
  >
    <div
        className={styles.controller}
    >
      <div
          className={classNames(styles.navigationElement, styles.navigationButton, { [styles.disabled]: pageNumber === 1 })}
          onClick={navigatePreviousPage}
      >
        <ChevronLeft/>
        <SecondaryTypography.Small>
          Previous
        </SecondaryTypography.Small>
      </div>
      <div
          className={styles.navigationElement}
      >
        <SecondaryTypography.Small
            fontWeight='semi-bold'
        >
          { maxPageNumber !== 0 ? pageNumber : 0 } of { maxPageNumber }
        </SecondaryTypography.Small>
      </div>
      <div
          className={classNames(styles.navigationElement, styles.navigationButton, { [styles.disabled]: pageNumber === maxPageNumber })}
          onClick={navigateNextPage}
      >
        <SecondaryTypography.Small>
          Next
        </SecondaryTypography.Small>
        <ChevronRight/>
      </div>
    </div>
  </div>
);
