import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLevelList } from '../../api_service/level_api';
import { SkillLevel } from '../../types/assessment_types';
import { SelectOptions } from '../../types/react_select_types';

export const fetchSkillLevels = createAsyncThunk('skillLevels/fetchSkillLevels', async () => {
  return await getLevelList();
});

export interface SkillLevelState {
    levelList: SelectOptions[],
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
  levelList: [],
  loading: 'idle',
} as SkillLevelState;

export const skillLevelSlice = createSlice({
  name: 'levelList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkillLevels.pending, (state) => {
        return { ...state, loading: 'pending' };
      })
      .addCase(fetchSkillLevels.fulfilled, (state, action) => {
        let levelListArray: SelectOptions[] = [];
        if (action.payload) {
          action.payload.map((item: SkillLevel) => {
            levelListArray.push({
              value: item.id,
              label: item.levelName,
            });
          });
        }
        return { ...state, levelList: levelListArray, loading: 'succeeded' };
      });
  },
});

export default skillLevelSlice.reducer;
