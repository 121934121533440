import React, { useEffect, useState } from 'react';
import { PrimaryTypography } from '../../../../ui/typography/typography';
import { AssessmentsSearchObject } from '../../../../utils/types/assessment_types';
import { Tabs } from '../tabs/tabs';
import { AssessmentListTable } from './assessment_list_table/assessment_list_table';

export const AssessmentList = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabTitles = ['Active Assessments', 'In-Active Assessments'];
  const [searchObject, setSearchObject] = useState<AssessmentsSearchObject>({
    assessmentName: '',
    page: 1,
    limit: 10,
    active: true,
  });

  useEffect(() => {
    setSearchObject({
      ...searchObject,
      assessmentName: '',
      page: 1,
      active: activeTab === 0 ? true : false,
    });
  }, [activeTab]);

  const renderTable = () => {
    return (
      <AssessmentListTable
          activeTab={activeTab}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
      />
    );
  };

  return (
    <div
        className='w-full min-h-screen p-4'
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Assessments
      </PrimaryTypography.FiveXLarge>
      <div
          className='bg-white shadow-xl px-8 pt-4 pb-8 rounded mt-4'
      >
        <Tabs
            tabTitles={tabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
        { renderTable() }
      </div>
    </div>
  );
};
