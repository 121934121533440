import { configureStore } from '@reduxjs/toolkit';
import AuthPermissionsReducer from './features/auth_permissions_reducer';
import jobTitleReducer from './features/job_title_reducer';
import skillLevelReducer from './features/level_reducer';
import organizationUserReducer from './features/organization_user_reducer';
import questionReducer from './features/question_reducer';
import rolesReducer from './features/roles_reducer';
import skillsReducer from './features/skill_reducer';
import subscriptionReducer from './features/subscription_reducer';
import subSkillsReducer from './features/subskill_reducer';
import userGroupReducer from './features/user_group_reducer';
import userRoleReducer from './features/user_role_reducer';
import viewReducer from './features/ViewReducer';

export const store = configureStore({
  reducer: {
    questions: questionReducer,
    skills: skillsReducer,
    skillLevels: skillLevelReducer,
    roles: rolesReducer,
    subSkills: subSkillsReducer,
    userRoles: userRoleReducer,
    userGroups: userGroupReducer,
    organizationUsers: organizationUserReducer,
    permissions: AuthPermissionsReducer,
    jobTitles: jobTitleReducer,
    subscriptions: subscriptionReducer,
    view: viewReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
