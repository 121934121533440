import { SetStateAction, createContext } from 'react';
import { jobOpeningTypes } from '../types/JobOpenings';

interface IJobOpeningsContext {
  jobOpeningElements: jobOpeningTypes[],
  setJobOpeningElements?: SetStateAction<any>,
}

export const DefaultJobOpeningsState = {
  jobOpeningElements: [],
};

export const JobOpeningsContext = createContext<IJobOpeningsContext>(DefaultJobOpeningsState);
