import React, { useMemo  } from 'react';
import { Button } from '../../../../../../ui/button/button';
import { PrimaryTypography, SecondaryTypography } from '../../../../../../ui/typography/typography';
import { Product, SubscriptionFeatureDetails } from '../../../../../../utils/types/admin_types';

type FeatureCardProps = {
    product: Product,
    companySize: 'Small' | 'Medium' | 'Large',
    interval: 'month' | 'year',
    currentSubscription: SubscriptionFeatureDetails | undefined,
    img: string,
    description: string,
    cardDetailsUnavailable: boolean,
    isTrialUsed: boolean,
    addSubscription: (priceId: string, trial: boolean) => void,
    cancelSubscription: (featureType: string, cancel: boolean) => void,
}

export const FeatureCard = ({
  product,
  companySize,
  interval,
  currentSubscription,
  img,
  description,
  cardDetailsUnavailable,
  isTrialUsed,
  addSubscription,
  cancelSubscription,
}: FeatureCardProps) => {
  const selectedPrice = useMemo(() => {
    if (!product.prices) {
      return null;
    }

    return product.prices.find(
      (price) => price.companySize === companySize && price.interval === interval,
    );
  }, [product, companySize, interval]);

  const getTextStatusColors = (status: string) => {
    switch (status) {
      case 'active':
        return 'text-lime-700';
      case 'trialing':
        return 'text-yellow-600';
      default:
        return 'text-rose-800';
    }
  };

  const getColoredBorders = (status: string) => {
    switch (status) {
      case 'active':
        return 'border-lime-700';
      case 'trialing':
        return 'border-yellow-600';
      case 'no-activity':
        return '';
      default:
        return 'border-rose-800';
    }
  };

  return (
    <div
        className={`flex flex-col w-1/4 border border-4 rounded ${getColoredBorders(currentSubscription?.status || 'no-activity')}`}
    >
      <div
          className='flex flex-col items-center p-5'
      >
        <PrimaryTypography.FiveXLarge
            fontWeight='bold'
            alignment='center'

        >
          { product.feature } Performance
        </PrimaryTypography.FiveXLarge>
        <img
            src={img}
            alt={product.feature + 'image'}
            width='210px'
        />
      </div>
      <div
          className='mx-5 my-2'
      >
        <SecondaryTypography.Small>
          { description }
        </SecondaryTypography.Small>
        <div
            className='my-2'
        >
          {  currentSubscription &&
            <>
              <SecondaryTypography.Small
                  fontWeight='semi-bold'
              >
                <b>Status</b> - <span className={getTextStatusColors(currentSubscription.status)}>{ currentSubscription.status.charAt(0).toUpperCase() + currentSubscription.status.slice(1) }</span>
              </SecondaryTypography.Small>
              <SecondaryTypography.Small>
                <b>Start</b> - { new Date(currentSubscription.currentPeriodStart * 1000).toLocaleDateString() }
              </SecondaryTypography.Small>
              <SecondaryTypography.Small>
                <b>End</b>- { new Date(currentSubscription.currentPeriodEnd * 1000).toLocaleDateString() }
              </SecondaryTypography.Small>
              { currentSubscription.cancelAt &&
                <SecondaryTypography.Small>
                  <b>Cancelled At</b> - { new Date(currentSubscription.cancelAt * 1000).toLocaleDateString() }
                </SecondaryTypography.Small>
              }
            </>
          }
          <SecondaryTypography.Small>
            <b>Current Plan</b> - USD { selectedPrice && selectedPrice.unitAmount }
          </SecondaryTypography.Small>
        </div>
      </div>
      <div
          className='flex flex-row items-center justify-center bg-slate-100 mt-auto'
      >
        <div
            className='flex flex-row'
        >
          { !currentSubscription ?
            <>
              { !isTrialUsed &&
                <Button
                    variant='success'
                    onClick={() => selectedPrice && addSubscription(selectedPrice.priceId, true)}
                    size='xSmall'
                >
                  Try Now
                </Button>
              }
              <Button
                  variant='primary'
                  onClick={() => selectedPrice && addSubscription(selectedPrice.priceId, false)}
                  size='xSmall'
                  disabled={cardDetailsUnavailable}
              >
                Subscribe
              </Button>
            </>
            :
            <>
              { currentSubscription && currentSubscription.cancelAt ?
                <Button
                    variant='primary'
                    onClick={() => cancelSubscription(product.feature, false)}
                    size='xSmall'
                    disabled={cardDetailsUnavailable}
                >
                  Renew
                </Button>
                :
                <Button
                    variant='danger'
                    onClick={() => cancelSubscription(product.feature, true)}
                    size='xSmall'
                >
                  Cancel
                </Button>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
};
