import React, { FC } from 'react';
import { PrimaryTypography } from '../../../../../../ui/typography/typography';
import styles from './assessment_instructions_modal.module.css';
import fontStyles from './instructionSection.module.css';

type InstructionSectionProps = {
  instructionText?: string,
  instructionStepText?: string,
  instructionImgSrc?: string,
}

export const InstructionSection: FC<InstructionSectionProps> = ({
  instructionText,
  instructionStepText,
  instructionImgSrc,
}) => (
  <div>
    <PrimaryTypography.FiveXLarge
        fontWeight='bold'
    >
      { instructionText }
    </PrimaryTypography.FiveXLarge>
    <p className={fontStyles.instructionStep} dangerouslySetInnerHTML={{ __html: String(instructionStepText) }} />
    <div
        className={styles.graphicsContainer}
    >
      <img
          src={instructionImgSrc}
          alt='mic-and-video'
          width='400px'
      />
    </div>
  </div>
);
