import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { SecondaryTypography } from '../../typography/typography';
import styles from '../input.module.css';

type TextAreaProps = {
    label?: string,
    name: string,
    value: string,
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
    disabled?: boolean,
    customClass?: string,
}

export const TextArea = ({
  label,
  name,
  value,
  onChange,
  disabled,
  customClass,
}: TextAreaProps) => (
  <div>
    { label &&
      <label
          className={styles.labelStyles}
      >
        <SecondaryTypography.XSmall
            fontWeight='semi-bold'
        >
          { label }
        </SecondaryTypography.XSmall>
      </label>
    }
    <textarea
        className={classNames(styles.inputStyles, customClass)}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
    />
  </div>
);
