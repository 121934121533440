import { StylesConfig } from 'react-select';
import { SelectOptions } from '../../utils/types/react_select_types';

export const customStyles: StylesConfig<SelectOptions | unknown, false> = {
  control: (base, state) => ({
    ...base,
    borderRadius: '6px',
    border: '1px solid',
    borderColor: state.isFocused ? '#29AAE2 !important' : '#E5E5E5 !important',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '3px 4px',
  }),

  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#29AAE2 !important' : base.backgroundColor,
  }),

  container: (base) =>( {
    ...base,
    marginTop: '3px',
  }),
};
