import classNames from 'classnames';
import React, { useState } from 'react';
import { ChevronRight } from '../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { ContextBasedGeneratorJob, QuestionGeneratorJob, TopicBasedGeneratorJob } from '../../../../../utils/types/content_manager_types';
import { TriageRow } from '../triage_row/triage_row';
import styles from './document.module.css';
import { DocumentDetails } from './document_details/document_details';

export type DocumentProps = {
  job: TopicBasedGeneratorJob | ContextBasedGeneratorJob,
  updateJob: (newJobData: QuestionGeneratorJob) => void;
}

export const Document = ({
  job,
  updateJob,
}: DocumentProps) => {
  const [open, setOpen] = useState(false);

  const toggleDetails = () => {
    if (('transcript' in job && job.transcript && job.transcript.length > 0) || (job.topic && job.topic.length > 0) ) {
      setOpen(!open);
    }
  };

  return (
    <>
      <div
          className={classNames(
            styles.document, {
              [styles.open]: open,
            },
          )}
      >
        <TriageRow>
          <div
              className={styles.titleContainer}
          >
            { /* This is a bit of hack since the toggle button isn't a part of the title field, but having it in it's own
               field will put it out of alignment with everything else */ }
            <div
                className={styles.toggleBtnContainer}
            >
              <button
                  className={styles.toggleBtn}
                  onClick={toggleDetails}
              >
                <ChevronRight/>
              </button>
            </div>
            <div
                className={styles.contentContainer}
            >
              <SecondaryTypography.Small
                  className={styles.title}
              >
                { job.title }
              </SecondaryTypography.Small>
            </div>
          </div>
          <>
            <SecondaryTypography.Small
                className={styles.uploadDate}
            >
              { job.creationDateTime!== 0 ? new Date(job.creationDateTime * 1000).toUTCString() : 'Unavailable' }
            </SecondaryTypography.Small>
          </>
          <>
            { job.status.split('.').join('') }
          </>
          <>
            { `${job.user?.firstName} ${job.user?.lastName}` }
          </>
        </TriageRow>
      </div>
      { open &&
      <DocumentDetails
          job={job}
          updateJob={updateJob}
      />
      }
    </>
  );
};
