import { SelectOptions } from '../types/react_select_types';

export const assessmentTypes : Array<SelectOptions> = [
  {
    value: 'employee',
    label: 'Employees',
  },
  {
    value: 'candidate',
    label: 'Job Candidates',
  },
  // {
  //   value: 'partners',
  //   label: 'Partners',
  // },
];
