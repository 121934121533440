import classNames from 'classnames';
import React from 'react';
import { Button } from '../../../../../../ui/button/button';
import { ClockIcon, OpenLockIcon } from '../../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { convertSecondsToTimeTaken } from '../../../../../../utils/helpers/time';
import { UserAssessment } from '../../../../../../utils/types/assessment_types';
import commonStyles from '../../assigned_assessments.module.css';
import styles from './selected_assessment_modal.module.css';

type SelectedAssessmentModalProps = {
    selectedAssessment: UserAssessment | undefined,
    setModalContent: (content: number) => void,
}

export const SelectedAssessmentModal = ({
  selectedAssessment,
  setModalContent,
}: SelectedAssessmentModalProps) => {
  return (
    <div
        className={commonStyles.modalContentContainer}
    >
      <SecondaryTypography.Small
          className={commonStyles.greyText}
          keepDefaultMargins={true}
      >
        { selectedAssessment && selectedAssessment.assessment.description }
      </SecondaryTypography.Small>
      <div
          className={styles.assessmentInfoContainer}
      >
        <div
            className={classNames(commonStyles.iconInfoContainer, commonStyles.darkBlueText)}
        >
          <div
              className={classNames(commonStyles.iconClass, styles.lockIconGradient)}
          >
            <OpenLockIcon/>
          </div>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
          >
            Total: { selectedAssessment && selectedAssessment.assessment.totalScore } points
          </SecondaryTypography.Small>
        </div>
        <div
            className={classNames(commonStyles.greyText, commonStyles.iconInfoContainer)}
        >
          <div
              className={classNames(commonStyles.iconClass, styles.lockIconGradient)}
          >
            <ClockIcon/>
          </div>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
          >
            Duration: { selectedAssessment && selectedAssessment.assessment.totalTime && convertSecondsToTimeTaken(selectedAssessment.assessment.totalTime) } minutes
          </SecondaryTypography.Small>
        </div>
      </div>
      { /*<SecondaryTypography.Small*/ }
      { /*    className={commonStyles.greyText}*/ }
      { /*    keepDefaultMargins={true}*/ }
      { /*>*/ }
      { /*  You can take practise the tutorial before proceeding to the actual assessment. These marks will not be taken into consideration.*/ }
      { /*</SecondaryTypography.Small>*/ }
      <SecondaryTypography.Small
          className={commonStyles.greyText}
          keepDefaultMargins={true}
      >
        Disclaimer: During the process of the assessment your browser actions will be monitored.
      </SecondaryTypography.Small>
      <SecondaryTypography.Small
          className={commonStyles.redText}
      >
        Warning: Once you start the assessment, you must complete it and you cannot re-take it again.
      </SecondaryTypography.Small>
      <div
          className={styles.buttonContainer}
      >
        { /*<Button*/ }
        { /*    onClick={() => {}}*/ }
        { /*    variant='primary'*/ }
        { /*    size='medium'*/ }
        { /*    customClass={commonStyles.assessmentModalButton}*/ }
        { /*>*/ }
        { /*  Practice Tutorial*/ }
        { /*</Button>*/ }
        <Button
            onClick={() => setModalContent(2)}
            variant='secondary'
            size='medium'
            customClass={commonStyles.assessmentModalButton}
        >
          Start Assessment
        </Button>
      </div>
    </div>
  );
};
