import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import { RootState } from '../../../utils/redux_store/store';
import { Tabs } from '../../assessment/view/tabs/tabs';
import baseStyles from '../admin.module.css';
import { JobTitleManagement } from './JobTitleManagement';
import { SkillsManagement } from './SkillsManagement';

export const AssessmentFramework = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabTitles = ['Skills', 'Job Titles'];
  const skills = useSelector((state: RootState) => state.skills);
  const subSkillList = useSelector((state: RootState) => state.subSkills.subSkillsList);
  const jobTitles = useSelector((state: RootState) => state.jobTitles.jobTitlesList);

  const renderTabContent = () => {
    if (activeTab === 0 && skills.loading === 'succeeded') {
      return <SkillsManagement skills={skills.skillsList} subSkillList={subSkillList}/>;
    } else if (activeTab === 1)  {
      return <JobTitleManagement jobTitles={jobTitles}/>;
    }
  };

  return (
    <div
        className={baseStyles.container}
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Admin
      </PrimaryTypography.FiveXLarge>
      <div
          className={baseStyles.contentContainer}
      >
        <SecondaryTypography.Medium
            keepDefaultMargins={true}
            fontWeight='bold'
        >
          Assessment Framework
        </SecondaryTypography.Medium>
        <div
            className='flex flex-row gap-3'
        >
          <SecondaryTypography.Small
              fontWeight='semi-bold'
          >
            { skills.skillsList.length } x Skills
          </SecondaryTypography.Small>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
          >
            { subSkillList.length } x Sub-Skills
          </SecondaryTypography.Small>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
          >
            { jobTitles.length } x Job-Titles
          </SecondaryTypography.Small>
        </div>
        <Tabs
            tabTitles={tabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
        { renderTabContent() }
      </div>
    </div>
  );
};
