import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import AsyncCustomSelect from '../../../../../ui/custom_react_select/async_select';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { MultiSelectListItem } from '../../../../../ui/data_source_filter/MultiSelectListItem';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { getAssessmentUsers, setAssessmentEmployeePartnerQuery } from '../../../../../utils/api_service/assessment_api';
import { getPartnerEmployees, getPartnerList, partnerEmployeeSearch } from '../../../../../utils/api_service/partner_api';
import { debounce } from '../../../../../utils/helpers/debounce';
import { OrganizationUser, PartnerEntity, PartnerUser } from '../../../../../utils/types/admin_types';
import { FormattedSelectOptions } from '../../../../../utils/types/analytics/analytics_types';
import { AssessmentUser, PartnerQueryFilter, UserAssessmentResponse } from '../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import commonStyles from '../../assessment_list/assessment_list_table/assessment_list_table.module.css';
import styles from './PartnerAssessmentUsers.module.css';

type PartnerAssessmentUsersProps = {
  assessmentId: string,
  query: any,
}

export const PartnerAssessmentUsers = ({
  assessmentId,
  query,
}: PartnerAssessmentUsersProps) => {
  const [partnerList, setPartnerList] = useState<PartnerUser[]>([]);
  const [partnerValues, setPartnerValues] = useState<PartnerEntity[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<AssessmentUser[]>([]);
  const [partnerEntityOptions, setPartnerEntityOptions] = useState<SelectOptions[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<FormattedSelectOptions[]>([]);
  const [selectedPartners, setSelectedPartners] = useState<SelectOptions[]>([]);
  const [queryObject, setQueryObject] = useState<PartnerQueryFilter>({
    partnerEntityIds: query.partnerEntityIds,
    includedPartnerEmployees: query.includedPartnerEmployees.map((partner: any) => partner.id),
  });

  useEffect(() => {
    const predefinedPartners = query.includedPartnerEmployees;
    const transformedPartners = predefinedPartners.map((partner: any) => ({
      value: partner.id,
      label: partner.user.firstName + ' ' + partner.user.lastName,
    }));
    setSelectedPartners(transformedPartners);
    const fetchPartnerEmployees = async () => {
      try {
        const response = await getPartnerEmployees();
        const data = await response.json();
        if (data && data.partner){
          setPartnerList(data.partner);
        }
      } catch (error) {
        toast.error('Error fetching partners: ' + error, { position: 'bottom-center' });
      }
    };
    fetchPartnerEmployees();

    const fetchPartners = async () => {
      try {
        const response = await getPartnerList({ subEntities: true });
        if (response.ok) {
          const data = await response.json();
          setPartnerValues(data);
          const entityList = getFormattedEntityList(data);
          const result: SelectOptions[] = [];
          const entityResult: FormattedSelectOptions[] = [];
          const partnerEntityIds: string[] = query.partnerEntityIds;
          partnerEntityIds.forEach((id) => {
            const item = entityList.find((element) => element.value === id);
            if (item) {
              result.push({ value: item.value, label: item.label });
              entityResult.push({ value: item.value, label: item.label, level: item.level });
            }
          });
          setSelectedEntities(entityResult);
          setPartnerEntityOptions(result);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching products: ' + error, { position: 'bottom-center' });
      }

    };
    fetchPartners();

    getAssessmentUsers(assessmentId).then(response => {
      if (response.length > 0) {
        let assigned: AssessmentUser[] = [];
        response.map((item: UserAssessmentResponse) => {
          let assessmentUser: AssessmentUser = {
            assessmentUserId: item.id,
            userId: item.userId,
            firstName: item.user.firstName,
            lastName: item.user.lastName,
            email: item.user.email,
            score: item.score,
            status: item.status,
            enabled: item.enabled,
          };
          assigned = [...assigned, assessmentUser];
        });
        setAssignedUsers(assigned);
      }
    });
  },[]);

  const getValuesFromSelectOptions = (options: SelectOptions[]): string[] => {
    return options.map(option => option.value);
  };

  const handleIncludedPartnerEmployeesChange = (selectedOptions: SelectOptions[]) => {
    setSelectedPartners(selectedOptions);
    const newQueryObject: PartnerQueryFilter = {
      ...queryObject,
      includedPartnerEmployees: getValuesFromSelectOptions(selectedOptions),
    };
    setQueryObject(newQueryObject);
    getQueryResults(newQueryObject);
  };

  const handlePartnerEntityIdsChange = (selectedOptions: FormattedSelectOptions[]) => {
    setSelectedEntities(selectedOptions);
    const newQueryObject: PartnerQueryFilter = {
      ...queryObject,
      partnerEntityIds: getValuesFromSelectOptions(selectedOptions),
    };
    setQueryObject(newQueryObject);
    getQueryResults(newQueryObject);
  };

  const formattedOrgListOptions = useMemo(() => {
    const formattedOrgList: FormattedSelectOptions[] = [];
    const processPartnerEntity = (level: number, partnerEntity?: PartnerEntity) => {
      if (!partnerEntity) return;
      formattedOrgList.push({ value: partnerEntity.id, label: partnerEntity.name, level });
      for (const org of partnerEntity.subEntities) {
        processPartnerEntity(level + 1, org);
      }
    };
    for (const partnerEntity of partnerValues) {
      processPartnerEntity(0, partnerEntity);
    }
    const removeIncludeUsers = selectedEntities.map((entity: SelectOptions) => entity.value);
    const finalOptions = formattedOrgList.filter((option) => !removeIncludeUsers.includes(option.value));

    return finalOptions;
  }, [selectedEntities]);

  const partnerOptions = useMemo(() => {
    const mappedOptions = partnerList.map((partner: PartnerUser) => ({
      value: partner.id,
      label: partner.user.firstName + ' ' + partner.user.lastName,
    }));

    return mappedOptions;
  }, [partnerList]);

  const getFormattedEntityList = (partnerEntities: PartnerEntity[]): FormattedSelectOptions[] => {
    const formattedOrgList: FormattedSelectOptions[] = [];
    const processPartnerEntity = (level: number, partnerEntity?: PartnerEntity) => {
      if (!partnerEntity) return;
      formattedOrgList.push({ value: partnerEntity.id, label: partnerEntity.name, level });
      for (const org of partnerEntity.subEntities) {
        processPartnerEntity(level + 1, org);
      }
    };
    for (const partnerEntity of partnerEntities) {
      processPartnerEntity(0, partnerEntity);
    }
    const removeIncludeUsers = partnerEntityOptions.map((employee: SelectOptions) => employee.value);
    const finalOptions = formattedOrgList.filter((option) => !removeIncludeUsers.includes(option.value));

    return finalOptions;
  };

  const partnerLoadOptions = async (query: string) => {
    const searchParams: { page: number; recordsPerPage: number; query: string } = {
      page: 1,
      recordsPerPage: 10,
      query: query,
    };
    try {
      const response = await getPartnerEmployees(searchParams);
      if (response.ok) {
        const data = await response.json();
        return data.partner.map((partner: PartnerUser) => ({
          value: partner.id,
          label: `${partner.user.firstName} ${partner.user.lastName}`,
        }));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error fetching partners: ' + error, { position: 'bottom-center' });
      return [];
    }
  };

  const getQueryResults = async (query: PartnerQueryFilter) => {
    try {
      const response = await partnerEmployeeSearch(query);
      if (response.ok) {
        const data = await response.json();
        if (data.partnerEmployees.length > 0) {
          let assigned: AssessmentUser[] = [];
          data.partnerEmployees.map((orgUser: OrganizationUser) => {
            let partnerUser: AssessmentUser = {
              assessmentUserId: '',
              userId: orgUser.id,
              firstName: orgUser.user.firstName,
              lastName: orgUser.user.lastName,
              email: orgUser.user.email,
              score: 0,
              status: 'Assigned',
              enabled: true,
            };
            assigned = [...assigned, partnerUser];
          });
          setAssignedUsers(assigned);
        } else {
          setAssignedUsers([]);
        }
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error getting query data: ' + error, { position: 'bottom-center' });
    }
  };

  const loadOptionsDebounced =  debounce((inputValue: string, callback: (options: any) => void) => {
    partnerLoadOptions(inputValue).then(options => callback(options));
  }, 500);

  const saveQuery = async () => {
    let query: PartnerQueryFilter = {
      ...queryObject,
      assessmentId: assessmentId,
    };
    try{
      const response = await setAssessmentEmployeePartnerQuery(query);
      if (response.ok) {
        const data = await response.json();
        if (data.id) {
          try {
            getAssessmentUsers(data.id).then(response => {
              if (response.length > 0) {
                let assigned: AssessmentUser[] = [];
                response.map((item: UserAssessmentResponse) => {
                  let assessmentUser: AssessmentUser = {
                    assessmentUserId: item.id,
                    userId: item.userId,
                    firstName: item.user.firstName,
                    lastName: item.user.lastName,
                    email: item.user.email,
                    score: item.score,
                    status: item.status,
                    enabled: item.enabled,
                  };
                  assigned = [...assigned, assessmentUser];
                });
                setAssignedUsers(assigned);
              } else {
                setAssignedUsers([]);
              }
            });
          } catch (error) {
            toast.error('Error retrieving results : ' + error, { position: 'bottom-center' });
          }
        }
      }
    } catch (error) {
      toast.error('Error setting assessment query : ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <>
      { query.partnerEntityIds.length === partnerEntityOptions.length &&
        <div
            className={styles.inputContainer}
        >
          <CustomSelect
              label='Select Partner Entity'
              name='partnerEntityId'
              options={formattedOrgListOptions}
              onChange={handlePartnerEntityIdsChange}
              clearable={true}
              components={{ Option: MultiSelectListItem }}
              isMulti
              value={partnerEntityOptions}
          />
          <AsyncCustomSelect
              value={selectedPartners}
              defaultOptions={partnerOptions}
              onChange={handleIncludedPartnerEmployeesChange}
              loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
              isMulti
              label={'Included Partner Employees'}
          />
          <Button
              onClick={saveQuery}
              variant='primary'
              size='medium'
          >
            Save Query
          </Button>
        </div>
      }
      { assignedUsers.length > 0 &&
      <table
          className={commonStyles.tableStyle}
      >
        <thead>
          <tr
              className={commonStyles.tableHeader}
          >
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Partner Id
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Name
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Email
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Score
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Status
              </SecondaryTypography.XSmall>
            </th>
          </tr>
        </thead>
        <tbody>
          { assignedUsers.map((partner) => (
            <tr
                className={styles.tableRow}
                key={partner.userId}
            >
              <td>
                <SecondaryTypography.XSmall
                    alignment='center'
                >
                  0012
                </SecondaryTypography.XSmall>
              </td>
              <td>
                <SecondaryTypography.XSmall
                    alignment='center'
                >
                  { `${partner.firstName} ${partner.lastName}` }
                </SecondaryTypography.XSmall>
              </td>
              <td>
                <SecondaryTypography.XSmall
                    alignment='center'
                >
                  { partner.email }
                </SecondaryTypography.XSmall>
              </td>
              <td>
                <SecondaryTypography.XSmall
                    alignment='center'
                >
                  { partner.score }
                </SecondaryTypography.XSmall>
              </td>
              <td>
                <SecondaryTypography.XSmall
                    alignment='center'
                    textCase='capitalize'
                    fontWeight='bold'
                    className={'text-sky-500'}
                >
                  { partner.status }
                </SecondaryTypography.XSmall>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
      }
    </>
  );
};
