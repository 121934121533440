import React from 'react';

export function handleCheckboxChange(entityAction: string, checked: boolean, selectedActions: string[], setSelectedActions: React.Dispatch<React.SetStateAction<string[]>>) {
  setSelectedActions((prevSelectedActions) => {
    if (checked) {
      return [...prevSelectedActions, entityAction];
    } else {
      return prevSelectedActions.filter((ea) => ea !== entityAction);
    }
  });
}

export function handleHeaderCheckboxChange(action: string, checked: boolean, entities: string[], selectedActions: string[], setSelectedActions: React.Dispatch<React.SetStateAction<string[]>>) {
  setSelectedActions((prevSelection) => {
    if (checked) {
      return [
        ...prevSelection,
        ...entities.map((entity) => `${entity}:${action}`),
      ];
    } else {
      return prevSelection.filter(
        (ea) => !entities.some((entity) => ea.startsWith(`${entity}:${action}`)),
      );
    }
  });
}
