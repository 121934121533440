import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { AddIcon, RefreshIcon } from '../../../../ui/icons/icons';
import { Modal } from '../../../../ui/modal/modal';
import { PaginationController } from '../../../../ui/pagination_controller/pagination_controller';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { addUserGroup, deleteUserGroup, editUserGroup } from '../../../../utils/api_service/user_groups';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import {
  addUserGroupRedux,
  deleteUserGroupRedux,
  editUserGroupRedux,
  fetchUserGroups,
} from '../../../../utils/redux_store/features/user_group_reducer';
import { RootState, store } from '../../../../utils/redux_store/store';
import { UserGroup } from '../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import baseStyles from '../../admin.module.css';
import commonStyles from '../access_management.module.css';
import { AdminSearchBar } from '../admin_search_bar/admin_search_bar';
import { GroupRow } from './group_row/group_row';
import { AddGroupModal } from './modals/add_group_modal';
import { EditGroupModal } from './modals/edit_group_modal';
import { useGroupSearch } from './useGroupSearch';

export const GroupManagement = () => {
  const userGroups = useSelector((state: RootState) => state.userGroups.userGroupList);
  const userRole = useSelector((state: RootState) => state.userRoles.userRoleList);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editedGroup, setEditedGroup] = useState<UserGroup>(userGroups[0]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { paginatedGroups, totalPages } = useGroupSearch(userGroups, searchTerm, pageNumber, 8);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPageNumber(1);
  };

  const addGroup = async (groupName: string, roles: SelectOptions[]) => {
    const roleList = roles.map(role => role.value);
    try {
      const response = await addUserGroup(groupName, roleList);
      if (response.ok) {
        const data = await response.json();
        setAddModalVisible(false);
        const updatedGroup = {
          id: data.id,
          organizationId: data.organizationId,
          groupName: groupName,
          roles: userRole.filter((item) => roleList.includes(item.id)),
        };
        store.dispatch(addUserGroupRedux(updatedGroup));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error adding group: ' + error, { position: 'bottom-center' });
    }
  };

  const deleteGroup = async (id: string) => {
    try {
      const response = await deleteUserGroup(id);
      if (response.ok) {
        store.dispatch(deleteUserGroupRedux(id));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error deleting group: ' + error, { position: 'bottom-center' });
    }
  };

  const openEditModal = (group: UserGroup) => {
    setEditedGroup(group);
    setEditModalVisible(true);
  };

  const editGroup = async (group: UserGroup, groupName: string, roles: SelectOptions[]) => {
    const roleList = roles.map(role => role.value);
    try {
      const response = await editUserGroup(group.id, groupName, roleList);
      if (response.ok) {
        setEditModalVisible(false);
        const updatedGroup = {
          ...group,
          groupName: groupName,
          roles: userRole.filter((item) => roleList.includes(item.id)),
        };
        store.dispatch(editUserGroupRedux(updatedGroup));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error editing group: ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <>
      <div
          className={commonStyles.searchContainer}
      >
        <AdminSearchBar
            searchTerm={searchTerm}
            handleChange={handleSearchTermChange}
        />
        <div
            className={classNames(commonStyles.flexRowContainer, commonStyles.iconGap, commonStyles.blueText)}
            onClick={() => store.dispatch(fetchUserGroups())}
        >
          <div
              className={commonStyles.icons}
          >
            <RefreshIcon/>
          </div>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
              textCase='capitalize'
          >
            Refresh
          </SecondaryTypography.Small>
        </div>
        <PermissionGate
            requiredPermissions={[Permission.GROUPS_CREATE]}
        >
          <Button
              icon={<AddIcon/>}
              onClick={() => setAddModalVisible(true)}
              variant='primary'
              size='medium'
          >
            Add Group
          </Button>
        </PermissionGate>
      </div>
      <table
          className={baseStyles.tableStyle}
      >
        <thead>
          <tr>
            <th
                style={{ width: 0 }}
            >
              <input type='checkbox'/>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='left'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Group Name
              </SecondaryTypography.XSmall>
            </th>
          </tr>
        </thead>
        <tbody>
          { paginatedGroups.map((group: UserGroup) => {
            return (
              <GroupRow
                  key={group.id}
                  group={group}
                  deleteGroup={deleteGroup}
                  onEditClick={openEditModal}
              />
            );
          })
          }
        </tbody>
      </table>
      <PaginationController
          pageNumber={pageNumber}
          maxPageNumber={totalPages}
          navigateNextPage={() => setPageNumber((pageNumber) => pageNumber + 1)}
          navigatePreviousPage={() => setPageNumber((pageNumber) => pageNumber - 1)}
      />
      <Modal
          visible={addModalVisible}
          modalContent={<AddGroupModal userRoleList={userRole} addGroup={addGroup}/>}
          toggle={() => setAddModalVisible(false)}
          headerText='Add Group'
          headerClass={baseStyles.modalHeader}
      />
      <Modal
          visible={editModalVisible}
          modalContent={<EditGroupModal group={editedGroup} userRoleList={userRole} editGroup={editGroup}/>}
          toggle={() => setEditModalVisible(false)}
          headerText='Edit Roles in Group'
          headerClass={baseStyles.modalHeader}
      />
    </>
  );
};
