import React, { Children, FC } from 'react';
import styles from './triage_row.module.css';

export const TriageRow: FC = ({ children }) => (
  <div
      className={styles.rowContainer}
  >
    { Children.map(children, child => (
      <div
          className={styles.item}
      >
        { child }
      </div>
    )) }
  </div>
);
