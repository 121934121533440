import { UserRole } from '../../../../utils/types/admin_types';

export const useRoleSearch = (roles: UserRole[], searchTerm: string, currentPage: number, resultsPerPage: number): {
    paginatedRoles: UserRole[];
    totalPages: number;
} => {
  const filteredRoles = roles.filter((role) =>
    role.roleName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const totalPages = Math.ceil(filteredRoles.length / resultsPerPage);

  const paginatedRoles = filteredRoles.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage,
  );

  return { paginatedRoles, totalPages };
};
