import React from 'react';
import { Button } from '../../../../ui/button/button';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import styles from './Modals.module.css';

type DeleteUnitModalProps = {
    deleteUnit: () => void,
    cancelEvent: () => void,
}

export const DeleteUnitModal = ({
  deleteUnit,
  cancelEvent,
}: DeleteUnitModalProps) => {
  return (
    <>
      <SecondaryTypography.Small
          keepDefaultMargins={true}
      >
        Warning - By pressing the <b>Delete</b> button below, the selected unit and all branches underneath it will be permanently deleted.
      </SecondaryTypography.Small>
      <SecondaryTypography.Small
          keepDefaultMargins={true}
      >
        To cancel this operation, press the <b>Cancel</b> button below
      </SecondaryTypography.Small>
      <div
          className={styles.deleteModalButtons}
      >
        <Button
            onClick={deleteUnit}
            variant='danger'
            size='medium'
        >
          Delete
        </Button>
        <Button
            onClick={cancelEvent}
            variant='secondary'
            size='medium'
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
