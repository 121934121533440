import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/level';

export function getLevelList() {
  return fetch(createRequest(apiEndpoint + '/get', 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}
