import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import loginRegisterBg from '../../assets/images/backgrounds/login_register_bg.png';
import defaultCam from '../../assets/images/graphics/default-cam.svg';
import personOutline from '../../assets/images/graphics/person.svg';
import logoBlackText from '../../assets/images/logos/white-bg-logo-hiacuity.png';
import { Button } from '../../ui/button/button';
import { GreenTick, RedCross } from '../../ui/icons/icons';
import { SecondaryTypography } from '../../ui/typography/typography';
import { awsUpload, detectFace, getStorageLink } from '../../utils/api_service/storage_api';
import { updateProfilePic } from '../../utils/api_service/user_api';
import { IS_IOS } from '../assessment/perform/assigned_assessments/assigned_assessments';
import styles from './login_register.module.css';

export const IdentityCheck = () => {
  const history = useHistory();
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [picDisabled, setPicDisabled] = useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    getUserCamera();
  },[videoRef]);

  useEffect(() => {
    const processImage = async () => {
      if (isProcessing) {
        const video = videoRef.current;
        const photo = photoRef.current;
        if (video && photo) {
          const ctx = photo.getContext('2d');
          photo.width = 400;
          photo.height = video.videoHeight;
          ctx?.drawImage(video, 0, 0, 400, 480);
          const dataURL = photo.toDataURL('image/png');
          setDisabled(false);
          setVerified(true);
        }
        setIsProcessing(false);
        setPicDisabled(false);
      }
    };
    processImage();
  },[isProcessing]);

  useEffect(() => {
    const canvas = photoRef.current;
    const video = videoRef.current;
    const image = new Image();
    if (canvas && video) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        canvas.width = 200;
        canvas.height = 240;
        image.src = defaultCam;
        image.onload = () => {
          ctx.drawImage(image, 0, 0, 200, 240);
        };
      }
    }
  },[]);

  const detectFaceImage = async (image: string) => {
    try {
      const response = await detectFace(image);
      const responseData = await response.json();

      if (responseData.detected) {
        setDisabled(false);
        setVerified(true);
      } else {
        setDisabled(true);
        setVerified(false);
        toast.error('Error Detecting Face', { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error Detecting Face', { position: 'bottom-center' });
    }
  };

  const getUserCamera = () => {
    navigator.mediaDevices.getUserMedia({
      video: true,
    })
      .then((stream) => {
        let video = videoRef.current;
        if (video) {
          video.muted = true;
          video.srcObject = stream;
          video.autoplay = true;
        }
      });
  };

  const takePicture = async () => {
    setIsProcessing(true);
    setPicDisabled(true);
  };

  const uploadPicture = async (fileName: string, folderName: string, image: Blob) => {
    try {
      const storageLinkResponse = await getStorageLink(fileName, folderName);
      await awsUpload(storageLinkResponse.url, image);
      const updateProfilePicResponse = await updateProfilePic(storageLinkResponse.fileName);

      if (updateProfilePicResponse.id) {
        if (localStorage.getItem('id')) {
          localStorage.setItem('profilePic', updateProfilePicResponse.profilePic);
          history.goBack();
        } else {
          localStorage.clear();
          history.push('/login');
        }
      } else {
        toast.error('Error setting profile picture', { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error uploading picture', { position: 'bottom-center' });
    }
  };

  const capturePicture = (imageName: string) => {
    const folderName = 'profile-pic';
    const photo = photoRef.current;
    if (photo) {
      photo.toBlob((blob) => {
        if (blob) {
          uploadPicture(imageName, folderName, blob);
        }
      }, 'image/jpeg', 0.9);
    }
  };

  const skip = () => {
    if (localStorage.getItem('email')) {
      history.goBack();
    } else {
      localStorage.clear();
      history.push('/login');
    }
  };

  return (
    <div
        className={styles.pageContainer}
    >
      <div
          className={styles.mainContainer}
      >
        <div
            className='flex justify-center items-center bg-gray-800 text-white w-0 h-0 lg:w-[54%] text-2xl'
        >
          <img
              className={styles.leftContainerBg}
              src={loginRegisterBg}
              alt='HiAcuity-Bg'
          />
        </div>
        <div
            className='w-full h-full lg:w-[46%] bg-white box-border p-10'
        >
          <div className='flex justify-between'>
            <img
                className={styles.logoStyles}
                src={logoBlackText}
                alt='HiAcuity-Logo'
            />
            <Button
                onClick={() => skip()}
                variant={'primary'}
                size={'medium'}
                customClass='w-24'
            >
              Skip
            </Button>
          </div>
          <h1
              className={styles.formHeading}
          >
            Identity Check
          </h1>
          <p>
            Please take an initial photo of yourself. This photo can be re-taken at a later stage.
            Please note the registration will NOT complete until the photo is taken.
            Any Assessments will not be available until a registration photo is taken.
          </p>
          <div className='w-full flex flex-col sm:flex-row justify-center sm:justify-start'>
            <div className='relative m-auto sm:m-0'>
              { IS_IOS ? (
                <video
                    className='mt-2 mb-2 object-fill w-[48vw] h-[25vh] sm:w-[34vw] sm:h-[25vh] md:w-[36vw] md:h-[25vh] lg:w-[11vw] lg:h-[25vh] border border-black'
                    ref={videoRef}
                    autoPlay={true}
                    playsInline={true}
                    muted={true}
                />
              ) : (
                <video
                    className='mt-2 mb-2 object-fill w-[48vw] h-[25vh] sm:w-[34vw] sm:h-[25vh] md:w-[36vw] md:h-[25vh] lg:w-[11vw] lg:h-[25vh] border border-black'
                    ref={videoRef}
                />
              )
              }
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[235px] h-[235px] sm:w-[36vw] sm:h-[28vh] md:w-[32vw] md:h-[22vh] lg:w-[14vw] lg:h-[23vh] pointer-events-none '>
                <img
                    src={personOutline}
                />
              </div>
            </div>
            <div className='relative m-auto sm:m-0'>
              <canvas
                  className='mt-2 mb-2 object-fill w-[48vw] h-[25vh] sm:w-[34vw] sm:h-[25vh] md:w-[36vw] md:h-[25vh] lg:w-[11vw] lg:h-[25vh] border border-black'
                  ref={photoRef}>
              </canvas>
              { isProcessing && <div className='text-center absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-3/4 p-4 rounded font-bold w-64' style={{ top: '85%' }}>
                Processing image<br />
                Please wait...
              </div> }
            </div>
          </div>
          <div className='flex justify-evenly'>
            <Button
                onClick={() => takePicture()}
                variant={'primary'}
                size={'medium'}
                customClass={styles.buttonStyles}
                disabled={picDisabled}
            >
              Take Picture
            </Button>
            <div className='mt-2.5 flex flex-col gap-2.5'>
              <div className='flex gap-2.5'>
                <SecondaryTypography.Medium
                    fontWeight='semi-bold'
                >
                  Full face
                </SecondaryTypography.Medium>
                { verified ? (
                  <GreenTick/>
                ) : (
                  <RedCross/>
                ) }
              </div>
              <div className='flex gap-2.5'>
                <SecondaryTypography.Medium
                    fontWeight='semi-bold'
                >
                  Lighting
                </SecondaryTypography.Medium>
                { verified ? (
                  <GreenTick/>
                ) : (
                  <RedCross/>
                ) }
              </div>
            </div>
          </div>
          <div className='mt-10 flex justify-center items-center'>
            <Button
                onClick={() => capturePicture('test.jpg')}
                size='medium'
                variant='primary'
                customClass={styles.submitButton}
                disabled={disabled}
            >
              Complete Registration
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
