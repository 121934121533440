import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getOrganizationUserList } from '../../api_service/organization_users';
import { OrganizationUser } from '../../types/admin_types';

export const fetchOrganizationUsers = createAsyncThunk('organizationUsers/fetchOrganizationUsers', async () => {
  try {
    const response = await getOrganizationUserList();
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      toast.error(errorData.error, { position: 'bottom-center' });
    }
  } catch (error) {
    toast.error('Error fetching users: ' + error, { position: 'bottom-center' });
  }
});

export interface OrganizationUserState {
    organizationUserList: OrganizationUser[];
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: OrganizationUserState = {
  organizationUserList: [],
  loading: 'idle',
};

const organizationUserSlice = createSlice({
  name: 'organizationUsers',
  initialState,
  reducers: {
    addOrganizationUserRedux: (state, action: PayloadAction<OrganizationUser>) => {
      state.organizationUserList.push(action.payload);
    },
    editOrganizationUserRedux: (state, action: PayloadAction<OrganizationUser>) => {
      const { id } = action.payload;
      const index = state.organizationUserList.findIndex((organizationUser) => organizationUser.id === id);
      if (index !== -1) {
        state.organizationUserList[index] = action.payload;
      }
    },
    deleteOrganizationUserRedux: (state, action: PayloadAction<string>) => {
      const id= action.payload;
      state.organizationUserList = state.organizationUserList.filter((organizationUser) => organizationUser.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationUsers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
        state.organizationUserList = action.payload;
        state.loading = 'succeeded';
      });
  },
});

export const { addOrganizationUserRedux, editOrganizationUserRedux, deleteOrganizationUserRedux } = organizationUserSlice.actions;
export default organizationUserSlice.reducer;
