import { pdf } from '@react-pdf/renderer';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import React, { useMemo, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { OrganizationUnit } from '../../pages/admin/OrganizationSettings/TreeStructure';
import { Button } from '../../ui/button/button';
import { AnalyticsSearchObject, ChartOrderItem, FormattedSelectOptions } from '../../utils/types/analytics/analytics_types';
import { SelectOptions } from '../../utils/types/react_select_types';
import { ChevronDown, ChevronUp, LoadingIcon } from '../icons/icons';
import { SecondaryTypography } from '../typography/typography';
import styles from './data_source_filter.module.css';
import { MultiSelectListItem } from './MultiSelectListItem';
import { PdfCreator } from './PdfCreator';

type CollapsibleFilterProps = {
    open:boolean
    skillList: SelectOptions[] | undefined,
    assessmentList?: SelectOptions[] | undefined,
    participantNo?: SelectOptions[] | undefined,
    organizationTree: OrganizationUnit | undefined,
    assessmentTypeList: SelectOptions[] | undefined,
    searchObject: AnalyticsSearchObject,
    setSearchObject: (searchObject: AnalyticsSearchObject) => void,
    searchResultChartOrder: ChartOrderItem[] | undefined,
    type?: string
};

export const getFormattedOrgList = (level: number, organisationUnit?: OrganizationUnit): FormattedSelectOptions[] => {
  const formattedOrgList: FormattedSelectOptions[] = [];

  if (!organisationUnit) return formattedOrgList;
  formattedOrgList.push({ value: organisationUnit.id, label: organisationUnit.unitName, level });

  for (const org of organisationUnit.children) {
    formattedOrgList.push( ...getFormattedOrgList(level + 1, org) );
  }

  return formattedOrgList;
};

export const CollapsibleFilter = ({
  open,
  skillList,
  assessmentList,
  participantNo,
  organizationTree,
  assessmentTypeList,
  searchObject,
  setSearchObject,
  searchResultChartOrder,
  type,
} : CollapsibleFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [pdfLoading, setPdfLoading] = useState(false);

  const formattedOrgList = useMemo(() => getFormattedOrgList(0, organizationTree), [organizationTree]);

  const toggleCollapsible = (open: boolean) => {
    setIsOpen(!open);
  };

  const handleTimeChange = (timeRange: DateValueType) => {
    setSearchObject({
      ...searchObject,
      timeRange,
    });
  };

  const handleMultiSelectChange = (selectedValues: MultiValue<SelectOptions>, action: any) => {

    if (action.action === 'remove-value' ) {
      setSearchObject({
        ...searchObject,
        [action.name]: '',
      });
    }
    setSearchObject({
      ...searchObject,
      [action.name]: selectedValues ? type==='assessment'? [selectedValues[selectedValues.length - 1]] : selectedValues : '',
    });
  };

  const handleSelectChange = (action: any, actionValues: any) => {
    setSearchObject({
      ...searchObject,
      [actionValues.name]: action,
    });

  };

  const handleOrgUnitSelect = (selectedValues: MultiValue<SelectOptions>, action: any) => {
    if (action.action === 'remove-value' ) {
      setSearchObject({
        ...searchObject,
        [action.name]: searchObject.organization.filter((e) => e.value !== action.removedValue.value),
      });
      return;
    }

    if (action.action === 'deselect-option' ) {
      // Deselect all the child items along with the deselected one
      const deselectedVal: FormattedSelectOptions = action.option;
      const toRemove = [deselectedVal.value];
      const currentLevel = deselectedVal.level;
      const index = formattedOrgList.findIndex(e => e.value === deselectedVal.value);
      for (let i = index + 1; i < formattedOrgList.length; i++) {
        const element = formattedOrgList[i];
        if (element.level > currentLevel) {
          toRemove.push(element.value);
        } else {
          break;
        }
      }
      setSearchObject({
        ...searchObject,
        [action.name]: searchObject.organization.filter((e) => !toRemove.includes(e.value)),
      });
      return;
    }

    if (action.action === 'select-option') {
      const selectCandidates = new Set(selectedValues);
      const selectedValue = action.option;
      const currentLevel = (selectedValue as FormattedSelectOptions).level;
      selectCandidates.add(selectedValue);

      const index = formattedOrgList.findIndex(e => e.value === selectedValue.value);
      for (let i = index + 1; i < formattedOrgList.length; i++) {
        const element = formattedOrgList[i];
        if (element.level > currentLevel) {
          selectCandidates.add(element);
        } else {
          break;
        }
      }

      setSearchObject({
        ...searchObject,
        [action.name]: selectedValues ? type==='assessment'? [selectedValues[selectedValues.length - 1]] : Array.from(selectCandidates) : '',
      });

    }
  };

  const getFlatternFilterString = (searchArray: Array<SelectOptions>) => {
    return searchArray.map(item => item.label).join(', ');
  };

  const getImageList = async () => {
    const imageList: Array<string> = [];
    if (!searchResultChartOrder) return;
    for (const item of searchResultChartOrder) {
      const id = item.chartId;
      const element = document.getElementById(id);
      if(element) {
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/jpg');
        imageList.push(data);
      }
    }
    return imageList;
  };

  const handleDashboardExport = async  () => {
    setPdfLoading(true);
    const filterList: Array<string> = [];
    const assessmentType = 'Assessment Type : ' + (searchObject.assessmentType.length? getFlatternFilterString(searchObject.assessmentType) : 'N/A');
    const organization = 'Organisation : ' + (searchObject.organization.length? getFlatternFilterString(searchObject.organization) : 'N/A');
    const skill = 'Skills : ' + (searchObject.skill.length? getFlatternFilterString(searchObject.skill): 'N/A');
    const timeRange = 'Time Range: ' + (searchObject.timeRange?.startDate ? searchObject.timeRange?.startDate?.toString() + ' - ' + searchObject.timeRange?.endDate?.toString() : 'All');
    filterList.push(assessmentType, organization, skill, timeRange);

    const imageList = await getImageList();
    const doc = (<PdfCreator imageList={imageList} filterList={filterList}/>);
    const blob = await pdf(doc).toBlob();

    if(blob){
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = 'Analytics.pdf';
      alink.click();
    }
    setPdfLoading(false);
  };

  return (
    <div className={styles.filterBoxBottomBorder}>
      <div className={classNames(styles.filterBox, { [styles.open]: isOpen })}>
        <div className={styles.filterHeaderContainer}>
          <SecondaryTypography.Medium
              fontWeight='semi-bold'
              className={styles.filterHeader}
          >
            Data Source Filters
          </SecondaryTypography.Medium>
        </div>
        <div className={styles.iconContainer}>
          <div
              className={classNames(styles.icons, styles.clickable)}
              onClick={() => {
                toggleCollapsible(isOpen);
              }}
          >
            { isOpen ? <ChevronUp /> : <ChevronDown /> }
          </div>
        </div>
      </div>
      { isOpen &&
        <div className={classNames('flex flex-col md:flex-row md:flex-wrap md:items-center', styles.inputSubContainer)} >
          <div>
            <SecondaryTypography.Small
                keepDefaultMargins={true}
            >
              Time Range
            </SecondaryTypography.Small>
            <Datepicker
                displayFormat='DD/MM/YYYY'
                containerClassName={styles.datePicker}
                showShortcuts={true}
                value={searchObject.timeRange}
                onChange={handleTimeChange}
            />
          </div>
          <div>
            <SecondaryTypography.Small
                keepDefaultMargins={true}
            >
              Organization Unit
            </SecondaryTypography.Small>
            <Select
                className={styles.selectContainer}
                placeholder='Select Organization Unit'
                name='organization'
                options={formattedOrgList}
                onChange={handleOrgUnitSelect}
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={type !== 'assessment'? { Option: MultiSelectListItem }: {}}
                value={searchObject.organization}
                isMulti
            />
          </div>
          <div>
            <SecondaryTypography.Small
                keepDefaultMargins={true}
            >
              Assessment Type
            </SecondaryTypography.Small>
            <Select
                className={styles.selectContainer}
                placeholder='Select Assessment Type'
                name='assessmentType'
                options={assessmentTypeList}
                onChange={handleMultiSelectChange}
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={type !== 'assessment'? { Option: MultiSelectListItem }: {}}
                value={searchObject.assessmentType}
                isMulti
            />
          </div>
          { type === 'assessment' ? (
            <div>
              <SecondaryTypography.Small
                  keepDefaultMargins={true}
              >
                Assessment Name
              </SecondaryTypography.Small>
              <Select
                  className={styles.selectContainer}
                  placeholder='Select Assessment'
                  name='assessmentName'
                  options={assessmentList}
                  onChange={handleSelectChange}
                  isClearable={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={searchObject.assessmentName}
              />
            </div>
          ) : (
            <div>
              <SecondaryTypography.Small
                  keepDefaultMargins={true}
              >
                Skill
              </SecondaryTypography.Small>
              <Select
                  className={styles.selectContainer}
                  placeholder='Select Skill'
                  name='skill'
                  options={skillList}
                  onChange={handleMultiSelectChange}
                  isClearable={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option: MultiSelectListItem }}
                  value={searchObject.skill}
                  isMulti
              />
            </div>
          ) }
          { type==='assessment' &&
          <div>
            <SecondaryTypography.Small
                keepDefaultMargins={true}
            >
              No of Top Participants
            </SecondaryTypography.Small>
            <Select
                className={styles.selectContainer}
                placeholder='Specify Participant count'
                name='participantNo'
                onChange={handleSelectChange}
                options={participantNo}
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={searchObject.participantNo}
            />
          </div>
          }
          <Button variant={'primary'} size={'large'} onClick={handleDashboardExport} disabled={pdfLoading}>
            { pdfLoading ? <div className='flex flex-row justify-center'><LoadingIcon /><div className='ml-2'>Generating...</div></div> : 'Export' }
          </Button>
        </div>
      }
    </div>
  );
};
