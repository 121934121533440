import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../../ui/button/button';
import { CustomSelect } from '../../../../../../ui/custom_react_select/custom_select';  // Replace with the actual path
import Tooltip from '../../../../../../ui/tooltip';
import { PrimaryTypography, SecondaryTypography } from '../../../../../../ui/typography/typography';
import { convertSecondsToTimeTaken } from '../../../../../../utils/helpers/time';
import { UserAssessment } from '../../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../../utils/types/react_select_types';
import commonStyles from '../../assigned_assessments.module.css';
import styles from './assigned_list_modal.module.css';

type AssignedListModalProps = {
  userAssessmentList: UserAssessment[],
  selectedUserAssessment: UserAssessment | undefined,
  setSelectedUserAssessment: (selectedAssessment: UserAssessment | undefined) => void
  setModalContent: (content: number) => void,
};

export const AssignedListModal = ({
  userAssessmentList,
  selectedUserAssessment,
  setSelectedUserAssessment,
  setModalContent,
}: AssignedListModalProps) => {
  const history = useHistory();
  const [selectedAssessment, setSelectedAssessment] = useState<SelectOptions | undefined>(
    selectedUserAssessment !== undefined
      ? {
        value: selectedUserAssessment?.assessment.id,
        label: selectedUserAssessment?.assessment.assessmentTitle,
      }
      : undefined);

  const filteredAssessmentList = useMemo(() => {
    const CURRENT_TIME = Date.now() / 1000;

    return userAssessmentList.filter((assessment) => assessment.assessment.totalScore !== 0 && assessment.assessment.totalTime !== 0 &&
      assessment.assessment.startDate < CURRENT_TIME && assessment.assessment.endDate > CURRENT_TIME);
  }, [userAssessmentList]);

  useEffect(() => {
    if (filteredAssessmentList.length === 1) {
      setSelectedUserAssessment(filteredAssessmentList[0]);
    }
  }, [filteredAssessmentList]);

  const assessmentOptions = useMemo(() =>
    filteredAssessmentList.map((assessment: UserAssessment) => ({
      value: assessment.assessment.id,
      label: assessment.assessment.assessmentTitle,
    }))
  ,[filteredAssessmentList]);

  const handleAssessmentSelect = (selected: SelectOptions) => {
    const selectedUserAssessment = userAssessmentList.find((userAssessment: UserAssessment) => userAssessment.assessment.id === selected.value);
    setSelectedUserAssessment(selectedUserAssessment);
    setSelectedAssessment(selected);
  };

  return (
    <div className={commonStyles.modalContentContainer}>
      <PrimaryTypography.SevenXLarge fontWeight='bold'>
        Welcome, { localStorage.getItem('firstname') }! 👋
      </PrimaryTypography.SevenXLarge>
      { localStorage.getItem('profilePic') === 'null' &&
      <SecondaryTypography.Small className={commonStyles.redText} keepDefaultMargins={true}>
        You are not allowed to take the assessment until you have taken the registration photo
      </SecondaryTypography.Small>
      }
      { filteredAssessmentList.length === 1 && selectedUserAssessment ? (
        <>
          <SecondaryTypography.Medium fontWeight='semi-bold'>
            Assessment Name - { selectedUserAssessment.assessment.assessmentTitle }
          </SecondaryTypography.Medium>
          <SecondaryTypography.Small fontWeight='semi-bold' className={commonStyles.greyText}>
            Assessment duration - { convertSecondsToTimeTaken(
              selectedUserAssessment.assessment.totalTime,
              false,
            ) }{ ' ' }
            minutes
          </SecondaryTypography.Small>
          { selectedUserAssessment.assessmentUser.status === 'In Progress' &&
            <SecondaryTypography.Small fontWeight='semi-bold' className={commonStyles.redText}>
              Questions remaining: { selectedUserAssessment.assessmentUser.questionsRemaining }
            </SecondaryTypography.Small>
          }
          <SecondaryTypography.Small fontWeight='semi-bold' className={commonStyles.greyText}>
            Assessment points - { selectedUserAssessment.assessment.totalScore } points
          </SecondaryTypography.Small>
        </>
      ) : filteredAssessmentList.length > 0 ? (
        <>
          <SecondaryTypography.Small className={commonStyles.greyText} keepDefaultMargins={true}>
            Here are the assignments assigned to you by HiAcuity
          </SecondaryTypography.Small>
          <div className={styles.userAssessmentListItem}>
            <CustomSelect
                name='assessment'
                label='Select Assessment'
                options={assessmentOptions}
                value={selectedAssessment}
                onChange={(selectedOption: any) =>
                  handleAssessmentSelect(selectedOption)
                }
            />
            { selectedUserAssessment && (
              <div className='py-2'>
                <div className='flex justify-between'>
                  <SecondaryTypography.Small fontWeight='semi-bold' className={commonStyles.greyText}>
                    Assessment duration - { convertSecondsToTimeTaken(
                      selectedUserAssessment.assessment.totalTime,
                      false,
                    ) }{ ' ' }
                    minutes
                  </SecondaryTypography.Small>
                  { selectedUserAssessment.assessmentUser.status === 'In Progress' &&
                  <SecondaryTypography.Small fontWeight='semi-bold' className={commonStyles.redText}>
                    Questions remaining: { selectedUserAssessment.assessmentUser.questionsRemaining }
                  </SecondaryTypography.Small>
                  }
                </div>
                <SecondaryTypography.Small fontWeight='semi-bold' className={commonStyles.greyText}>
                  Assessment points - { selectedUserAssessment.assessment.totalScore } points
                </SecondaryTypography.Small>
              </div>
            ) }
          </div>
        </>
      ) : (
        <SecondaryTypography.Small className={commonStyles.greyText} keepDefaultMargins={true}>
          No active assignments currently assigned
        </SecondaryTypography.Small>
      ) }
      <div className={styles.userButtonContainer}>
        <div className='flex flex-row items-center gap-1'>
          <Button
              onClick={() => setModalContent(4)}
              variant='primary'
              size='medium'
          >
            Practice Tutorial
          </Button>
          <Tooltip
              message='You can try a practice assessment'
              position='bottom'
          />
        </div>
        { userAssessmentList.length > 0 &&
        <div className='flex flex-row items-center gap-1'>
          <Tooltip
              message='You can attempt selected assessment'
              position='bottom'
          />
          { localStorage.getItem('profilePic') === 'null' ? (
            <Button
                onClick={() => history.push('/identity-check')}
                variant='primary'
                size='medium'
            >
              Take Registration Photo
            </Button>
          ) : (
            <Button
                onClick={() => setModalContent(1)}
                variant='primary'
                size='medium'
                disabled={selectedUserAssessment === undefined}
            >
              Start Assessment
            </Button>
          ) }
        </div>
        }
      </div>
    </div>
  );
};
