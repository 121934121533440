import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './typography.module.css';

type Variant = 'primary' | 'secondary';

type Tag = 'p' | 'h6' | 'h5' | 'h4' | 'h3' | 'h2' | 'h1';

type Size = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

type Alignment = 'left' | 'right' | 'center' | 'justify';

type FontWeight = 'light' | 'normal' | 'semi-bold' | 'bold';

type TypographyCase = 'none' | 'lowercase' | 'uppercase' | 'capitalize';

type TypographyProps = {
  alignment?: Alignment,
  fontWeight?: FontWeight,
  italicized?: boolean,
  keepDefaultMargins?: boolean,
  textCase?: TypographyCase,
  className?: string,
}

const getVariantClassName = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
  }
};

const getSizeClassName = (size: Size) => {
  switch (size) {
    case 'xs':
      return styles.xSmall;
    case 's':
      return styles.small;
    case 'm':
      return styles.medium;
    case 'l':
      return styles.large;
    case 'xl':
      return styles.xLarge;
    case '2xl':
      return styles.twoXLarge;
    case '3xl':
      return styles.threeXLarge;
    case '4xl':
      return styles.fourXLarge;
    case '5xl':
      return styles.fiveXLarge;
    case '6xl':
      return styles.sixXLarge;
    case '7xl':
      return styles.sevenXLarge;
    default:
      return styles.medium;
  }
};

const getAlignmentClassName = (alignment: Alignment) => {
  switch (alignment) {
    case 'center':
      return styles.center;
    case 'right':
      return styles.right;
    case 'justify':
      return styles.justified;
    case 'left':
    default:
      return styles.left;
  }
};

const getFontWeightClassName = (fontWeight: FontWeight) => {
  switch (fontWeight) {
    case 'light':
      return styles.light;
    case 'semi-bold':
      return styles.semiBold;
    case 'bold':
      return styles.bold;
    case 'normal':
    default:
      return styles.normal;
  }
};

const getTypographyCaseClassName = (textCase: TypographyCase | undefined) => {
  switch (textCase) {
    case 'lowercase':
      return styles.lowercase;
    case 'uppercase':
      return styles.uppercase;
    case 'capitalize':
      return styles.capitalize;
    default:
      return styles.none;
  }
};

const getClassNames = ({
  variant,
  size,
  alignment = 'left',
  fontWeight = 'normal',
  italicized = false,
  keepDefaultMargins = false,
  textCase,
  className,
}: {
  variant: Variant,
  size: Size,
} & TypographyProps) => {
  return classNames(
    getVariantClassName(variant),
    getSizeClassName(size),
    getAlignmentClassName(alignment),
    getFontWeightClassName(fontWeight),
    {
      [styles.italicized]: italicized,
      [styles.removeMargins]: !keepDefaultMargins,
    },
    getTypographyCaseClassName(textCase),
    className,
  );
};

type TypographyArgs = {
  tag: Tag,
  variant: Variant,
  size: Size,
  children?: React.ReactNode,
} & TypographyProps;

const renderTypography = ({
  tag,
  variant,
  size,
  alignment,
  fontWeight,
  italicized,
  keepDefaultMargins,
  textCase,
  className,
  children,
}: TypographyArgs) => {
  return React.createElement(
    tag,
    {
      className: getClassNames({
        size,
        variant,
        alignment,
        fontWeight,
        italicized,
        keepDefaultMargins,
        textCase,
        className,
      }),
    },
    children,
  );
};

namespace Primary {
  const renderPrimaryTypography = (args: Omit<TypographyArgs, 'variant'>) => renderTypography({ variant: 'primary', ...args });
  export const Medium: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h6', size: 'm', ...props });
  export const Large: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h6', size: 'l', ...props });
  export const XLarge: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h5', size: 'xl', ...props });
  export const TwoXLarge: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h4', size: '2xl', ...props });
  export const ThreeXLarge: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h4', size: '3xl', ...props });
  export const FourXLarge: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h4', size: '4xl', ...props });
  export const FiveXLarge: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h3', size: '5xl', ...props });
  export const SixXLarge: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h2', size: '6xl', ...props });
  export const SevenXLarge: FC<TypographyProps> = (props) => renderPrimaryTypography({ tag: 'h1', size: '7xl', ...props });
}

namespace Secondary {
  const renderSecondaryTypography = (args: Omit<TypographyArgs, 'tag' | 'variant'>) => renderTypography({ tag: 'p', variant: 'secondary', ...args });
  export const XSmall: FC<TypographyProps> = (props) => renderSecondaryTypography({ size: 'xs', ...props });
  export const Small: FC<TypographyProps> = (props) => renderSecondaryTypography({ size: 's', ...props });
  export const Medium: FC<TypographyProps> = (props) => renderSecondaryTypography({ size: 'm', ...props });
  export const Large: FC<TypographyProps> = (props) => renderSecondaryTypography({ size: 'l', ...props });
  export const XLarge: FC<TypographyProps> = (props) => renderSecondaryTypography({ size: 'xl', ...props });
}

export const PrimaryTypography = {
  Medium: Primary.Medium,
  Large: Primary.Large,
  XLarge: Primary.XLarge,
  TwoXLarge: Primary.TwoXLarge,
  ThreeXLarge: Primary.ThreeXLarge,
  FourXLarge: Primary.FourXLarge,
  FiveXLarge: Primary.FiveXLarge,
  SixXLarge: Primary.SixXLarge,
  SevenXLarge: Primary.SevenXLarge,
};

export const SecondaryTypography = {
  XSmall: Secondary.XSmall,
  Small: Secondary.Small,
  Medium: Secondary.Medium,
  Large: Secondary.Large,
  XLarge: Secondary.XLarge,
};
