import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/job_title';

export function getJobTitleList() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function searchJobTitleList(keyword: string){
  return fetch(createRequest(apiEndpoint + `/search?keyword=${keyword}`, 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function createJobTitle(titleName: string) {
  let body = {
    jobTitle: titleName,
    jobDescription: titleName,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}
