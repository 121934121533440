import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RefreshIcon } from '../../../ui/icons/icons';
import { PaginationController } from '../../../ui/pagination_controller/pagination_controller';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import { deleteCandidate, getCandidateList } from '../../../utils/api_service/candidate';
import { fetchOrganizationUsers } from '../../../utils/redux_store/features/organization_user_reducer';
import { store } from '../../../utils/redux_store/store';
import { OrganizationUser } from '../../../utils/types/admin_types';
import commonStyles from '../access_management/access_management.module.css';
import { AdminSearchBar } from '../access_management/admin_search_bar/admin_search_bar';
import baseStyles from '../admin.module.css';
import { CandidateRow } from './CandidateRow';

export const CandidateManagement = () => {
  const [candidateList, setCandidateList] = useState<OrganizationUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchCandidates = useCallback(async () => {
    try {
      const response = await getCandidateList(searchTerm, pageNumber, 8);
      if (response.ok) {
        const data = await response.json();
        setCandidateList(data.candidate);
        setTotalPages(data.pages);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error fetching candidates: ' + error, { position: 'bottom-center' });
    }
  }, [searchTerm, pageNumber]);

  useEffect(() => {
    const debounceSearchTerm = setTimeout(() => {
      fetchCandidates();
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchTerm, fetchCandidates]);

  useEffect(() => {
    fetchCandidates();
  }, [pageNumber, fetchCandidates]);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPageNumber(1);
  };

  const deleteUser = async (id: string) => {
    try {
      const response = await deleteCandidate(id);
      if (response.ok) {
        const updatedEmployees = candidateList.filter((candidate) => candidate.id !== id);
        setCandidateList(updatedEmployees);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error deleting candidate: ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <div
        className={baseStyles.container}
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Admin
      </PrimaryTypography.FiveXLarge>
      <div
          className={baseStyles.contentContainer}
      >
        <SecondaryTypography.Medium
            keepDefaultMargins={true}
            fontWeight='bold'
        >
          Candidate Management
        </SecondaryTypography.Medium>
        <div
            className={commonStyles.searchContainer}
        >
          <AdminSearchBar
              searchTerm={searchTerm}
              handleChange={handleSearchTermChange}
          />
          <div
              className={classNames(commonStyles.flexRowContainer, commonStyles.iconGap, commonStyles.blueText)}
              onClick={() => store.dispatch(fetchOrganizationUsers())}
          >
            <div
                className={commonStyles.icons}
            >
              <RefreshIcon/>
            </div>
            <SecondaryTypography.Small
                fontWeight='semi-bold'
                textCase='capitalize'
            >
              Refresh
            </SecondaryTypography.Small>
          </div>
        </div>
        <table
            className={baseStyles.tableStyle}
        >
          <thead>
            <tr>
              <th
                  style={{ width: 0 }}
              >
                <input type='checkbox'/>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Name
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Email
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Groups
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Last Active
                </SecondaryTypography.XSmall>
              </th>
            </tr>
          </thead>
          <tbody>
            { candidateList.map((candidate: any) => {
              return (
                <CandidateRow
                    key={candidate.id}
                    candidate={candidate}
                    deleteUser={deleteUser}
                />
              );
            })
            }
          </tbody>
        </table>
        <PaginationController
            pageNumber={pageNumber}
            maxPageNumber={totalPages}
            navigateNextPage={() => setPageNumber((pageNumber) => pageNumber + 1)}
            navigatePreviousPage={() => setPageNumber((pageNumber) => pageNumber - 1)}
        />
      </div>
    </div>
  );
};
