import React from 'react';

export interface SingleButton {
  disabled: boolean;
  label: string;
  svg: string;
}

interface ButtonProps {
  button: SingleButton;
  active: boolean;
  onClick: () => void;
  additionalClasses?: string;
}

interface ButtonColumnProps {
  buttons: SingleButton[];
  currentTab: string;
  onStatusChange: (label: string) => void;
}

const Button = ({ button, onClick, active, additionalClasses = '' }: ButtonProps) => (
  <button
      className={`flex flex-col items-center justify-center text-center p-2 space-y-2 border border-gray-200 w-24 h-24  text-white
      ${active ? 'bg-primary-green-500' : button.disabled ? 'bg-gray-300' : 'bg-primary-green-200'} ${additionalClasses}`}
      onClick={onClick}
  >
    <div className='flex flex-col items-center justify-center text-xs'>
      <img
          className={'object-contain w-3/5 h-3/5 py-1'}
          src={button.svg}
          alt={button.label}
      />
      { button.label }
    </div>
  </button>
);

const ButtonColumn = ({ buttons, onStatusChange, currentTab }: ButtonColumnProps) => {
  return (
    <div className='flex flex-col'>
      { buttons.map((button, index) => (
        <Button
            key={button.label}
            button={button}
            onClick={() => onStatusChange(button.label)}
            active={currentTab === button.label}
            additionalClasses={
              `${index === 0 ? 'rounded-t-lg' : ''} ${index === buttons.length - 1 ? 'rounded-b-lg' : ''}`
            }
        />
      )) }
      <Button
          key='filler'
          button={{ label: '', disabled: true, svg: '' }}
          onClick={() => {}}
          active={false}
          additionalClasses={'h-full cursor-not-allowed bg-gray-300'}
      />
    </div>
  );
};

export default ButtonColumn;
