import React, { useState } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';

interface CategoryData {
  label: string;
  value: number;
}

export interface TimeData {
  [key: string]: CategoryData[];
}

type PieChartCardProps = {
  label: string;
  data: TimeData | undefined;
  loading?: boolean;
  colors?: string[];
  centerLabel?: string
}

const PieChartCard = ({ label, data, loading = false, colors =  ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF3333'], centerLabel = '' }: PieChartCardProps) => {
  const [timeRange, setTimeRange] = useState<string>('Week');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeRange(event.target.value);
  };

  const selectedData = data ? data[timeRange] : [{ 'label': '', 'value': 1 }];
  const totalValue = selectedData.reduce((acc, cur) => acc + cur.value, 0);

  const centerValue = selectedData.find(item => item.label === centerLabel)?.value || 0;
  const centerPercent = Math.floor(centerValue / totalValue * 100);

  return (
    <div className={`rounded-md shadow-lg ${loading ? 'animate-pulse' : ''}`}>
      { loading ? (
        <div className='h-72 bg-gray-300'>
        </div>
      ) : (
        <div className='flex flex-col'>
          <div className='flex items-center justify-between p-4'>
            <div className='font-medium text-lg'>
              { label }
            </div>
            <div>
              <select value={timeRange} onChange={handleChange}
                  className='border-2 px-2 py-1 shadow-md rounded-md outline-none bg-white text-sm'>
                <option value='Week'>Last Week</option>
                <option value='Month'>Last Month</option>
                <option value='6 Months'>6 Months</option>
              </select>
            </div>
          </div>
          <div className='flex p-4'>
            <ResponsiveContainer width='75%' height={180} className='pr-5'>
              <PieChart>
                { totalValue > 0 ? (
                  <Pie
                      dataKey='value'
                      isAnimationActive={true}
                      data={selectedData}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={5}
                      fill='#8884d8'
                  >
                    { selectedData.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    )) }
                    { centerLabel !== '' && (
                      <Label
                          position='center'
                          width={5}
                          fontSize='20px'
                          fill='#555'
                          fontWeight='600'
                      >
                        { `${centerPercent}%` }
                      </Label>
                    ) }
                  </Pie>
                ) : (
                  <Pie
                      dataKey='value'
                      isAnimationActive={true}
                      data={[{ name: 'No Data', value: 1 }]}
                      innerRadius={60}
                      outerRadius={80}
                      paddingAngle={0}
                      fill='#eeeeee'
                  />
                ) }
              </PieChart>
            </ResponsiveContainer>
            <div className='w-full flex flex-col items-center'>
              <div className='text-4xl font-bold mb-3'>{ totalValue }</div>
              <div className='mt-4'>
                <ul>
                  { selectedData.map((entry, index) => (
                    <li key={index} className='flex items-center justify-between'>
                      <span style={{ backgroundColor: colors[index % colors.length] }} className='h-4 w-4 mr-2 rounded-md'></span>
                      <span className='flex-grow'>{ entry.label }</span>
                      <span className='ml-10'>{ entry.value }</span>
                    </li>
                  )) }
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) }
    </div>
  );
};

export default PieChartCard;
