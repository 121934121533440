import classNames from 'classnames';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartOrderItem, DataType } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { MediumColors } from './Colors';
import { DashletIconGroup } from './DashletIconGroup';

type ColumnChartAssessmentDashletProps = {
    id:string,
    data: Array<DataType>,
    title: string,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void,
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}
export const ColumnChartAssessmentDashlet = ({ id, data, title, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, fullScreenDetected, setFullScreenDetected, handleDashletVisibility, editMode }: ColumnChartAssessmentDashletProps) => {

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  return (
    <div className='group relative w-full col-span-2 rounded border border-gray-200 shadow h-full p-2 overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={classNames(styles.dataContainer, dashletItem.hidden ? 'opacity-40': '')}
      >
        <div className='font-bold text-sm mb-2 text-center'>{ title }</div>
        <div className='flex justify-center'>
          { (!data || data.length === 0) ?
            <div className={'text-md text-gray-700 flex place-content-center place-items-center h-[300px]'}>No data to preview</div> :
            <ResponsiveContainer className='w-full h-full' height={fullScreenDetected? 500 : 300}>
              <BarChart
                  layout='vertical'
                  data={data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis type='number' label={{ value: '', position: 'insideBottom' }} />
                <YAxis dataKey='name' type='category' tick={{ fontSize: 14 }}/>
                <Tooltip />
                <Legend />
                <Bar dataKey='max' fill={MediumColors.RED} name='Time Allocated'  />
                <Bar dataKey='value' fill={MediumColors.GREEN} name='Time Completed' />
              </BarChart>
            </ResponsiveContainer>
          }
        </div>
      </div>
    </div>
  );
};
