const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const convertSecondsToTimeTaken = (time: number | undefined, showHours?: boolean) => {
  if (time) {
    if (time > 3600 || showHours) {
      return new Date(time * 1000).toISOString().substring(11, 19);
    } else {
      return new Date(time * 1000).toISOString().substring(14, 19);
    }
  }
};

export const convertStringToUnix = (stringTime: string) => {
  if (stringTime.length > 0) {
    // const d = Date.parse(stringTime);
    return (parseInt(stringTime.split(':')[0]) * 60) + parseInt(stringTime.split(':')[1]) || 0;
  }
};

export const secondsToString = (seconds: number): string => {
  if (seconds === undefined) {
    return '00:00';
  }
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0');
};

export const formatDateTime = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${day}-${month}-${year}-${hours}${minutes}${seconds}`;
};

export const formatDatePretty = (date: Date): string => {
  const dayOfWeek = weekdays[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
};

export const unixToCurrentTimezone = (seconds: number): string => {
  const unixTimestampMilliseconds = seconds * 1000;
  const date = new Date(unixTimestampMilliseconds);

  return date.toLocaleString();
};

export const formatUnixPretty = (unix: number): string => {
  const date = new Date(unix * 1000);

  return formatDatePretty(date);
};
