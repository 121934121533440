import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowRight } from '../../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { toggleAttemptMarkedStatus } from '../../../../../../utils/api_service/assessment_api';
import { convertSecondsToTimeTaken } from '../../../../../../utils/helpers/time';
import { AssessmentUser } from '../../../../../../utils/types/assessment_types';
import styles from './assessment_result_row.module.css';
import { ResultStatus, ResultStatusCapsule } from './result_status_capsule/result_status_capsule';

type AssessmentResultRowProps = {
  assessmentResult: AssessmentUser,
  skillIndex: number | undefined,
  displaySkillLapsedTime: boolean,
  selected: boolean,
  onSelect: (assessmentResult: AssessmentUser) => void,
  isCandidate: boolean,
}

export const AssessmentResultRow = ({
  assessmentResult,
  skillIndex,
  displaySkillLapsedTime,
  selected,
  onSelect,
  isCandidate,
}: AssessmentResultRowProps) => {
  const history = useHistory();
  const [markedStatus, setMarkedStatus] = useState<boolean>(assessmentResult.marked || false);

  const onCheckboxToggle = () => {
    toggleAttemptMarkedStatus(assessmentResult.assessmentUserId, !markedStatus).then(response => {
      if (response.ok) {
        setMarkedStatus(!markedStatus);
      }
    });
  };

  return (
    <tr
        className={styles.tableRow}
    >
      { isCandidate &&
        <td>
          <input
              type='checkbox'
              name='selected'
              checked={selected}
              onChange={() => onSelect(assessmentResult)}
          />
        </td>
      }
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            keepDefaultMargins={true}
        >
          { assessmentResult.firstName + ' ' + assessmentResult.lastName }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            keepDefaultMargins={true}
        >
          { assessmentResult.endDate && new Date(assessmentResult.endDate * 1000).toDateString() }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            keepDefaultMargins={true}
        >
          { assessmentResult.maxScore && Math.round((assessmentResult.score/assessmentResult.maxScore)*100) }%
        </SecondaryTypography.XSmall>
      </td>
      { skillIndex !== undefined &&
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            keepDefaultMargins={true}
        >
          { assessmentResult.skills && assessmentResult.skills[skillIndex].correctCount }/
          { assessmentResult.skills && assessmentResult.skills[skillIndex].totalCount }
        </SecondaryTypography.XSmall>
      </td>
      }
      { displaySkillLapsedTime &&
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            keepDefaultMargins={true}
        >
          { skillIndex !== undefined ?
            assessmentResult.skills && convertSecondsToTimeTaken(assessmentResult.skills[skillIndex].timeTakenForSkill) :
            convertSecondsToTimeTaken(assessmentResult.completionTime, true)
          }
        </SecondaryTypography.XSmall>
      </td>
      }
      <td>
        <ResultStatusCapsule
            status={ResultStatus.PASS}
        />
      </td>
      <td>
        <input
            type='checkbox'
            name='marked'
            checked={markedStatus}
            onChange={onCheckboxToggle}
        />
      </td>
      <td
          className='flex justify-center items-center gap-2 cursor-pointer text-sky-500'
          onClick={() => history.push(`/view-assessment-result/${assessmentResult.assessmentUserId}`)}
      >
        <SecondaryTypography.XSmall
            fontWeight='bold'
            alignment='center'
            keepDefaultMargins={true}
        >
          View details
        </SecondaryTypography.XSmall>
        <ArrowRight className='h-4 w-4'/>
      </td>
    </tr>
  );
};

