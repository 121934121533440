import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import loginRegisterBg from '../../assets/images/backgrounds/login_register_bg.png';
import logoBlackText from '../../assets/images/logos/white-bg-logo-hiacuity.png';
import { Button } from '../../ui/button/button';
import { TextInput } from '../../ui/input/text_input/text_input';
import { createUser, getUser, loginUser } from '../../utils/api_service/user_api';
import { FormInput } from '../../utils/types/login_register_types';
import styles from './login_register.module.css';
import { validate, validateForm } from './validation/validation_rules';

type UserLandingParams = {
    token: string,
    email: string,
    firstname: string,
    lastname: string
}

export const UserLanding = () => {
  const history = useHistory();
  const { token, email, firstname, lastname } = useParams<UserLandingParams>();
  const [landingInputs, setLandingInputs] = useState<FormInput>({
    email: email,
    firstname: firstname,
    lastname: lastname,
    password: '',
    confirmPassword: '',
    errors: {
      emailError: '',
      passwordError: '',
      confirmPasswordError: '',
    },
  });

  useEffect(() => {
    localStorage.setItem('token', token);
    getUser().then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to fetch user data');
      }
    }).then(data => {
      if (data && data.isVerified) {
        localStorage.clear();
        history.push(`/login/${email}`);
      }
    }).catch(error => {
      toast.error(error.message, { position: 'bottom-center' });
      history.push('/login');
    });
  }, [token, email, history]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let errors = validate(event.target, landingInputs);

    setLandingInputs({
      ...landingInputs,
      [event.target.name]: event.target.value,
      errors: errors,
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isSubmittedFormValid = validateForm(landingInputs);
    if (!isSubmittedFormValid) {
      toast.error('One or more of your details are invalid!', { position: 'bottom-center' });
      return;
    }

    try {
      const passwordChangeResponse = await createUser(landingInputs.password);
      if (!passwordChangeResponse.ok) {
        throw new Error('Password change failed');
      }

      const loginResponse = await loginUser(landingInputs);
      if (!loginResponse.success) {
        throw new Error('Entered email or password is incorrect or unverified');
      }

      localStorage.setItem('token', loginResponse.accessToken);

      history.push(`/terms-conditions/${loginResponse.accessToken}`);
    } catch (error: any) {
      toast.error(error.message, { position: 'bottom-center' });
    }
  };

  return (
    <div
        className={styles.pageContainer}
    >
      <div
          className={styles.mainContainer}
      >
        <div
            className={styles.leftContainer}
        >
          <img
              className={styles.leftContainerBg}
              src={loginRegisterBg}
              alt='HiAcuity-Bg'
          />
        </div>
        <div
            className={styles.formContainer}
        >
          <img
              className={styles.logoStyles}
              src={logoBlackText}
              alt='HiAcuity-Logo'
          />
          <h1
              className={styles.formHeading}
          >
            Signup
          </h1>
          <form
              className={styles.form}
              onSubmit={handleSubmit}
              data-testid='user-landing-form'
          >
            <div
                className={styles.nameFieldContainer}
            >
              <TextInput
                  type='text'
                  label='Firstname'
                  name='firstname'
                  value={landingInputs.firstname || ''}
                  onChange={handleChange}
                  required={true}
                  disabled={true}
              />
              <TextInput
                  type='text'
                  label='Lastname'
                  name='lastname'
                  value={landingInputs.lastname || ''}
                  onChange={handleChange}
                  required={true}
                  disabled={true}
              />
            </div>
            <TextInput
                type='email'
                label='Email'
                data-testid='email'
                name='email'
                value={landingInputs.email}
                onChange={handleChange}
                disabled={true}
            />
            <h1
                className={styles.formHeading}
            >
              Set Password
            </h1>
            <TextInput
                type='password'
                label='Password'
                data-testid='password'
                name='password'
                value={landingInputs.password}
                onChange={handleChange}
            />
            <span
                className={styles.invalidInput}
            >
              &nbsp;{ landingInputs.errors.passwordError || '' }
            </span>
            <TextInput
                type='password'
                label='Confirm Password'
                data-testid='password'
                name='confirmPassword'
                value={landingInputs.confirmPassword || ''}
                onChange={handleChange}
            />
            <span
                className={styles.invalidInput}
            >
              &nbsp;{ landingInputs.errors.confirmPasswordError || '' }
            </span>
            <p
                className={styles.portalSwitcher}
            >
              Already have an account? <a href='/login'>Sign-In</a>
            </p>
            <Button
                onClick={() => {}}
                size='medium'
                variant='primary'
                customClass={styles.submitButton}
            >
              Create Account
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
