import React from 'react';
import emailSuccessImage from '../../../../assets/images/graphics/email-success.svg';
import styles from '../../verify_email.module.css';

type SuccessNoticeProps = {
    navigateToSignIn: () => void,
}

export const SuccessNotice = ({
  navigateToSignIn,
}: SuccessNoticeProps) => (
  <div
      className={styles.noticeContainer}
  >
    <img
        src={emailSuccessImage}
        alt='email-verify'
        width='250px'
    />
    <h1
        className={styles.verificationNoticeHeader}
    >
      Email Verification Successful!
    </h1>
    <p
        className={styles.verificationNoticeInfo}
    >
      <a
          className={styles.resendLink}
          onClick={navigateToSignIn}
      >
        Click Here
      </a>
      &nbsp;to Log-in to your account.
    </p>
  </div>
);
