export const DashletTitles: any = {
  'HoursSaved': 'Hours Saved',
  'Assessments': '# of Assessments',
  'AssessmentSessions': '# of Assessment Sessions',
  'AssessmentType': 'Assessment Type',
  'TopJobTitle': 'Job Title',
  'TopSkill': 'Skill',
  'AssessmentSessionsTimeBased': 'Assessment Sessions - Time Based',
  'HoursSavedTimeBased': 'Hours Saved - Time Based',
  'AssessmentStatus': 'Current Assessment Status',
  'ParticipantsInvited': 'Participants Invited',
  'ParticipantsTopScore': 'Top Score',
  'ParticipantsQuickestTime': 'Quickest Time',
  'AverageResultSkill': 'Average Result Skill',
  'CountSkills': 'Skills Breakdown',
  'AverageSpeedSkill': 'Average Participation Speed',
  'Top10Participants': 'Top 10 Participants',
  'DominantEmotion': 'Dominant Emotion',
  'SessionDistribution': 'Assessment Sessions - Distribution',
};

export const defaultDashletOrder = [
  'HoursSavedTotal',
  'HoursSavedEmployees',
  'HoursSavedJobCandidates',
  'HoursSavedPartners',
  'MoneySavedTotal',
  'MoneySavedEmployees',
  'MoneySavedJobCandidates',
  'MoneySavedPartners',
  'HoursSaved',
  'Assessments',
  'AssessmentSessions',
  'TopJobTitle',
  'AssessmentType',
  'TopSkill',
  'AssessmentSessionsTimeBased',
  'HoursSavedTimeBased',
];

export const defaultValues: any = {
  'HoursSavedTotal': {
    name: 'Total',
    value: 0,
  },
  'HoursSavedEmployees': {
    name: 'Employees',
    value: 0,
  },
  'HoursSavedJobCandidates': {
    name: 'Job Candidates',
    value: 0,
  },
  'HoursSavedPartners': {
    name: 'Partners',
    value: 0,
  },
  'MoneySavedTotal': {
    name: 'Total',
    value: 0,
    currency: '',
  },
  'MoneySavedEmployees': {
    name: 'Employees',
    value: 0,
    currency: '',
  },
  'MoneySavedJobCandidates': {
    name: 'Job Candidates',
    value: 0,
    currency: '',
  },
  'MoneySavedPartners': {
    name: 'Partners',
    value: 0,
    currency: '',
  },
  'HoursSaved': [],
  'Assessments': [],
  'AssessmentSessions': [],
  'TopJobTitle': [],
  'AssessmentType': [],
  'TopSkill': [],
  'AssessmentSessionsTimeBased': [],
  'HoursSavedTimeBased': [],
};

export const defaultChartOrder = [
  {
    chartId: 'HoursSavedTotal',
    hidden: false,
  },
  {
    chartId: 'HoursSavedEmployees',
    hidden: false,
  },
  {
    chartId: 'HoursSavedJobCandidates',
    hidden: false,
  },
  {
    chartId: 'HoursSavedPartners',
    hidden: false,
  },
  {
    chartId: 'MoneySavedTotal',
    hidden: false,
  },
  {
    chartId: 'MoneySavedEmployees',
    hidden: false,
  },
  {
    chartId: 'MoneySavedJobCandidates',
    hidden: false,
  },
  {
    chartId: 'MoneySavedPartners',
    hidden: false,
  },
  {
    chartId: 'HoursSaved',
    hidden: false,
  },
  {
    chartId: 'Assessments',
    hidden: false,
  },
  {
    chartId: 'AssessmentSessions',
    hidden: false,
  },
  {
    chartId: 'TopJobTitle',
    hidden: false,
  },
  {
    chartId: 'AssessmentType',
    hidden: false,
  },
  {
    chartId: 'TopSkill',
    hidden: false,
  },
  {
    chartId: 'AssessmentSessionsTimeBased',
    hidden: false,
  },
  {
    chartId: 'HoursSavedTimeBased',
    hidden: false,
  },
];

export const defaultChartOrderAssessment = [
  {
    chartId: 'AssessmentStatus',
    hidden: false,
  },
  {
    chartId: 'ParticipantsInvited',
    hidden: false,
  },
  {
    chartId: 'ParticipantsTopScore',
    hidden: false,
  },
  {
    chartId: 'ParticipantsQuickestTime',
    hidden: false,
  },
  {
    chartId: 'DominantEmotion',
    hidden: false,
  },
  {
    chartId: 'AverageResultSkill',
    hidden: false,
  },
  {
    chartId: 'CountSkills',
    hidden: false,
  },
  {
    chartId: 'AverageSpeedSkill',
    hidden: false,
  },
  {
    chartId: 'SessionDistribution',
    hidden: false,
  },
  {
    chartId: 'Top10Participants',
    hidden: false,
  },
];
