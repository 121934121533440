import { createMultiPartRequest, createRequest } from '../helpers/api_utils';
import { Question, QuestionSearch } from '../types/assessment_types';
import { QuestionGeneratorJob, Settings } from '../types/content_manager_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/job';

interface JobRequestBody {
  title: string;
  topic: string | undefined;
  generationType: string;
  settings: RequestSettings;
}

interface RequestSettings {
  level: string | undefined;
  jobTitle: string;
  questionType: string | undefined;
  time: number | undefined;
  points: number | undefined;
}

export function searchQuestions(searchObject: QuestionSearch) {
  return fetch(createRequest(apiEndpoint + '/title/qna', 'POST', 'application/json', true, searchObject))
    .then(response => {
      return response.json();
    });
}

export function searchPaginatedQuestions(searchObject: QuestionSearch) {
  return fetch(createRequest(apiEndpoint + '/title/paginated/qna', 'POST', 'application/json', true, searchObject))
    .then(response => {
      return response.json();
    });
}

export function addQuestion(question: Question) {
  return fetch(createRequest(apiEndpoint + '/qna', 'POST', 'application/json', true, question))
    .then(response => {
      return response.json();
    });
}

export function updateQuestion(question: Question) {
  return fetch(createRequest(apiEndpoint + '/qna', 'PUT', 'application/json', true, question))
    .then(response => {
      return response.json();
    });
}

export function deleteQuestion(id: string) {
  return fetch(createRequest(apiEndpoint + `/qna/delete/${id}`, 'DELETE', 'application/json', true));
}

export function getJobQuestions(jobId: string) {
  return fetch(createRequest(apiEndpoint + `/qna/list/${jobId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function getJobList(page: number = 1, limit: number = 10, keyword?: string, generation_type?: string | null) {
  let queryParams = `?page=${page}&limit=${limit}`;
  if (keyword) {
    queryParams += `&keyword=${encodeURIComponent(keyword)}`;
  }
  if (generation_type) {
    queryParams += `&generation_type=${encodeURIComponent(generation_type)}`;
  }

  const url = apiEndpoint + '/list' + queryParams;

  return fetch(createRequest(url, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function uploadFile(file: File, topic: string, title:string) {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('topic', topic);
  formData.append('title', title);
  return fetch(createMultiPartRequest(apiEndpoint + '/file', 'POST', true, formData))
    .then(response => {
      return response.json();
    }).catch((error) => {
      return error;
    });
}

export function getJob(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function putJob(id: string, job: QuestionGeneratorJob) {
  let body : JobRequestBody = {
    title: job.title,
    topic: job.topic,
    generationType: job.generationType,
    settings: {
      level: job.settings?.level,
      jobTitle: job.settings?.jobTitle as string,
      questionType: job.settings?.questionType,
      time: job.settings?.time,
      points: job.settings?.points,
    },
  };
  return fetch(createRequest(apiEndpoint + `/${id}`, 'PUT', 'application/json', true, body))
    .then(response => {
      return response.json();
    });
}

export function createJob(title: string, topic: string, type: string, settings: Settings) {
  let body : JobRequestBody = {
    title: title,
    topic: topic,
    generationType: type,
    settings: {
      level: settings?.level,
      jobTitle: settings?.jobTitle as string,
      questionType: settings?.questionType,
      time: settings?.time,
      points: settings?.points,
    },
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body))
    .then(response => {
      return response.json();
    });
}

export function getJobStatus(fileHash: string) {
  return fetch(createRequest(apiEndpoint + `/history/${fileHash}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function generateQuestions(jobId: string) {
  return fetch(createRequest(apiEndpoint + `/qna/generate/${jobId}`, 'POST', 'application/json', true));
}

export function exportQuestions(jobId: string, questionIds: Array<string>) {
  let body = {
    jobId: jobId,
    questionIds: questionIds,
  };
  return fetch(createRequest(apiEndpoint + '/export/qna', 'POST', 'application/json', true, body))
    .then(response => {
      return response.json();
    });
}
