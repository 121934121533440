import React from 'react';
import { useHistory } from 'react-router-dom';
import takingAssessment from '../../../../assets/images/graphics/taking-assessment.svg';
import { unixToCurrentTimezone } from '../../../../utils/helpers/time';
import { ParticipantCardData } from '../index';

type ParticipantCardProps = {
  userAssessment: ParticipantCardData,
}

type StatusMapping = {
  [status: string]: { action: string; buttonLabel: string };
};

const isPast = (endDate: number): boolean => {
  const currentUnixTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
  return endDate < currentUnixTimestamp;
};

const statusMappings: StatusMapping = {
  'Assigned': { action: 'Please start assessment', buttonLabel: 'Start' },
  'In Progress': { action: 'Please continue', buttonLabel: 'Continue' },
  'Expired': { action: 'None', buttonLabel: 'None' },
  'Completed': { action: 'None', buttonLabel: 'None' },
};

const ParticipantCard = ({ userAssessment }: ParticipantCardProps) => {
  const history = useHistory();
  const assessmentExpired = isPast(userAssessment.endDate);

  if (assessmentExpired) {
    userAssessment.status = 'Expired';
  }

  const buttonClick = () => {
    history.push('/assigned-assessments');
  };

  return (
    <div className='relative flex gap-4 shadow-2xl rounded-lg p-5 h-60'>
      <div className='hidden md:w-1/3 md:h-1/6 md:block text-center'>
        <img src={takingAssessment} alt='Taking Assessment' className='h-44'/>
      </div>
      <div className='flex flex-col gap-1 w-2/3 text-left text-sm'>
        <span className='text-lg font-semibold pb-2'>{ userAssessment.assessmentTitle }</span>
        <span className={userAssessment.isActive ? 'text-green-800' : 'text-red-800'}>{ userAssessment.isActive ? 'Active' : 'Not Active' }</span>
        <div>
          <span className='text-green-500'>{ unixToCurrentTimezone(userAssessment.startDate) }</span> - <span
              className='text-red-500'>{ unixToCurrentTimezone(userAssessment.endDate) }</span>
        </div>
        <span>Status: { userAssessment.status }</span>
        <span className='mb-10'>Action Required: { statusMappings[userAssessment.status]['action'] }</span>
        <div className='h-1/4'>
          { (userAssessment.status !== 'Expired' && userAssessment.status !== 'Completed') && (
            <div className='absolute bottom-0 right-0 text-right m-4 mt-6'>
              <button
                  className={`text-white rounded-md text-base px-3 py-1 w-24 ${!userAssessment.isActive ? 'bg-gray-300' : 'bg-primary-green-400 hover:bg-primary-green-300'}`}
                  disabled={!userAssessment.isActive}
                  onClick={() => buttonClick()}
              >
                { statusMappings[userAssessment.status]['buttonLabel'] }
              </button>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default ParticipantCard;
