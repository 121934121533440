import React, { useState } from 'react';
import profileImage from '../../assets/images/logos/profile-image.png';
import { Modal } from '../../ui/modal/modal';
import { PrimaryTypography, SecondaryTypography } from '../../ui/typography/typography';
import { CollapsibleDetails } from './CollapsibleDetails';
import { LiveCameraModal } from './LiveCameraModal/LiveCameraModal';
import styles from './Profile.module.css';

export const Profile = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [firstNameInput, setFirstNameInput] = useState<string>(
    () => localStorage.getItem('firstname') || '',
  );
  const [lastNameInput, setLastNameInput] = useState<string>(
    () => localStorage.getItem('lastname') || '',
  );
  const [profileLink, setProfileLink] = useState<string>(
    () => localStorage.getItem('profilePic') || '',
  );

  const loadModalContent = () => {
    return (
      <LiveCameraModal
          setProfileLink={setProfileLink}
          setIsModalOpen={setIsModalOpen}
      />
    );
  };

  return (
    <div
        className={styles.mainContainer}
    >
      <PrimaryTypography.FourXLarge
          fontWeight='bold'
          className={styles.topTitle}
      >
        Admin
      </PrimaryTypography.FourXLarge>
      <PrimaryTypography.SevenXLarge
          fontWeight='bold'
          className={styles.heading}
      >
        Profile Settings
      </PrimaryTypography.SevenXLarge>
      <div
          className={styles.questionBox}>
      </div>
      <div
          className={styles.pageContainer}
      >
        <Modal
            visible={isModalOpen}
            toggle={() => setIsModalOpen(false)}
            modalContent={loadModalContent()}
            customClass={styles.modalCustomClass}
            headerClass={styles.headerBackground}
            headerText={'Update Profile Picture'}
        />
        <div
            className={styles.userContainer}
        >
          <div
              className={styles.detailsContainer}
          >
            <div
                className={styles.profilePicContainer}>
              { profileLink==='null' ? (
                <img
                    src={profileImage}
                    alt='Avatar'
                />
              ) : (
                <img
                    src={profileLink}
                    alt='Avatar'
                />
              ) }
              <div
                  className={styles.overlay} onClick={() => {setIsModalOpen(true);}}>
                <span
                    className={styles.overlayText}>
                  <SecondaryTypography.Medium
                      alignment='center'
                  >
                    Click to change profile picture
                  </SecondaryTypography.Medium>
                </span>
              </div>
            </div>
            <div>
              <SecondaryTypography.Medium
                  fontWeight='bold'
                  alignment='center'
              >
                { firstNameInput } { lastNameInput }
              </SecondaryTypography.Medium>
            </div>
            <div>
              <SecondaryTypography.Medium
                  fontWeight='bold'
                  alignment='center'
              >
                { localStorage.getItem('email') }
              </SecondaryTypography.Medium>
            </div>
            <div
                className={styles.profileInfo}>
              <div
                  className={styles.profileDetail}>
                <SecondaryTypography.Medium
                    fontWeight='bold'
                >
                  First name
                </SecondaryTypography.Medium>
                <SecondaryTypography.Medium
                >
                  { localStorage.getItem('firstname') }
                </SecondaryTypography.Medium>
              </div>
              <div
                  className={styles.profileDetail}>
                <SecondaryTypography.Medium
                    fontWeight='bold'
                >
                  Last name
                </SecondaryTypography.Medium>
                <SecondaryTypography.Medium
                >
                  { localStorage.getItem('lastname') }
                </SecondaryTypography.Medium>
              </div>
              <div
                  className={styles.profileDetail}>
                <SecondaryTypography.Medium
                    fontWeight='bold'
                >
                  Email address
                </SecondaryTypography.Medium>
                <SecondaryTypography.Medium
                >
                  { localStorage.getItem('email') }
                </SecondaryTypography.Medium>
              </div>
            </div>
          </div>
        </div>
        <div
            className={styles.collapsibleSection}>
          <div
              className={styles.collapsibleArea}>
            <CollapsibleDetails
                open={true}
                title='Account Settings'
                firstName={String(localStorage.getItem('firstname'))}
                lastName={String(localStorage.getItem('lastname'))}
                privacy={false}
                setFirstNameInput={setFirstNameInput}
                setLastNameInput={setLastNameInput}
            />
          </div>
          <div
              className={styles.collapsibleArea}>
            <CollapsibleDetails
                open={false}
                title='Privacy Settings'
                privacy={true}
                setFirstNameInput={setFirstNameInput}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
