const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getPermissions = (token: string) => {
  const scope = parseJwt(token).scope.trim();
  return scope === '' ? [] : scope.split(' ');
};

export const isUserLoggedIn = () => {
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');
  let loggedState: boolean;
  if (token && id) {
    let decoded = parseJwt(token);
    loggedState = Date.now() < decoded.exp * 1000;
  } else {
    loggedState = false;
  }
  return loggedState;
};

export const getOrgUserId = () => {
  const token = localStorage.getItem('token');
  let orgUserId: string;
  if (token) {
    let decoded = parseJwt(token);
    orgUserId = decoded.org_user_id;
  } else {
    orgUserId = '';
  }
  return orgUserId;
};
