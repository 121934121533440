import React from 'react';
import { Permission } from '../../utils/helpers/permissions';
import { Features } from '../../utils/helpers/subscriptionEnum';
import { AnalyticsIcon, BriefCaseIcon, ClipBoardIcon, CogIcon, HomeIcon, QuestionMark } from '../icons/icons';

export const SidebarData = [
  {
    title: 'Home',
    icon: <HomeIcon className='stroke-white h-6 w-6' />,
    assignedPermissions: [],
    matchAnyPermission: false,
    navLinks: [
      {
        label: 'Home',
        path: '/home',
        assignedPermissions: [],
      },
      {
        label: 'User Profile',
        path: '/user-profile',
        assignedPermissions: [],
      },
    ],
  },
  {
    title: 'Recruitment',
    icon: <BriefCaseIcon className='stroke-white h-6 w-6' />,
    assignedPermissions: [],
    matchAnyPermission: false,
    navLinks: [
      {
        label: 'Create Job Openings',
        path: '/job-openings',
        assignedPermissions: [],
      },
      {
        label: 'View Job Openings',
        path: '/job-openings-list',
        assignedPermissions: [],
      },
    ],
  },
  {
    title: 'Assessment',
    icon: <ClipBoardIcon className='stroke-white h-6 w-6' />,
    assignedPermissions: [],
    matchAnyPermission: false,
    navLinks: [
      {
        label: 'Create Assessment',
        path: '/assessment',
        assignedPermissions: [Permission.ASSESSMENTS_CREATE, Permission.QUESTIONS_VIEW],
        assignedSubscription: 'ANY',
      },
      {
        label: 'Assessment List',
        path: '/assessment-list',
        assignedPermissions: [Permission.ASSESSMENTS_VIEW],
      },
      {
        label: 'Assigned Assessments',
        path: '/assigned-assessments',
        assignedPermissions: [],
      },
      {
        label: 'Practice Assessment',
        path: '/assigned-assessments?practice=true',
        assignedPermissions: [],
      },
    ],
  },
  {
    title: 'Analytics',
    icon: <AnalyticsIcon className='stroke-white h-5 w-5' />,
    assignedPermissions: [Permission.ENTERPRISE_ANALYTICS_VIEW, Permission.ORGANISATION_ANALYTICS_VIEW],
    matchAnyPermission: true,
    navLinks: [
      {
        label: 'Enterprise Analytics',
        path: '/enterprise-analytics',
        assignedPermissions: [Permission.ENTERPRISE_ANALYTICS_VIEW],
      },
      {
        label: 'Organisation Analytics',
        path: '/organisation-analytics',
        assignedPermissions: [Permission.ORGANISATION_ANALYTICS_VIEW],
      },
      {
        label: 'Assessment Analytics',
        path: '/assessment-analytics',
        assignedPermissions: [Permission.ORGANISATION_ANALYTICS_VIEW],
      },
    ],
  },
  {
    title: 'Questions',
    icon: <QuestionMark className='stroke-white h-6 w-6' />,
    assignedPermissions: [Permission.QUESTIONS_VIEW],
    matchAnyPermission: false,
    navLinks: [
      {
        label: 'Question Bank',
        path: '/question-bank',
        assignedPermissions: [Permission.QUESTIONS_VIEW],
      },
      {
        label: 'Content Manager',
        path: '/content-manager',
        assignedPermissions: [Permission.JOB_VIEW],
      },
      {
        label: 'Auto Generate Questions',
        path: '/upload-file',
        assignedPermissions: [Permission.JOB_CREATE],
      },
    ],
  },
  {
    title: 'Admin',
    icon: <CogIcon className='stroke-white h-6 w-6' />,
    assignedPermissions: [Permission.ROLES_VIEW, Permission.USERS_VIEW, Permission.GROUPS_VIEW],
    matchAnyPermission: true,
    navLinks: [
      {
        label: 'Users/Groups',
        path: '/access-management',
        assignedPermissions: [],
      },
      {
        label: 'Organization Settings',
        path: '/organization-settings',
        assignedPermissions: [Permission.ORGUNIT_VIEW],
      },
      {
        label: 'Assessment Framework',
        path: '/assessment-framework',
        assignedPermissions: [],
      },
      {
        label: 'Employee Management',
        path: '/employee-management',
        assignedPermissions: [Permission.EMPLOYEE_VIEW],
        assignedSubscription: Features.EMPLOYEE,
      },
      {
        label: 'Candidate Management',
        path: '/candidate-management',
        assignedPermissions: [Permission.CANDIDATE_VIEW],
        assignedSubscription: Features.CANDIDATE,
      },
      {
        label: 'Partner Management',
        path: '/partner-management',
        assignedPermissions: [Permission.PARTNER_VIEW],
        assignedSubscription: Features.PARTNER,
      },
      {
        label: 'Analytics Settings',
        path: '/analytics-settings',
        assignedPermissions: [Permission.ENTERPRISE_ANALYTICS_EDIT],
      },
    ],
  },
];
