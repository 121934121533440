import React from 'react';
import { SearchIconNewUI } from '../../../../ui/icons/icons';

type SearchBarProps = {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}
const SearchBar = ({ setSearchTerm }: SearchBarProps) => {
  return (
    <div className='relative w-full'>
      <div className='absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none'>
        <SearchIconNewUI className='h-6 w-6 text-gray-500'/>
      </div>
      <input
          type='text'
          className='text-xl font-light w-full p-2 pl-14 pr-10 rounded-md border border-gray-300 outline-none'
          onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
