import React, { useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import AsyncCustomSelect from '../../../../ui/custom_react_select/async_select';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { getEmployeeList } from '../../../../utils/api_service/employees';
import { debounce } from '../../../../utils/helpers/debounce';
import { EmployeeUser, JobTitle, SingleEmployee, UserGroup , UserRole } from '../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import styles from '../../access_management/modals.module.css';
import { OrganizationUnit } from '../../OrganizationSettings/TreeStructure';

type EditEmployeeModalProps = {
    employeeUsers: EmployeeUser[],
    organizationUnits: OrganizationUnit[],
    jobTitles: JobTitle[],
    userGroupList: UserGroup[],
    userRoleList: UserRole[],
    singleEmployee: SingleEmployee | null,
    employee: EmployeeUser,
    addNewJobTitle: (title: string) => void,
    editUser: (employee: EmployeeUser, groups: SelectOptions[], roles: SelectOptions[], selectedJobTitle: string, organizationUnit: string, reportingManager: string | undefined) => void,
}

export const EditEmployeeModal = ({
  employeeUsers,
  organizationUnits,
  jobTitles,
  userGroupList,
  userRoleList,
  singleEmployee,
  employee,
  addNewJobTitle,
  editUser,
}: EditEmployeeModalProps) => {
  const [selectedGroups, setSelectedGroups] = useState(() =>
    employee?.groups.map((group) => ({
      value: group.id,
      label: group.groupName,
    })),
  );
  const [selectedRoles, setSelectedRoles] = useState(() =>
    employee?.roles.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  );
  const [reportingManager, setReportingManager] = useState<SelectOptions | undefined>(() => {
    if (singleEmployee && singleEmployee.reportingManager) {
      const manager = singleEmployee.reportingManager;
      return { value: manager.id, label: manager.user.firstName + ' ' + manager.user.lastName };
    }
  });
  const [organizationUnit, setOrganizationUnit] = useState<SelectOptions | undefined>(() => {
    if (singleEmployee && singleEmployee.organizationUnitId) {
      const orgUnit = singleEmployee.organizationUnitId;
      return { value: orgUnit.id, label: orgUnit.unitName };
    }
  });
  const [selectedJobTitle, setSelectedJobTitle] = useState<SelectOptions | undefined>(() => {
    if (employee.jobTitle && jobTitles.length > 0) {
      const title = jobTitles.find((title) => title.id === employee.jobTitle.id);
      if (title) {
        return { value: title.id, label: title.jobTitle };
      }
    }
  });

  const defaultManagerOptions = employeeUsers.map((employee) => ({
    value: employee.id,
    label: employee.user.firstName + ' ' + employee.user.lastName,
  }));

  const managerLoadOptions = async (query: string) => {
    try {
      const response = await getEmployeeList(query, 1, 10);
      if (response.ok) {
        const data = await response.json();

        return data.employee.map((employee: EmployeeUser) => ({
          value: employee.id,
          label: `${employee.user.firstName} ${employee.user.lastName}`,
        }));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
      return [];
    }
  };

  const loadOptionsDebounced =  debounce((inputValue: string, callback: (options: any) => void) => {
    managerLoadOptions(inputValue).then(options => callback(options));
  }, 500);

  const groupOptions = useMemo(() =>
    userGroupList.map((group) => ({
      value: group.id,
      label: group.groupName,
    })),
  [userGroupList],
  );

  const roleOptions = useMemo(() =>
    userRoleList.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  [userRoleList],
  );

  const organizationUnitOptions = useMemo(() =>
    organizationUnits.map((unit) => ({
      value: unit.id,
      label: unit.unitName,
    })),
  [organizationUnits],
  );

  const jobTitleOptions = useMemo(() =>
    jobTitles.map((title) => ({
      value: title.id,
      label: title.jobTitle,
    })),
  [jobTitles],
  );

  const handleGroupChange = (selectedOptions: SelectOptions[]) => {
    setSelectedGroups(selectedOptions);
  };

  const handleRoleChange = (selectedOptions: SelectOptions[]) => {
    setSelectedRoles(selectedOptions);
  };

  const handleManagerChange = (selectedOptions: SelectOptions) => {
    setReportingManager(selectedOptions);
  };

  const handleUnitChange = (selectedOptions: SelectOptions) => {
    setOrganizationUnit(selectedOptions);
  };

  const handleTitleChange = (selectedOptions: SelectOptions) => {
    setSelectedJobTitle(selectedOptions);
  };

  return (
    <>
      <div
          className={styles.modalInputContainer}
      >
        <CustomSelect
            name='jobTitle'
            options={jobTitleOptions}
            onChange={handleTitleChange}
            label={'Job Title'}
            value={selectedJobTitle}
            creatable
            onCreateOption={addNewJobTitle}
        />
        <AsyncCustomSelect
            value={reportingManager}
            defaultOptions={defaultManagerOptions}
            onChange={handleManagerChange}
            loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
            label={'Reporting Manager'}
        />
        <CustomSelect
            name='organizationUnit'
            options={organizationUnitOptions}
            onChange={handleUnitChange}
            label={'Organization Unit'}
            value={organizationUnit}
        />
        <CustomSelect
            name='groups'
            options={groupOptions}
            onChange={handleGroupChange}
            isMulti={true}
            label={'Group Membership'}
            value={selectedGroups}
        />
        <CustomSelect
            name='roles'
            options={roleOptions}
            onChange={handleRoleChange}
            isMulti={true}
            label={'Role Membership'}
            value={selectedRoles}
        />
      </div>
      <div>
        <Button
            onClick={() => organizationUnit && selectedJobTitle &&
              editUser(employee, selectedGroups, selectedRoles, selectedJobTitle.value, organizationUnit.value,
                reportingManager !== null && reportingManager !== undefined ? reportingManager.value : undefined)}
            variant={'primary'}
            size={'medium'}
            disabled={!(organizationUnit !== undefined && selectedJobTitle !== undefined)}
        >
          Edit Employee
        </Button>
      </div>
    </>
  );
};
