let hostname = process.env.REACT_APP_API_URL || 'localhost:8000';
let faceAPIUrl = process.env.REACT_APP_FACE_API_URL || 'face-detection-service.dev.hiacuity.com';
let proto = (hostname: string):string[] => {
  if (hostname.indexOf('localhost') !== -1) {
    return ['http','ws'];
  }
  return ['https', 'wss'];
};
export const apiUrl = `${proto(hostname)[0]}://${hostname}`;
export const wsUrl  = `${proto(hostname)[1]}://${hostname}/ws`;
export const faceUrl = `${proto(faceAPIUrl)[0]}://${faceAPIUrl}`;
