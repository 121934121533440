import React from 'react';
import { SidebarData } from './sidebar_data';
import { SideMenu } from './sidemenu/sidemenu';

export const SideBar = () => {

  return (
    <>
      <SideMenu
          sidebarData={SidebarData}
      />
    </>
  );
};
