import classNames from 'classnames';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuContext } from '../../../../utils/contexts/contexts';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import { ChevronLeft, ChevronRight } from '../../../icons/icons';
import { SideBarItem } from '../sidemenu';
import styles from './submenu.module.css';

type SubMenuProps = {
  menuOpen: boolean,
  items: SideBarItem,
}

export const SubMenu = ({
  menuOpen,
  items,
}: SubMenuProps) => {
  const history = useHistory();
  const location = useLocation();
  const { toggleMenuState } = useContext(MenuContext);
  return (
    <nav
        className={`h-full border-r ${menuOpen ? 'w-64': 'w-4'}`}
    >
      <div
          className={`w-6 h-6 relative top-4 ${menuOpen ? 'float-right': ''} rounded-full border cursor-pointer flex justify-center items-center bg-white shadow-lg`}
          onClick={toggleMenuState}
      >
        { menuOpen ? <ChevronLeft className='h-4 w-4'/>:<ChevronRight className='h-4 w-4'/> }
      </div>
      <ul
          className={`w-full h-full ${!menuOpen ? 'hidden': ''}`}
      >
        <h4
            className={styles.subMenuHeader}
        >
          { items.title }
        </h4>
        { items.navLinks && items.navLinks.map(navItem => (
          <PermissionGate
              key={navItem.path}
              requiredPermissions={navItem.assignedPermissions}
              requiredFeature={navItem.assignedSubscription}
          >
            <li>
              <a
                  className={classNames(styles.menuSubItemLink, { [styles.selected]: location.pathname === navItem.path })}
                  aria-label={navItem.label}
                  onClick={() => history.push(navItem.path)}
              >
                { navItem.label }
              </a>
            </li>
          </PermissionGate>
        )) }
      </ul>
    </nav>
  );
};
