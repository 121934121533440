import React from 'react';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { extractEntityValues, getFormattedEntityList } from './getFormattedEntityList';
import { handleCheckboxChange, handleHeaderCheckboxChange } from './modal_utils';
import styles from './select_permissions.module.css';

type SelectPermissionsProps = {
    selectedActions: string[],
    setSelectedActions: React.Dispatch<React.SetStateAction<string[]>>,
    entities: string[],
    actions: string[],
}

export const SelectPermissions = ({
  selectedActions,
  setSelectedActions,
  entities,
  actions,
}: SelectPermissionsProps) => (
  <table
      className={styles.tableStyle}
      style={{ width: '100%' }}
  >
    <thead>
      <tr>
        <th></th>
        { actions.map((action) => (
          <th
              key={action}
          >
            <SecondaryTypography.Small
                textCase='capitalize'
                fontWeight='semi-bold'
                alignment='center'
            >
              { action }
            </SecondaryTypography.Small>
          </th>
        )) }
      </tr>
      <tr>
        <th></th>
        { actions.map((action) => (
          <th key={action}>
            <label>
              <input
                  type='checkbox'
                  checked={getFormattedEntityList(entities).every((entity) =>
                    selectedActions.includes(`${entity.entityValue}:${action}`),
                  )}
                  onChange={(e) =>
                    handleHeaderCheckboxChange(
                      action,
                      e.target.checked,
                      extractEntityValues(getFormattedEntityList(entities)),
                      selectedActions,
                      setSelectedActions,
                    )
                  }
              />
            </label>
          </th>
        )) }
      </tr>
    </thead>
    <tbody>
      { getFormattedEntityList(entities).map((entity) => (
        <tr key={entity.entityValue}>
          <td>
            <SecondaryTypography.Small
                textCase='capitalize'
            >
              { entity.entityLabel }
            </SecondaryTypography.Small>
          </td>
          { actions.map((action) => {
            const entityAction = `${entity.entityValue}:${action}`;
            const isChecked = selectedActions.includes(entityAction);
            return (
              <td key={entityAction}>
                <input
                    type='checkbox'
                    checked={isChecked}
                    onChange={(e) =>
                      handleCheckboxChange(
                        entityAction,
                        e.target.checked,
                        selectedActions,
                        setSelectedActions,
                      )
                    }
                />
              </td>
            );
          }) }
        </tr>
      )) }
    </tbody>
  </table>
);
