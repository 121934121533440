import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { AddIcon } from '../../../../ui/icons/icons';
import { Modal } from '../../../../ui/modal/modal';
import { PaginationController } from '../../../../ui/pagination_controller/pagination_controller';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { addPartnerEmployee, deletePartnerEmployee, editPartnerEmployee, getPartnerEmployees, getPartnerList } from '../../../../utils/api_service/partner_api';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import { fetchUserGroups } from '../../../../utils/redux_store/features/user_group_reducer';
import { fetchUserRoles } from '../../../../utils/redux_store/features/user_role_reducer';
import { RootState, store } from '../../../../utils/redux_store/store';
import { PartnerEntity, PartnerUser } from '../../../../utils/types/admin_types';
import { FormattedSelectOptions } from '../../../../utils/types/analytics/analytics_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import baseStyles from '../../admin.module.css';
import { AddPartnerEmployeeModal } from './Modals/AddPartnerEmployeeModal';
import { EditPartnerEmployeeModal } from './Modals/EditPartnerEmployee';
import { PartnerEmployeeRow } from './PartnerEmployeeRow';

export const PartnerEmployeeManagement = () => {
  const [partnerEmployees, setPartnerEmployees] = useState<PartnerUser[]>([]);
  const [partnerList, setPartnerList] = useState<PartnerEntity[]>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const userGroup = useSelector((state: RootState) => state.userGroups.userGroupList);
  const userRole = useSelector((state: RootState) => state.userRoles.userRoleList);
  const [addEmployeeModalVisible, setAddEmployeeModalVisible] = useState<boolean>(false);
  const [editEmployeeModalVisible, setEditEmployeeModalVisible] = useState<boolean>(false);
  const [editedPartnerEmployee, setEditedPartnerEmployee] = useState<PartnerUser>(partnerEmployees[0]);

  const fetchPartners = useCallback(async () => {
    try {
      const response = await getPartnerEmployees({ page: pageNumber, recordsPerPage: 8 });
      if (response.ok) {
        const data = await response.json();
        setPartnerEmployees(data.partner);
        setTotalPages(data.pages);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error fetching partners: ' + error, { position: 'bottom-center' });
    }
  }, [pageNumber]);

  useEffect(() => {
    fetchPartners();
  }, [pageNumber, fetchPartners]);

  useEffect(() => {
    store.dispatch(fetchUserGroups());
    store.dispatch(fetchUserRoles());
  }, []);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await getPartnerList({ subEntities: true });
        if (response.ok) {
          const data = await response.json();
          setPartnerList(data);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching products: ' + error, { position: 'bottom-center' });
      }
    };

    fetchPartners();
  }, []);

  const openEditModal = async (partnerEmployee: PartnerUser) => {
    // try {
    //   const response = await getPartnerEmployees(pageNumber, 8, partnerEmployee.id);
    //   if (response.ok) {
    //     const data = await response.json();
    //     setEditedPartnerEmployee(data);
    //     setEditEmployeeModalVisible(true);
    //   } else {
    //     const errorData = await response.json();
    //     toast.error(errorData.error, { position: 'bottom-center' });
    //   }
    // } catch (error) {
    //   toast.error('Error fetching products: ' + error, { position: 'bottom-center' });
    // }
    setEditedPartnerEmployee(partnerEmployee);
    setEditEmployeeModalVisible(true);
  };

  const createPartnerEmployee = async (email: string, firstName: string, lastName: string, partnerEntityId: FormattedSelectOptions, jobTitle: string) => {
    try {
      const response = await addPartnerEmployee(email, firstName, lastName, partnerEntityId, jobTitle);
      if (response.ok) {
        const data = await response.json();
        setPartnerEmployees([...partnerEmployees, data]);
        setAddEmployeeModalVisible(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error Adding Partner Employee: ' + error, { position: 'bottom-center' });
    }
  };

  const updatePartnerEmployee = async (id: string, partnerEntityId: FormattedSelectOptions, jobTitle: string, roles: SelectOptions[], groups: SelectOptions[]) => {
    try {
      const response = await editPartnerEmployee(id, partnerEntityId, jobTitle, roles.map(option => option.value), groups.map(option => option.value));
      if (response.ok) {
        const data = await response.json();
        const updatedPartnerEmployees = partnerEmployees.map((employee) =>
          employee.id === data.id ? { ...employee, ...data } : employee,
        );
        setPartnerEmployees(updatedPartnerEmployees);
        setEditEmployeeModalVisible(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error Editing Partner Employee: ' + error, { position: 'bottom-center' });
    }
  };

  const removePartnerEmployee = async (id: string) => {
    try {
      const response = await deletePartnerEmployee(id);
      if (response.ok) {
        const updatedPartnerEmployees = partnerEmployees.filter((employee) => employee.id !== id);
        setPartnerEmployees(updatedPartnerEmployees);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error Deleting Partner Employee: ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <div
        className={baseStyles.container}
    >
      <div
          className={baseStyles.contentContainer}
      >
        <SecondaryTypography.Medium
            keepDefaultMargins={true}
            fontWeight='bold'
        >
          Partner Employee Management
        </SecondaryTypography.Medium>
        <PermissionGate
            requiredPermissions={[Permission.PARTNER_CREATE]}
        >
          <Button
              icon={<AddIcon/>}
              onClick={() => { setAddEmployeeModalVisible(true); }}
              variant='primary'
              size='medium'
          >
            Add Partner Employee
          </Button>
        </PermissionGate>
        <table
            className={baseStyles.tableStyle}
        >
          <thead>
            <tr>
              <th
                  style={{ width: 0 }}
              >
                <input type='checkbox'/>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Name
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Email
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Groups
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Last Active
                </SecondaryTypography.XSmall>
              </th>
            </tr>
          </thead>
          <tbody>
            { partnerEmployees.map((partner: PartnerUser) => {
              return (
                <PartnerEmployeeRow
                    key={partner.id}
                    partner={partner}
                    deleteUser={removePartnerEmployee}
                    onEditClick={openEditModal}
                />
              );
            })
            }
          </tbody>
        </table>
        <PaginationController
            pageNumber={pageNumber}
            maxPageNumber={totalPages}
            navigateNextPage={() => setPageNumber((pageNumber) => pageNumber + 1)}
            navigatePreviousPage={() => setPageNumber((pageNumber) => pageNumber - 1)}
        />
        <Modal
            visible={addEmployeeModalVisible}
            modalContent={
              <AddPartnerEmployeeModal
                  partnerEntityList={partnerList || []}
                  addPartnerEmployee={createPartnerEmployee}
              />
            }
            toggle={() => setAddEmployeeModalVisible(false)}
            headerText='Invite New Partner Employee'
            headerClass={baseStyles.modalHeader}
        />
        <Modal
            visible={editEmployeeModalVisible}
            modalContent={
              <EditPartnerEmployeeModal
                  partnerEntityList={partnerList || []}
                  partnerEmployee={editedPartnerEmployee}
                  userGroupList={userGroup}
                  userRoleList={userRole}
                  editPartnerEmployee={updatePartnerEmployee}
              />
            }
            toggle={() => setEditEmployeeModalVisible(false)}
            headerText='Edit Employee Details'
            headerClass={baseStyles.modalHeader}
        />
      </div>
    </div>
  );
};
