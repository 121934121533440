import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getDashboard } from '../../../utils/api_service/dashboard';
import { formatDatePretty } from '../../../utils/helpers/time';
import { DashboardData } from '../../../utils/types/DashboardTypes';
import DashboardAssessmentTable from '../DashboardAssessmentTable';
import DashboardJobOpeningTable from '../DashboardJobOpeningTable';
import DashboardUserTable from '../DashboardUserTable';
import PieChartCard from '../ui/PieChartCard';
import { TabsLine } from '../ui/TabsLine';

const AdminDashboard = () => {
  const [timeData, setTimeData] = useState<DashboardData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('User');
  const today = new Date();
  const formattedDate = formatDatePretty(today);

  useEffect(() => {
    setLoading(true);
    getDashboard()
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .then((data) => {
        setTimeData(data);
      })
      .catch(() => {
        toast.error('Error Fetching Analytics', { position: 'bottom-center' });
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const renderTable = () => {
    if (activeTab === 'User') {
      return <DashboardUserTable />;
    } else if (activeTab === 'Assessment') {
      return <DashboardAssessmentTable />;
    } else if (activeTab === 'Job Opening') {
      return <DashboardJobOpeningTable />;
    }
  };

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col'>
        <span className='text-xl font-medium'>Welcome, { localStorage.getItem('firstname') }</span>
        <span className='text-gray-500'>{ formattedDate }</span>
      </div>
      <div className='flex gap-4'>
        <div className='w-full md:w-1/3'>
          <PieChartCard label={'Job Opening'} data={timeData?.jobOpenings} loading={loading}
              colors={['#00C49F', '#0088FE', '#FF3333']}/>
        </div>
        <div className='w-full md:w-1/3'>
          <PieChartCard label={'Assessment Type'} data={timeData?.assessments} loading={loading}
              colors={['#FF8042', '#FFBB28', '#00C49F']}/>
        </div>
        <div className='w-full md:w-1/3'>
          <PieChartCard label={'User Assessment Session'} data={timeData?.userAssessments} loading={loading}
              colors={['#00C49F', '#FF3333']} centerLabel={'Completed'}/>
        </div>
      </div>
      <div>
        <TabsLine tabTitles={['User', 'Assessment', 'Job Opening']} activeTab={activeTab} setActiveTab={setActiveTab}/>
      </div>
      <>
        { renderTable() }
      </>
    </div>
  );
};

export default AdminDashboard;
