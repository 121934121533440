import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/dashboard';

export function getDashboard() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true));
}

export function getDashboardUsers(search: string, page: number = 1, limit: number = 5) {
  return fetch(createRequest(`${apiEndpoint}/user?search=${search}&page=${page}&limit=${limit}`, 'GET', 'application/json', true));
}

export function getDashboardAssessments(search: string, page: number = 1, limit: number = 5) {
  return fetch(createRequest(`${apiEndpoint}/assessment?search=${search}&page=${page}&limit=${limit}`, 'GET', 'application/json', true));
}

export function getUserAssessments(search: string) {
  return fetch(createRequest(`${apiEndpoint}/user-assessments?search=${search}`, 'GET', 'application/json', true));
}
