import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../ui/button/button';
import { TextArea } from '../../../../../ui/input/text_area/text_area';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { ToggleInput } from '../../../../../ui/input/toggle_input/toggle_input';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import { getAssessment, getAssessmentSettings, updateAssessmentSettings, updateAssessmentStatus } from '../../../../../utils/api_service/assessment_api';
import { AssessmentContext } from '../../../../../utils/contexts/contexts';
import { Assessment, AssessmentSettings } from '../../../../../utils/types/assessment_types';
import styles from '../create_questions/create_questions.module.css';

export const SettingsAndPermissions = () => {
  const history = useHistory();
  const { assessmentState, setAssessmentState } = useContext(AssessmentContext);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [settings, setSettings] = useState<AssessmentSettings>({
    assessmentId: assessmentState.assessmentId,
    showScore: false,
    customMessage: '',
    shuffleQuestions: false,
    enableCopy: false,
    attemptCount: '1',
    blurVideo: false,
    unsuccessfulEmail: false,
    thresholdPercent: '0',
  });

  useEffect(() => {
    getAssessment(assessmentState.assessmentId).then((response: Assessment) => {
      if (response.id === assessmentState.assessmentId) {
        setIsActive(response.isActive);
        getAssessmentSettings(assessmentState.assessmentId).then((response: AssessmentSettings) => {
          if (response.assessmentId === assessmentState.assessmentId) {
            setSettings({
              ...response,
              attemptCount: response.attemptCount.toString(),
              thresholdPercent: response.thresholdPercent && response.thresholdPercent.toString(),
            });
          }
        });
      }
    });
  }, [assessmentState.assessmentId]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.value,
    });
  };

  const navigateBackToQuestions = () => {
    saveAssessment();
    setAssessmentState({
      ...assessmentState,
      assessmentPageNo: 2,
    });
  };

  const saveAssessment = () => {
    updateAssessmentStatus(assessmentState.assessmentId, isActive).then(response => {
      if (response.ok) {
        updateAssessmentSettings(assessmentState.assessmentId, settings).then(response => {
          if (response.ok) {
            history.push('./assessment-list');
          }
        });
      }
    });
  };

  return (
    <>
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Permissions and settings
      </PrimaryTypography.FiveXLarge>
      <div
          className={styles.createQuestionsContainer}
      >
        <SecondaryTypography.Medium
            fontWeight='semi-bold'
            keepDefaultMargins={true}
        >
          Status of Test
        </SecondaryTypography.Medium>
        <SecondaryTypography.XSmall>
          This option gives you access to switch the status of
          assessment as active or inactive.
        </SecondaryTypography.XSmall>
        <ToggleInput
            toggled={isActive}
            handleToggle={() => setIsActive(!isActive)}
            name='isActive'
            informationText='Switch to make active/inactive'
        />
        <SecondaryTypography.Medium
            fontWeight='semi-bold'
        >
          Show score at end of test
        </SecondaryTypography.Medium>
        <ToggleInput
            toggled={settings.showScore}
            handleToggle={() => setSettings({
              ...settings,
              showScore: !settings.showScore,
            })}
            name='showScore'
            informationText='Show total score'
        />
        <TextArea
            label='Show this custom message at end of test'
            name='customMessage'
            value={settings.customMessage}
            onChange={handleInputChange}
        />
        <SecondaryTypography.Medium
            fontWeight='semi-bold'
            keepDefaultMargins={true}
        >
          Shuffle questions
        </SecondaryTypography.Medium>
        <SecondaryTypography.XSmall>
          This gives you the option to shuffle the questions
          that candidates see on the interface.
        </SecondaryTypography.XSmall>
        <ToggleInput
            toggled={settings.shuffleQuestions}
            handleToggle={() => setSettings({
              ...settings,
              shuffleQuestions: !settings.shuffleQuestions,
            })}
            name='shuffleQuestions'
            informationText='Shuffle question order in assessment'
        />
        <SecondaryTypography.Medium
            fontWeight='semi-bold'
            keepDefaultMargins={true}
        >
          Option to Cut, Copy and Paste
        </SecondaryTypography.Medium>
        <SecondaryTypography.XSmall>
          This gives you the option to restrict candidates and
          team members from cut/copy/paste options when attending the assessment.
        </SecondaryTypography.XSmall>
        <ToggleInput
            toggled={settings.enableCopy}
            handleToggle={() => setSettings({
              ...settings,
              enableCopy: !settings.enableCopy,
            })}
            name='enableCopy'
            informationText='Restrict cut/copy/paste during assessment'
        />
        <SecondaryTypography.Small
            fontWeight='semi-bold'
            keepDefaultMargins={true}
        >
          Option to Blur video
        </SecondaryTypography.Small>
        <SecondaryTypography.XSmall>
          This gives you the option blur the video
          to maintain the anonymity of the candidate.
        </SecondaryTypography.XSmall>
        <ToggleInput
            toggled={settings.blurVideo}
            handleToggle={() => setSettings({
              ...settings,
              blurVideo: !settings.blurVideo,
            })}
            name='edit'
            informationText='Blur face of the candidate'
        />
        <TextInput
            label='Number of attempts'
            name='attemptCount'
            value={settings.attemptCount}
            type='number'
            onChange={handleInputChange}
            min='1'
            max='10'
        />
        { assessmentState.assessmentType === 'candidate' &&
        <>
          <ToggleInput
              toggled={settings.unsuccessfulEmail || false}
              handleToggle={() => setSettings({
                ...settings,
                unsuccessfulEmail: !settings.unsuccessfulEmail,
              })}
              name='unsuccessfulEmail'
              informationText='Automatic Unsuccessful Email'
          />
          <TextInput
              label='Threshold Percentage (%)'
              name='thresholdPercent'
              value={settings.thresholdPercent || '0'}
              type='number'
              onChange={handleInputChange}
              min='1'
              max='100'
          />
        </>
        }
        <div
            className={styles.buttonContainer}
        >
          <Button
              onClick={navigateBackToQuestions}
              variant='secondary'
              size='medium'
              customClass={styles.bottomButtons}
          >
            Back
          </Button>
          <Button
              onClick={saveAssessment}
              variant='primary'
              size='medium'
              customClass={styles.bottomButtons}
          >
            Save Assessment
          </Button>
        </div>
      </div>
    </>
  );
};
