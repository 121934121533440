import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import emailOpenImage from '../../../../../assets/images/graphics/email-open-logo.svg';
import { Button } from '../../../../../ui/button/button';
import { Modal } from '../../../../../ui/modal/modal';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import {
  deleteAssessmentUser,
  getAssessmentUsers,
  inviteUserToAssessment,
  updateAssessmentUserStatus,
} from '../../../../../utils/api_service/assessment_api';
import { addUsersToAssessmentWithFile } from '../../../../../utils/api_service/user_api';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import {
  AssessmentUser,
  InviteUserRequest,
  User,
  UserAssessmentResponse,
} from '../../../../../utils/types/assessment_types';
import commonStyles from '../../assessment_list/assessment_list_table/assessment_list_table.module.css';
import { AssessmentUserRow } from './assessment_user_row/assessment_user_row';
import styles from './assessment_users.module.css';
import { InviteUserModal } from './invite_user_modal/invite_user_modal';
import { UserSearch } from './user_search/user_search';

type AssessmentUsersProps = {
  assessmentId: string,
}

export const AssessmentUsers = ({
  assessmentId,
}: AssessmentUsersProps) => {
  const [assignedUsers, setAssignedUsers] = useState<AssessmentUser[]>([]);
  const [inviteModal, toggleInviteModal] = useState<boolean>(false);

  useEffect(() => {
    if (assignedUsers.length === 0) {
      getAssessmentUsers(assessmentId).then(response => {
        if (response.length > 0) {
          response.map((item: UserAssessmentResponse) => {
            let assessmentUser: AssessmentUser = {
              assessmentUserId: item.id,
              userId: item.userId,
              firstName: item.user.firstName,
              lastName: item.user.lastName,
              email: item.user.email,
              score: item.score,
              status: item.status,
              enabled: item.enabled,
            };
            setAssignedUsers(assignedUsers => [
              ...assignedUsers,
              assessmentUser,
            ]);
          });
        }
      });
    }
  }, [assessmentId]);

  const addAssessmentUser = (user: User) => {
    let inviteUserDetails: InviteUserRequest = {
      assessmentId: assessmentId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };
    inviteUserToAssessment(inviteUserDetails).then(response => {
      if (response.id) {
        let assessmentUser: AssessmentUser = {
          assessmentUserId: response.id,
          userId: response.userId,
          firstName: response.user.firstName,
          lastName: response.user.lastName,
          email: response.user.email,
          score: response.score,
          status: response.status,
          enabled: response.enabled,
        };
        setAssignedUsers(assignedUsers => [
          ...assignedUsers,
          assessmentUser,
        ]);
        toast.success('Successfully added user!', { position: 'bottom-center' });
      } else {
        toast.error(response.message, { position: 'bottom-center' });
      }
    });
  };

  const inviteUserForAssessment = (inviteUserDetails: InviteUserRequest) => {
    inviteUserDetails.assessmentId = assessmentId;
    inviteUserToAssessment(inviteUserDetails).then(response => {
      if (response.id) {
        let assessmentUser: AssessmentUser = {
          assessmentUserId: response.id,
          userId: response.userId,
          firstName: inviteUserDetails.firstName,
          lastName: inviteUserDetails.lastName,
          email: inviteUserDetails.email,
          score: response.score,
          status: response.status,
          enabled: response.enabled,
        };
        setAssignedUsers(assignedUsers => [
          ...assignedUsers,
          assessmentUser,
        ]);
        toggleInviteModal(false);
        toast.success('Successfully invited new user!', { position: 'bottom-center' });
      } else {
        toggleInviteModal(false);
        toast.error(response.error, { position: 'bottom-center' });
      }
    });
  };

  const inviteUsersFromCSV = (csvFile: File) => {
    addUsersToAssessmentWithFile(csvFile, assessmentId).then(response => {
      if (response.ok) {
        return response.json();
      }
    }).then(response => {
      response.map((addUserResponse: any) => {
        if (assignedUsers.filter(user => user.userId === addUserResponse.user.id).length === 0) {
          let assessmentUser: AssessmentUser = {
            assessmentUserId: addUserResponse.id,
            userId: addUserResponse.userId,
            firstName: addUserResponse.user.firstName,
            lastName: addUserResponse.user.lastName,
            email: addUserResponse.user.email,
            score: addUserResponse.score,
            status: addUserResponse.status,
            enabled: addUserResponse.enabled,
          };
          setAssignedUsers(assignedUsers => [
            ...assignedUsers,
            assessmentUser,
          ]);
        }
      });
      toggleInviteModal(false);
      toast.success('Successfully added users!', { position: 'bottom-center' });
    });
  };

  const deleteUserFromAssessment = (assessmentUser: AssessmentUser) => {
    deleteAssessmentUser(assessmentUser.assessmentUserId).then(response => {
      if (response.ok) {
        let assignedUsersCopy = assignedUsers.filter(item => item.assessmentUserId !== assessmentUser.assessmentUserId);
        setAssignedUsers(
          assignedUsersCopy,
        );
      }
    });
  };

  const changeUserActivityStatus = (assessmentUser: AssessmentUser) => {
    updateAssessmentUserStatus(assessmentUser.assessmentUserId, !assessmentUser.enabled).then(response => {
      if (response.ok) {
        let assignedUsersCopy = assignedUsers;
        let index = assignedUsersCopy.findIndex((user: AssessmentUser) => user.userId === assessmentUser.userId);
        assessmentUser.enabled = !assessmentUser.enabled;
        assignedUsersCopy[index] = assessmentUser;
        setAssignedUsers(
          assignedUsers,
        );
      }
    });
  };

  return (
    <div>
      <PermissionGate
          requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
      >
        <UserSearch
            displayInviteModal={toggleInviteModal}
            assignedUsers={assignedUsers}
            addAssessmentUser={addAssessmentUser}
        />
      </PermissionGate>
      <PrimaryTypography.TwoXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        List of Candidates
      </PrimaryTypography.TwoXLarge>
      <table
          className={commonStyles.tableStyle}
      >
        <thead>
          <tr
              className={commonStyles.tableHeader}
          >
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Name
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Email
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Score
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Status
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='center'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Actions
              </SecondaryTypography.XSmall>
            </th>
          </tr>
        </thead>
        <tbody>
          { assignedUsers.length > 0 && assignedUsers.map((user) => {
            return (
              <AssessmentUserRow
                  key={user.email}
                  assessmentUser={user}
                  deleteUserFromAssessment={deleteUserFromAssessment}
                  changeUserActivityStatus={changeUserActivityStatus}
              />
            );
          })  }
        </tbody>
      </table>
      { assignedUsers.length === 0 &&
      <div
          className={styles.inviteUsers}
      >
        <img
            className={styles.centerEmailLogo}
            src={emailOpenImage}
            alt='email-verify'
            width='180px'
        />
        <Button
            onClick={() => toggleInviteModal(true)}
            variant='primary'
            size='medium'
        >
          Invite Members
        </Button>
      </div>
      }
      <Modal
          visible={inviteModal}
          modalContent={
            <InviteUserModal
                inviteUserForAssessment={inviteUserForAssessment}
                inviteUsersFromCSV={inviteUsersFromCSV}
            />
          }
          toggle={() => toggleInviteModal(!inviteModal)}
          toggleable={true}
          customClass={styles.inviteModalClass}
      />
    </div>
  );
};
