import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { createSkill, createSubSkill } from '../../utils/api_service/skill_api';
import { convertFileToBase64, validateQuestionMedia } from '../../utils/helpers/fileHandler';
import { Permission } from '../../utils/helpers/permissions';
import PermissionGate from '../../utils/hooks/PermissionGate';
import { addNewSkill } from '../../utils/redux_store/features/skill_reducer';
import { addNewSubSkill } from '../../utils/redux_store/features/subskill_reducer';
import { Question, Skill, SubSkill } from '../../utils/types/assessment_types';
import { SelectOptions } from '../../utils/types/react_select_types';
import { ChevronDown, ChevronUp, ListIcon, PencilIcon, TrashIcon } from '../icons/icons';
import { SecondaryTypography } from '../typography/typography';
import styles from './collapsible_question.module.css';
import { ExpandedQuestion } from './expanded_question/expanded_question';

type CollapsibleQuestionProps = {
  question: Question,
  provided?: DraggableProvided,
  open: boolean,
  editQuestion: (question: Question) => void,
  deleteQuestion: (id: string) => void,
  skillList: SelectOptions[] | undefined,
  levelList: SelectOptions[] | undefined,
  jobTitleList: SelectOptions[] | undefined,
  subSkillList: SubSkill[] | undefined,
  setSkillList: any,
}

export const CollapsibleQuestion = ({
  question,
  provided,
  open,
  editQuestion,
  deleteQuestion,
  skillList,
  levelList,
  jobTitleList,
  subSkillList,
  setSkillList,
}: CollapsibleQuestionProps) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [toggledQuestion, setToggledQuestion] = useState<Question>(question);
  const [skills, setSkills] = useState<SelectOptions[]>(skillList || []);

  const returnSubSkills = (parent: string | null) => {
    let subSkills: SelectOptions[] = [];
    subSkillList?.map((subSkill: SubSkill) => {
      if (subSkill.parentSkillId === parent) {
        subSkills.push({ label: subSkill.subskillName, value: subSkill.id });
      }
    });

    return subSkills;
  };

  const [subSkills1, setSubSkills1] = useState<SelectOptions[]>(() => returnSubSkills(toggledQuestion.skillId));
  const [subSkills2, setSubSkills2] = useState<SelectOptions[]>(() => returnSubSkills(toggledQuestion.subSkill1Id));
  const [subSkills3, setSubSkills3] = useState<SelectOptions[]>(() => returnSubSkills(toggledQuestion.subSkill2Id));

  const toggleCollapsible = (open: boolean) => {
    if (skills.length !== skillList?.length) {
      setSkillList(skills);
    }
    setIsOpen(!open);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
    if (event.target.name === 'time') {
      setToggledQuestion({
        ...toggledQuestion,
        time: event.target.valueAsNumber,
      });
    } else {
      setToggledQuestion({
        ...toggledQuestion,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSelectChange = (selectedValue: SelectOptions, action: any) => {
    if (action.name === 'skillId') {
      selectedValue && setSubSkills1(() => returnSubSkills(selectedValue.value));
      setSubSkills2([]);
      setSubSkills3([]);
      setToggledQuestion((question) => ({
        ...question,
        [action.name]: selectedValue ? selectedValue.value : null,
        subSkill1Id: null,
        subSkill2Id: null,
        subSkill3Id: null,
      }));
    } else if (action.name === 'subSkill1Id') {
      selectedValue && setSubSkills2(() => returnSubSkills(selectedValue.value));
      setSubSkills3([]);
      setToggledQuestion((question) => ({
        ...question,
        [action.name]: selectedValue ? selectedValue.value : null,
        subSkill2Id: null,
        subSkill3Id: null,
      }));
    } else if (action.name === 'subSkill2Id') {
      selectedValue && setSubSkills3(() => returnSubSkills(selectedValue.value));
      setToggledQuestion((question) => ({
        ...question,
        [action.name]: selectedValue ? selectedValue.value : null,
        subSkill3Id: null,
      }));
    } else {
      setToggledQuestion({
        ...toggledQuestion,
        [action.name]: selectedValue ? selectedValue.value : null,
      });
    }
  };

  const handleMultiSelectChange = (selectedValue: SelectOptions[], action: any) => {
    let jobTitleList: string[] = [];

    selectedValue.map((title: SelectOptions) => {
      jobTitleList.push(title.value);
    });

    setToggledQuestion({
      ...toggledQuestion,
      [action.name]: jobTitleList,
    });
  };

  const returnSelectedJobTitles = () => {
    let selectedTitles: SelectOptions[] = [];

    toggledQuestion.jobTitle?.map((titleId: string) => {
      let selectedTitle = jobTitleList?.find(titleOption => titleOption.value === titleId);
      if (selectedTitle) {
        selectedTitles.push({ label: selectedTitle?.label, value: titleId });
      }
    });

    return selectedTitles;
  };

  const onAddNewSkill = (skill: string, parent?: string | null, level?: string) => {
    if (parent) {
      createSubSkill(skill, parent).then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then((response: SubSkill) => {
        if (level) {
          updateSubSkillList(level, response.id, skill);
          dispatch(addNewSubSkill(response));
        }
      });
    } else {
      createSkill(skill).then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then((response: Skill) => {
        setSkills((skills) => [...skills, { value: response.id, label: skill }]);
        dispatch(addNewSkill({ value: response.id, label: skill }));
      });
    }
  };

  const updateSubSkillList = (level: string, subSkillId: string, skillName: string) => {
    if (level === '1') {
      setSubSkills1((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    } else if (level === '2') {
      setSubSkills2((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    } else if (level === '3') {
      setSubSkills3((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    }
  };

  const addFileViaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.item(0);
    if (file && validateQuestionMedia(file)) {
      convertFileToBase64(file).then(result => {
        setToggledQuestion({
          ...toggledQuestion,
          mediaQuestion: true,
          mediaExtension: file.name.split('.').pop(),
          // @ts-ignore
          fileEncoded: result,
        });
      });
    }
  };

  const clearMediaFile = () => {
    setToggledQuestion({
      ...toggledQuestion,
      mediaQuestion: false,
      mediaExtension: null,
      fileEncoded: null,
    });
  };

  const questionTypes: SelectOptions[] = [
    {
      value: 'Modern',
      label: 'Video',
    },
    {
      value: 'Classic',
      label: 'Text',
    },
  ];

  if (provided) {
    return (
      <div
          {...provided.draggableProps}
      >
        <div
            className={classNames(styles.questionBox, { [styles.open]: isOpen })}
            key={toggledQuestion.id}
            ref={provided.innerRef}
        >
          <div
              className={styles.questionHeaderContainer}
          >
            <PermissionGate
                requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
            >
              <div
                  className={styles.icons}
                  {...provided.dragHandleProps}
              >
                <ListIcon/>
              </div>
            </PermissionGate>
            <SecondaryTypography.Medium
                fontWeight='semi-bold'
                className={styles.questionHeader}
            >
              { toggledQuestion.question }
            </SecondaryTypography.Medium>
          </div>
          <div
              className={styles.iconContainer}
          >
            <div
                className={classNames(styles.icons, styles.clickable)}
                onClick={() => {
                  toggleCollapsible(isOpen);
                  setIsDisabled(true);
                }}
            >
              {
                isOpen ? <ChevronUp/> : <ChevronDown/>
              }
            </div>
            <PermissionGate
                requiredPermissions={[Permission.QUESTIONS_EDIT]}
            >
              <div
                  className={classNames(styles.icons, styles.editIcon, styles.clickable)}
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                    setIsOpen(true);
                  }}
              >
                <PencilIcon/>
              </div>
            </PermissionGate>
            <PermissionGate
                requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
            >
              <div
                  className={classNames(styles.icons, styles.trashIcon, styles.clickable)}
                  onClick={() => {
                    if (toggledQuestion.assessmentQuestionId) {
                      deleteQuestion(toggledQuestion.assessmentQuestionId);
                    }
                  }}
              >
                <TrashIcon/>
              </div>
            </PermissionGate>
          </div>
        </div>
        { isOpen &&
          <ExpandedQuestion
              toggledQuestion={toggledQuestion}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              isOpen={isOpen}
              editQuestion={editQuestion}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleMultiSelectChange={handleMultiSelectChange}
              onAddNewSkill={onAddNewSkill}
              returnSelectedJobTitles={returnSelectedJobTitles}
              addFileViaInput={addFileViaInput}
              clearMediaFile={clearMediaFile}
              toggleCollapsible={toggleCollapsible}
              skills={skills}
              subSkills1={subSkills1}
              subSkills2={subSkills2}
              subSkills3={subSkills3}
              levelList={levelList}
              jobTitleList={jobTitleList || []}
              questionTypes={questionTypes}
          />
        }
      </div>
    );
  } else {
    return (
      <>
        <div
            className={classNames(styles.questionBox, { [styles.open]: isOpen })}
            key={toggledQuestion.id}
        >
          <div
              className={styles.questionHeaderContainer}
          >
            <SecondaryTypography.Medium
                fontWeight='semi-bold'
                className={styles.questionHeader}
            >
              { toggledQuestion.question }
            </SecondaryTypography.Medium>
          </div>
          <div
              className={styles.iconContainer}
          >
            <div
                className={classNames(styles.icons, styles.clickable)}
                onClick={() => {
                  toggleCollapsible(isOpen);
                  setIsDisabled(true);
                }}
            >
              {
                isOpen ? <ChevronUp/> : <ChevronDown/>
              }
            </div>
            <PermissionGate
                requiredPermissions={[Permission.QUESTIONS_EDIT]}
            >
              <div
                  className={classNames(styles.icons, styles.editIcon, styles.clickable)}
                  onClick={() => {
                    setIsDisabled(!isDisabled);
                    setIsOpen(true);
                  }}
              >
                <PencilIcon/>
              </div>
            </PermissionGate>
            <PermissionGate
                requiredPermissions={[Permission.QUESTIONS_DELETE]}
            >
              <div
                  className={classNames(styles.icons, styles.trashIcon, styles.clickable)}
                  onClick={() => {
                    if (toggledQuestion.id) {
                      deleteQuestion(toggledQuestion.id);
                    }
                  }}
              >
                <TrashIcon/>
              </div>
            </PermissionGate>
          </div>
        </div>
        { isOpen &&
          <ExpandedQuestion
              toggledQuestion={toggledQuestion}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              isOpen={isOpen}
              editQuestion={editQuestion}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleMultiSelectChange={handleMultiSelectChange}
              onAddNewSkill={onAddNewSkill}
              returnSelectedJobTitles={returnSelectedJobTitles}
              addFileViaInput={addFileViaInput}
              clearMediaFile={clearMediaFile}
              toggleCollapsible={toggleCollapsible}
              skills={skills}
              subSkills1={subSkills1}
              subSkills2={subSkills2}
              subSkills3={subSkills3}
              levelList={levelList}
              jobTitleList={jobTitleList || []}
              questionTypes={questionTypes}
          />
        }
      </>
    );
  }
};
