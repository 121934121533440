import React, { useState } from 'react';
import { PrimaryTypography } from '../../../ui/typography/typography';
import commonStyles from '../../admin/admin.module.css';
import { Tabs } from '../../assessment/view/tabs/tabs';
import { UploadFile } from './ContextSection/upload_file';
import { TopicQuestionGenerate } from './TopicSection/index';

export const QuestionGenerate = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabTitles = ['Context Based', 'Topic Based'];

  const renderTabContent = () => {
    if (activeTab === 0) {
      return <UploadFile/>;
    } else if (activeTab === 1)  {
      return <TopicQuestionGenerate/>;
    }
  };

  return (
    <div className={commonStyles.container}>
      <PrimaryTypography.FiveXLarge fontWeight='bold' keepDefaultMargins={true}>
        Auto Question Generate
      </PrimaryTypography.FiveXLarge>
      <div className={commonStyles.contentContainer}>
        <Tabs
            tabTitles={tabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
        { renderTabContent() }
      </div>
    </div>
  );
};
