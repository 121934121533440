import classNames from 'classnames';
import React from 'react';
import { ChartOrderItem, DataType } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { DashletIconGroup } from './DashletIconGroup';

type TableDashletProps = {
    id:string,
    data: Array<DataType>,
    title: string,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void,
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}
export const TableDashlet = ({ id, data, title, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, setFullScreenDetected, handleDashletVisibility, editMode }: TableDashletProps) => {

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  return (
    <div className='group relative w-full col-span-2 rounded border border-gray-200 shadow h-full p-2 overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={classNames(styles.dataContainer, dashletItem.hidden ? 'opacity-40': '')}
      >
        <div className='font-bold text-sm mb-2 text-center'>{ title }</div>
        <div className='flex justify-center'>
          { (!data || data.length === 0 || data === undefined) ?
            <div className={'text-md text-gray-700 flex place-content-center place-items-center h-[300px]'}>No data to preview</div> :
            <table className='w-full sm:w-full md:w-5/6 border border-black text-sm'>
              <tbody>
                { data.map((item, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-blue-100' : 'bg-white'}>
                    <td className='px-3 py-1 border border-black text-left'>{ item.name }</td>
                    <td className='px-3 py-1 border border-black text-right'>{ item.value }%</td>
                  </tr>
                )) }
              </tbody>
            </table>
          }
        </div>
      </div>
    </div>
  );
};
