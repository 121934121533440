import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { SecondaryTypography } from '../../typography/typography';
import styles from '../input.module.css';

type MinuteSecondsInputProps = {
    label?: string,
    name: string,
    value: number,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    required?: boolean,
    customClass?: string,
}

export const MinuteSecondsInput = ({
  label,
  name,
  value,
  onChange,
  disabled,
  required,
  customClass,
}: MinuteSecondsInputProps) => {
  return (
    <div>
      { label &&
      <label
          className={styles.labelStyles}
      >
        <SecondaryTypography.XSmall
            fontWeight='semi-bold'
        >
          { label }
        </SecondaryTypography.XSmall>
      </label>
      }
      <input
          className={classNames(styles.inputStyles, customClass)}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          type='number'
          min='0'
          max='300'
      />
    </div>
  );
};
