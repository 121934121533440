import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsCharts } from '../../../ui/analytics_charts/AnalyticsCharts';
import { CollapsibleFilter } from '../../../ui/data_source_filter/CollapsibleFilter';
import { Loader } from '../../../ui/loader/loader';
import { PrimaryTypography } from '../../../ui/typography/typography';
import { defaultChartOrder } from '../../../utils/analytics_data/chartData';
import { assessmentTypes } from '../../../utils/analytics_data/filterData';
import { formatAPIData } from '../../../utils/analytics_data/formatter';
import { searchAnalytics } from '../../../utils/api_service/analytics_api';
import { getOrganizationTree } from '../../../utils/api_service/organization_unit';
import { getDashletOrder } from '../../../utils/api_service/organization_users';
import { RootState } from '../../../utils/redux_store/store';
import { AnalyticsData, AnalyticsSearchObject, AnalyticsSearchRequest, ChartOrderItem } from '../../../utils/types/analytics/analytics_types';
import { SelectOptions } from '../../../utils/types/react_select_types';
import { OrganizationUnit } from '../../admin/OrganizationSettings/TreeStructure';

const CHART_TYPE = 'enterprise';

export const EnterpriseAnalytics = () => {

  const skills = useSelector((state: RootState) => state.skills);
  const [skillList, setSkillList] = useState<SelectOptions[]>(skills.skillsList);
  const [searchObject, setSearchObject] = useState<AnalyticsSearchObject>({
    timeRange: { startDate: dayjs().subtract(1, 'year').toDate(), endDate: dayjs().toDate() },
    organization: [],
    assessmentType: [],
    skill: [],
  });
  const [searchResult, setSearchResult] = useState<AnalyticsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResultChartOrder, setSearchResultChartOrder] = useState<ChartOrderItem[]>();
  const [organizationTree, setOrganizationTree] = useState<OrganizationUnit>();
  const [chartOrder, setChartOrder] = useState<ChartOrderItem[]>(defaultChartOrder);

  useEffect(() => {
    const debounceSearchTerm = setTimeout(() => {
      setLoading(true);

      const searchTerms: AnalyticsSearchRequest = {
        dashboardType: CHART_TYPE,
        timeRange: searchObject.timeRange,
        organization: searchObject.organization.map(item => item.value),
        assessmentType: searchObject.assessmentType.map(item => item.value),
        skill: searchObject.skill.map(item => item.value),
      };
      searchAnalytics(searchTerms).then(async response => {
        const formattedSearchResult: any = await formatAPIData(response);
        setSearchResult(formattedSearchResult);
        setLoading(false);
      });
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchObject]);

  useEffect(() => {
    if (!chartOrder || !searchResult) return;

    const temp: ChartOrderItem[] = [];
    for (const item of chartOrder) {
      if (Object.hasOwn(searchResult, item.chartId)) {
        temp.push(item);
      }
    }
    setSearchResultChartOrder(temp);
  }, [chartOrder, searchResult]);

  useEffect(() => {
    setSkillList(skills.skillsList);
  }, [skills.skillsList]);

  useEffect(() => {
    getOrganizationTree().then(response => {
      setOrganizationTree(response);
    });

    setChartOrder(defaultChartOrder);
    getDashletOrder(CHART_TYPE).then(response => {
      if (!response.chartOrder?.length) return;
      setChartOrder(response.chartOrder);
    }).catch( () => {
      setChartOrder(defaultChartOrder);
    });
  }, []);

  return (
    <>
      <div className='min-h-screen w-full'>
        <PrimaryTypography.TwoXLarge keepDefaultMargins={true} fontWeight='bold'>
          Enterprise Analytics
        </PrimaryTypography.TwoXLarge>
        <div className='flex flex-col bg-white p-4 shadow-lg border'>
          <CollapsibleFilter
              open={true}
              skillList={skillList}
              organizationTree={organizationTree}
              assessmentTypeList={assessmentTypes}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              searchResultChartOrder={searchResultChartOrder}
          />
          <AnalyticsCharts
              searchResult={searchResult}
              chartOrder={chartOrder}
              setChartOrder={setChartOrder}
              searchResultChartOrder={searchResultChartOrder}
              type={CHART_TYPE}
          />
        </div>
      </div>
      <Loader
          loading={loading}
      />
    </>
  );
};
