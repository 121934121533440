import classNames from 'classnames';
import React from 'react';
import { PencilIcon, TrashIcon } from '../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import { UserGroup } from '../../../../../utils/types/admin_types';
import rowStyles from '../../row.module.css';

type GroupRowProp = {
    group: UserGroup,
    deleteGroup: (id: string) => void,
    onEditClick: (editedInfo: UserGroup) => void,
}

export const GroupRow = ({
  group,
  deleteGroup,
  onEditClick,
}: GroupRowProp) => {
  return (
    <tr
        className={rowStyles.tableRowStyle}
    >
      <td>
        <input type='checkbox'/>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.leftBorder)}
      >
        <SecondaryTypography.XSmall>
          { group.groupName }
        </SecondaryTypography.XSmall>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.rightBorder)}
      >
        <div
            className={rowStyles.iconContainer}
        >
          <PermissionGate
              requiredPermissions={[Permission.GROUPS_EDIT]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.editIcon, rowStyles.clickable)}
                onClick={() => onEditClick(group)}
            >
              <PencilIcon/>
            </div>
          </PermissionGate>
          <PermissionGate
              requiredPermissions={[Permission.GROUPS_DELETE]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.trashIcon, rowStyles.clickable)}
                onClick={() => deleteGroup(group.id)}
            >
              <TrashIcon/>
            </div>
          </PermissionGate>
        </div>
      </td>
    </tr>
  );
};
