import React, { useState } from 'react';
import { TrashIcon } from '../../../../../../ui/icons/icons';
import { ToggleInput } from '../../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { Permission } from '../../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../../utils/hooks/PermissionGate';
import usePermissions from '../../../../../../utils/hooks/usePermissions';
import { AssessmentUser } from '../../../../../../utils/types/assessment_types';
import styles from './assessment_user_row.module.css';

type AssessmentUserRowProps = {
  assessmentUser: AssessmentUser,
  deleteUserFromAssessment?: (assessmentUser: AssessmentUser) => void,
  changeUserActivityStatus?: (assessmentUser: AssessmentUser) => void,
}

export const AssessmentUserRow = ({
  assessmentUser,
  deleteUserFromAssessment,
  changeUserActivityStatus,
}: AssessmentUserRowProps) => {
  const { hasPermissions } = usePermissions();
  const [toggled, setToggled] = useState<boolean>(assessmentUser.enabled);

  const getTextColor = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'text-lime-500';
      case 'In Progress':
        return 'text-yellow-500';
      case 'Scoring':
        return 'text-violet-600';
      case 'Expired':
        return 'text-rose-600';
      case 'Assigned':
        return 'text-sky-500';
      default:
        return '';
    }
  };

  return (
    <tr
        className={styles.tableRow}
    >
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
        >
          { assessmentUser.firstName + ' ' + assessmentUser.lastName }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
        >
          { assessmentUser.email }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
        >
          { assessmentUser.score }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall
            alignment='center'
            textCase='capitalize'
            fontWeight='bold'
            className={getTextColor(assessmentUser.status)}
        >
          { assessmentUser.status }
        </SecondaryTypography.XSmall>
      </td>
      { changeUserActivityStatus && deleteUserFromAssessment &&
        <td
            className={styles.activeCell}
        >
          <ToggleInput
              toggled={toggled}
              handleToggle={() => {
                changeUserActivityStatus(assessmentUser);
                setToggled(!toggled);
              }}
              name='enabled'
              disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
          />
          <PermissionGate
              requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
          >
            <div
                className={styles.deleteIcon}
                onClick={() => deleteUserFromAssessment(assessmentUser)}
            >
              <TrashIcon/>
            </div>
          </PermissionGate>
        </td>
      }
    </tr>
  );
};
