import React, { useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { PrimaryTypography } from '../../../../../ui/typography/typography';
import { submitPreScreenAnswers } from '../../../../../utils/api_service/assessment_api';
import { PreScreenAnswers, PreScreenQuestion, UserAssessment } from '../../../../../utils/types/assessment_types';
import commonStyles from '../assigned_assessments.module.css';
import styles from '../question/question.module.css';
import {toast} from "react-toastify";

type PreScreenPerformProps = {
  preScreenQuestions: PreScreenQuestion[],
  selectedUserAssessment: UserAssessment | undefined,
  finishedPreScreen: (userAssessment: UserAssessment) => void,
}

const PreScreenPerform = ({ preScreenQuestions, selectedUserAssessment, finishedPreScreen }: PreScreenPerformProps) => {
  const [preScreenAnswers, setPreScreenAnswers] = useState<PreScreenAnswers[]>([]);

  const handleChangeAnswer = (questionId: string, questionType: 'numeric' | 'multiple-choice', answer: string | number) => {
    let transformedAnswer: string | number;
    if (questionType === 'numeric') {
      transformedAnswer = parseInt(answer as string, 10);
    } else {
      transformedAnswer = answer.toString();
    }

    setPreScreenAnswers(prevAnswers => {
      const existingAnswerIndex = prevAnswers.findIndex(ans => ans.questionId === questionId);
      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = { questionId, answer: transformedAnswer, questionType: questionType };
        return updatedAnswers;
      } else {
        return [...prevAnswers, { questionId, answer: transformedAnswer, questionType: questionType }];
      }
    });
  };

  const getAnswerValue = (questionId: string) => {
    const answerObj = preScreenAnswers.find(ans => ans.questionId === questionId);
    return answerObj ? answerObj.answer : '';
  };

  const validateAnswers = () => {
    for (const question of preScreenQuestions) {
      const answerObj = preScreenAnswers.find(ans => ans.questionId === question.questionId);
      if (!answerObj || answerObj.answer === '') {
        return false;
      }
    }
    return true;
  };

  const submitAnswers = async () => {
    if (selectedUserAssessment && selectedUserAssessment.assessment && selectedUserAssessment.assessmentUser){
      try {
        const response = await submitPreScreenAnswers(selectedUserAssessment.assessment.id, selectedUserAssessment.assessmentUser.id, preScreenAnswers);
        const responseUserAssessment: UserAssessment = await response.json();
        finishedPreScreen(responseUserAssessment);
      } catch {
        toast.error('Submitting Error. Try Again', { position: 'bottom-center' });
      }
    }
  };

  return (
    <div className={`${commonStyles.contentContainer} min-h-screen relative`}>
      <div
          className={styles.assessmentHeader}
      >
        <PrimaryTypography.TwoXLarge
            fontWeight='bold'
        >
          Pre-Screening Questions
        </PrimaryTypography.TwoXLarge>
      </div>
      <div>
        { preScreenQuestions.length > 0 && (
          <div className='flex flex-col gap-4  rounded-lg p-5 mb-10'>
            { preScreenQuestions.map((question, indexNum) => (
              <div key={indexNum} className='flex flex-col gap-1 items-center mb-4 w-full'>
                <div className='self-start text-lg mb-2'>
                  { `${indexNum + 1}. ` } { question.question }
                </div>
                { question.questionType === 'multiple-choice' ? (
                  <div className='w-full flex flex-col gap-1'>
                    { question.choices?.map((choice, idx) => (
                      <div key={idx} className='w-4/6 mx-4 cursor-pointer'>
                        <div className={`border-2 ${getAnswerValue(question.questionId) === choice ? 'border-green-400' : 'border-gray-300'} py-2 px-5 rounded-lg`}
                            onClick={() => handleChangeAnswer(question.questionId, question.questionType, choice)}>
                          { choice }
                        </div>
                      </div>
                    )) }
                  </div>
                ) : (
                  <div className='w-4/6 self-start mx-4'>
                    <TextInput name={`answer-${question.questionId}`} value={getAnswerValue(question.questionId).toString()}
                        type='number' onChange={(e) => handleChangeAnswer(question.questionId, question.questionType, e.target.value)}/>
                  </div>
                ) }
              </div>
            )) }
          </div>
        ) }
      </div>
      <div className='absolute bottom-10 right-10'>
        <Button onClick={() => submitAnswers()} variant='primary' size='large' disabled={!validateAnswers()}>
          Continue Assessment
        </Button>
      </div>
    </div>
  );
};

export default PreScreenPerform;
