import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddQuestionModal } from '../../../../ui/add_question_modal/add_question_modal';
import { Button } from '../../../../ui/button/button';
import { CollapsibleQuestion } from '../../../../ui/collapsible_question/collapsible_question';
import { AddIcon, TrashIcon } from '../../../../ui/icons/icons';
import inputStyles from '../../../../ui/input/input.module.css';
import { Loader } from '../../../../ui/loader/loader';
import { Modal } from '../../../../ui/modal/modal';
import { PaginationController } from '../../../../ui/pagination_controller/pagination_controller';
import { Filter } from '../../../../ui/question_search_bar/filter/filter';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import {
  addQuestion,
  deleteQuestion,
  searchPaginatedQuestions,
  updateQuestion,
} from '../../../../utils/api_service/job_api';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import { editQuestionRedux } from '../../../../utils/redux_store/features/question_reducer';
import { RootState } from '../../../../utils/redux_store/store';
import { Question, QuestionSearch } from '../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import styles from './question_bank_search.module.css';

export const QuestionBankSearch = () => {
  const dispatch = useDispatch();
  const skills = useSelector((state: RootState) => state.skills);
  const levels = useSelector((state: RootState) => state.skillLevels);
  const jobTitles = useSelector((state: RootState) => state.jobTitles);
  const subSkills = useSelector((state: RootState) => state.subSkills);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchResults, setSearchResults] = useState<Question[]>([]);
  const [skillList, setSkillList] = useState<SelectOptions[]>(skills.skillsList);
  const [searchObject, setSearchObject] = useState<QuestionSearch>({
    questionTitle: '',
    skillId: '',
    levelId: '',
    subSkill1Id: '',
    subSkill2Id: '',
    subSkill3Id: '',
    jobTitle: '',
    questionPage: 10,
  });
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const debounceSearchTerm = setTimeout(() => {
      setLoading(true);
      const searchTerms: QuestionSearch = {
        questionTitle: searchTerm || '',
        skillId: searchObject.skillId,
        levelId: searchObject.levelId,
        subSkill1Id: searchObject.subSkill1Id,
        subSkill2Id: searchObject.subSkill2Id,
        subSkill3Id: searchObject.subSkill3Id,
        jobTitle: searchObject.jobTitle,
        pageNumber: 1,
        questionPage: searchObject.questionPage && searchObject.questionPage !== 0 ? searchObject.questionPage : 10,
      };
      searchPaginatedQuestions(searchTerms).then(response => {
        setSearchResults(response.questions);
        setPageNumber(1);
        setTotalPages(response.pages);
        setTotalQuestions(response.numberOfQuestions);
        setLoading(false);
      });
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchTerm, searchObject]);

  useEffect(() => {
    setSkillList(skills.skillsList);
  }, [skills.skillsList]);

  const switchPages = (pageNumber: number) => {
    setLoading(true);
    const searchTerms: QuestionSearch = {
      questionTitle: searchTerm || '',
      skillId: searchObject.skillId,
      levelId: searchObject.levelId,
      subSkill1Id: searchObject.subSkill1Id,
      subSkill2Id: searchObject.subSkill2Id,
      subSkill3Id: searchObject.subSkill3Id,
      jobTitle: searchObject.jobTitle,
      pageNumber: pageNumber,
      questionPage: searchObject.questionPage && searchObject.questionPage !== 0 ? searchObject.questionPage : 10,
    };
    searchPaginatedQuestions(searchTerms).then(response => {
      setPageNumber(pageNumber);
      setTotalQuestions(response.numberOfQuestions);
      setSearchResults(response.questions);
      setLoading(false);
    });
  };

  const addNewQuestion = (question: Question) => {
    addQuestion(question).then((response: Question) => {
      if (response.id) {
        setAddQuestionModalOpen(false);
        setSearchResults(searchResults => [...searchResults, question]);
      }
    });
  };

  const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const editQuestion = (question: Question) => {
    updateQuestion(question).then((response: Question) => {
      if (response.id && searchResults) {
        let questionListCopy = [...searchResults];
        let index = questionListCopy.findIndex(item => item.id === question.id);
        questionListCopy[index] = question;
        setSearchResults(
          questionListCopy,
        );
        dispatch(editQuestionRedux(question));
      }
    });
  };

  const deleteQuestionFromJob = (id: string) => {
    deleteQuestion(id).then(response => {
      if (response.ok && searchResults) {
        let questionListCopy = searchResults.filter(item => item.id !== id);
        setSearchResults(
          questionListCopy,
        );
      }
    });
  };

  return (
    <>
      <Filter
          skillList={skillList}
          levelList={levels.levelList}
          subSkillList={subSkills.subSkillsList}
          jobTitleList={jobTitles.jobTitlesList}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
      />
      <div
          className={classNames(styles.flexDirectionRow,styles.searchBarContainer)}
      >
        <div
            style={{ width: '100%' }}
        >
          <input
              className={inputStyles.inputStyles}
              type='text'
              placeholder='Search Questions'
              onChange={onSearchTermChange}
          />
        </div>
        <PermissionGate
            requiredPermissions={[Permission.QUESTIONS_CREATE]}
        >
          <Button
              onClick={() => setAddQuestionModalOpen(true)}
              variant='success'
              size='medium'
              icon={<AddIcon/>}
          >
            New
          </Button>
        </PermissionGate>
        <PermissionGate
            requiredPermissions={[Permission.QUESTIONS_DELETE]}
        >
          <Button
              onClick={() => {}}
              variant='danger'
              size='medium'
              icon={<TrashIcon/>}
          >
            Delete
          </Button>
        </PermissionGate>
      </div>
      <div
          className={styles.questionResultContainer}
      >
        { skills.skillsList.length > 0 && levels.levelList.length > 0 && jobTitles.jobTitlesList.length > 0
            && subSkills.subSkillsList.length > 0 && searchResults?.map((question: Question) => {
          return (
            <div
                key={question.id}
            >
              <CollapsibleQuestion
                  question={question}
                  open={false}
                  editQuestion={editQuestion}
                  deleteQuestion={deleteQuestionFromJob}
                  skillList={skillList}
                  levelList={levels.levelList}
                  jobTitleList={jobTitles.jobTitlesList}
                  subSkillList={subSkills.subSkillsList}
                  setSkillList={setSkillList}
              />
            </div>
          );
        }) }
      </div>
      <div
          className={classNames(styles.flexDirectionRow, styles.paginationContainer)}
      >
        <SecondaryTypography.XSmall
            className={inputStyles.labelStyles}
            fontWeight='semi-bold'
        >
          Total Questions - { totalQuestions }
        </SecondaryTypography.XSmall>
        <PaginationController
            pageNumber={pageNumber}
            maxPageNumber={totalPages}
            navigateNextPage={() => switchPages(pageNumber + 1)}
            navigatePreviousPage={() => switchPages(pageNumber - 1)}
        />
        <div
            className={classNames(styles.flexDirectionRow, styles.questionNumberInputContainer)}
        >
          <SecondaryTypography.XSmall
              className={inputStyles.labelStyles}
              fontWeight='semi-bold'
          >
            No. of Questions
          </SecondaryTypography.XSmall>
          <input
              className={inputStyles.inputStyles}
              name='questionPage'
              type='number'
              value={searchObject.questionPage}
              onChange={(event) => setSearchObject({
                ...searchObject,
                [event.target.name]: parseInt(event.target.value),
              })}
          />
        </div>
      </div>
      <Modal
          visible={addQuestionModalOpen}
          modalContent={
            <AddQuestionModal
                addNewQuestion={addNewQuestion}
                skillList={skillList}
                levelList={levels.levelList}
                jobTitleList={jobTitles.jobTitlesList}
                subSkillList={subSkills.subSkillsList}
                setSkillList={setSkillList}
            />
          }
          toggle={() => setAddQuestionModalOpen(false)}
      />
      <Loader
          loading={loading}
      />
    </>
  );
};
