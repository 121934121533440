import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { DatePicker } from '../../../../../ui/input/date_picker/date_picker';
import { TextArea } from '../../../../../ui/input/text_area/text_area';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { ToggleInput } from '../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { updateAssessment, updateAssessmentStatus } from '../../../../../utils/api_service/assessment_api';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import usePermissions from '../../../../../utils/hooks/usePermissions';
import { Assessment, BasicInfoInputs } from '../../../../../utils/types/assessment_types';
import commonStyles from '../../view.module.css';
import styles from './assessment_summary.module.css';

type AssessmentSummaryProps = {
  assessment: Assessment,
}

export const AssessmentSummary = ({
  assessment,
}: AssessmentSummaryProps) => {
  const { hasPermissions } = usePermissions();
  const [assessmentFormInfo, setAssessmentFormInfo] = useState<BasicInfoInputs>({
    title: assessment.assessmentTitle,
    description: assessment.description,
    startDate: new Date(assessment.startDate * 1000).toISOString().split('T')[0],
    endDate: new Date(assessment.endDate * 1000).toISOString().split('T')[0],
    type: assessment.assessmentType,
    jobPosition: assessment.jobPosition || undefined,
  });
  const [active, setActive] = useState<boolean>(assessment.isActive);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAssessmentFormInfo({
      ...assessmentFormInfo,
      [event.target.name]: event.target.value,
    });
  };

  const changeDate = (timeRange: any) => {
    setAssessmentFormInfo({
      ...assessmentFormInfo,
      startDate: timeRange.startDate,
      endDate: timeRange.endDate,
    });
  };

  const handleSubmit = () => {
    updateAssessment(assessmentFormInfo, assessment.id).then(response => {
      if (response[0].id) {
        updateAssessmentStatus(assessment.id, active).then(response => {
          if (response.ok) {
            toast.success('Assessment Details Updated!', { position: 'bottom-center' });
          }
        });
      }
    });
  };

  return (
    <>
      <div
          className={styles.formContainer}
      >
        <TextInput
            label='Assessment Name'
            name='title'
            value={assessmentFormInfo.title}
            type='text'
            onChange={handleChange}
            disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
        />
        <DatePicker
            label='Time-Range'
            startDate={assessmentFormInfo.startDate}
            endDate={assessmentFormInfo.endDate}
            onChange={changeDate}
            disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
        />
        { assessment.assessmentType === 'candidate' &&
          <>
            <TextInput
                label='Job Title'
                name='jobPosition'
                value={assessmentFormInfo.jobPosition || ''}
                type='text'
                onChange={handleChange}
                disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
            />
            <div/>
          </>
        }
        <TextArea
            label='Description'
            name='description'
            value={assessmentFormInfo.description}
            onChange={handleChange}
            disabled={!hasPermissions([Permission.ASSESSMENTS_EDIT])}
        />
      </div>
      <PermissionGate
          requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
      >
        <SecondaryTypography.XSmall
            fontWeight='semi-bold'
            keepDefaultMargins={true}
        >
          Status of Test
        </SecondaryTypography.XSmall>
        <ToggleInput
            toggled={active}
            handleToggle={() => setActive(!active)}
            name='is_active'
            informationText='Switch to make active/inactive'
        />
        <Button
            onClick={handleSubmit}
            variant='primary'
            size='medium'
            customClass={commonStyles.customButton}
        >
          Save Changes
        </Button>
      </PermissionGate>
    </>
  );
};
