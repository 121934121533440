import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import employeeIllustration from '../../../../../assets/images/backgrounds/employee_performance.png';
import candidateIllustration from '../../../../../assets/images/backgrounds/job_candidate.png';
import partnerIllustration from '../../../../../assets/images/backgrounds/partner_performance.png';
import { Button } from '../../../../../ui/button/button';
import { CardIcon } from '../../../../../ui/icons/icons';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import { addSubscription, cancelSubscription, getStripePortalLink, getSubscriptionProducts } from '../../../../../utils/api_service/subscription_api';
import { FeatureNames } from '../../../../../utils/helpers/subscriptionEnum';
import { fetchSubscriptions } from '../../../../../utils/redux_store/features/subscription_reducer';
import { RootState, store } from '../../../../../utils/redux_store/store';
import { Product } from '../../../../../utils/types/admin_types';
import { FeatureCard } from './FeatureCard';

const featureDescriptions = {
  candidateDescription: 'Automatically assess any skills of any candidate at any time. Easily compare candidate results and automatically short list the top contenders.',
  employeeDescription: 'Continuously assess employees to keep their skills sharp. Compare employees within teams, and compare the skills of teams and departments. Identify high and low achievers, as well as skill gaps that needs attention.',
  partnerDescription: 'Ensure you select only the best contractors to work for you. Assess all potential partners and compare employees between different partners in order to make the best fully informed decision.',
};

export const Subscription = () => {
  const subscription = useSelector((state: RootState) => state.subscriptions.subscriptions);
  const [productsList, setProductsList] = useState<Product[]>();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getSubscriptionProducts();
        if (response.ok) {
          const data = await response.json();
          setProductsList(Object.values(data));
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching products: ' + error, { position: 'bottom-center' });
      }
    };

    fetchProducts();
  }, []);

  const getSubscriptionObject = (feature: string) => {
    if (subscription) {
      switch (feature) {
        case FeatureNames.CANDIDATE:
          return subscription.candidateFeature;
        case FeatureNames.EMPLOYEE:
          return subscription.employeeFeature;
        case FeatureNames.PARTNER:
          return subscription.partnerFeature;
      }
    }
  };

  const getTrialStatus = (feature: string) => {
    if (subscription) {
      switch (feature) {
        case FeatureNames.CANDIDATE:
          return subscription.trial.candidateFeature;
        case FeatureNames.EMPLOYEE:
          return subscription.trial.employeeFeature;
        case FeatureNames.PARTNER:
          return subscription.trial.partnerFeature;
        default:
          return true;
      }
    }
    return true;
  };

  const getImage = (feature: string) => {
    switch (feature) {
      case FeatureNames.CANDIDATE:
        return candidateIllustration;
      case FeatureNames.EMPLOYEE:
        return employeeIllustration;
      case FeatureNames.PARTNER:
        return partnerIllustration;
      default:
        return candidateIllustration;
    }
  };

  const getDescriptions = (feature: string) => {
    switch (feature) {
      case FeatureNames.CANDIDATE:
        return featureDescriptions.candidateDescription;
      case FeatureNames.EMPLOYEE:
        return featureDescriptions.employeeDescription;
      case FeatureNames.PARTNER:
        return featureDescriptions.partnerDescription;
      default:
        return featureDescriptions.candidateDescription;
    }
  };

  const createSubscription = async (priceId: string, trial: boolean) => {
    try {
      const response = await addSubscription(priceId, trial);
      if (response.ok) {
        toast.success(trial ? 'Trial Started' : 'Successfully subscribed', { position: 'bottom-center' });
        store.dispatch(fetchSubscriptions());
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error subscribing: ' + error, { position: 'bottom-center' });
    }
  };

  const deleteSubscription = async (featureType: string, cancel: boolean) => {
    try {
      const response = await cancelSubscription(featureType, cancel);
      if (response.ok) {
        toast.success(`${ cancel ? 'Removed' : 'Renewed' } Subscription`, { position: 'bottom-center' });
        store.dispatch(fetchSubscriptions());
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error removing subscribing: ' + error, { position: 'bottom-center' });
    }
  };

  const accessStripePortal = async () => {
    try {
      const response = await getStripePortalLink();
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.portal_url;
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error subscribing: ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <>
      <div
          className='flex flex-row select-none'
      >
        <div
            className='flex flex-row w-full justify-evenly mt-5'
        >
          {  productsList?.map((product: Product) => (
            <FeatureCard
                key={product.productId}
                product={product}
                companySize={subscription?.companySize || 'Small'}
                interval={subscription?.interval || 'month'}
                currentSubscription={getSubscriptionObject(product.feature)}
                img={getImage(product.feature)}
                description={getDescriptions(product.feature)}
                isTrialUsed={getTrialStatus(product.feature)}
                cardDetailsUnavailable={subscription?.defaultCard === undefined}
                addSubscription={createSubscription}
                cancelSubscription={deleteSubscription}
            />
          ),
          ) }
        </div>
        <div
            className='flex flex-col mt-5'
        >
          <PrimaryTypography.Medium
              fontWeight='bold'
          >
            Payment
          </PrimaryTypography.Medium>
          <div
              className='flex flex-row items-center gap-x-2'
          >
            { subscription && subscription?.defaultCard ?
              <>
                <div
                    className='flex flex-row'
                >
                  <CardIcon
                      strokeColor={subscription?.defaultCard.brand === 'Visa' ? '#29AAE2' : '#D72A27'}
                  />
                  <SecondaryTypography.Small>
                  &nbsp;{ subscription?.defaultCard.brand }
                  </SecondaryTypography.Small>
                </div>
                <div>
                  <SecondaryTypography.XSmall>
                    Ending in <b>{ subscription?.defaultCard.last4 }</b>
                  </SecondaryTypography.XSmall>
                  <SecondaryTypography.XSmall>
                    Expires <b>{ subscription?.defaultCard.expiryDate }</b>
                  </SecondaryTypography.XSmall>
                </div>
              </>
              :
              <SecondaryTypography.Medium>
                No card details available
              </SecondaryTypography.Medium>
            }
            <Button
                variant='primary'
                size='medium'
                onClick={() => accessStripePortal()}
            >
              Manage
            </Button>
          </div>
          <div
              className='mt-2'
          >
            <PrimaryTypography.Medium
                fontWeight='bold'
            >
              Policy
            </PrimaryTypography.Medium>
            <SecondaryTypography.Small>
              <b>Company Size</b>: { subscription && subscription.companySize }
            </SecondaryTypography.Small>
            <SecondaryTypography.Small>
              <b>Billing Cycle</b>: { subscription && subscription.interval.charAt(0).toUpperCase() + subscription.interval.slice(1) }
            </SecondaryTypography.Small>
          </div>
        </div>
      </div>
      <div
          className='mt-5'
      >
        <SecondaryTypography.XSmall
            fontWeight='bold'
            alignment='center'
        >
          NOTE: To change any policies, please contact sales@hiacuity.com
        </SecondaryTypography.XSmall>
      </div>
    </>
  );
};
