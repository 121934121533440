import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../ui/modal/modal';
import { AssessmentContext, DefaultAssessmentState } from '../../../utils/contexts/contexts';
import styles from './create_assessment.module.css';
import { BasicInformation } from './form_content/basic_information/basic_information';
import { CreateQuestions } from './form_content/create_questions/create_questions';
import { SettingsAndPermissions } from './form_content/settings_and_permissions/settings_and_permissions';
import { ChooseAssessmentTypeModal } from './modal_content/choose_assessment_type_modal';

export const CreateAssessmentPage = () => {
  const history = useHistory();
  const [assessmentState, setAssessmentState] = useState(DefaultAssessmentState.assessmentState);
  const [modalState, setModalState] = useState<boolean>(assessmentState.assessmentType === null);

  const renderFormPage = () => {
    if (assessmentState.assessmentType !== null && assessmentState.assessmentPageNo === 1) {
      return <BasicInformation/>;
    } else if (assessmentState.assessmentPageNo === 2) {
      return <CreateQuestions/>;
    } else if (assessmentState.assessmentPageNo === 3) {
      return <SettingsAndPermissions/>;
    }
  };

  const handleModalToggle = () => {
    setModalState(false);
    history.push('/assessment-list');
  };

  // TODO(Joseph): Assessment Page needs to be implemented as per the design
  return (
    <AssessmentContext.Provider
        value={{
          assessmentState: assessmentState,
          setAssessmentState: setAssessmentState,
        }}
    >
      <div
          className='w-full min-h-screen p-4'
      >
        { renderFormPage() }
        <Modal
            visible={modalState}
            modalContent={
              <ChooseAssessmentTypeModal closeModal={() => setModalState(false)}/>
            }
            toggleable={true}
            customClass={styles.modalDimensions}
            toggle={handleModalToggle}
        />
      </div>
    </AssessmentContext.Provider>
  );
};
