import React, { Dispatch, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../ui/button/button';
import { UploadFileComponent } from '../../../../../../ui/input/FileInput';
import { uploadFileJobDescription } from '../../../../../../utils/api_service/JobOpenings';

const fileTypes = ['application/pdf'];

type ImportJobDescriptionModalProps = {
  closeModal: () => void,
  setLoading: Dispatch<React.SetStateAction<boolean>>,
}

const ImportJobDescription = ({ closeModal, setLoading } : ImportJobDescriptionModalProps) => {
  const { id } = useParams<{ id: string }>();
  const [uploadedFile, setUploadedFile] = useState<File[] | null>(null);
  const [transcript, setTranscript] = useState('');

  const fileUploaded = uploadedFile ? uploadedFile.length > 0 : false;

  const uploadJobDescription = async () => {
    if (!uploadedFile?.length && !transcript) {
      toast.error('Please select a file or enter a transcript', { position: 'bottom-center' });
      return;
    }
    try {
      const source = uploadedFile && uploadedFile?.length > 0 ? uploadedFile[0] : undefined;
      const response = await uploadFileJobDescription(id, source, transcript);
      if (response.ok) {
        toast.success('Uploaded. Wait To Process.', { position: 'bottom-center' });
        setLoading(true);
        closeModal();
      } else {
        toast.error('Upload unsuccessful. Please try again.', { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error. Please try again.', { position: 'bottom-center' });
    }
  };

  return (
    <div className='flex flex-col items-center px-8 w-full'>
      <div className='text-1xl font-bold text-center my-5'>
        Import Job Description
      </div>
      <div className='flex items-center mt-4'>
        <div>
          <UploadFileComponent
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              validTypes={fileTypes}
              isMultiple={false}
              disabled={transcript !== ''}
          />
        </div>
        <div className='mx-4'>
          <span className='text-gray-500'>Or</span>
        </div>
        <div>
          <textarea className={`border-2 ${fileUploaded ? 'border-gray-400' : 'border-primary-blue-500'} p-2 outline-none`}
              cols={50}
              rows={8}
              placeholder='Paste the Job Description' onChange={(e) => setTranscript(e.target.value)}
              disabled={fileUploaded}>
          </textarea>
        </div>
      </div>
      <div className='mt-4'>
        <Button
            onClick={() => {uploadJobDescription();}}
            variant={'primary'}
            size={'medium'}
            disabled={!(fileUploaded || transcript !== '')}
        >
          Import Job Description
        </Button>
      </div>
    </div>
  );
};

export default ImportJobDescription;
