import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { ChartOrderItem } from '../../utils/types/analytics/analytics_types';
import { CopyIcon, FullScreenIcon, HiddenIcon, ShowIcon } from '../icons/icons';

type DashletIconGroupProps = {
    id: string,
    dashletItem: ChartOrderItem;
    setFullScreenDetected?: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}
export const DashletIconGroup = ({ id, dashletItem, setFullScreenDetected, handleDashletVisibility, editMode }: DashletIconGroupProps) => {

  const handleCopyDashlet = async ()=> {
    const element = document.getElementById(id);
    if (element){
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL('image/jpg');
      copyImageToClipboard(data)
        .then(() => {
          NotificationManager.success('Image Copied!');
        })
        .catch(() => {
          NotificationManager.error('Unable to copy image!');
        });
    }
  };

  const handleFullScreen = () => {
    const elem: any = document.getElementById(id);
    if(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        if(setFullScreenDetected) {
          setFullScreenDetected(true);
        }
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
        if(setFullScreenDetected) {
          setFullScreenDetected(true);
        }
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
        if(setFullScreenDetected) {
          setFullScreenDetected(true);
        }
      }
    }
  };

  const updateDashletVisibility = () => {
    const editedItem: ChartOrderItem = {
      chartId: dashletItem.chartId,
      hidden: !dashletItem.hidden,
    };
    handleDashletVisibility(editedItem);
  };

  return (
    <div className='invisible group-hover:visible group-hover:bg-white absolute top-0 -right-4 h-10 w-10 flex justify-items-stretch cursor-pointer z-10 space-y-3'>
      <div>
        <div onClick={handleFullScreen}><FullScreenIcon /></div>
        <div onClick={handleCopyDashlet}><CopyIcon /></div>
        { editMode && <div onClick={updateDashletVisibility}> { dashletItem.hidden ? <ShowIcon /> :<HiddenIcon/> } </div> }
      </div>
    </div>
  );
};
