import React  from 'react';
import { createPortal } from 'react-dom';
import styles from './loader.module.css';

type LoaderProps = {
    loading: boolean,
}

export const Loader = ({
  loading,
}: LoaderProps) => loading ? createPortal(
  <div
      className={styles.loadingBackground}
  >
    <div
        className={styles.loadingBar}
    >
      <div
          className={styles.loadingCircleInner}
      />
      <div
          className={styles.loadingCircleOuter}
      />
    </div>
  </div>, document.body,
) : null;
