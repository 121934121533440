import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSkill, createSubSkill } from '../../utils/api_service/skill_api';
import { convertFileToBase64, validateQuestionMedia } from '../../utils/helpers/fileHandler';
import { addNewSkill } from '../../utils/redux_store/features/skill_reducer';
import { addNewSubSkill } from '../../utils/redux_store/features/subskill_reducer';
import { Question, Skill, SubSkill } from '../../utils/types/assessment_types';
import { SelectOptions } from '../../utils/types/react_select_types';
import { Button } from '../button/button';
import { CustomSelect } from '../custom_react_select/custom_select';
import { MinuteSecondsInput } from '../input/minute_seconds_input/minute_seconds_input';
import { TextArea } from '../input/text_area/text_area';
import { TextInput } from '../input/text_input/text_input';
import { PrimaryTypography, SecondaryTypography } from '../typography/typography';
import styles from './add_question_modal.module.css';

type AddQuestionModelProps = {
  addNewQuestion: (question: Question) => void,
  skillList: SelectOptions[],
  levelList: SelectOptions[],
  jobTitleList: SelectOptions[],
  subSkillList: SubSkill[] | undefined,
  setSkillList: any,
}

export const AddQuestionModal = ({
  addNewQuestion,
  skillList,
  levelList,
  jobTitleList,
  subSkillList,
  setSkillList,
}:AddQuestionModelProps) => {
  const newQuestionState: Question = {
    id: '',
    jobId: '',
    question: '',
    answer: '',
    skillId: '',
    levelId: '',
    subSkill1Id: '',
    subSkill2Id: '',
    subSkill3Id: '',
    questionType: 'Modern',
    questionTheme: '',
    time: 30,
    points: 1,
    fileEncoded: null,
    mediaQuestion: false,
    mediaExtension: null,
    jobTitle: [],
  };
  const dispatch = useDispatch();
  const [newQuestion, setNewQuestion] = useState<Question>(newQuestionState);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [subSkills1, setSubSkills1] = useState<SelectOptions[]>([]);
  const [subSkills2, setSubSkills2] = useState<SelectOptions[]>([]);
  const [subSkills3, setSubSkills3] = useState<SelectOptions[]>([]);

  const returnSubSkills = (parent: string | undefined) => {
    let subSkills: SelectOptions[] = [];
    subSkillList?.map((subSkill: SubSkill) => {
      if (subSkill.parentSkillId === parent) {
        subSkills.push({ label: subSkill.subskillName, value: subSkill.id });
      }
    });

    return subSkills;
  };

  const questionTypes: SelectOptions[] = [
    {
      value: 'Modern',
      label: 'Video',
    },
    {
      value: 'Classic',
      label: 'Text',
    },
  ];

  const updateSubSkillList = (level: string, subSkillId: string, skillName: string) => {
    if (level === '1') {
      setSubSkills1((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    } else if (level === '2') {
      setSubSkills2((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    } else if (level === '3') {
      setSubSkills3((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    }
  };

  const onAddNewSkill = (skill: string, parent?: string | null, level?: string) => {
    if (parent) {
      createSubSkill(skill, parent).then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then((response: SubSkill) => {
        if (level) {
          updateSubSkillList(level, response.id, skill);
          dispatch(addNewSubSkill(response));
        }
      });
    } else {
      createSkill(skill).then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then((response: Skill) => {
        setSkillList((skills: any) => [...skills, { value: response.id, label: skill }]);
        dispatch(addNewSkill({ value: response.id, label: skill }));
      });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
    if (event.target.name === 'time') {
      setNewQuestion({
        ...newQuestion,
        time: event.target.valueAsNumber,
      });
    } else {
      setNewQuestion({
        ...newQuestion,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleMultiSelectChange = (selectedValue: SelectOptions[], action: any) => {
    let jobTitles: string[] = [];

    selectedValue.map((jobTitle: SelectOptions) => {
      jobTitles.push(jobTitle.value);
    });

    setNewQuestion({
      ...newQuestion,
      [action.name]: jobTitles,
    });
  };

  const handleSelectChange = (selectedValue: SelectOptions, action: any) => {
    if (action.name === 'skillId') {
      selectedValue && setSubSkills1(() => returnSubSkills(selectedValue.value));
      setSubSkills2([]);
      setSubSkills3([]);
      setNewQuestion((question) => ({
        ...question,
        [action.name]: selectedValue ? selectedValue.value : null,
        subSkill1Id: null,
        subSkill2Id: null,
        subSkill3Id: null,
      }));
    } else if (action.name === 'subSkill1Id') {
      selectedValue && setSubSkills2(() => returnSubSkills(selectedValue.value));
      setSubSkills3([]);
      setNewQuestion((question) => ({
        ...question,
        [action.name]: selectedValue ? selectedValue.value : null,
        subSkill2Id: null,
        subSkill3Id: null,
      }));
    } else if (action.name === 'subSkill2Id') {
      selectedValue && setSubSkills3(() => returnSubSkills(selectedValue.value));
      setNewQuestion((question) => ({
        ...question,
        [action.name]: selectedValue ? selectedValue.value : null,
        subSkill3Id: null,
      }));
    } else {
      setNewQuestion({
        ...newQuestion,
        [action.name]: selectedValue ? selectedValue.value : null,
      });
    }
  };

  const addFileViaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.item(0);
    if (file && validateQuestionMedia(file)) {
      convertFileToBase64(file).then(result => {
        setNewQuestion({
          ...newQuestion,
          mediaQuestion: true,
          mediaExtension: file.name.split('.').pop(),
          // @ts-ignore
          fileEncoded: result,
        });
        setUploadedFile(file);
      });
    }
  };

  const clearMediaFile = () => {
    setUploadedFile(undefined);
    setNewQuestion({
      ...newQuestion,
      mediaQuestion: false,
      mediaExtension: '',
      fileEncoded: '',
    });
  };

  const renderAddMedia = () => {
    if (uploadedFile) {
      return (
        <div
            className={styles.uploadMediaContainer}
        >
          <SecondaryTypography.XSmall
              keepDefaultMargins={true}
              className={styles.uploadMediaText}
              fontWeight='semi-bold'
          >
            Uploaded File: { uploadedFile.name }
          </SecondaryTypography.XSmall>
          <Button
              onClick={clearMediaFile}
              variant='danger'
              size='xSmall'
          >
            Clear File
          </Button>
        </div>
      );
    } else {
      return (
        <>
          <input
              type='file'
              onChange={addFileViaInput}
              id='upload-file-input'
              style={{ display: 'none' }}
          />
          <Button
              onClick={() => document.getElementById('upload-file-input')?.click()}
              variant='primary'
              size='xSmall'
          >
            Upload Media
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
      >
        Add new question
      </PrimaryTypography.FiveXLarge>
      <div
          className={styles.largeInputGrid}
      >
        <TextInput
            label='Question'
            name='question'
            value={newQuestion.question}
            type='text'
            onChange={handleChange}
        />
        <TextArea
            label='Answer'
            name='answer'
            value={newQuestion.answer}
            onChange={handleChange}
        />
      </div>
      { renderAddMedia() }
      <div
          className={styles.smallInputGrid}
      >
        <CustomSelect
            label='Skill'
            name='skillId'
            value={skillList?.filter(option => option.value === newQuestion.skillId)[0]}
            options={skillList}
            onChange={handleSelectChange}
            creatable={true}
            onCreateOption={onAddNewSkill}
        />
        { newQuestion.skillId !== null &&
        <CustomSelect
            label='Sub-Skill 1'
            name='subSkill1Id'
            value={subSkills1?.filter(option => option.value === newQuestion.subSkill1Id)[0]}
            options={subSkills1}
            onChange={handleSelectChange}
            creatable={true}
            clearable={true}
            onCreateOption={(input) => onAddNewSkill(input, newQuestion.skillId, '1')}
        />
        }
        { newQuestion.subSkill1Id &&
        <CustomSelect
            label='Sub-Skill 2'
            name='subSkill2Id'
            value={subSkills2?.filter(option => option.value === newQuestion.subSkill2Id)[0]}
            options={subSkills2}
            onChange={handleSelectChange}
            creatable={true}
            clearable={true}
            onCreateOption={(input) => onAddNewSkill(input, newQuestion.subSkill1Id, '2')}
        />
        }
        { newQuestion.subSkill2Id &&
        <CustomSelect
            label='Sub-Skill 3'
            name='subSkill3Id'
            value={subSkills3?.filter(option => option.value === newQuestion.subSkill3Id)[0]}
            options={subSkills3}
            onChange={handleSelectChange}
            creatable={true}
            clearable={true}
            onCreateOption={(input) => onAddNewSkill(input, newQuestion.subSkill2Id, '3')}
        />
        }
        <CustomSelect
            label='Level'
            name='levelId'
            value={levelList?.filter(option => option.value === newQuestion.levelId)[0]}
            options={levelList}
            onChange={handleSelectChange}
        />
        <CustomSelect
            label='Job Title'
            name='jobTitle'
            options={jobTitleList}
            onChange={handleMultiSelectChange}
            isMulti={true}
        />
        <CustomSelect
            label='Question Type'
            name='questionType'
            value={newQuestion.questionType ? questionTypes?.filter(option => option.value === newQuestion.questionType)[0] : questionTypes[0]}
            options={questionTypes}
            onChange={handleSelectChange}
        />
        <MinuteSecondsInput
            label='Timer (Seconds)'
            name='time'
            value={newQuestion.time}
            onChange={handleChange}
        />
        <TextInput
            label='Points for Question'
            name='points'
            value={newQuestion.points.toString()}
            type='number'
            onChange={handleChange}
        />
        <Button
            variant='primary'
            size='medium'
            onClick={() => addNewQuestion(newQuestion)}
            customClass={styles.addQuestionButton}
        >
          Add Question
        </Button>
      </div>
    </>
  );
};
