import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/user-group';

export function getUserGroupList() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true));
}

export function addUserGroup(groupName: string, roles: string[]) {
  const body = {
    group_id: '',
    group_name: groupName,
    roles: roles,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export function deleteUserGroup(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'DELETE', 'application/json', true));
}

export function editUserGroup(id: string, name: string, roles: string[]) {
  const body = {
    group_id: id,
    group_name: name,
    roles: roles,
  };
  return fetch(createRequest(apiEndpoint, 'PUT', 'application/json', true, body));
}
