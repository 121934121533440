import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsCharts } from '../../../ui/analytics_charts/AnalyticsCharts';
import { CollapsibleFilter } from '../../../ui/data_source_filter/CollapsibleFilter';
import { Loader } from '../../../ui/loader/loader';
import { PrimaryTypography } from '../../../ui/typography/typography';
import { defaultChartOrderAssessment } from '../../../utils/analytics_data/chartData';
import { assessmentTypes } from '../../../utils/analytics_data/filterData';
import { searchAnalyticsAssessment, searchAssessmentAnalytics } from '../../../utils/api_service/analytics_api';
import { getOrganizationTree } from '../../../utils/api_service/organization_unit';
import { getDashletOrder } from '../../../utils/api_service/organization_users';
import { RootState } from '../../../utils/redux_store/store';
import { AnalyticsSearchObject, AssessmentAnalyticsData, AssessmentAnalyticsSearchRequest, ChartOrderItem } from '../../../utils/types/analytics/analytics_types';
import { SelectOptions } from '../../../utils/types/react_select_types';
import { OrganizationUnit } from '../../admin/OrganizationSettings/TreeStructure';

const CHART_TYPE = 'assessment';

export const AssessmentAnalytics = () => {

  const skills = useSelector((state: RootState) => state.skills);
  const [skillList, setSkillList] = useState<SelectOptions[]>();
  const [participantNo, setParticipantNo] = useState<SelectOptions[]>();
  const [assessmentList, setAssessmentList] = useState<SelectOptions[]>(skills.skillsList);
  const [searchObject, setSearchObject] = useState<AnalyticsSearchObject>({
    timeRange: { startDate: dayjs().subtract(1, 'year').toDate(), endDate: dayjs().toDate() },
    organization: [],
    assessmentType: [
      {
        value: 'employee',
        label: 'Employees',
      },
    ],
    skill: [],
    assessmentName: undefined,
    participantNo: {
      value: '10',
      label: '10',
    },
  });
  const [searchResult, setSearchResult] = useState<AssessmentAnalyticsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResultChartOrder, setSearchResultChartOrder] = useState<ChartOrderItem[]>();
  const [organizationTree, setOrganizationTree] = useState<OrganizationUnit>();
  const [chartOrder, setChartOrder] = useState<ChartOrderItem[]>(defaultChartOrderAssessment);
  const emptyData: AssessmentAnalyticsData = {};

  useEffect(() => {
    const debounceSearchTerm = setTimeout(() => {
      setLoading(true);
      if(searchObject.assessmentType[0]===undefined){
        setSearchObject({
          ...searchObject,
          assessmentType: [{
            value: 'employee',
            label: 'Employees',
          }],
        });
      }

      if (searchObject.timeRange && searchObject.timeRange.startDate && searchObject.timeRange.endDate) {

        const searchTerms: AssessmentAnalyticsSearchRequest = {
          timeRange: formatDateStrings(searchObject.timeRange.startDate, searchObject.timeRange.endDate),
          organizationUnitId: searchObject.organization[0]? searchObject.organization[0].value : '',
          assessmentType: searchObject.assessmentType[0]? searchObject.assessmentType.map(item => item.value?.charAt(0).toUpperCase()  + item.value?.slice(1))[0] : 'Employee',
        };

        searchAnalyticsAssessment(searchTerms).then(async response => {
          const formattedData: SelectOptions[] = response.map((item: any) => ({
            value: (item as { id: string }).id,
            label: (item as { assessmentTitle: string }).assessmentTitle,
          }));

          setAssessmentList(formattedData);
        });

        if (searchObject.assessmentName?.value==='' || searchObject.assessmentName?.value===undefined){
          setSearchResult(emptyData);
          setLoading(false);
        }
        else {
          searchAssessmentAnalytics(searchObject.assessmentName?.value, searchObject.participantNo?.value==='' || searchObject.participantNo?.value===undefined ? 0 : parseInt(searchObject.participantNo?.value, 10)).then(async response => {
            setSearchResult(response);
            setLoading(false);
          });
        }
      }
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchObject]);

  useEffect(() => {
    if (!chartOrder || !searchResult) return;

    const temp: ChartOrderItem[] = [];
    for (const item of chartOrder) {
      if (Object.hasOwn(searchResult, item.chartId)) {
        temp.push(item);
      }
    }
    setSearchResultChartOrder(temp);
  }, [chartOrder, searchResult]);

  useEffect(() => {
    setSkillList(skills.skillsList);
  }, [skills.skillsList]);

  useEffect(() => {
    const result: SelectOptions[] = [];

    for (let i = 1; i <= 10; i++) {
      result.push({ value: i.toString(), label: i.toString() });
    }
    setParticipantNo(result);

  }, []);

  useEffect(() => {
    getOrganizationTree().then(response => {
      setOrganizationTree(response);
    });

    setChartOrder(defaultChartOrderAssessment);
    getDashletOrder(CHART_TYPE).then(response => {
      if (!response.chartOrder?.length) return;
      setChartOrder(response.chartOrder);
    }).catch( () => {
      setChartOrder(defaultChartOrderAssessment);
    });
  }, []);

  const formatDateStrings = (startDate: any, endDate: any) => {
    const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
    const formattedEndDate = new Date(endDate).toISOString().split('T')[0];

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  };

  return (
    <>
      <div className='min-h-screen w-full'>
        <PrimaryTypography.TwoXLarge keepDefaultMargins={true} fontWeight='bold'>
          Assessment Analytics
        </PrimaryTypography.TwoXLarge>
        <div className='flex flex-col bg-white p-4 shadow-lg border'>
          <CollapsibleFilter
              open={true}
              skillList={skillList}
              assessmentList={assessmentList}
              participantNo={participantNo}
              organizationTree={organizationTree}
              assessmentTypeList={assessmentTypes}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              searchResultChartOrder={searchResultChartOrder}
              type={CHART_TYPE}
          />
          <AnalyticsCharts
              searchResult={searchResult}
              chartOrder={chartOrder}
              setChartOrder={setChartOrder}
              searchResultChartOrder={searchResultChartOrder}
              type={CHART_TYPE}
          />
        </div>
      </div>
      <Loader
          loading={loading}
      />
    </>
  );
};
