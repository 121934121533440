import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { ArrowLeft } from '../../../../../ui/icons/icons';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import {
  getAssessment,
  getDetailedAssessmentResults,
  resultsPreScreen,
} from '../../../../../utils/api_service/assessment_api';
import { convertSecondsToTimeTaken } from '../../../../../utils/helpers/time';
import {
  Assessment,
  AssessmentQuestionResult,
  AttemptSkill,
  PreScreenResults,
  User,
} from '../../../../../utils/types/assessment_types';
import commonStyles from '../../view.module.css';
import { CollapsibleResult } from './collapsible_result/collapsible_result';
import styles from './view_detailed_assessment_results.module.css';

export const ViewDetailedAssessmentResults = () => {
  const history = useHistory();
  const { assessmentUserId } = useParams<{ assessmentUserId: string }>();
  const [detailedQuestions, setDetailedQuestions] = useState<AssessmentQuestionResult[]>([]);
  const [storedQuestions, setStoredQuestions] = useState<AssessmentQuestionResult[]>([]);
  const [assessmentDetails, setAssessmentDetails] = useState<Assessment>();
  const [skills, setSkills] = useState<AttemptSkill[]>();
  const [skillVisibility, setSkillVisibility] = useState<boolean>(false);
  const [completionFilter, setCompletionFilter] = useState<boolean>(false);
  const [selectedSkill, setSelectedSkill] = useState<string>();
  const [user, setUser] = useState<User>();
  const [preScreenResults, setPreScreenResults] = useState<PreScreenResults[]>([]);

  useEffect(() => {
    getDetailedAssessmentResults(assessmentUserId).then(response => {
      setSkills(response.skills);
      let newDetailedQuestions: AssessmentQuestionResult[] = [];
      let newStoredQuestions: AssessmentQuestionResult[] = [];

      response.questions.forEach((result: AssessmentQuestionResult) => {
        let questionResult = result;
        let startTime: number = 0;
        let endTime: number = 0;
        if (questionResult.timeStarted && questionResult.timeCompleted) {
          startTime = new Date(questionResult.timeStarted.toString().split(' ').join('T')).getTime() / 1000;
          endTime = new Date(questionResult.timeCompleted.toString().split(' ').join('T')).getTime() / 1000;
        }
        questionResult.completionTime = endTime - startTime;
        newDetailedQuestions.push(questionResult);
        newStoredQuestions.push(questionResult);
      });

      setDetailedQuestions(newDetailedQuestions);
      setStoredQuestions(newStoredQuestions);
      setUser(response.user);

      getAssessment(response.questions[0].assessmentId).then((response) => {
        setAssessmentDetails(response);
      });
    });

    resultsPreScreen(assessmentUserId)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        setPreScreenResults(data);
      })
      .catch(() => {
        toast.error('Pre Screen Questions Error', { position: 'bottom-center' });
      });
  }, [assessmentUserId]);

  const score = useMemo(() => {
    return detailedQuestions.reduce((totalScore, question) => totalScore + (Number.isFinite(question.score) ? question.score : 0), 0);
  }, [detailedQuestions]);

  const maxScore = useMemo(() => {
    return detailedQuestions.reduce((totalMaxScore, question) => totalMaxScore + (Number.isFinite(question.maxScore) ? question.maxScore : 0), 0);
  }, [detailedQuestions]);

  const totalElapsedTime = () => {
    let timeElapsed: number = 0;
    skills?.map((skill: AttemptSkill) => {
      timeElapsed += skill.timeTakenForSkill;
    });

    return convertSecondsToTimeTaken(timeElapsed);
  };

  const filterSortBySkill = (skillId: string) => {
    if (skillId === selectedSkill) {
      setDetailedQuestions(storedQuestions);
      setSelectedSkill(undefined);
      setCompletionFilter(false);
    } else {
      let filteredQuestions = storedQuestions.filter(question => question.skillId === skillId);
      filteredQuestions = filteredQuestions.sort((compareOne, compareTwo) =>
      // @ts-ignore
        compareTwo.score - compareOne.score,
      );
      setDetailedQuestions(filteredQuestions);
      setSelectedSkill(skillId);
      setCompletionFilter(false);
    }
  };

  const filterCompletionTime = () => {
    if (!completionFilter) {
      let filteredQuestions = detailedQuestions.filter(question => question.isCorrect);
      filteredQuestions = filteredQuestions.sort((compareOne, compareTwo) =>
      // @ts-ignore
        compareOne.completionTime - compareTwo.completionTime,
      );
      setDetailedQuestions(filteredQuestions);
      setCompletionFilter(true);
    } else {
      if (selectedSkill) {
        let filteredQuestions = storedQuestions.filter(question => question.skillId === selectedSkill);
        filteredQuestions = filteredQuestions.sort((compareOne, compareTwo) =>
        // @ts-ignore
          compareTwo.score - compareOne.score,
        );
        setDetailedQuestions(filteredQuestions);
        setCompletionFilter(false);
      } else {
        setDetailedQuestions(storedQuestions);
        setCompletionFilter(false);
      }
    }
  };

  const updateDetailedQuestionArray = (questionResult: AssessmentQuestionResult, getLinkStatus?: boolean) => {
    let detailedQuestionsCopy = [...detailedQuestions];
    if (!getLinkStatus) {
      questionResult.approved = true;
    }
    let index = detailedQuestionsCopy.findIndex(item => item.id === questionResult.id);
    detailedQuestionsCopy[index] = questionResult;
    setDetailedQuestions(
      detailedQuestionsCopy,
    );
  };

  return (
    <div
        className={commonStyles.container}
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Assessment Results
      </PrimaryTypography.FiveXLarge>
      <div
          className={commonStyles.contentContainer}
      >
        <div
            className={styles.backButtonContainer}
            onClick={() => history.push(`/view-assessment/${detailedQuestions[0].assessmentId}/4`)}
        >
          <div>
            <ArrowLeft className='h-4 w-4'/>
          </div>
          <SecondaryTypography.Small
              fontWeight='bold'
          >
            Back to all results
          </SecondaryTypography.Small>
        </div>
        <SecondaryTypography.Medium
            keepDefaultMargins={true}
            fontWeight='semi-bold'
            className='mt-1 mb-3'
        >
          { user?.firstName + ' ' + user?.lastName } - { assessmentDetails?.assessmentTitle }
        </SecondaryTypography.Medium>
        <div
            className={styles.flexInlineRow}
            style={{ justifyContent: 'space-between' }}
        >
          <div>
            { skills?.map((skill: AttemptSkill) => {
              return (
                <div
                    key={skill.skillId}
                    className={classNames(styles.flexInlineRow, styles.capsuleCommonStyles)}
                >
                  { skill.skillName }&nbsp;-&nbsp;
                  <div
                      className={styles.skillSummaryCapsule}
                  >
                    { skill.correctCount } out of { skill.totalCount }
                  </div>
                </div>
              );
            }) }
          </div>
          <div>
            <SecondaryTypography.Small
                fontWeight='semi-bold'
            >
              Total Score = { score/maxScore * 100 }%
            </SecondaryTypography.Small>
            <SecondaryTypography.Small
                fontWeight='semi-bold'
            >
              Elapsed Time - { totalElapsedTime() }
            </SecondaryTypography.Small>
          </div>
        </div>
        <div
            className={styles.flexInlineRow}
        >
          <Button
              size='xSmall'
              variant='secondary'
              onClick={() => setSkillVisibility((visibility) => !visibility)}
              selected={skillVisibility}
          >
            Competency
          </Button>
          <Button
              size='xSmall'
              variant='secondary'
              onClick={filterCompletionTime}
              selected={completionFilter}
          >
            Completion Time
          </Button>
        </div>
        <div
            className={styles.flexInlineRow}
        >
          { skillVisibility && skills?.map((skill: AttemptSkill) => {
            return (
              <Button
                  size='xSmall'
                  variant='success'
                  onClick={() => {filterSortBySkill(skill.skillId);}}
                  key={skill.skillName}
                  selected={selectedSkill === skill.skillId}
              >
                { skill.skillName }
              </Button>
            );
          }) }
        </div>
        { preScreenResults.length > 0 && (
          <div className='flex flex-col gap-6 my-6'>
            <div className='text-xl font-bold text-secondary-blue-500'> Pre Screen Results</div>
            <div className='flex flex-col gap-4'>
              { preScreenResults.map((result) => (
                <div key={result.questionId}>
                  <div className='p-2 px-5 font-semibold bg-gray-100'>{ result.question }</div>
                  <div className='p-2'>Answer: { result.answer }</div>
                </div>
              )) }
            </div>
          </div>
        ) }
        <div className='text-xl font-bold text-primary-green-500 my-6'>
          Assessment Results
        </div>
        { detailedQuestions.map((question) => {
          return (
            <CollapsibleResult
                key={question.id}
                assessmentQuestionResult={question}
                updateDetailedQuestionArray={updateDetailedQuestionArray}
            />
          );
        }) }
      </div>
    </div>
  );
};
