import React, { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { setPreScreenQuestions } from '../../../../../utils/api_service/assessment_api';
import { Assessment, PreScreenQuestion } from '../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';

const questionType: SelectOptions[] = [{
  label: 'Numeric',
  value: 'numeric',
}, {
  label: 'Multiple Choice',
  value: 'multiple-choice',
}];

type PreScreenQuestionsProps = {
  assessmentInfo: Assessment
  setAssessmentInfo: Dispatch<SetStateAction<Assessment | undefined>>
}

const PreScreenQuestions = ({ assessmentInfo, setAssessmentInfo }: PreScreenQuestionsProps) => {
  const [questions, setQuestions] = useState<PreScreenQuestion[]>(assessmentInfo.preScreen ? assessmentInfo.preScreen: []);
  const [newQuestionType, setNewQuestionType] = useState<'numeric' | 'multiple-choice'>('numeric');
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [choices, setChoices] = useState<string[]>(['']);

  const generateUniqueKey = () => {
    return `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;
  };

  const handleAddQuestion = () => {
    if (!newQuestionText.trim()) return;

    const filteredChoices = choices.filter(choice => choice.trim() !== '');
    const newQuestion: PreScreenQuestion = {
      questionId: generateUniqueKey(),
      questionType: newQuestionType,
      question: newQuestionText,
      ...(newQuestionType === 'multiple-choice' && { choices: [...filteredChoices] }),
    };
    setQuestions([...questions, newQuestion]);
    setNewQuestionText('');
    setChoices(['']);
  };

  const handleChoiceChange = (index: number, value: string) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const handleDeleteQuestion = (id: string) => {
    setQuestions(questions.filter(question => question.questionId !== id));
  };

  const handleSaveQuestions = async () => {
    try {
      const response = await setPreScreenQuestions(assessmentInfo.id, questions);
      const assessment: Assessment = await response.json();
      setAssessmentInfo(assessment);
      toast.success('Saved Pre Screen Questions', { position: 'bottom-center' });
    } catch (error) {
      toast.error('Failed to Save. Try Again', { position: 'bottom-center' });
    }
  };

  return (
    <div className='flex-col p-4 relative'>
      { questions.length > 0 && (
        <div className='flex flex-col gap-4 bg-gray-100 rounded-lg p-5 mb-10'>
          { questions.map((question, indexNum) => (
            <div key={indexNum} className='flex flex-col gap-1 items-center mb-2 w-full'>
              <div className='flex w-full gap-4'>
                <div className='w-8/12'>
                  <TextInput
                      onChange={() => {
                      }}
                      label={`${indexNum + 1}. Question`}
                      name={`question-${question.questionId}`}
                      value={question.question}
                      type='text'
                      disabled={true}
                  />
                </div>
                <div className='3/12'>
                  <TextInput
                      onChange={() => {
                      }}
                      label='Question Type'
                      name={`question-type-${question.questionId}`}
                      value={questionType.find(option => option.value === question.questionType)?.label || ''}
                      type='text'
                      disabled={true}
                  />
                </div>
                <div className='mt-2'>
                  <Button
                      onClick={() => handleDeleteQuestion(question.questionId)}
                      variant='secondary'
                      size='small'
                  >
                    X
                  </Button>
                </div>
              </div>
              { question.questionType === 'multiple-choice' && (
                <div className='w-full flex flex-col gap-1'>
                  { question.choices?.map((choice, idx) => (
                    <div key={idx} className='w-4/6'>
                      <TextInput
                          onChange={() => {
                          }}
                          label=''
                          name={`choice-${question.questionId}-${idx}`}
                          value={choice}
                          type='text'
                          disabled={true}
                      />
                    </div>
                  )) }
                </div>
              ) }
            </div>
          )) }
        </div>
      ) }
      <div className='flex gap-4'>
        <div className='w-8/12'>
          <TextInput
              label='Question'
              name='question'
              value={newQuestionText}
              type='text'
              onChange={(e) => setNewQuestionText(e.target.value)}
          />
        </div>
        <div className='w-4/12'>
          <CustomSelect
              label='Question Type'
              name='questionType'
              value={questionType[0]}
              options={questionType}
              onChange={(selectedOption: SelectOptions) => setNewQuestionType(selectedOption.value as 'numeric' | 'multiple-choice')}
          />
        </div>
      </div>
      { newQuestionType === 'multiple-choice' && (
        <div className='mt-2'>
          { choices.map((choice, index) => (
            <div key={index} className='flex'>
              <div className='w-full mt-[6px]'>
                <TextInput
                    name='question'
                    value={choice}
                    type='text'
                    onChange={(e) => handleChoiceChange(index, e.target.value)}
                />
              </div>
              <div>
                { index === choices.length - 1 && (
                  <Button
                      onClick={() => setChoices([...choices, ''])}
                      variant='secondary'
                      size='small'
                  >
                    Add Choice
                  </Button>
                ) }
              </div>
            </div>
          )) }
        </div>
      ) }
      <Button
          onClick={() => handleAddQuestion()}
          variant='primary'
          size='xSmall'
          disabled={newQuestionText === '' || (newQuestionType === 'multiple-choice' && choices.every(choice => choice.trim() === ''))}
      >
        Add Question
      </Button>
      <div className='absolute -bottom-10 right-0'>
        <Button
            onClick={() => handleSaveQuestions()}
            variant='primary'
            size='xSmall'
        >
          Save Pre Screen Questions
        </Button>
      </div>

    </div>
  );
};

export default PreScreenQuestions;
