import { createRequest } from '../helpers/api_utils';
import {
  AssessmentSettings,
  AssessmentsSearchObject,
  BasicInfoInputs,
  CandidateStatus, FilterState,
  InviteUserRequest,
  PartnerQueryFilter, PreScreenAnswers,
  PreScreenQuestion,
  QueryFilter,
  QuestionSortOrder,
} from '../types/assessment_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/assessments';
const qnaApiEndpoint = apiEndpoint + '/qna';
const settingsApiEndpoint = apiEndpoint + '/settings';
const assessmentUserApiEndpoint = apiEndpoint + '/user';
const assessmentFlowApiEndpoint = apiUrl + '/webrtc';
const candidateStatusEndpoint = assessmentUserApiEndpoint + '/candidate_status';

export function createAssessment(inputs: BasicInfoInputs) {
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, inputs))
    .then(response => {
      return response.json();
    });
}

export function updateAssessment(inputs: BasicInfoInputs, assessmentId: string) {
  return fetch(createRequest(apiEndpoint + `/update?id=${encodeURIComponent(assessmentId)}`, 'PUT', 'application/json', true, inputs))
    .then(response => {
      return response.json();
    });
}

export function getAssessment(id: string) {
  return fetch(createRequest(apiEndpoint + `?id=${encodeURIComponent(id)}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function deleteAssessment(id: string) {
  return fetch(createRequest(apiEndpoint + `/${encodeURIComponent(id)}`, 'DELETE', 'application/json', true));
}

export function getAssessmentList() {
  return fetch(createRequest(apiEndpoint + '/list', 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function searchAssessmentList(searchObject: AssessmentsSearchObject) {
  return fetch(createRequest(apiEndpoint + '/search', 'POST', 'application/json', true, searchObject))
    .then(response => {
      return response.json();
    });
}

export function getAssessmentQuestions(id: string) {
  return fetch(createRequest(apiEndpoint + `/questions/${encodeURIComponent(id)}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function addQuestionToAssessment(sortingOrder: string, questionId: string, assessmentId: string) {
  const body = {
    sort_order: sortingOrder,
    question_answer_id: questionId,
    assessment_id: assessmentId,
  };
  return fetch(createRequest(qnaApiEndpoint, 'POST', 'application/json', true, body))
    .then(response => {
      return response.json();
    });
}

export function updateQuestionOrderInAssessment(sortedQuestionOrder: QuestionSortOrder[]) {
  return fetch(createRequest(qnaApiEndpoint + '/sort_order', 'PUT', 'application/json', true, sortedQuestionOrder));
}

export function deleteQuestionFromAssessment(id: string) {
  return fetch(createRequest(qnaApiEndpoint + `/delete/${encodeURIComponent(id)}`, 'DELETE', 'application/json', true));
}

export function updateAssessmentStatus(id: string, status: boolean) {
  const body = {
    assessmentId: id,
    isActive: status,
  };
  return fetch(createRequest(apiEndpoint + '/status', 'PUT', 'application/json', true, body));
}

export function updateAssessmentSettings(id: string, assessmentSettings: AssessmentSettings) {
  const body = {
    assessment_id: id,
    show_score: assessmentSettings.showScore,
    shuffle_questions: assessmentSettings.shuffleQuestions,
    enable_copy: assessmentSettings.enableCopy,
    attempt_count: parseInt(assessmentSettings.attemptCount),
    custom_message: assessmentSettings.customMessage,
    blur_video: assessmentSettings.blurVideo,
    threshold_percent: parseInt(assessmentSettings.thresholdPercent || '0'),
    unsuccessful_email: assessmentSettings.unsuccessfulEmail,
  };
  return fetch(createRequest(settingsApiEndpoint + '/update', 'PUT', 'application/json', true, body));
}

export function getAssessmentSettings(id: string) {
  return fetch(createRequest(settingsApiEndpoint + `?id=${encodeURIComponent(id)}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function inviteUserToAssessment(inviteUserRequest: InviteUserRequest) {
  return fetch(createRequest(apiEndpoint + '/invite', 'POST', 'application/json', true, inviteUserRequest))
    .then(response => {
      return response.json();
    });
}

export function getAssessmentUsers(assessmentId: string) {
  return fetch(createRequest(assessmentUserApiEndpoint + `/get/assessment_id?assessment_id=${assessmentId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function getAssessmentsByUserId(userId: string) {
  return fetch(createRequest(assessmentUserApiEndpoint + `/get/user_id?user_id=${userId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function deleteAssessmentUser(assessmentUserId: string) {
  return fetch(createRequest(assessmentUserApiEndpoint + `/${assessmentUserId}`, 'DELETE', 'application/json', true));
}

export function updateAssessmentUserStatus(assessmentUserId: string, enabled: boolean) {
  const body = {
    assessmentUserId: assessmentUserId,
    enabled: enabled,
  };
  return fetch(createRequest(assessmentUserApiEndpoint + '/status', 'PUT', 'application/json', true, body));
}

export function startAssessment(userAssessmentId: string) {
  return fetch(createRequest(assessmentFlowApiEndpoint + `/start-assessment?user_assessment_id=${userAssessmentId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function startPracticeAssessment() {
  return fetch(createRequest(assessmentFlowApiEndpoint + '/practice-assessment', 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function assessmentPing(userAssessmentQuestionId: string) {
  return fetch(createRequest(assessmentFlowApiEndpoint + `/ping?user_assessment_question_answer_id=${userAssessmentQuestionId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function navigateNextQuestion(userAssessmentId: string) {
  return fetch(createRequest(assessmentFlowApiEndpoint + `/next?user_assessment_id=${userAssessmentId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function uploadQuestionAnswer(userAssessmentQnaId: string, timeExpired: string, textAnswer?: string, videoFile?: File) {
  let formData = new FormData();
  formData.append('user_assessment_qna_id', userAssessmentQnaId);
  formData.append('time_expired_str', timeExpired.toString());
  if (textAnswer && videoFile) {
    formData.append('user_answer', textAnswer);
    formData.append('file', videoFile);
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  };

  return fetch(assessmentFlowApiEndpoint + '/upload', requestOptions);
}

export function getDownloadLink(userAssessmentQnaId: string) {
  return fetch(createRequest(assessmentFlowApiEndpoint + `/download?user_assessment_qna_id=${userAssessmentQnaId}`, 'POST', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function getAssessmentResultList(assessmentId: string) {
  return fetch(createRequest(apiEndpoint + `/user/${assessmentId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function getDetailedAssessmentResults(userAssessmentId: string) {
  return fetch(createRequest(apiEndpoint + `/user/detailed/${userAssessmentId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function toggleAttemptMarkedStatus(assessmentUserId: string, marked: boolean) {
  const body = {
    assessment_user_id: assessmentUserId,
    marked: marked,
  };
  return fetch(createRequest(apiEndpoint + '/user/marked', 'PUT', 'application/json', true, body));
}

export function updateAnswerNotesAndStatus(assessmentUserQnaId: string, isCorrect: boolean, personalNotes: string) {
  const body = {
    assessmentUserQnaId: assessmentUserQnaId,
    isCorrect: isCorrect,
    personalNotes: personalNotes || '',
  };
  return fetch(createRequest(apiEndpoint + '/user/marked/QNA', 'PUT', 'application/json', true, body));
}

export function getManagerSummary(managerId: string) {
  return fetch(createRequest(apiEndpoint + `/manager-dashboard-summary?user_id=${managerId}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function setAssessmentEmployeeQuery(query: QueryFilter) {
  return fetch(createRequest(apiEndpoint + '/query', 'POST', 'application/json', true, query))
    .then(response => {
      return response.json();
    });
}

export function setAssessmentEmployeePartnerQuery(query: PartnerQueryFilter) {
  return fetch(createRequest(apiEndpoint + '/partner/query', 'POST', 'application/json', true, query));
}

export function setCandidateStatus(requestBody: { assessmentId: string, candidateStatus: { status: string, userId: string }[] }) {
  return fetch(createRequest(candidateStatusEndpoint, 'POST', 'application/json', true, requestBody));
}

export function sendCandidateEmail(requestBody: { assessmentId: string, candidateStatus: CandidateStatus}) {
  return fetch(createRequest(candidateStatusEndpoint + '/email', 'POST', 'application/json', true, requestBody));
}

export function exportCandidateCSV(requestBody: { assessmentId: string, candidateStatus: CandidateStatus}) {
  return fetch(createRequest(candidateStatusEndpoint + '/csv', 'POST', 'application/json', true, requestBody));
}

export function setPreScreenQuestions(assessmentId: string, preScreenQuestions: PreScreenQuestion[]) {
  const requestBody = {
    preScreenQuestions: preScreenQuestions,
  };
  return fetch(createRequest(apiEndpoint + `/${assessmentId}/pre-screen`, 'POST', 'application/json', true, requestBody));
}

export function submitPreScreenAnswers(assessmentId: string, userAssessmentId: string, preScreenAnswers: PreScreenAnswers[]) {
  const requestBody = {
    preScreenAnswers: preScreenAnswers,
  };
  return fetch(createRequest(apiEndpoint + `/${assessmentId}/pre-screen/${userAssessmentId}/answers`, 'POST', 'application/json', true, requestBody));
}

export function filterPreScreenAnswers(assessmentId: string, preScreenFilters: FilterState[]) {
  const requestBody = {
    filterPreScreen: preScreenFilters,
  };
  return fetch(createRequest(apiEndpoint + `/${assessmentId}/pre-screen/filter`, 'POST', 'application/json', true, requestBody));
}

export function resultsPreScreen(userAssessmentId: string) {
  return fetch(createRequest(apiEndpoint + `/pre-screen/${userAssessmentId}/results`, 'GET', 'application/json', true));
}
