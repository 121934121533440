import React, { useEffect, useState } from 'react';
import { searchPaginatedQuestions } from '../../../utils/api_service/job_api';
import { Question, QuestionSearch } from '../../../utils/types/assessment_types';
import { Button } from '../../button/button';
import { QuestionMark } from '../../icons/icons';
import { Loader } from '../../loader/loader';
import { PaginationController } from '../../pagination_controller/pagination_controller';
import { SecondaryTypography } from '../../typography/typography';
import styles from './search_bar.module.css';

type SearchBarProps = {
  handleQuestionAdd: (question: Question) => void,
  searchObject: QuestionSearch,
}

export const SearchBar = ({
  handleQuestionAdd,
  searchObject,
}: SearchBarProps) => {
  const [searchResults, setSearchResults] = useState<Question[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const debounceSearchTerm = setTimeout(() => {
      setLoading(true);
      const searchTerms: QuestionSearch = {
        questionTitle: searchObject.questionTitle,
        skillId: searchObject.skillId,
        levelId: searchObject.levelId,
        subSkill1Id: searchObject.subSkill1Id,
        subSkill2Id: searchObject.subSkill2Id,
        subSkill3Id: searchObject.subSkill3Id,
        jobTitle: searchObject.jobTitle,
        pageNumber: 1,
        questionPage: 10,
        assessmentId: searchObject.assessmentId,
      };
      searchPaginatedQuestions(searchTerms).then(response => {
        setSearchResults(response.questions);
        setPageNumber(1);
        setTotalPages(response.pages);
        setLoading(false);
      });
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchObject]);

  const switchPages = (pageNumber: number) => {
    setLoading(true);
    const searchTerms: QuestionSearch = {
      questionTitle: searchObject.questionTitle,
      skillId: searchObject.skillId,
      levelId: searchObject.levelId,
      subSkill1Id: searchObject.subSkill1Id,
      subSkill2Id: searchObject.subSkill2Id,
      subSkill3Id: searchObject.subSkill3Id,
      jobTitle: searchObject.jobTitle,
      pageNumber: pageNumber,
      questionPage: 10,
      assessmentId: searchObject.assessmentId,
    };
    searchPaginatedQuestions(searchTerms).then(response => {
      setPageNumber(pageNumber);
      setSearchResults(response.questions);
      setLoading(false);
    });
  };

  const removeFromSearchResults = (question: Question) => {
    let searchResultsCopy = searchResults;
    if (searchResultsCopy) {
      searchResultsCopy = searchResultsCopy.filter(item => item.id !== question.id);
      setSearchResults(searchResultsCopy);
    }
  };

  return (
    <div
        className={styles.searchBarContainer}
    >
      <div
          className={styles.searchResults}
      >
        <div
            className={styles.paginationContainer}
        >
          <PaginationController
              pageNumber={pageNumber}
              maxPageNumber={totalPages}
              navigatePreviousPage={() => switchPages(pageNumber - 1)}
              navigateNextPage={() => switchPages(pageNumber + 1)}
          />
        </div>
        { searchResults && searchResults.map((question: Question) => {
          return (
            <div
                className={styles.searchResultItem}
                key={question.id}
            >
              <div
                  className={styles.questionInfo}
              >
                <div
                    className={styles.questionIcon}
                >
                  <QuestionMark/>
                </div>
                <SecondaryTypography.Small
                    fontWeight='semi-bold'
                >
                  { question.question }
                </SecondaryTypography.Small>
              </div>
              <Button
                  onClick={() => {
                    handleQuestionAdd(question);
                    removeFromSearchResults(question);
                  }}
                  variant='primary'
                  size='xSmall'
                  customClass={styles.addQuestionButton}
              >
                Add Question
              </Button>
            </div>
          );
        }) }
      </div>
      <Loader
          loading={loading}
      />
    </div>
  );
};
