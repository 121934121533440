import React, { ChangeEvent } from 'react';
import { Button } from '../../../../../../../ui/button/button';
import { ArrowRight } from '../../../../../../../ui/icons/icons';
import { TextInput } from '../../../../../../../ui/input/text_input/text_input';
import { PrimaryTypography } from '../../../../../../../ui/typography/typography';
import { InviteUserRequest } from '../../../../../../../utils/types/assessment_types';
import styles from './invite_single_user.module.css';

type InviteSingleUserProps = {
    inviteUserDetails: InviteUserRequest,
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
    inviteUserForAssessment: (userDetails: InviteUserRequest) => void,
    setAddSingleUser: (value: boolean) => void,
}

export const InviteSingleUser = ({
  inviteUserDetails,
  handleInputChange,
  inviteUserForAssessment,
  setAddSingleUser,
}: InviteSingleUserProps) => {
  return (
    <>
      <div
          className={styles.backIcon}
          onClick={() => setAddSingleUser(false)}
      >
        <ArrowRight/>
      </div>
      <PrimaryTypography.TwoXLarge
          fontWeight='bold'
          alignment='center'
      >
        Send Email Invite
      </PrimaryTypography.TwoXLarge>
      <div
          className={styles.formContainer}
      >
        <TextInput
            label='First Name'
            name='firstName'
            value={inviteUserDetails.firstName}
            type='text'
            onChange={handleInputChange}
        />
        <TextInput
            label='Last Name'
            name='lastName'
            value={inviteUserDetails.lastName}
            type='text'
            onChange={handleInputChange}
        />
        <TextInput
            label='Email'
            name='email'
            value={inviteUserDetails.email}
            type='text'
            onChange={handleInputChange}
        />
      </div>
      <Button
          onClick={() => inviteUserForAssessment(inviteUserDetails)}
          variant='primary'
          size='medium'
      >
        Send Invite
      </Button>
    </>
  );
};
