import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getJobTitleList } from '../../api_service/job_title';
import { JobTitle } from '../../types/admin_types';
import { SelectOptions } from '../../types/react_select_types';

export const fetchJobTitles = createAsyncThunk('jobTitles/fetchJobTitles', async () => {
  return await getJobTitleList();
});

export interface JobTitlesState {
  jobTitlesList: SelectOptions[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: JobTitlesState = {
  jobTitlesList: [],
  loading: 'idle',
};

export const jobTitlesSlice = createSlice({
  name: 'jobTitles',
  initialState,
  reducers: {
    addJobTitleToRedux: (state, action: PayloadAction<SelectOptions>) => {
      const jobTitlesListCopy = [...state.jobTitlesList, action.payload];
      return {
        ...state,
        jobTitlesList: jobTitlesListCopy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobTitles.pending, (state) => {
        return { ...state, loading: 'pending' };
      })
      .addCase(fetchJobTitles.fulfilled, (state, action) => {
        let jobTitleArray: SelectOptions[] = [];
        if (action.payload) {
          action.payload.map((item: JobTitle) => {
            jobTitleArray.push({
              value: item.id,
              label: item.jobTitle,
            });
          });
        }
        return { ...state, jobTitlesList: jobTitleArray, loading: 'succeeded' };
      });
  },
});

export const { addJobTitleToRedux } = jobTitlesSlice.actions;
export default jobTitlesSlice.reducer;
