import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/organization_analytics_settings';

export function getAnalyticsSettings() {
  return fetch(createRequest(apiEndpoint + '/', 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function createAnalyticsSettings(hourlyRate: string, currency: string) {
  let body = {
    hourly_rate: hourlyRate,
    currency: currency,
  };
  return fetch(createRequest(apiEndpoint + '/', 'POST', 'application/json', true, body))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function updateAnalyticsSettings(hourlyRate: string, currency: string) {
  let body = {
    hourly_rate: hourlyRate,
    currency: currency,
  };
  return fetch(createRequest(apiEndpoint + '/', 'POST', 'application/json', true, body))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}
