import React from 'react';
import { useHistory } from 'react-router-dom';
import loginRegisterBg from '../../assets/images/backgrounds/login_register_bg.png';
import logoBlackText from '../../assets/images/logos/white-bg-logo-hiacuity.png';
import { Button } from '../../ui/button/button';
import styles from './login_register.module.css';

export const TermsConditions = () => {
  const history = useHistory();

  const handleSubmit = () => {
    history.push(`/identity-check/${localStorage.getItem('token')}`);
  };

  return (
    <div
        className={styles.pageContainer}
    >
      <div
          className={styles.mainContainer}
      >
        <div
            className={styles.leftContainer}
        >
          <img
              className={styles.leftContainerBg}
              src={loginRegisterBg}
              alt='HiAcuity-Bg'
          />
        </div>
        <div
            className={styles.formContainer}
        >
          <img
              className={styles.logoStyles}
              src={logoBlackText}
              alt='HiAcuity-Logo'
          />
          <h1
              className={styles.formHeading}
          >
            Terms and Conditions
          </h1>
          <form
              className={styles.form}
              onSubmit={handleSubmit}
              data-testid='change-password-form'
          >
            <p>
              This application may contain automatic video interview questions.
              Some questions may require you to answer with the video camera on.
            </p>
            <p>
              <br />
              By clicking on the Consent and Continue button, you are consenting
              to the Terms and Conditions in the data privacy policy contain in
              the hyperlink below.
            </p>
            <p>
              <br />
              If you do not agree to the terms and conditions please close this
              window and exit this application
              <br />
              <br />
            </p>
            <Button
                onClick={() => {}}
                size='medium'
                variant='primary'
                customClass={styles.submitButton}
            >
              Consent and Continue
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
