export const MediumColors = {
  RED: '#ef4444',
  BLUE: '#3b82f6',
  GREEN: '#22c55e',
  ORANGE: '#f97316',
  FUCHSIA: '#d946ef',
  PURPLE: '#a855f7',
  CYAN: '#06b6d4',
  PINK: '#ec4899',
  YELLOW: '#eab308',
  LIME: '#84cc16',
};

export const LightColors = {
  RED: '#fca5a5',
  BLUE: '#93c5fd',
  GREEN: '#86efac',
  ORANGE: '#fdba74',
  FUCHSIA: '#f0abfc',
  PURPLE: '#d8b4fe',
  CYAN: '#67e8f9',
  PINK: '#f9a8d4',
  YELLOW: '#fde047',
  LIME: '#bef264',
};

