import React from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { SecondaryTypography } from '../../typography/typography';
import styles from '../input.module.css';
import 'react-datepicker/dist/react-datepicker.css';

type DatePickerProps = {
    label?: string,
    startDate: Date | string | null,
    endDate: Date | string | null,
    onChange: (timeRange: any) => void,
    disabled?: boolean,
    asSingle?: boolean,
    useRange?: boolean,
}

export const DatePicker = ({
  label = '',
  startDate,
  endDate,
  onChange,
  disabled,
  asSingle = false,
  useRange = true,
}: DatePickerProps) => (
  <div>
    <label
        className={styles.labelStyles}
    >
      <SecondaryTypography.XSmall
          fontWeight='semi-bold'
      >
        { label }
      </SecondaryTypography.XSmall>
    </label>
    <Datepicker
        asSingle={asSingle}
        containerClassName={'position: relative border border-solid border-gray-300 rounded-md shadow-sm mt-1'}
        displayFormat='DD/MM/YYYY'
        value={{ startDate: startDate, endDate: endDate }}
        showShortcuts={true}
        onChange={onChange}
        disabled={disabled}
        useRange={useRange}
    />
  </div>
);
