import classNames from 'classnames';
import React, { FC, RefObject } from 'react';
import { SecondaryTypography } from '../typography/typography';

import styles from './button.module.css';

type Variant = 'primary' | 'secondary' | 'success' | 'danger';

type Size = 'xSmall'| 'small'| 'medium'| 'large'| 'xLarge';

type ButtonProps = {
  onClick: () => void,
  variant: Variant,
  size: Size,
  customClass?: string,
  disabled?: boolean,
  icon?: JSX.Element,
  ref?: RefObject<HTMLButtonElement>,
  selected?: boolean,
}

const getButtonVariant = (variant: Variant) => {
  switch (variant){
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
    case 'success':
      return styles.success;
    case 'danger':
      return styles.danger;
  }
};

const getButtonSize = (size: Size) => {
  switch (size){
    case 'xSmall':
      return styles.xSmall;
    case 'small':
      return styles.small;
    case 'medium':
      return styles.medium;
    case 'large':
      return styles.large;
    case 'xLarge':
      return styles.xLarge;
  }
};

export const Button: FC<ButtonProps> = ({
  children,
  onClick,
  variant,
  size,
  customClass,
  disabled,
  icon,
  ref,
  selected,
}) => (
  <button
      className={classNames(styles.button, getButtonVariant(variant), getButtonSize(size), customClass)}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      aria-selected={selected}
  >
    { icon &&
    <div
        className={styles.buttonIcon}
    >
      { icon }
    </div>
    }
    <SecondaryTypography.Small
        fontWeight='bold'
    >
      { children }
    </SecondaryTypography.Small>
  </button>
);
