import { SetStateAction, createContext } from 'react';
import { AssessmentType } from '../types/assessment_types';

interface IMenuContext {
    menuOpen: boolean;
    setMenuOpen?: (menuOpen: boolean) => void;
    toggleMenuState?: () => void
}

export const DefaultMenuState = {
  menuOpen: false,
};

export const MenuContext = createContext<IMenuContext>(DefaultMenuState);

interface IAssessment {
    assessmentType: AssessmentType,
    assessmentId: string,
    assessmentPageNo: number,
}

interface IAssessmentContext {
    assessmentState: IAssessment,
    setAssessmentState?: SetStateAction<any>,
}

export const DefaultAssessmentState = {
  assessmentState: {
    assessmentType: null,
    assessmentId: '',
    assessmentPageNo: 1,
  },
};

export const AssessmentContext = createContext<IAssessmentContext>(DefaultAssessmentState);
