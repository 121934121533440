import { createRequest } from '../helpers/api_utils';
import { FormInput } from '../types/login_register_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/user';
const apiEndpointEmail = apiEndpoint + '/email';

export function loginUser(user: FormInput) {
  const body ={
    email: user.email,
    password: user.password,
  };
  return fetch(createRequest(apiEndpoint + '/token', 'POST', 'application/json', false, body))
    .then(response => {
      return response.json();
    });
}

export function registerUser(user: FormInput) {
  const body = {
    first_name: user.firstname,
    last_name: user.lastname,
    email: user.email,
    password: user.password,
    organization_id: '0',
    profile_type: '0',
  };
  return fetch(createRequest(apiEndpoint + '/register', 'POST', 'application/json', false, body));
}

export function findUser(id: string) {
  return fetch(createRequest(apiEndpoint + `/find?user_id=${id}`, 'GET', 'application/json', false));
}

export function userSearch(name: string) {
  return fetch(createRequest(apiEndpoint + `/search?name=${name}`, 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function isUserVerified(token: string) {
  const body = {
    token: token,
  };
  return fetch(createRequest(apiEndpointEmail + '/verification', 'POST', 'application/json', false, body));
}

export function resendEmail(email: string) {
  const body = {
    email: email,
  };
  return fetch(createRequest(apiEndpointEmail + '/verification/resend', 'POST', 'application/json', false, body));
}

export function changePassword(password: string) {
  const body = {
    password: password,
  };
  return fetch(createRequest(apiUrl + '/password', 'PUT', 'application/json', true, body));
}

export function requestPasswordChange(email: string) {
  const body = {
    email: email,
  };
  return fetch(createRequest(apiUrl + '/password-reset', 'POST', 'application/json', true, body))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function getUser() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true));
}

export function createUser(password: string) {
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, { password: password }));
}

export function getCurrentUser() {
  return fetch(createRequest(apiEndpoint + '/me', 'GET', 'application/json', true))
    .then(response => {
      return response.json();
    });
}

export function updateUser(first_name: string, last_name: string) {
  const body = {
    first_name: first_name,
    last_name: last_name,
  };
  return fetch(createRequest(apiEndpoint + '/update', 'PUT', 'application/json', true, body))
    .then(response => {
      return response.json();
    });
}

export function deleteCurrentUser() {
  return fetch(createRequest(apiEndpoint + '/delete', 'PUT', 'application/json', true));
}

export function updateProfilePic(fileName: string) {
  const body = {
    file_name: fileName,
  };
  return fetch(createRequest(apiEndpoint + '/profile-pic', 'POST', 'application/json', true, body))
    .then(response => {
      return response.json();
    });
}

export function addUsersToAssessmentWithFile(csvFile: File, assessmentId: string) {
  let formData = new FormData();
  formData.append('file', csvFile);
  formData.append('assessment_id', assessmentId);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  };

  return fetch(apiEndpoint + '/add/csv', requestOptions);
}
