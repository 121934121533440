export enum Features {
    CANDIDATE = 'candidateFeature',
    EMPLOYEE = 'employeeFeature',
    PARTNER = 'partnerFeature',
}

export enum FeatureNames {
    CANDIDATE = 'Candidate',
    EMPLOYEE = 'Employee',
    PARTNER = 'Partner',
}
