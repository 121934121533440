import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { DatePicker } from '../../../../../ui/input/date_picker/date_picker';
import { TextArea } from '../../../../../ui/input/text_area/text_area';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import { createAssessment, getAssessment, updateAssessment } from '../../../../../utils/api_service/assessment_api';
import { AssessmentContext } from '../../../../../utils/contexts/contexts';
import { Assessment, BasicInfoInputs } from '../../../../../utils/types/assessment_types';
import styles from './basic_information.module.css';

export const BasicInformation = () => {
  const { assessmentState, setAssessmentState } = useContext(AssessmentContext);
  const [basicInfoInputs, setBasicInfoInputs] = useState<BasicInfoInputs>({
    type: assessmentState.assessmentType,
    title: '',
    description: '',
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0],
  });

  useEffect(() => {
    loadAssessment();
  },[]);

  const loadAssessment = () => {
    assessmentState.assessmentId && getAssessment(assessmentState.assessmentId).then((response: Assessment) => {
      setBasicInfoInputs({
        ...basicInfoInputs,
        title: response.assessmentTitle,
        description: response.description,
        startDate: new Date(response.startDate * 1000).toISOString().split('T')[0],
        endDate: new Date(response.endDate * 1000).toISOString().split('T')[0],
        jobPosition: response.jobPosition || undefined,
      });
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBasicInfoInputs({
      ...basicInfoInputs,
      [event.target.name]: event.target.value,
    });
  };

  const changeDate = (timeRange: any) => {
    setBasicInfoInputs({
      ...basicInfoInputs,
      startDate: timeRange.startDate,
      endDate: timeRange.endDate,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (assessmentState.assessmentId) {
      updateAssessment(basicInfoInputs, assessmentState.assessmentId).then(() => {
        setAssessmentState({
          ...assessmentState,
          assessmentPageNo: 2,
        });
      });
    } else {
      createAssessment(basicInfoInputs).then(response => {
        setAssessmentState({
          ...assessmentState,
          assessmentId: response.id,
          assessmentPageNo: 2,
        });
      });
    }
  };

  return (
    <>
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Basic Information
      </PrimaryTypography.FiveXLarge>
      <SecondaryTypography.Medium
          keepDefaultMargins={true}
      >
        Create a new assessment and choose questions in the next step
      </SecondaryTypography.Medium>
      <form
          className={styles.basicInformationForm}
          onSubmit={handleSubmit}
      >
        <div
            className={styles.formFlexGrid}
        >
          <TextInput
              label='Assessment Name'
              name='title'
              value={basicInfoInputs.title}
              type='text'
              onChange={handleChange}
          />
          <DatePicker
              label='Time-Range'
              startDate={basicInfoInputs.startDate}
              endDate={basicInfoInputs.endDate}
              onChange={changeDate}
          />
          { assessmentState.assessmentType === 'candidate' &&
            <>
              <TextInput
                  label='Job Title'
                  name='jobPosition'
                  value={basicInfoInputs.jobPosition || ''}
                  type='text'
                  onChange={handleChange}
              />
              <div/>
            </>
          }
          <TextArea
              label='Description'
              name='description'
              value={basicInfoInputs.description}
              onChange={handleChange}
          />
        </div>
        <Button
            onClick={() => {}}
            variant={'primary'}
            size={'medium'}
            customClass={styles.buttonStyles}
        >
          Next
        </Button>
      </form>
    </>
  );
};
