import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { createSkill, createSubSkill } from '../../../../utils/api_service/skill_api';
import { addNewSkill } from '../../../../utils/redux_store/features/skill_reducer';
import { addNewSubSkill } from '../../../../utils/redux_store/features/subskill_reducer';
import { Skill, SubSkill } from '../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';

type SkillsManagementProps = {
  skills: SelectOptions[],
  subSkillList: SubSkill[],
}

export const SkillsManagement = ({
  skills,
  subSkillList,
}: SkillsManagementProps ) => {
  const dispatch = useDispatch();
  const [selectedSkill, setSelectedSkill] = useState<string | null>();
  const [selectedSubSkill1, setSelectedSubSkill1] = useState<string | null>();
  const [selectedSubSkill2, setSelectedSubSkill2] = useState<string | null>();
  const [selectedSubSkill3, setSelectedSubSkill3] = useState<string | null>();
  const [subSkills1, setSubSkills1] = useState<SelectOptions[]>([]);
  const [subSkills2, setSubSkills2] = useState<SelectOptions[]>([]);
  const [subSkills3, setSubSkills3] = useState<SelectOptions[]>([]);

  const returnSubSkills = (parent: string | null) => {
    let subSkills: SelectOptions[] = [];
    subSkillList?.map((subSkill: SubSkill) => {
      if (subSkill.parentSkillId === parent) {
        subSkills.push({ label: subSkill.subskillName, value: subSkill.id });
      }
    });

    return subSkills;
  };

  const onAddNewSkill = (skill: string, parent?: string | null, level?: string) => {
    if (parent) {
      createSubSkill(skill, parent).then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then((response: SubSkill) => {
        if (level) {
          updateSubSkillList(level, response.id, skill);
          dispatch(addNewSubSkill(response));
        }
      });
    } else {
      createSkill(skill).then(response => {
        if (response.ok) {
          return response.json();
        }
      }).then((response: Skill) => {
        dispatch(addNewSkill({ value: response.id, label: skill }));
      });
    }
  };

  const updateSubSkillList = (level: string, subSkillId: string, skillName: string) => {
    if (level === '1') {
      setSubSkills1((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    } else if (level === '2') {
      setSubSkills2((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    } else if (level === '3') {
      setSubSkills3((subSkills) => [...subSkills, { value: subSkillId, label: skillName }]);
    }
  };

  const handleSelectChange = (selectedValue: SelectOptions, action: any) => {
    if (action.name === 'skillId') {
      selectedValue && setSubSkills1(() => returnSubSkills(selectedValue.value));
      setSubSkills2([]);
      setSubSkills3([]);
      setSelectedSkill(selectedValue ? selectedValue.value : null);
      setSelectedSubSkill1(null);
      setSelectedSubSkill2(null);
      setSelectedSubSkill3(null);
    } else if (action.name === 'subSkill1Id') {
      selectedValue && setSubSkills2(() => returnSubSkills(selectedValue.value));
      setSubSkills3([]);
      setSelectedSubSkill1(selectedValue ? selectedValue.value : null);
      setSelectedSubSkill2(null);
      setSelectedSubSkill3(null);
    } else if (action.name === 'subSkill2Id') {
      selectedValue && setSubSkills3(() => returnSubSkills(selectedValue.value));
      setSelectedSubSkill2(selectedValue ? selectedValue.value : null);
      setSelectedSubSkill3(null);
    } else {
      setSelectedSubSkill3(selectedValue ? selectedValue.value : null);
    }
  };

  return (
    <div className='grid grid-cols-2 gap-4 mt-5'>
      <CustomSelect
          label='Skill'
          name='skillId'
          options={skills}
          value={skills?.filter(option => option.value === selectedSkill)[0]}
          onChange={handleSelectChange}
          creatable={true}
          onCreateOption={onAddNewSkill}
      />
      { selectedSkill &&
      <CustomSelect
          label='Sub-Skill 1'
          name='subSkill1Id'
          value={subSkills1?.filter(option => option.value === selectedSubSkill1)[0]}
          options={subSkills1}
          onChange={handleSelectChange}
          creatable={true}
          clearable={true}
          onCreateOption={(input) => onAddNewSkill(input, selectedSkill, '1')}
      />
      }
      { selectedSubSkill1 &&
      <CustomSelect
          label='Sub-Skill 2'
          name='subSkill2Id'
          value={subSkills2?.filter(option => option.value === selectedSubSkill2)[0]}
          options={subSkills2}
          onChange={handleSelectChange}
          creatable={true}
          clearable={true}
          onCreateOption={(input) => onAddNewSkill(input, selectedSubSkill1, '2')}
      />
      }
      { selectedSubSkill2 &&
      <CustomSelect
          label='Sub-Skill 3'
          name='subSkill3Id'
          value={subSkills3?.filter(option => option.value === selectedSubSkill3)[0]}
          options={subSkills3}
          onChange={handleSelectChange}
          creatable={true}
          clearable={true}
          onCreateOption={(input) => onAddNewSkill(input, selectedSubSkill2, '3')}
      />
      }
    </div>
  );
};
