import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { Modal } from '../../../../ui/modal/modal';
import { PrimaryTypography, SecondaryTypography } from '../../../../ui/typography/typography';
import { generateQuestions, getJob, putJob  } from '../../../../utils/api_service/job_api';
import { ContextBasedGeneratorJob, Settings } from '../../../../utils/types/content_manager_types';
import commonStyles from '../../questions.module.css';
import { GenerateProperties } from '../GenerateProperties/GenerateProperties';
import { QuestionsList } from '../question_list';
import { UploadDocumentDropbox } from '../upload_document_dropbox/upload_document_dropbox';
import styles from './upload-file.module.css';

export const UploadFile = () => {
  const questionsCount = useRef(0);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [transcribeLoading, setTranscribeLoading] = useState<boolean>(false);
  const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
  const [errorJob, setErrorJob] = useState<boolean>(false);
  const [propertiesModal, setPropertiesModal] = useState<boolean>(false);
  const [job, setJob] = useState<ContextBasedGeneratorJob>({
    id: '',
    generationType: '',
    _cls: '',
    title: '',
    organizationId: '',
    status: 'Uploading...',
    creationDateTime: 0,
    topic: '',
    questions: [],
  });

  const [editGenerateProperties, setEditGenerateProperties] = useState<Settings>({
    level: '',
    jobTitle: '',
    questionType: '',
    time: 30,
    points: 5,
  });

  const editProperties = () => {
    const updatedJobWithSettings = {
      ...job,
      settings: editGenerateProperties,
    };
    putJob(job.id, updatedJobWithSettings).then(response => {
      setJob(response);
      setPropertiesModal(false);
    });
  };

  const pollJob = (id: string, generateQuestions?: boolean) => {
    getJob(id).then(response => {
      setJob(response);

      if (response.status && response.status.includes('Error')) {
        setTranscribeLoading(false);
        setQuestionsLoading(false);
        setErrorJob(true);
        toast.error(`${response.status} Please Try Again`, { position: 'bottom-center' });
      } else {
        if (generateQuestions) {
          questionsCount.current = (response.questions?.length || 0);
          if (questionsCount.current === 0) {
            setTimeout(() => {
              pollJob(id, true);
            }, 5000);
          } else {
            setQuestionsLoading(false);
          }
        } else {
          if (!response.transcript || response.transcript.length === 0) {
            setTimeout(() => {
              pollJob(id);
            }, 5000);
          } else {
            setTranscribeLoading(false);
          }
        }
      }
    }).catch(() => {
      toast.error('Error. Please try again', { position: 'bottom-center' });
      setErrorJob(true);
    });
  };

  const scrollToBottomOfPage = (): void => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const generateQuestionsOfTranscript = () => {
    scrollToBottomOfPage();
    setQuestionsLoading(true);
    setErrorJob(false);
    generateQuestions(job.id).then(response => {
      if (response.ok) {
        pollJob(job.id, true);
      }
    }).catch(() => {
      toast.error('Error. Please try again', { position: 'bottom-center' });
      setErrorJob(true);
      setQuestionsLoading(false);
    });
  };

  return (
    <div>
      <div
          className={commonStyles.contentContainer}
      >
        <UploadDocumentDropbox
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            pollJobStatus={pollJob}
            setJob={setJob}
            setTranscribeLoading={setTranscribeLoading}
            transcribeLoading={transcribeLoading}
        />
        { transcribeLoading &&
        <>
          <div
              className={`${styles.transcriptHeaderContainer} py-2`}
          >
            <PrimaryTypography.TwoXLarge
                className={`mt-5 mb-2.5  ${errorJob ? 'text-red-400' : 'text-primary-blue-500'}`}
                fontWeight='bold'
            >
              { job.status }
            </PrimaryTypography.TwoXLarge>
          </div>
          <div className='p-[9px_13px] mt-3 border border-gray-300 box-border shadow-sm rounded-md animate-pulse'>
            <div className='space-y-4 p-3'>
              <div className='h-4 bg-gray-300 rounded'></div>
              <div className='h-4 bg-gray-300 rounded'></div>
              <div className='h-4 bg-gray-300 rounded w-3/5'></div>
            </div>
          </div>
        </>
        }
        { job.transcript && job.transcript.length > 0 &&
        <>
          <div
              className={`${styles.transcriptHeaderContainer} py-2`}
          >
            <PrimaryTypography.TwoXLarge
                className='flex justify-between items-center w-full text-primary-blue-500'
                fontWeight='bold'
            >
              Auto-generated Transcript
            </PrimaryTypography.TwoXLarge>
            <div className='flex'>
              <Button
                  onClick={() => setPropertiesModal(true)}
                  variant='secondary'
                  size='xSmall'
                  customClass={styles.generateButton}
              >
                Change Properties
              </Button>
              <Button
                  onClick={() => generateQuestionsOfTranscript()}
                  variant='secondary'
                  size='xSmall'
                  customClass={styles.generateButton}
                  disabled={job.questions && job.questions.length > 0 || !job.settings}
              >
                Generate Questions
              </Button>
            </div>
          </div>
          <div
              className={styles.transcriptContainer}
              id='transcript-container'
          >
            <SecondaryTypography.Small>
              { job.transcript }
            </SecondaryTypography.Small>
          </div>
        </>
        }
        <div className='mt-5'>
          <QuestionsList
              job={job}
              questionsLoading={questionsLoading}
              setJob={setJob}
              errorJob={errorJob}
          />
        </div>
      </div>
      <Modal
          visible={propertiesModal}
          modalContent={
            <div className='p-8 flex flex-col'>
              <GenerateProperties
                  setEditGenerateProperties={setEditGenerateProperties}
                  job={job}
              />
              <div className='flex justify-center w-full mt-4'>
                <Button
                    onClick={() => editProperties()}
                    variant='primary'
                    size='xSmall'
                    disabled={editGenerateProperties.jobTitle === '' || editGenerateProperties.level === '' || (job.questions && job.questions.length > 0)}
                >
                  Save Properties
                </Button>
              </div>
            </div>
          }
          toggle={() => setPropertiesModal(false)}
      />
    </div>
  );
};
