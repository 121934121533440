import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AuthPermissionsState {
    permissions: string[]; // replace with the actual type of permissions
}

const initialState: AuthPermissionsState = {
  permissions: [],
};

const authPermissionsSlice = createSlice({
  name: 'authPermissions',
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
  },
});

export const { setPermissions } = authPermissionsSlice.actions;
export default authPermissionsSlice.reducer;
