import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllSubSkills } from '../../api_service/skill_api';
import { SubSkill } from '../../types/assessment_types';

export const fetchSubSkills = createAsyncThunk('skills/fetchSubSkills', async () => {
  return await getAllSubSkills();
});

export interface SubSkillLevelState {
    subSkillsList: SubSkill[],
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
  subSkillsList: [],
  loading: 'idle',
} as SubSkillLevelState;

export const subSkillSlice = createSlice({
  name: 'subSkillsList',
  initialState,
  reducers: {
    addNewSubSkill: (state, action: PayloadAction<SubSkill>) => {
      let subSkillsListCopy = [...state.subSkillsList];
      subSkillsListCopy.push(action.payload);
      return {
        ...state,
        subSkillsList: subSkillsListCopy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubSkills.pending, (state) => {
        return { ...state, loading: 'pending' };
      })
      .addCase(fetchSubSkills.fulfilled, (state, action) => {
        return { ...state, subSkillsList: action.payload, loading: 'succeeded' };
      });
  },
});

export const { addNewSubSkill } = subSkillSlice.actions;
export default subSkillSlice.reducer;
