import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { searchQuestions } from '../../api_service/job_api';
import { Question, QuestionSearch } from '../../types/assessment_types';

const questionObject: QuestionSearch = {
  questionTitle: '',
  skillId: '',
  levelId: '',
  subSkill1Id: '',
  subSkill2Id: '',
  subSkill3Id: '',
  jobTitle: '',
};

export const fetchQuestions = createAsyncThunk('questions/fetchQuestions', async () => {
  return await searchQuestions(questionObject);
});

export interface QuestionState {
  questionList: Question[],
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
  questionList: [],
  loading: 'idle',
} as QuestionState;

export const questionSlice = createSlice({
  name: 'questionList',
  initialState,
  reducers: {
    deleteQuestionRedux: (state, action: PayloadAction<{ id: string }>) => {
      let questionListCopy = [...state.questionList];
      let index = questionListCopy.findIndex(item => item.assessmentQuestionId === action.payload.id);
      questionListCopy.splice(index, 1);
      return {
        ...state,
        questionList: questionListCopy,
      };
    },
    editQuestionRedux: (state, action: PayloadAction<Question>) => {
      const questionListCopy = [...state.questionList];
      let index = questionListCopy.findIndex(item => item.id === action.payload.id);
      questionListCopy[index] = action.payload;
      return {
        ...state,
        questionList: questionListCopy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        return { ...state, loading: 'pending' };
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        return { ...state, questionList: action.payload, loading: 'succeeded' };
      });
  },
});

export const { editQuestionRedux } = questionSlice.actions;
export default questionSlice.reducer;
