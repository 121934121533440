import React, { useEffect } from 'react';
import micCamAccess from '../../../../../../assets/images/practice_instructions/microphone-cam-access.png';
import micCamNext from '../../../../../../assets/images/practice_instructions/microphone-cam-next.png';
import redoRecording from '../../../../../../assets/images/practice_instructions/redo-recording.png';
import startRecording from '../../../../../../assets/images/practice_instructions/start-recording.png';
import stopRecording from '../../../../../../assets/images/practice_instructions/stop-recording.png';
import systemSetup from '../../../../../../assets/images/practice_instructions/system-setup.png';
import { Button } from '../../../../../../ui/button/button';
import { UserAssessment } from '../../../../../../utils/types/assessment_types';
import commonStyles from '../../assigned_assessments.module.css';
import styles from './assessment_instructions_modal.module.css';
import { InstructionSection } from './instructionSection';

type AssessmentInstructionsModalProps = {
  setModalContent: (content: number) => void,
  setIsPractice: (content: boolean) => void,
  userAssessmentList: UserAssessment[],
}

export const AssessmentInstructionsModal = ({
  setModalContent, setIsPractice, userAssessmentList,
}: AssessmentInstructionsModalProps) => {
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => {
      localStorage.setItem('permissions', 'granted');
    });
  }, []);

  const handlePractice = () => {
    setIsPractice(true);
    setModalContent(3);
  };

  return (
    <div
        className={commonStyles.modalContentContainer}
        style={{ paddingTop: 'unset' }}
    >
      <InstructionSection
          instructionText='Allow Web Browser Access to Camera and Microphone'
          instructionStepText = '<b>1.</b> Please allow the Web Browser access to your camera and microphone. See example below for Google Chrome - Click the <b>Allow</b> Button:'
          instructionImgSrc={micCamAccess}
      />
      <InstructionSection
          instructionText='Allow Application Access to Camera and Microphone'
          instructionStepText = '<b>2.</b> Click the </b>Next</b> button if you give permission for the application to use your web browser and microphone. See screenshot below:'
          instructionImgSrc={micCamNext}
      />
      <InstructionSection
          instructionText='System Setup: Select Webcam and Microphone'
          instructionStepText = '<b>3.</b> Select Webcam and Microphone - then click on the <b>Next</b> button. See Example below:'
          instructionImgSrc={systemSetup}
      />
      <InstructionSection
          instructionText='Press Start Recording and speak your answer'
          instructionStepText = '<b>4.</b> Read the question, then press the <b>Start</b> Recording button and then speak your answer'
          instructionImgSrc={startRecording}
      />
      <InstructionSection
          instructionText='Press Stop Recording when you have finished'
          instructionStepText = '<b>5.</b> When you have finished speaking your answer, press the <b>Stop Recording</b> button:'
          instructionImgSrc={stopRecording}
      />
      <InstructionSection
          instructionText='Press Submit'
          instructionStepText = '<b>6.</b> Press <b>Submit</b> to move on. Please note, you can Redo the recording by pressing the Redo Recording button:'
          instructionImgSrc={redoRecording}
      />
      <div
          className={styles.ButtonContainer}>
        <Button
            variant='primary'
            size='medium'
            onClick={() => handlePractice()}
            customClass={commonStyles.assessmentModalButton}
        >
          Start Practice Assessment
        </Button>
        <Button
            variant='primary'
            size='medium'
            onClick={() => setModalContent(0)}
            customClass={commonStyles.assessmentModalButton}
            disabled={userAssessmentList.length > 0 ? false: true}
        >
          Start Real Assessment
        </Button>
      </div>
    </div>
  );
};
