import { useSelector } from 'react-redux';
import { RootState } from '../redux_store/store';

interface UsePermissionsProps {
    hasPermissions: (requiredPerms: string[]) => boolean,
    permissions: string[],
}

function usePermissions(): UsePermissionsProps {
  const permissions = useSelector((state: RootState) => state.permissions.permissions);
  return {
    hasPermissions: (requiredPermissions) => requiredPermissions.every(permission => permissions.includes(permission)),
    permissions: permissions,
  };
}

export default usePermissions;
