export const convertFileToBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const validateQuestionMedia = (file: File) => {
  let fileExtension = file.name.split('.').pop();
  return fileExtension?.toLowerCase().match(/^(mp4|mkv|avi|webm|jpg|jpeg|png|gif)$/) && file.size <= 20000000;
};
