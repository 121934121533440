import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route as RouteImpl, RouteProps } from 'react-router';
import { Loader } from '../../ui/loader/loader';
import { getPermissions, isUserLoggedIn } from '../helpers/auth';
import { Features } from '../helpers/subscriptionEnum';
import { RootState } from '../redux_store/store';

type PublicRouteProps = RouteProps & {
  shouldRedirectIfLoggedIn?: boolean;
};

type PrivateRouteProps = RouteProps & {
  requiredPermissions: string[];
  requiredFeature?: string;
};

export function Route({ shouldRedirectIfLoggedIn = false, ...routeProps }: PublicRouteProps) {
  if (shouldRedirectIfLoggedIn && isUserLoggedIn()) {
    return <Redirect to='/home' />;
  } else {
    return <RouteImpl {...routeProps} />;
  }
}

export function AuthenticatedRoute({ requiredPermissions, requiredFeature, ...routeProps }: PrivateRouteProps) {
  const subscriptions = useSelector((state: RootState) => state.subscriptions);
  const token =  localStorage.getItem('token');

  if (!isUserLoggedIn()) {
    return <Redirect to='/login' />;
  }

  if (requiredPermissions.length === 0) {
    return <RouteImpl {...routeProps} />;
  }

  if (requiredPermissions && token) {
    const permissions = getPermissions(token);
    const accessGranted = requiredPermissions.every(permission => permissions.includes(permission));
    if (!accessGranted) {
      return <Redirect to='/home' />;
    }
  }

  if (subscriptions.loading !== 'succeeded') {
    return <Loader loading={true}/>;
  }

  if (requiredFeature && subscriptions.subscriptions) {
    const subscriptionData = subscriptions.subscriptions;
    switch (requiredFeature) {
      case Features.EMPLOYEE:
        if (subscriptionData.employeeFeature?.status !== 'active' && subscriptionData.employeeFeature?.status !== 'trialing') {
          return <Redirect to='/home' />;
        }
        break;
      case Features.CANDIDATE:
        if (subscriptionData.candidateFeature?.status !== 'active' && subscriptionData.candidateFeature?.status !== 'trialing') {
          return <Redirect to='/home' />;
        }
        break;
      case Features.PARTNER:
        if (subscriptionData.partnerFeature?.status !== 'active' && subscriptionData.partnerFeature?.status !== 'trialing') {
          return <Redirect to='/home' />;
        }
        break;
      default:
        break;
    }
  }

  return <RouteImpl {...routeProps} />;
}
