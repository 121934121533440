import React from 'react';

type LoadingSpinnerProps = {
  label?: string,
}

const LoadingSpinner = ({ label = '' }: LoadingSpinnerProps) => {
  return (
    <div className='flex justify-center items-center h-full'>
      <div className='flex flex-col items-center'>
        <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900 mb-5'></div>
        <div className='text-lg text-gray-900'>
          { label }
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
