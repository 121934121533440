import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentView } from '../../../utils/redux_store/features/ViewReducer';
import { RootState } from '../../../utils/redux_store/store';
import { View } from '../../../utils/types/assessment_types';

const ViewOption: React.FC = () => {
  const dispatch = useDispatch();
  const accessibleViews = useSelector((state: RootState) => state.view.accessibleViews);
  const currentView = useSelector((state: RootState) => state.view.currentView);
  const handleViewChange = (view: View) => {
    dispatch(setCurrentView(view));
  };

  return (
    <div className='flex justify-center border border-gray-400 font-bold rounded-md'>
      { accessibleViews.map((view: View) => (
        <button
            key={view}
            className={`mx-2 md:px-4 md:py-2 px-2 py-1 rounded-lg text-sm md:text-base ${
              currentView === view ? 'text-primary-green-400' : 'text-gray-400'
            }`}
            onClick={() => handleViewChange(view)}
            disabled={!accessibleViews.includes(view)}
        >
          { view }
        </button>
      )) }
    </div>
  );
};

export default ViewOption;
