import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import loginRegisterBg from '../../assets/images/backgrounds/login_register_bg.png';
import logoBlackText from '../../assets/images/logos/white-bg-logo-hiacuity.png';
import { Button } from '../../ui/button/button';
import { TextInput } from '../../ui/input/text_input/text_input';
import { SecondaryTypography } from '../../ui/typography/typography';
import { changePassword } from '../../utils/api_service/user_api';
import { FormInput } from '../../utils/types/login_register_types';
import styles from './login_register.module.css';
import { validate, validateForm } from './validation/validation_rules';

export const ChangePassword = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState<FormInput>({
    email: localStorage.getItem('email') || 'sample@hiacuity.com',
    password: '',
    confirmPassword: '',
    errors: {
      emailError: '',
      passwordError: '',
      confirmPasswordError: '',
    },
  });
  const [formValid, setFormValid] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let errors = validate(event.target, password);

    setPassword({
      ...password,
      [event.target.name]: event.target.value,
      errors: errors,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isSubmittedFormValid = validateForm(password);
    if (!localStorage.getItem('email')) {
      localStorage.setItem('token', token);
    }
    if (isSubmittedFormValid) {
      changePassword(password.password)
        .then(response => {
          if (response.ok) {
            setFormValid(true);
            history.push('/identity-check');
            toast.success('Password changed successfully!', {
              position: 'bottom-center',
            });
          } else {
            if (!localStorage.getItem('email')) {
              localStorage.removeItem('token');
            }
            return response.json().then(data => {
              toast.error(data.message, { position: 'bottom-center' });
            });
          }
        })
        .catch(error => toast.error(error, { position: 'bottom-center' }));
    } else {
      toast.error('One or more of your details are invalid!', { position: 'bottom-center' });
      setFormValid(false);
    }
  };

  return (
    <div
        className={styles.pageContainer}
    >
      <div
          className={styles.mainContainer}
      >
        <div
            className={styles.leftContainer}
        >
          <img
              className={styles.leftContainerBg}
              src={loginRegisterBg}
              alt='HiAcuity-Bg'
          />
        </div>
        <div
            className={styles.formContainer}
        >
          <img
              className={styles.logoStyles}
              src={logoBlackText}
              alt='HiAcuity-Logo'
          />
          <h1
              className={styles.formHeading}
          >
            Change Password
          </h1>
          <SecondaryTypography.Small
              keepDefaultMargins={true}
          >
            Please enter the new password and change credentials
            for higher security.
          </SecondaryTypography.Small>
          <form
              className={styles.form}
              onSubmit={handleSubmit}
              data-testid='change-password-form'
          >
            <TextInput
                type='password'
                label='Password'
                data-testid='password'
                name='password'
                value={password.password}
                onChange={handleChange}
            />
            <span
                className={styles.invalidInput}
            >
              &nbsp;{ !formValid ? password.errors.passwordError : '' }
            </span>
            <TextInput
                type='password'
                label='Confirm Password'
                data-testid='confirmPassword'
                name='confirmPassword'
                value={password.confirmPassword || ''}
                onChange={handleChange}
            />
            <span
                className={styles.invalidInput}
            >
              &nbsp;{ !formValid ? password.errors.confirmPasswordError : '' }
            </span>
            <Button
                onClick={() => {}}
                size='medium'
                variant='primary'
                customClass={styles.submitButton}
            >
              Change Password
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
