import { Document, Image, Page, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Times-Roman',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 20,
    marginHorizontal: 80,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

type PdfCreatorProps = {
    imageList?: Array<string>;
    filterList: Array<string>;
}

export const PdfCreator = ({ imageList, filterList }: PdfCreatorProps) => {

  return(
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          ~ HiAcuity ~
        </Text>
        <Text style={styles.title}>Analytics Charts</Text>
        <Text style={styles.subtitle}>Data Source Filters</Text>
        { filterList.map((filter: string, index: number) => {
          return (
            <Text key={index} style={styles.text}>{ filter }</Text>
          );
        }) }
        { imageList && imageList.map((image: string, index: number) => {
          return (
            <Image key={index} src={image} style={styles.image}/>
          );
        }) }
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};
