import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/role';

export function getJobRoleList() {
  return fetch(createRequest(apiEndpoint + '/get', 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function createJobRole(roleName: string) {
  const body = {
    role_name: roleName,
    role_description: roleName,
  };
  return fetch(createRequest(apiEndpoint + '/create', 'POST', 'application/json', true, body));
}
