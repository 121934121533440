import { OrganizationUser } from '../../../../utils/types/admin_types';

export const useUserSearch = (organizationUsers: OrganizationUser[], searchTerm: string, currentPage: number, resultsPerPage: number): {
    paginatedUsers: OrganizationUser[];
    totalPages: number;
} => {
  const filteredUsers = organizationUsers.filter((orgUser) =>
    orgUser.user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        orgUser.user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        orgUser.user.lastName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const totalPages = Math.ceil(filteredUsers.length / resultsPerPage);

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage,
  );

  return { paginatedUsers, totalPages };
};
