import React, { useContext, useState } from 'react';
import jobIllustration from '../../../../assets/images/graphics/job-illustration.svg';
import partnerIllustration from '../../../../assets/images/graphics/partner-illustration.svg';
import teamIllustration from '../../../../assets/images/graphics/team-illustration.svg';
import { Button } from '../../../../ui/button/button';
import { AssessmentContext } from '../../../../utils/contexts/contexts';
import { FeatureNames, Features } from '../../../../utils/helpers/subscriptionEnum';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import { AssessmentType } from '../../../../utils/types/assessment_types';
import styles from './choose_assessment_type_modal.module.css';
import { SelectAssessmentType } from './select_assessment_type/select_assessment_type';

type ChooseAssessmentTypeModalProps = {
  closeModal: () => void,
}

export const ChooseAssessmentTypeModal = ({ closeModal }: ChooseAssessmentTypeModalProps) => {
  const { assessmentState, setAssessmentState } = useContext(AssessmentContext);
  const [selectedType, setSelectedType] = useState<AssessmentType>(null);

  const setType = () => {
    setAssessmentState({
      ...assessmentState,
      assessmentType: selectedType,
    });
    closeModal();
  };

  return (
    <>
      <h3
          className={`${styles.modalHeader} pt-8`}
      >
        Choose assessment type
      </h3>
      <div
          className={`${styles.selectTypeContainer} pt-6`}
      >
        <PermissionGate
            key={FeatureNames.EMPLOYEE}
            requiredPermissions={[]}
            requiredFeature={Features.EMPLOYEE}
        >
          <SelectAssessmentType
              image={teamIllustration}
              label='Team Members'
              selected={selectedType === 'employee'}
              onClick={() => setSelectedType('employee')}
          />
        </PermissionGate>
        <PermissionGate
            key={FeatureNames.CANDIDATE}
            requiredPermissions={[]}
            requiredFeature={Features.CANDIDATE}
        >
          <SelectAssessmentType
              image={jobIllustration}
              label='Job Candidates'
              selected={selectedType === 'candidate'}
              onClick={() => setSelectedType('candidate')}
          />
        </PermissionGate>
        <PermissionGate
            key={FeatureNames.PARTNER}
            requiredPermissions={[]}
            requiredFeature={Features.PARTNER}
        >
          <SelectAssessmentType
              image={partnerIllustration}
              label='Partners'
              selected={selectedType === 'partner'}
              onClick={() => setSelectedType('partner')}
          />
        </PermissionGate>
      </div>
      <Button
          onClick={setType}
          variant='primary'
          size='medium'
          disabled={selectedType === null}
          customClass={styles.buttonCustom}
      >
        Next
      </Button>
    </>
  );
};
