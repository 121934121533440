import React, { ChangeEvent, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { QuestionSearch, SubSkill } from '../../../utils/types/assessment_types';
import { SelectOptions } from '../../../utils/types/react_select_types';
import { FilterIcon } from '../../icons/icons';
import styles from './filter.module.css';

type FilterProps = {
    skillList: SelectOptions[],
    levelList: SelectOptions[],
    subSkillList: SubSkill[] | undefined,
    jobTitleList: SelectOptions[] | undefined,
    searchObject: QuestionSearch,
    setSearchObject: (searchObject: QuestionSearch) => void,
}

const customStyles = {
  container: (base: any) =>( {
    ...base,
    width: '200px',
  }),
};

export const Filter = ({
  skillList,
  levelList,
  subSkillList,
  jobTitleList,
  searchObject,
  setSearchObject,
}: FilterProps) => {
  const [subSkills1, setSubSkills1] = useState<SelectOptions[]>();
  const [subSkills2, setSubSkills2] = useState<SelectOptions[]>();
  const [subSkills3, setSubSkills3] = useState<SelectOptions[]>();

  const returnSubSkills = (parent: string | undefined) => {
    let subSkills: SelectOptions[] = [];
    subSkillList?.map((subSkill: SubSkill) => {
      if (subSkill.parentSkillId === parent) {
        subSkills.push({ label: subSkill.subskillName, value: subSkill.id });
      }
    });

    return subSkills;
  };

  const handleSelectChange = (selectedValue: SingleValue<SelectOptions>, action: any) => {
    if (action.name === 'skillId') {
      selectedValue && setSubSkills1(() => returnSubSkills(selectedValue.value));
      setSubSkills2([]);
      setSubSkills3([]);
      setSearchObject({
        ...searchObject,
        [action.name]: selectedValue ? selectedValue.value : '',
        subSkill1Id: '',
        subSkill2Id: '',
        subSkill3Id: '',
      });
    } else if (action.name === 'subSkill1Id') {
      selectedValue && setSubSkills2(() => returnSubSkills(selectedValue.value));
      setSubSkills3([]);
      setSearchObject({
        ...searchObject,
        [action.name]: selectedValue ? selectedValue.value : '',
        subSkill2Id: '',
        subSkill3Id: '',
      });
    } else if (action.name === 'subSkill2Id') {
      selectedValue && setSubSkills3(() => returnSubSkills(selectedValue.value));
      setSearchObject({
        ...searchObject,
        [action.name]: selectedValue ? selectedValue.value : '',
        subSkill3Id: '',
      });
    } else {
      setSearchObject({
        ...searchObject,
        [action.name]: selectedValue ? selectedValue.value : '',
      });
    }
  };

  const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    let searchTitle = event.target.value;
    setSearchObject({
      ...searchObject,
      questionTitle: searchTitle,
    });
  };

  return (
    <div
        className={styles.filterContainer}
    >
      <FilterIcon/>
      <Select
          placeholder='Select Skill'
          name='skillId'
          options={skillList}
          onChange={handleSelectChange}
          isClearable={true}
          styles={customStyles}
      />
      { searchObject.skillId !== null && searchObject.skillId.length > 0 &&
      <Select
          placeholder='Select Sub-Skill 1'
          name='subSkill1Id'
          options={subSkills1}
          onChange={handleSelectChange}
          isClearable={true}
          styles={customStyles}
      />
      }
      { searchObject.subSkill1Id.length > 0 &&
      <Select
          placeholder='Select Sub-Skill 2'
          name='subSkill2Id'
          options={subSkills2}
          onChange={handleSelectChange}
          isClearable={true}
          styles={customStyles}
      />
      }
      { searchObject.subSkill2Id.length > 0 &&
      <Select
          placeholder='Select Sub-Skill 3'
          name='subSkill3Id'
          options={subSkills3}
          onChange={handleSelectChange}
          isClearable={true}
          styles={customStyles}
      />
      }
      <Select
          placeholder='Select Skill Level'
          name='levelId'
          options={levelList}
          onChange={handleSelectChange}
          isClearable={true}
          styles={customStyles}
      />
      <Select
          placeholder='Select Job Title'
          name='jobTitle'
          options={jobTitleList}
          onChange={handleSelectChange}
          isClearable={true}
          styles={customStyles}
      />
      <input
          className='placeholder-secondary-grey-200 block px-2 py-2 border shadow-sm rounded-md normal-case font-normal text-base leading-6 focus:outline-none focus:border-primary-blue-500'
          type='text'
          placeholder='Search Questions'
          onChange={onSearchTermChange}
      />
    </div>
  );
};
