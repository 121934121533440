import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '../../../ui/modal/modal';
import { getJobOpening } from '../../../utils/api_service/JobOpenings';
import { JobOpeningsContext } from '../../../utils/contexts/JobOpening';
import { jobOpeningTypes } from '../../../utils/types/JobOpenings';
import styles from '../../assessment/create/create_assessment.module.css';
import JobOpeningDetails from './JobOpeningDetails';
import { ChooseRecruitmentTypeModal } from './ModalContent/ChooseRecruitementType';
import Chevron from './ui/Chevron';
import LoadingSpinner from './ui/LoaderSpinner';

interface Step {
  label: jobOpeningTypes;
  status: 'active' | 'non-active' | 'disabled';
}

export const JobOpeningsCreate = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [modalState, setModalState] = useState<boolean>(!id || id.trim() === '');
  const [currentPage, setCurrentPage] = useState<jobOpeningTypes>('Job Opening Details');
  const [jobOpeningLoading, setJobOpeningLoading] = useState<boolean>(false);
  const [jobOpeningElements, setJobOpeningElements] = useState<jobOpeningTypes[]>([]);

  useEffect(() => {
    setJobOpeningLoading(true);
    if (id) {
      getJobOpening(id)
        .then(response => {
          if (!response.ok) {
            toast.error('Error Getting JobOpening', { position: 'bottom-center' });
          }
          return response.json();
        })
        .then(data => {
          setJobOpeningElements(data.jobOpeningElements);
        })
        .catch(() => {
          toast.error('Error Getting JobOpening', { position: 'bottom-center' });
        });
    }
    setJobOpeningLoading(false);
  }, [id]);

  const steps: Step[] = useMemo(() => {
    const initialSteps : Step[] = [
      { label: 'Job Opening Details', status: 'disabled' },
      { label: 'Job Posting', status: 'disabled' },
      { label: 'Applicant Analysis', status: 'disabled' },
      { label: 'Interviews', status: 'disabled' },
      { label: 'Analytics', status: 'non-active' },
    ];

    return initialSteps.map(step => ({
      ...step,
      status: step.label === currentPage ? 'active' : (jobOpeningElements.includes(step.label) ? 'non-active' : 'disabled'),
    }));
  }, [currentPage, jobOpeningElements]);

  const jobOpeningRenderMap: { [key in string]: React.ReactNode } = {
    'Job Opening Details': <JobOpeningDetails />,
    'Job Posting': <></>,
    'Applicant Analysis': <></>,
    'Interviews': <></>,
    'Analytics': <></>,
  };

  const handleModalToggle = () => {
    setModalState(false);
    history.push('/job-openings-list');
  };

  const renderFormPage = () => {
    if (jobOpeningLoading) {
      return <LoadingSpinner />;
    } else {
      return jobOpeningRenderMap[currentPage];
    }
  };

  return (
    <JobOpeningsContext.Provider
        value={{
          jobOpeningElements: jobOpeningElements,
          setJobOpeningElements: setJobOpeningElements,
        }}
    >
      <div>
        <div
            className='w-full min-h-screen p-4'
        >
          { !modalState && (
            <>
              <Chevron steps={steps} setCurrentPage={setCurrentPage}/>
              <div className='flex py-5'>
                <div className='flex w-4/5 items-center gap-5'>
                </div>
                <div className='w-1/5 border-2 flex border-gray justify-center items-center rounded-lg'>
                  <h5 className='text-gray-500 font-bold'>Status</h5>
                </div>
              </div>
              { renderFormPage() }
            </>
          ) }
          <Modal
              visible={modalState}
              modalContent={
                <ChooseRecruitmentTypeModal closeModal={() => setModalState(false)}/>
              }
              toggleable={true}
              customClass={styles.modalDimensions}
              toggle={handleModalToggle}
          />
        </div>
      </div>
    </JobOpeningsContext.Provider>
  );
};
