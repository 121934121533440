import { getAnalyticsSettings } from '../api_service/organization_analytics_settings';
import { defaultDashletOrder, defaultValues } from './chartData';

const populateCacheFromTimeData = (cache: any, timeBasedData: any, key: string) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  for (const item of timeBasedData[key]) {
    const _key = key.split(' ').join('_');
    if (cache[`${item.year}-${item.month}`]) {
      cache[`${item.year}-${item.month}`][_key] = item.count;
      cache[`${item.year}-${item.month}`]['Total'] = cache[`${item.year}-${item.month}`]['Total'] + item.count;
    } else {
      cache[`${item.year}-${item.month}`] = {
        time: `${months[item.month - 1]} ${item.year}`,
        Employees: 0,
        Job_Candidates: 0,
        Partners: 0,
        Total: item.count,
      };
      cache[`${item.year}-${item.month}`][_key] = item.count;
    }
  }
};

const populateTimeSeriesData = (searchResult: any, timeSeries: string) => {
  let cache: any = {};
  const keys = ['Job Candidates', 'Employees', 'Partners'];

  for (const key of keys) {
    if (searchResult[timeSeries][key]) {
      populateCacheFromTimeData(cache, searchResult[timeSeries], key);
    }
  }
  searchResult[timeSeries] = Object.values(cache);
};

const getCurrency = async () => {
  const response = await getAnalyticsSettings();
  return response.currency;
};

export const formatAPIData = async (searchResult: any) => {
  let formattedSearchResult: any = {};
  const hoursKeys: any = {
    'Total': 'HoursSavedTotal',
    'Employees': 'HoursSavedEmployees',
    'Job Candidates': 'HoursSavedJobCandidates',
    'Partners': 'HoursSavedPartners',
  };

  const moneyKeys: any = {
    'Total': 'MoneySavedTotal',
    'Employees': 'MoneySavedEmployees',
    'Job Candidates': 'MoneySavedJobCandidates',
    'Partners': 'MoneySavedPartners',
  };

  if (searchResult.HoursSaved) {
    for (const item of searchResult.HoursSaved) {
      formattedSearchResult[hoursKeys[item.name]] = item;
    }

    formattedSearchResult['HoursSaved'] = searchResult.HoursSaved.filter((item:any) => item.name !== 'Total');
    delete searchResult.HoursSaved;
  }

  if (searchResult.MoneySaved) {
    for (const item of searchResult.MoneySaved) {
      formattedSearchResult[moneyKeys[item.name]] = item;
    }
    delete searchResult.MoneySaved;
  }

  if(searchResult.AssessmentSessionsTimeBased) {
    populateTimeSeriesData(searchResult, 'AssessmentSessionsTimeBased');
  }

  if(searchResult.HoursSavedTimeBased) {
    populateTimeSeriesData(searchResult, 'HoursSavedTimeBased');
  }

  formattedSearchResult = {
    ...formattedSearchResult,
    ...searchResult,
  };

  let currency: string = '';
  for (const key of defaultDashletOrder) {
    if (!Object.hasOwn(formattedSearchResult, key)) {
      if (Object.values(moneyKeys).includes(key)){
        if (!currency) {
          currency = await getCurrency();
        }
        defaultValues[key] = { ...defaultValues[key], currency: currency };
      }
      formattedSearchResult[key] = defaultValues[key];
    }
  }
  return formattedSearchResult;
};
