import classNames from 'classnames';
import React from 'react';
import { SolidCheckIcon } from '../../../../../ui/icons/icons';
import styles from './select_assessment_type.module.css';

type SelectAssessmentTypeProps = {
    image: string,
    label: string,
    selected: boolean,
    onClick: () => void;
    width?: string,
    disabled?: boolean
}

export const SelectAssessmentType = ({
  image,
  label,
  selected,
  onClick,
  disabled = false,
}: SelectAssessmentTypeProps) =>  (
  <div
      className={`${classNames(styles.selectTypeButtons, { [styles.selected]: selected })} ${disabled ? 'bg-gray-300' : ''}`}
      onClick={onClick}
  >
    <div
        className={classNames(styles.checkIcon, { [styles.selected]: selected })}
    >
      <SolidCheckIcon/>
    </div>
    <div className='w-40 h-32 flex items-center justify-center'>
      <img
          className={'object-contain'}
          src={image}
          alt={label}
      />
    </div>
    <p
        className={styles.selectTypeText}
    >
      { label }
    </p>
  </div>
);
