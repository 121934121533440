import React, { useEffect, useState }  from 'react';
import { NotificationManager } from 'react-notifications';
import { Button } from '../../../ui/button/button';
import { TextInput } from '../../../ui/input/text_input/text_input';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import { getAnalyticsSettings, updateAnalyticsSettings } from '../../../utils/api_service/organization_analytics_settings';

export const AnalyticsSettings = () => {

  const [hourlyRate, setHourlyRate] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');

  const handleSaveButton = () => {
    if(hourlyRate && currency) {
      updateAnalyticsSettings(hourlyRate, currency).then(() => {
        NotificationManager.success('Settings saved successfully!');
      }).catch(() => {
        NotificationManager.error('Unable to save settings!');
      });
    }
  };

  useEffect(() => {
    getAnalyticsSettings().then(response => {
      if(response?.hourlyRate) {
        setHourlyRate(response.hourlyRate);
      }
      if (response?.currency) {
        setCurrency(response.currency);
      }
    });
  }, []);

  return (
    <div className='w-full min-h-screen'>
      <PrimaryTypography.TwoXLarge
          keepDefaultMargins={true}
          fontWeight='bold'
      >
        Analytics Settings
      </PrimaryTypography.TwoXLarge>
      <SecondaryTypography.Small
          keepDefaultMargins={true}
      >
        You can change the hourly rate and currency in below.
      </SecondaryTypography.Small>
      <div className='flex flex-col min-h-[80vh] p-4 m-2'>
        <div className='flex items-center mb-4'>
          <SecondaryTypography.Small
              keepDefaultMargins={true}
              className='w-1/2 sm:w-1/5'
          >
            Hourly Rate
          </SecondaryTypography.Small>
          <TextInput
              name='hourlyRate'
              value={hourlyRate || ''}
              type='number'
              onChange={(event) => setHourlyRate(event.target.value)}
          />
        </div>
        <div className='flex items-center mb-4'>
          <SecondaryTypography.Small
              keepDefaultMargins={true}
              className='w-1/2 sm:w-1/5'
          >
            Currency
          </SecondaryTypography.Small>
          <TextInput
              name='currency'
              value={currency}
              type='text'
              onChange={(event) => setCurrency(event.target.value)}
          />
        </div>
        <div className='flex'>
          <Button
              onClick={() => handleSaveButton()}
              variant={'primary'}
              size={'medium'}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
