import React from 'react';
import { QuestionMark } from '../icons/icons';

type ToolTipProps = {
  message: string,
  position: 'top' | 'bottom' | 'left' | 'right'
}

export default function Tooltip({
  message,
  position,
}: ToolTipProps) {
  return (
    <div
        className='group relative flex cursor-pointer'
    >
      <QuestionMark className='stroke-slate-500 h-6 w-6'/>
      <span
          className={`absolute ${position}-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100`}
      >
        { message }
      </span>
    </div>
  );
}
