import React, { useEffect } from 'react';
import micAndVideoGraphics from '../../../../../../assets/images/graphics/mic_and_video.svg';
import { Button } from '../../../../../../ui/button/button';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import commonStyles from '../../assigned_assessments.module.css';
import styles from './enable_cam_mic_modal.module.css';

type EnableCamMicModalProps = {
  setModalContent: (content: number) => void,
}

export const EnableCamMicModal = ({ setModalContent }: EnableCamMicModalProps) => {
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => {
      localStorage.setItem('permissions', 'granted');
    });
  }, []);

  return (
    <div
        className={commonStyles.modalContentContainer}
        style={{ paddingTop: 'unset' }}
    >
      <div
          className={styles.graphicsContainer}
      >
        <img
            src={micAndVideoGraphics}
            alt='mic-and-video'
            width='200px'
        />
      </div>
      <SecondaryTypography.Small
          className={commonStyles.greyText}
          keepDefaultMargins={true}
      >
        Enable Microphone & Camera permissions so that you can answer for questions and we can record it for evaluation.
      </SecondaryTypography.Small>
      <Button
          variant='primary'
          size='medium'
          onClick={() => setModalContent(3)}
          customClass={commonStyles.assessmentModalButton}
      >
        Next
      </Button>
    </div>
  );
};
