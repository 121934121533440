import React, { useState } from 'react';
import { SearchIconNewUI } from '../../../ui/icons/icons';
import { JobOpeningData } from '../../../utils/types/DashboardTypes';
import { dummyData } from './DummyData';

export type TableColumn = {
  title: string;
  width: string;
};

const DashboardJobOpeningTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const columns: TableColumn[] = [{ title: 'Job Title', width: 'w-1/12' }, { title: 'Applicants', width: 'w-1/12' }, { title: 'Suitable', width: 'w-1/12' }, { title: 'Outstanding', width: 'w-1/12' }, { title: 'Shortlist', width: 'w-1/12' }, { title: 'Unsuccessful', width: 'w-1/12' }, { title: 'Start Date', width: 'w-1/12' } , { title: 'End Date', width: 'w-1/12' }  ];
  return (
    <div className='flex flex-col'>
      <div className='flex items-center border-b border-gray-200 pb-4'>
        <div className='relative w-full'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none'>
            <SearchIconNewUI className='h-6 w-6 text-gray-500'/>
          </div>
          <input
              type='text'
              className='text-xl font-light w-full p-2 pl-14 pr-10 rounded-md border border-gray-300 outline-none'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <table className='w-full'>
        <thead>
          <tr>
            { columns.map((column, index) => (
              <th key={index} className={`border-b-2 px-4 py-3 ${column.width} font-semibold text-gray-500`}>
                <div className='flex justify-start'>
                  { column.title }
                </div>
              </th>
            )) }
          </tr>
        </thead>
        <tbody>
          { dummyData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              { columns.map((column, colIndex) => (
                <React.Fragment key={colIndex}>
                  <td className={`border-b-2 px-4 py-3 ${column.width}`}>
                    <div className='flex justify-start text-left'>
                      { row[column.title as keyof JobOpeningData] }
                    </div>
                  </td>
                </React.Fragment>
              )) }
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  );
};

export default DashboardJobOpeningTable;
