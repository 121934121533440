import React from 'react';
import { Question } from '../../../utils/types/assessment_types';
import { Button } from '../../button/button';
import { CloseIcon } from '../../icons/icons';
import { SecondaryTypography } from '../../typography/typography';
import styles from './upload_media.module.css';

type UploadMediaProps = {
    question: Question,
    addFileViaInput: (event: React.ChangeEvent<HTMLInputElement>) => void,
    clearMediaFile: () => void,
}

export const UploadMedia = ({
  question,
  addFileViaInput,
  clearMediaFile,
}: UploadMediaProps) => {

  const checkMediaType = () => {
    return !question.mediaExtension?.match(/^(mp4|mkv|avi|webm)$/);
  };

  const renderMediaInput = () => {
    if (question.mediaQuestion) {
      return (
        <div
            className={styles.mediaPreviewContainer}
        >
          <SecondaryTypography.XSmall
              className={styles.textClass}
              fontWeight='semi-bold'
          >
            Attached Media File
          </SecondaryTypography.XSmall>
          <div
              className={styles.imageVideoContainer}
          >
            { checkMediaType() ?
              <img
                  width='500px'
                  src={question.fileEncoded || question.fileUrl}
                  alt={question.id + 'Media'}
              />
              :
              <video
                  width='500px'
                  controls
                  src={question.fileEncoded || question.fileUrl}
              />
            }
            <div
                className={styles.iconClass}
                onClick={clearMediaFile}
            >
              <CloseIcon/>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
            className={styles.uploadButtonContainer}
        >
          <Button
              onClick={() => document.getElementById('upload-file-input')?.click()}
              variant='primary'
              size='xSmall'
          >
            Upload Media
          </Button>
          <SecondaryTypography.XSmall
              className={styles.textClass}
              fontWeight='semi-bold'
          >
            File types supported: mp4, mkv, avi, webm, jpg, jpeg, png, gif. Max Size: 20MB
          </SecondaryTypography.XSmall>
        </div>
      );
    }
  };

  return (
    <>
      <input
          type='file'
          onChange={addFileViaInput}
          id='upload-file-input'
          style={{ display: 'none' }}
      />
      { renderMediaInput() }
    </>
  );
};
