import classNames from 'classnames';
import React from 'react';
import { SecondaryTypography } from '../../typography/typography';
import inputStyles from '../input.module.css';
import styles from './toggle_input.module.css';

type ToggleInputProps = {
    toggled: boolean,
    handleToggle: () => void,
    name: string,
    informationText?: string,
    disabled?: boolean,
}

export const ToggleInput = ({
  toggled,
  handleToggle,
  name,
  informationText,
  disabled,
}: ToggleInputProps) => (
  <div
      className={classNames(styles.switchContainer, { [styles.disabled]: disabled })}
  >
    <label
        className={styles.switch}
    >
      <input
          type='checkbox'
          checked={toggled}
          onChange={handleToggle}
          name={name}
      />
      <span
          className={styles.slider}
      />
    </label>
    <SecondaryTypography.XSmall
        className={classNames(inputStyles.labelStyles, styles.infoText)}
        fontWeight='semi-bold'
    >
      { informationText }
    </SecondaryTypography.XSmall>
  </div>
);
