import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import Drawer from '../../../../../ui/DrawerRight';
import { PresentationChart, SortDown, SortUp, Users } from '../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import {
  exportCandidateCSV,
  filterPreScreenAnswers,
  sendCandidateEmail,
  setCandidateStatus,
} from '../../../../../utils/api_service/assessment_api';
import { CandidateStatusEnum } from '../../../../../utils/helpers/candidateStatus';
import {
  AssessmentUser,
  AttemptSkill,
  CandidateStatus, FilterState, PreScreenQuestion,
  UserAssessmentResponse,
} from '../../../../../utils/types/assessment_types';
import commonStyles from '../../assessment_list/assessment_list_table/assessment_list_table.module.css';
import styles from './assessment_result_list.module.css';
import { AssessmentResultRow } from './assessment_result_row/assessment_result_row';
import FilterPreScreen from './FilterPreScreen';

type AssessmentResultsProps = {
  assessmentId: string,
  userResults: UserAssessmentResponse[],
  highestScore: number,
  completionRatio: string,
  candidateStatus?: CandidateStatus,
  updateAssessmentUserList?: (selectedRows: AssessmentUser[], candidateStatus: CandidateStatus) => void,
  preScreenQuestions?: PreScreenQuestion[],
}

type SkillSelected = {
  selected: boolean,
  skillName: string,
  skillId: string,
  index: number | undefined,
  displayLapsedTime: boolean,
}

type ResultListData = {
  completionRate: string,
  highestScore: number,
}

export const AssessmentResults = ({
  assessmentId,
  userResults,
  highestScore,
  completionRatio,
  candidateStatus,
  updateAssessmentUserList,
  preScreenQuestions = [],
}: AssessmentResultsProps) => {
  const [filters, setFilters] = useState<FilterState[]>([]);
  const [skills, setSkills] = useState<AttemptSkill[]>();
  const [resultList, setResultList] = useState<AssessmentUser[]>([]);
  const [storedResultList, setStoredResultList] = useState<AssessmentUser[]>([]);
  const [skillVisibility, setSkillVisibility] = useState<boolean>(false);
  const [resultListData, setResultListData] = useState<ResultListData>();
  const [completionFilter, setCompletionFilter] = useState<boolean>(false);
  const [ascending, setAscending] = useState<boolean>(false);
  const [nameActive, setNameActive] = useState<boolean>(false);
  const [dateActive, setDateActive] = useState<boolean>(false);
  const [scoreActive, setScoreActive] = useState<boolean>(false);
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [skillSelected, setSkillSelected] = useState<SkillSelected>({
    selected: false,
    skillName: '',
    skillId: '',
    index: undefined,
    displayLapsedTime: false,
  });
  const [selectedRows, setSelectedRows] = useState<AssessmentUser[]>([]);
  const userAssessmentIds = userResults.map(item => item.id);
  const [userAssessmentId, setUserAssessmentId] = useState<string[]>(userAssessmentIds);

  useEffect(() => {
    if (userResults.length !== 0) {
      let assessmentResult: AssessmentUser[] = [];
      setSelectedRows([]);
      setResultListData({
        highestScore: highestScore,
        completionRate: completionRatio,
      });
      setSkills(userResults[0].skills);
      userResults.map((result: UserAssessmentResponse) => {
        if (result.candidateStatus === candidateStatus) {
          let assessmentUserResult: AssessmentUser = {
            assessmentUserId: result.id,
            userId: result.userId,
            firstName: result.user.firstName,
            lastName: result.user.lastName,
            email: result.user.email,
            score: result.score,
            maxScore: result.maxScore,
            status: result.status,
            enabled: result.enabled,
            startDate: result.startTime,
            endDate: result.endTime,
            marked: result.marked,
            skills: result.skills,
            completionTime: result.endTime && result.startTime && result.endTime - result.startTime,
          };
          assessmentResult.push(assessmentUserResult);
        } else if (candidateStatus === undefined) {
          let assessmentUserResult: AssessmentUser = {
            assessmentUserId: result.id,
            userId: result.userId,
            firstName: result.user.firstName,
            lastName: result.user.lastName,
            email: result.user.email,
            score: result.score,
            maxScore: result.maxScore,
            status: result.status,
            enabled: result.enabled,
            startDate: result.startTime,
            endDate: result.endTime,
            marked: result.marked,
            skills: result.skills,
            completionTime: result.endTime && result.startTime && result.endTime - result.startTime,
          };
          assessmentResult.push(assessmentUserResult);
        }
      });
      setResultList(assessmentResult);
      setStoredResultList(assessmentResult);
    }
  }, [candidateStatus]);

  const filterSkill = (index: number, skill: AttemptSkill) => {
    if (skill.skillId === skillSelected.skillId) {
      setResultList(storedResultList);
      setSkillSelected({
        selected: false,
        skillName: '',
        skillId: '',
        index: undefined,
        displayLapsedTime: false,
      });
    } else {
      setResultList((resultListCopy) =>
        resultListCopy.sort((compareOne, compareTwo) =>
        // @ts-ignore
          compareTwo.skills[index].correctCount - compareOne.skills[index].correctCount,
        ));
      setSkillSelected({
        selected: true,
        skillName: skill.skillName,
        skillId: skill.skillId,
        index: index,
        displayLapsedTime: false,
      });
    }
  };

  const filterCompletionTime = () => {
    if (!completionFilter) {
      if (skillSelected.skillName) {
        setSkillSelected({
          ...skillSelected,
          displayLapsedTime: true,
        });
        setResultList((resultListCopy) =>
          resultListCopy.sort((compareOne, compareTwo) =>
          // @ts-ignore
            compareOne.skills[skillSelected.index].timeTakenForSkill - compareTwo.skills[skillSelected.index].timeTakenForSkill,
          ));
        setCompletionFilter(true);
      } else {
        setResultList((resultListCopy) =>
          resultListCopy.sort((compareOne, compareTwo) =>
          // @ts-ignore
            compareOne.completionTime - compareTwo.completionTime,
          ));
        setCompletionFilter(true);
      }
    } else if (skillSelected.displayLapsedTime) {
      setResultList((resultListCopy) =>
        resultListCopy.sort((compareOne, compareTwo) =>
        // @ts-ignore
          compareTwo.skills[skillSelected.index].correctCount - compareOne.skills[skillSelected.index].correctCount,
        ));
      setCompletionFilter(false);
    } else {
      setResultList(storedResultList);
      setCompletionFilter(false);
    }
  };

  const sortByName = () => {
    setNameActive(true);
    setScoreActive(false);
    setDateActive(false);
    setAscending(!ascending);
    const sortedList = [...resultList];
    sortedList.sort((a, b) => {
      const firstNameComparison = ascending ? a.firstName.localeCompare(b.firstName) : b.firstName.localeCompare(a.firstName);
      if (firstNameComparison === 0) {
        return ascending ? a.lastName.localeCompare(b.lastName) : b.lastName.localeCompare(a.lastName);
      }
      return firstNameComparison;
    });
    setResultList(sortedList);
  };

  const sortByDate = () => {
    setNameActive(false);
    setScoreActive(false);
    setDateActive(true);
    setAscending(!ascending);
    const sortedList = [...resultList];
    sortedList.sort(
      ascending ? (a, b) => (a.endDate || 0) - (b.endDate || 0) : (a, b) => (b.endDate || 0) - (a.endDate || 0),
    );
    setResultList(sortedList);
  };

  const sortByScore = () => {
    setNameActive(false);
    setScoreActive(true);
    setDateActive(false);
    setAscending(!ascending);
    const sortedList = [...resultList];
    sortedList.sort(
      ascending ? (a, b) => (a.score || 0) - (b.score || 0) : (a, b) => (b.score || 0) - (a.score || 0),
    );
    setResultList(sortedList);
  };

  const toggleRowSelection = (assessmentResult: AssessmentUser) => {
    if (selectedRows.includes(assessmentResult)) {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== assessmentResult));
    } else {
      setSelectedRows([...selectedRows, assessmentResult]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === resultList.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(resultList);
    }
  };

  const changeCandidateStatus = (status: CandidateStatus) => {
    let candidateStatusArray: { status: string, userId: string }[] = [];
    if (userResults[0].assessmentId && updateAssessmentUserList) {
      selectedRows.map((row: AssessmentUser) => {
        candidateStatusArray = [...candidateStatusArray, { status: status, userId: row.userId }];
      });
      let requestBody = {
        assessmentId: userResults[0].assessmentId,
        candidateStatus: candidateStatusArray,
      };
      setCandidateStatus(requestBody).then(() => {
        updateAssessmentUserList(selectedRows, status);
        const updatedStoredResultList = storedResultList.filter(
          (item) => !selectedRows.some((selectedItem) => selectedItem.assessmentUserId === item.assessmentUserId),
        );
        setResultList(updatedStoredResultList);
        setStoredResultList(updatedStoredResultList);
      });
    }
  };

  const sendEmails = async () => {
    if (candidateStatus) {
      const requestBody = {
        assessmentId: assessmentId,
        candidateStatus: candidateStatus,
      };
      try {
        const response = await sendCandidateEmail(requestBody);
        if (response.ok) {
          const data = await response.json();
          toast.success(data.status, { position: 'bottom-center' });
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error sending mail: ' + error, { position: 'bottom-center' });
      }
    }
  };

  const exportCSV = async () => {
    if (candidateStatus) {
      const requestBody = {
        assessmentId: assessmentId,
        candidateStatus: candidateStatus,
      };
      try {
        const response = await exportCandidateCSV(requestBody);
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${candidateStatus}-candidates.csv`;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error sending mail: ' + error, { position: 'bottom-center' });
      }
    }
  };

  const applyFilter = async () => {
    try {
      const response = await filterPreScreenAnswers(assessmentId, filters);
      if (response.ok) {
        const data = await response.json();
        setUserAssessmentId(data.userAssessmentIds);
      } else {
        toast.error('Filter not updated', { position: 'bottom-center' });
      }
    } catch {
      toast.error('Filter not updated ', { position: 'bottom-center' });
    }
  };

  const resetFilter = async () => {
    setUserAssessmentId(userAssessmentIds);
    setFilters([]);
  };

  return (
    <>
      <div
          className={styles.flexInlineRow}
      >
        <div
            className={styles.infoBox}
        >
          <div
              className={styles.iconClass}
          >
            <Users className='w-8 h-8'/>
          </div>
          <div
              className={styles.infoBoxText}
          >
            <SecondaryTypography.XSmall
                fontWeight='semi-bold'
                keepDefaultMargins={false}
                className={styles.infoBoxSmallText}
            >
              Total Completions
            </SecondaryTypography.XSmall>
            <SecondaryTypography.Large
                fontWeight='semi-bold'
                keepDefaultMargins={false}
            >
              { resultListData?.completionRate }
            </SecondaryTypography.Large>
          </div>
        </div>
        <div
            className={styles.infoBox}
        >
          <div
              className={styles.iconClass}
          >
            <PresentationChart className='w-8 h-8'/>
          </div>
          <div
              className={styles.infoBoxText}
          >
            <SecondaryTypography.XSmall
                fontWeight='semi-bold'
                keepDefaultMargins={false}
                className={styles.infoBoxSmallText}
            >
              Highest Score
            </SecondaryTypography.XSmall>
            <SecondaryTypography.Large
                fontWeight='semi-bold'
                keepDefaultMargins={false}
            >
              { resultListData?.highestScore }%
            </SecondaryTypography.Large>
          </div>
        </div>
      </div>
      <div
          className={styles.flexInlineRow}
      >
        <Button
            size='xSmall'
            variant='secondary'
            onClick={() => setSkillVisibility(!skillVisibility)}
            selected={skillVisibility}
        >
          Competency
        </Button>
        <Button
            size='xSmall'
            variant='secondary'
            onClick={filterCompletionTime}
            selected={completionFilter}
        >
          Completion Time
        </Button>
        <Button
            size='xSmall'
            variant='secondary'
            onClick={() => {setFilterDrawer(!filterDrawer);}}
            selected={filterDrawer}
        >
          Pre-Screen Filter
        </Button>
        { selectedRows.length > 0 && candidateStatus !== CandidateStatusEnum.SHORTLISTED &&
          <Button
              size='xSmall'
              variant='success'
              onClick={() => changeCandidateStatus(CandidateStatusEnum.SHORTLISTED)}
          >
            Shortlist
          </Button>
        }
        { selectedRows.length > 0 && candidateStatus !== CandidateStatusEnum.UNSUCCESSFUL &&
          <Button
              size='xSmall'
              variant='danger'
              onClick={() => changeCandidateStatus(CandidateStatusEnum.UNSUCCESSFUL)}
          >
            Un-Successful
          </Button>
        }
        { candidateStatus  !== undefined && [CandidateStatusEnum.SHORTLISTED.toString(), CandidateStatusEnum.UNSUCCESSFUL.toString()].includes(candidateStatus) &&
          <Button
              size='xSmall'
              variant='primary'
              onClick={() => sendEmails()}
          >
            Send { candidateStatus.toUpperCase() } candidate mail
          </Button>
        }
        { candidateStatus  !== undefined &&
          <Button
              size='xSmall'
              variant='primary'
              onClick={() => exportCSV()}
          >
            Export Candidate CSV
          </Button>
        }
      </div>
      <div
          className={styles.flexInlineRow}
      >
        { skillVisibility && skills?.map((skill: AttemptSkill, index) => {
          return (
            <Button
                size='xSmall'
                variant='success'
                onClick={() => filterSkill(index, skill)}
                key={skill.skillName}
                selected={skillSelected.skillId === skill.skillId}
            >
              { skill.skillName }
            </Button>
          );
        }) }
      </div>
      <SecondaryTypography.Medium
          fontWeight='semi-bold'
          keepDefaultMargins={true}
      >
        Test Results
      </SecondaryTypography.Medium>
      <table
          className={commonStyles.tableStyle}
      >
        <thead>
          <tr
              className={commonStyles.tableHeader}
          >
            { candidateStatus !== undefined &&
              <th>
                <input
                    type='checkbox'
                    checked={selectedRows.length === resultList.length}
                    onChange={toggleSelectAll}
                />
              </th>
            }
            <th>
              <div
                  className={styles.sortCol}
                  onClick={sortByName}
              >
                <SecondaryTypography.XSmall
                    fontWeight='semi-bold'
                    textCase='uppercase'
                    alignment='center'
                >
                  Name
                </SecondaryTypography.XSmall>
                <div className={styles.sortArrows}>
                  <SortDown className={
                    nameActive ? ascending ? styles.activateArrow : styles.deactivateArrow : styles.deactivateArrow
                  }
                  />
                  <SortUp className={
                    nameActive ? ascending ? styles.deactivateArrow : styles.activateArrow : styles.deactivateArrow
                  }
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                  className={styles.sortCol}
                  onClick={sortByDate}
              >
                <SecondaryTypography.XSmall
                    fontWeight='semi-bold'
                    textCase='uppercase'
                    alignment='center'
                >
                  Date Completed
                </SecondaryTypography.XSmall>
                <div className={styles.sortArrows}>
                  <SortDown className={
                    dateActive ? ascending ? styles.activateArrow : styles.deactivateArrow : styles.deactivateArrow
                  }
                  />
                  <SortUp className={
                    dateActive ? ascending ? styles.deactivateArrow : styles.activateArrow : styles.deactivateArrow
                  }
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                  className={styles.sortCol}
                  onClick={sortByScore}
              >
                <SecondaryTypography.XSmall
                    fontWeight='semi-bold'
                    textCase='uppercase'
                    alignment='center'
                >
                  Score
                </SecondaryTypography.XSmall>
                <div className={styles.sortArrows}>
                  <SortDown className={
                    scoreActive ? ascending ? styles.activateArrow : styles.deactivateArrow : styles.deactivateArrow
                  }
                  />
                  <SortUp className={
                    scoreActive ? ascending ? styles.deactivateArrow : styles.activateArrow : styles.deactivateArrow
                  }
                  />
                </div>
              </div>
            </th>
            { skillSelected.selected &&
            <th>
              <SecondaryTypography.XSmall
                  fontWeight='semi-bold'
                  textCase='uppercase'
                  alignment='center'
              >
                { skillSelected.skillName } Summary
              </SecondaryTypography.XSmall>
            </th>
            }
            { completionFilter &&
            <th>
              <SecondaryTypography.XSmall
                  fontWeight='semi-bold'
                  textCase='uppercase'
                  alignment='center'
              >
                { skillSelected.skillName } Lapsed Time
              </SecondaryTypography.XSmall>
            </th>
            }
            <th>
              <SecondaryTypography.XSmall
                  fontWeight='semi-bold'
                  textCase='uppercase'
                  alignment='center'
              >
                Test Status
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  fontWeight='semi-bold'
                  textCase='uppercase'
                  alignment='center'
              >
                Mark as checked
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  fontWeight='semi-bold'
                  textCase='uppercase'
                  alignment='center'
              >
                Actions
              </SecondaryTypography.XSmall>
            </th>
          </tr>
        </thead>
        <tbody>
          { resultList
            .filter(result => userAssessmentId.includes(result.assessmentUserId))
            .map((result) => {
              return (
                <AssessmentResultRow
                    key={result.assessmentUserId}
                    assessmentResult={result}
                    skillIndex={skillSelected.index}
                    displaySkillLapsedTime={completionFilter}
                    selected={selectedRows.includes(result)}
                    onSelect={toggleRowSelection}
                    isCandidate={candidateStatus !== undefined}
                />
              );
            }) }
        </tbody>
      </table>
      { resultList.length === 0 &&
        <div className={styles.emptySect}>
          <SecondaryTypography.XSmall
              fontWeight='semi-bold'
              textCase='uppercase'
              alignment='center'
          >
            There are currently no { candidateStatus } candidates
          </SecondaryTypography.XSmall>
        </div>
      }
      <Drawer isOpen={filterDrawer} onClose={() => setFilterDrawer(false)}>
        <FilterPreScreen
            questions={preScreenQuestions}
            setFilters={setFilters}
            filters={filters}
            applyFilters={applyFilter}
            resetFilters={resetFilter}
        />
      </Drawer>
    </>
  );
};
