import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '../../../ui/button/button';
import { AddIcon, PlusBox, RefreshIcon } from '../../../ui/icons/icons';
import { Modal } from '../../../ui/modal/modal';
import { PaginationController } from '../../../ui/pagination_controller/pagination_controller';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import { addEmployeesWithCsv, createEmployee, deleteEmployee, editEmployee , getEmployee, getEmployeeList } from '../../../utils/api_service/employees';
import { createJobTitle, getJobTitleList } from '../../../utils/api_service/job_title';
import { getOrganizationUnitList } from '../../../utils/api_service/organization_unit';
import { Permission } from '../../../utils/helpers/permissions';
import PermissionGate from '../../../utils/hooks/PermissionGate';
import { fetchOrganizationUsers } from '../../../utils/redux_store/features/organization_user_reducer';
import { fetchUserGroups } from '../../../utils/redux_store/features/user_group_reducer';
import { fetchUserRoles } from '../../../utils/redux_store/features/user_role_reducer';
import { RootState, store } from '../../../utils/redux_store/store';
import { EmployeeUser, JobTitle, SingleEmployee } from '../../../utils/types/admin_types';
import { SelectOptions } from '../../../utils/types/react_select_types';
import commonStyles from '../access_management/access_management.module.css';
import { AdminSearchBar } from '../access_management/admin_search_bar/admin_search_bar';
import baseStyles from '../admin.module.css';
import { OrganizationUnit } from '../OrganizationSettings/TreeStructure';
import { EmployeeRow } from './EmployeeRow';
import { BulkUploadModal } from './Modals/BulkUploadModal';
import { CreateEmployeeModal } from './Modals/CreateEmployeeModal';
import { EditEmployeeModal } from './Modals/EditEmployeeModal';

export const EmployeeManagement = () => {
  const [employeeUserList, setEmployeeUserList] = useState<EmployeeUser[]>([]);
  const [organizationUnitList, setOrganizationUnitList] = useState<OrganizationUnit[]>([]);
  const [jobTitleList, setJobTitleList] = useState<JobTitle[]>([]);
  const userGroup = useSelector((state: RootState) => state.userGroups.userGroupList);
  const userRole = useSelector((state: RootState) => state.userRoles.userRoleList);
  const [inviteModalVisible, setInviteModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState<boolean>(false);
  const [editedEmployee, setEditedEmployee] = useState<EmployeeUser>(employeeUserList[0]);
  const [singleEmployee, setSingleEmployee] = useState<SingleEmployee | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchEmployees = useCallback(async () => {
    try {
      const response = await getEmployeeList(searchTerm, pageNumber, 8);
      if (response.ok) {
        const data = await response.json();
        setEmployeeUserList(data.employee);
        setTotalPages(data.pages);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
    }
  }, [searchTerm, pageNumber]);

  useEffect(() => {
    getOrganizationUnitList().then(response => {
      setOrganizationUnitList(response);
    });

    getJobTitleList().then(response => {
      setJobTitleList(response);
    });

    store.dispatch(fetchUserGroups());
    store.dispatch(fetchUserRoles());
  }, []);

  useEffect(() => {
    const debounceSearchTerm = setTimeout(() => {
      fetchEmployees();
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchTerm, fetchEmployees]);

  useEffect(() => {
    fetchEmployees();
  }, [pageNumber, fetchEmployees]);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPageNumber(1);
  };

  const deleteUser = async (id: string) => {
    try {
      const response = await deleteEmployee(id);
      if (response.ok) {
        const updatedEmployees = employeeUserList.filter((employee) => employee.id !== id);
        setEmployeeUserList(updatedEmployees);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error deleting employee: ' + error, { position: 'bottom-center' });
    }
  };

  const openEditModal = async (employee: EmployeeUser) => {
    const response = await getEmployee(employee.id);
    setSingleEmployee(response);
    setEditedEmployee(employee);
    setEditModalVisible(true);
  };

  const inviteEmployee = async (email: string, firstname: string, lastname: string, selectedJobTitle: string, organizationUnit: string, reportingManager: string | undefined) => {
    try {
      const response = await createEmployee(email, firstname, lastname, reportingManager, organizationUnit, selectedJobTitle);
      if (response.ok) {
        const data = await response.json();
        setInviteModalVisible(false);
        setEmployeeUserList((employeeUserList) => [...employeeUserList, data]);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error adding employee: ' + error, { position: 'bottom-center' });
    }
  };

  const inviteBulkEmployees = async (csvFile: File) => {
    try {
      const response = await addEmployeesWithCsv(csvFile);
      if (response.ok) {
        const data = await response.json();
        toast.success('Successfully uploaded file!', { position: 'bottom-center' });
        setEmployeeUserList((employeeUserList) => [...employeeUserList, ...data]);
        setBulkUploadModalVisible(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error adding employee: ' + error, { position: 'bottom-center' });
    }
  };

  const onAddNewJobTitle = (title: string) => {
    createJobTitle(title).then(response => {
      if (response.id) {
        setJobTitleList((jobTitleList) => [...jobTitleList, response]);
      }
    });
  };

  const editUser = async (employee: EmployeeUser, groups: SelectOptions[], roles: SelectOptions[],
    selectedJobTitle: string, organizationUnit: string, reportingManager: string | undefined) => {
    const groupList = groups.map(group => group.value);
    const roleList = roles.map(role => role.value);
    try {
      const response = await editEmployee(roleList, groupList, employee.id, reportingManager, organizationUnit, selectedJobTitle);
      if (response.ok) {
        setInviteModalVisible(false);
        setEditModalVisible(false);
        const updatedUser = {
          ...employee,
          reportingManager: reportingManager,
          organizationUnit: organizationUnit,
          selectedJobTitle: selectedJobTitle,
          groups: userGroup.filter((item) => groupList.includes(item.id)),
          roles: userRole.filter((item) => roleList.includes(item.id)),
        };
        const updatedEmployeeUserList = employeeUserList.map((employeeUser) => {
          if (employeeUser.id === employee.id) {
            return updatedUser;
          }
          return employeeUser;
        });
        setEmployeeUserList(updatedEmployeeUserList);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error editing employee: ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <div
        className={baseStyles.container}
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Admin
      </PrimaryTypography.FiveXLarge>
      <div
          className={baseStyles.contentContainer}
      >
        <SecondaryTypography.Medium
            keepDefaultMargins={true}
            fontWeight='bold'
        >
          Employee Management
        </SecondaryTypography.Medium>
        <div
            className={commonStyles.searchContainer}
        >
          <AdminSearchBar
              searchTerm={searchTerm}
              handleChange={handleSearchTermChange}
          />
          <div
              className={classNames(commonStyles.flexRowContainer, commonStyles.iconGap, commonStyles.blueText)}
              onClick={() => store.dispatch(fetchOrganizationUsers())}
          >
            <div
                className={commonStyles.icons}
            >
              <RefreshIcon/>
            </div>
            <SecondaryTypography.Small
                fontWeight='semi-bold'
                textCase='capitalize'
            >
              Refresh
            </SecondaryTypography.Small>
          </div>
          <div
              className={classNames(commonStyles.flexRowContainer, commonStyles.iconGap, commonStyles.blueText)}
          >
            <div
                className={classNames(commonStyles.icons, commonStyles.plusIconColor)}
            >
              <PlusBox/>
            </div>
            <div
                onClick={() => setBulkUploadModalVisible(true)}
            >
              <SecondaryTypography.Small
                  fontWeight='semi-bold'
                  textCase='capitalize'
              >
                Invite Users in Bulk
              </SecondaryTypography.Small>
            </div>
          </div>
          <PermissionGate
              requiredPermissions={[Permission.USERS_CREATE]}
          >
            <Button
                icon={<AddIcon/>}
                onClick={() => setInviteModalVisible(true)}
                variant='primary'
                size='medium'
            >
              Invite Users
            </Button>
          </PermissionGate>
        </div>
        <table
            className={baseStyles.tableStyle}
        >
          <thead>
            <tr>
              <th
                  style={{ width: 0 }}
              >
                <input type='checkbox'/>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Name
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Email
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Groups
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    alignment='left'
                    textCase='uppercase'
                    fontWeight='semi-bold'
                >
                  Last Active
                </SecondaryTypography.XSmall>
              </th>
            </tr>
          </thead>
          <tbody>
            { employeeUserList.map((employee: EmployeeUser) => {
              return (
                <EmployeeRow
                    key={employee.id}
                    employee={employee}
                    deleteUser={deleteUser}
                    onEditClick={openEditModal}
                />
              );
            })
            }
          </tbody>
        </table>
        <PaginationController
            pageNumber={pageNumber}
            maxPageNumber={totalPages}
            navigateNextPage={() => setPageNumber((pageNumber) => pageNumber + 1)}
            navigatePreviousPage={() => setPageNumber((pageNumber) => pageNumber - 1)}
        />
        <Modal
            visible={inviteModalVisible}
            modalContent={
              <CreateEmployeeModal
                  employeeUsers={employeeUserList}
                  organizationUnits={organizationUnitList}
                  jobTitles={jobTitleList}
                  addNewJobTitle={onAddNewJobTitle}
                  createEmployee={inviteEmployee}
              />
            }
            toggle={() => setInviteModalVisible(false)}
            headerText='Invite New Employee'
            headerClass={baseStyles.modalHeader}
        />
        <Modal
            visible={editModalVisible}
            modalContent={
              <EditEmployeeModal
                  employeeUsers={employeeUserList}
                  organizationUnits={organizationUnitList}
                  jobTitles={jobTitleList}
                  userGroupList={userGroup}
                  userRoleList={userRole}
                  singleEmployee={singleEmployee}
                  employee={editedEmployee}
                  addNewJobTitle={onAddNewJobTitle}
                  editUser={editUser}
              />
            }
            toggle={() => setEditModalVisible(false)}
            headerText='Edit Employee Details'
            headerClass={baseStyles.modalHeader}
        />
        <Modal
            visible={editModalVisible}
            modalContent={
              <EditEmployeeModal
                  employeeUsers={employeeUserList}
                  organizationUnits={organizationUnitList}
                  jobTitles={jobTitleList}
                  userGroupList={userGroup}
                  userRoleList={userRole}
                  singleEmployee={singleEmployee}
                  employee={editedEmployee}
                  addNewJobTitle={onAddNewJobTitle}
                  editUser={editUser}
              />
            }
            toggle={() => setEditModalVisible(false)}
            headerText='Edit Employee Details'
            headerClass={baseStyles.modalHeader}
        />
        <Modal
            visible={bulkUploadModalVisible}
            modalContent={
              <BulkUploadModal
                  inviteEmployeesFromCsv={inviteBulkEmployees}
              />
            }
            toggle={() => setBulkUploadModalVisible(false)}
            headerText='Bulk Invite Employees'
            headerClass={baseStyles.modalHeader}
        />
      </div>
    </div>
  );
};
