import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getUserGroupList } from '../../api_service/user_groups';
import { UserGroup } from '../../types/admin_types';

export const fetchUserGroups = createAsyncThunk('userGroups/fetchUserGroups', async () => {
  try {
    const response = await getUserGroupList();
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      toast.error(errorData.error, { position: 'bottom-center' });
    }
  } catch (error) {
    toast.error('Error fetching groups: ' + error, { position: 'bottom-center' });
  }
});

export interface UserGroupState {
    userGroupList: UserGroup[];
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: UserGroupState = {
  userGroupList: [],
  loading: 'idle',
};

const userGroupSlice = createSlice({
  name: 'userGroups',
  initialState,
  reducers: {
    addUserGroupRedux: (state, action: PayloadAction<UserGroup>) => {
      state.userGroupList.push(action.payload);
    },
    editUserGroupRedux: (state, action: PayloadAction<UserGroup>) => {
      const { id } = action.payload;
      const index = state.userGroupList.findIndex((userGroup) => userGroup.id === id);
      if (index !== -1) {
        state.userGroupList[index] = action.payload;
      }
    },
    deleteUserGroupRedux: (state, action: PayloadAction<string>) => {
      const id= action.payload;
      state.userGroupList = state.userGroupList.filter((userGroup) => userGroup.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserGroups.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUserGroups.fulfilled, (state, action) => {
        state.userGroupList = action.payload;
        state.loading = 'succeeded';
      });
  },
});

export const { addUserGroupRedux, editUserGroupRedux, deleteUserGroupRedux } = userGroupSlice.actions;
export default userGroupSlice.reducer;
