import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import loginRegisterBg from '../../assets/images/backgrounds/login_register_bg.png';
import logoBlackText from '../../assets/images/logos/white-bg-logo-hiacuity.png';
import { Button } from '../../ui/button/button';
import { TextInput } from '../../ui/input/text_input/text_input';
import { Modal } from '../../ui/modal/modal';
import { getCurrentUser, loginUser, requestPasswordChange } from '../../utils/api_service/user_api';
import { getPermissions } from '../../utils/helpers/auth';
import { setPermissions } from '../../utils/redux_store/features/auth_permissions_reducer';
import { fetchJobTitles } from '../../utils/redux_store/features/job_title_reducer';
import { fetchSkillLevels } from '../../utils/redux_store/features/level_reducer';
import { fetchSkills } from '../../utils/redux_store/features/skill_reducer';
import { fetchSubscriptions } from '../../utils/redux_store/features/subscription_reducer';
import { fetchSubSkills } from '../../utils/redux_store/features/subskill_reducer';
import { setAccessibleViews, setCurrentView } from '../../utils/redux_store/features/ViewReducer';
import { store } from '../../utils/redux_store/store';
import { User } from '../../utils/types/assessment_types';
import { FormInput } from '../../utils/types/login_register_types';
import styles from './login_register.module.css';
import { ResetPassword } from './modal_content/reset_password';
import { validate, validateForm } from './validation/validation_rules';

export const Login = () => {
  const history = useHistory();
  const { email } = useParams<{ email: string }>();
  const dispatch = useDispatch();

  const [loginInputs, setLoginInputs] = useState<FormInput>({
    email: email || '',
    password: '',
    errors: {
      emailError: '',
      passwordError: '',
    },
  });
  const [formValid, setFormValid] = useState<boolean>(true);
  const [modalState, setModalState] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let errors = validate(event.target, loginInputs);

    setLoginInputs({
      ...loginInputs,
      [event.target.name]: event.target.value,
      errors: errors,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isSubmittedFormValid = validateForm(loginInputs);
    if (isSubmittedFormValid) {
      loginUser(loginInputs)
        .then(response => {
          if (response.success) {
            setFormValid(true);
            localStorage.setItem('email', loginInputs.email);
            localStorage.setItem('token', response.accessToken);
            const permissions = getPermissions(response.accessToken);
            dispatch(setPermissions(permissions));
            getCurrentUser().then((response: User) => {
              localStorage.setItem('id', response.id);
              localStorage.setItem('firstname', response.firstName);
              localStorage.setItem('lastname', response.lastName);
              localStorage.setItem('profilePic', response.profilePic);
              response.role && localStorage.setItem('role', response.role);
              if (response.view) {
                dispatch(setCurrentView(response.view[0]));
                dispatch(setAccessibleViews(response.view));
              }
              if (permissions.length === 0) {
                history.push('/assigned-assessments');
              } else {
                store.dispatch(fetchSkills());
                store.dispatch(fetchSkillLevels());
                store.dispatch(fetchJobTitles());
                store.dispatch(fetchSubSkills());
                store.dispatch(fetchSubscriptions());
                history.push('/home');
              }
            });
          } else {
            toast.error(response.error, { position: 'bottom-center' });
          }
        })
        .catch(error => toast.error(error, { position: 'bottom-center' }));
    } else {
      toast.error('One or more of your details are invalid!', { position: 'bottom-center' });
      setFormValid(false);
    }
  };

  const sendPasswordResetMail = (event: FormEvent<HTMLFormElement>, email: string) => {
    event.preventDefault();
    requestPasswordChange(email).then(response => {
      if (response.success) {
        toast.success('An email has been sent to your inbox', { position: 'bottom-center' });
        setModalState(false);
      } else if (response.error) {
        toast.error(response.error, { position: 'bottom-center' });
      }
    });
  };

  return (
    <div
        className={styles.pageContainer}
    >
      <div
          className={styles.mainContainer}
      >
        <div
            className={styles.leftContainer}
        >
          <img
              className={styles.leftContainerBg}
              src={loginRegisterBg}
              alt='HiAcuity-Bg'
          />
        </div>
        <div
            className={styles.formContainer}
        >
          <img
              className={styles.logoStyles}
              src={logoBlackText}
              alt='HiAcuity-Logo'
          />
          <h1
              className={styles.formHeading}
          >
            Signin
          </h1>
          <form
              className='flex flex-col py-2'
              onSubmit={handleSubmit}
              data-testid='login-form'
          >
            <TextInput
                type='email'
                label='Email'
                name='email'
                data-testid='email'
                value={loginInputs.email}
                onChange={handleChange}
                required={true}
            />
            <span
                className={styles.invalidInput}>
              &nbsp;{ !formValid ? loginInputs.errors.emailError : '' }
            </span>
            <TextInput
                type='password'
                label='Password'
                name='password'
                data-testid='password'
                value={loginInputs.password}
                onChange={handleChange}
                required={true}
            />
            <span
                className={styles.invalidInput}>
              &nbsp;{ !formValid ? loginInputs.errors.passwordError : '' }
            </span>
            <p
                className='font-bold text-sm text-blue-800 no-underline hover:underline pb-2'
                style={{ cursor: 'pointer' }}
            >
              <a onClick={() => setModalState(true)}>Forgot password?</a>
            </p>
            <Button
                onClick={() => {}}
                size='medium'
                variant='primary'
                customClass={styles.submitButton}
            >
              Login
            </Button>
          </form>
        </div>
      </div>
      <Modal
          visible={modalState}
          toggle={() => setModalState(!modalState)}
          modalContent={<ResetPassword handleSubmit={sendPasswordResetMail}/>}
          customClass={styles.resetPasswordModal}
          headerText='Reset Password'
      />
    </div>
  );
};
