export enum Permission {
    ANALYTICS_CREATE = 'analytics:create',
    ANALYTICS_DELETE = 'analytics:delete',
    ANALYTICS_EDIT = 'analytics:edit',
    ANALYTICS_VIEW = 'analytics:view',
    ASSESSMENTS_CREATE = 'assessments:create',
    ASSESSMENTS_DELETE = 'assessments:delete',
    ASSESSMENTS_EDIT = 'assessments:edit',
    ASSESSMENTS_VIEW = 'assessments:view',
    ENTERPRISE_ANALYTICS_CREATE = 'enterprise_analytics:create',
    ENTERPRISE_ANALYTICS_DELETE = 'enterprise_analytics:delete',
    ENTERPRISE_ANALYTICS_EDIT = 'enterprise_analytics:edit',
    ENTERPRISE_ANALYTICS_VIEW = 'enterprise_analytics:view',
    GROUPS_CREATE = 'groups:create',
    GROUPS_DELETE = 'groups:delete',
    GROUPS_EDIT = 'groups:edit',
    GROUPS_VIEW = 'groups:view',
    JOB_CREATE = 'job:create',
    JOB_DELETE = 'job:delete',
    JOB_EDIT = 'job:edit',
    JOB_VIEW = 'job:view',
    ORGANISATION_ANALYTICS_CREATE = 'organization_analytics:create',
    ORGANISATION_ANALYTICS_DELETE = 'organization_analytics:delete',
    ORGANISATION_ANALYTICS_EDIT = 'organization_analytics:edit',
    ORGANISATION_ANALYTICS_VIEW = 'organization_analytics:view',
    QUESTIONS_CREATE = 'questions:create',
    QUESTIONS_DELETE = 'questions:delete',
    QUESTIONS_EDIT = 'questions:edit',
    QUESTIONS_VIEW = 'questions:view',
    ROLES_CREATE = 'roles:create',
    ROLES_DELETE = 'roles:delete',
    ROLES_EDIT = 'roles:edit',
    ROLES_VIEW = 'roles:view',
    SUPER_ADMIN = 'super:admin',
    USERS_CREATE = 'users:create',
    USERS_DELETE = 'users:delete',
    USERS_EDIT = 'users:edit',
    USERS_VIEW = 'users:view',
    ORGUNIT_CREATE = 'orgunit:create',
    ORGUNIT_DELETE = 'orgunit:delete',
    ORGUNIT_EDIT = 'orgunit:edit',
    ORGUNIT_VIEW = 'orgunit:view',
    JOBTITLE_CREATE = 'job_title:create',
    JOBTITLE_DELETE = 'job_title:delete',
    JOBTITLE_EDIT = 'job_title:edit',
    JOBTITLE_VIEW = 'job_title:view',
    EMPLOYEE_CREATE = 'employee:create',
    EMPLOYEE_DELETE = 'employee:delete',
    EMPLOYEE_EDIT = 'employee:edit',
    EMPLOYEE_VIEW = 'employee:view',
    CANDIDATE_CREATE = 'candidate:create',
    CANDIDATE_DELETE = 'candidate:delete',
    CANDIDATE_EDIT = 'candidate:edit',
    CANDIDATE_VIEW = 'candidate:view',
    PARTNER_CREATE = 'partner:create',
    PARTNER_EDIT = 'partner:edit',
    PARTNER_DELETE = 'partner:delete',
    PARTNER_VIEW = 'partner:view',
    SUBSCRIPTION_MANAGE = 'subscription:manage'
}
