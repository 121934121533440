import React from 'react';

type IconProps = {
  className?: string,
};

export const HomeIcon = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={props.className ?? 'h-6 w-6'} fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6' />
  </svg>
);

export const BriefCaseIcon = (props: IconProps) => (
  <svg width='24' height='25' className={props.className ?? 'h-6 w-6'} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M21 13.7554C18.2207 14.8805 15.1827 15.5 12 15.5C8.8173 15.5 5.7793 14.8805 3 13.7554M16 6.5V4.5C16 3.39543 15.1046 2.5 14 2.5H10C8.89543 2.5 8 3.39543 8 4.5V6.5M12 12.5H12.01M5 20.5H19C20.1046 20.5 21 19.6046 21 18.5V8.5C21 7.39543 20.1046 6.5 19 6.5H5C3.89543 6.5 3 7.39543 3 8.5V18.5C3 19.6046 3.89543 20.5 5 20.5Z' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
  </svg>
);

export const ClipBoardIcon = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={props.className ?? 'h-6 w-6'} fill='none' viewBox='0 0 24 24'
      stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}
        d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01'/>
  </svg>
);

export const QuestionMark = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={props.className ?? 'h-6 w-6'} fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
  </svg>
);

export const AnalyticsIcon = (props: IconProps) => (
  <svg width='20' height='18' viewBox='0 0 20 18' className={props.className ?? 'h-6 w-6'} stroke='white' strokeWidth={2} xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H18C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2V12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V2ZM5 17H15ZM7 13V17ZM13 13V17Z' />
    <path d='M5 17H15M7 13V17M13 13V17M1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H18C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2V12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V2Z' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 9L9 6L11 8L14 5' />
    <path d='M6 9L9 6L11 8L14 5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export const ChevronDown = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
  </svg>
);

export const ChevronUp = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 15l7-7 7 7' />
  </svg>
);

export const ChevronRight = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={props.className ?? 'h-6 w-6'} fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 5l7 7-7 7' />
  </svg>
);

export const ChevronLeft = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className={props.className ?? 'h-6 w-6'}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
  </svg>
);

export const CloseIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
  </svg>
);

export const SolidCheckIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
    <path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clipRule='evenodd' />
  </svg>
);

export const SearchIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' />
  </svg>
);

export const AddIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 4v16m8-8H4' />
  </svg>
);

export const TrashIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' />
  </svg>
);

export const ListIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4 6h16M4 10h16M4 14h16M4 18h16' />
  </svg>
);

export const PencilIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z' />
  </svg>
);

export const FilterIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z' />
  </svg>
);

export const UploadIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12' />
  </svg>
);

export const OpenLockIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z' />
  </svg>
);

export const ClockIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
  </svg>
);

export const ArrowRight = ({ className }: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={className || 'h-6 w-6'} fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M14 5l7 7m0 0l-7 7m7-7H3' />
  </svg>
);

export const ArrowLeft = ({ className }: IconProps) => (
  <ArrowRight className={`rotate-180 ${className || ''}`} />
);

export const CheckCircle = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
    <path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clipRule='evenodd' />
  </svg>
);

export const XCircle = () => (
  <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
    <path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z' clipRule='evenodd' />
  </svg>
);

export const Users = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={props.className ?? 'h-6 w-6'} fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z' />
    ;</svg>
);

export const PresentationChart = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={props.className ?? 'h-6 w-6'} fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z' />
  </svg>
);

export const CogIcon = (props: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className={props.className ?? 'h-6 w-6'} >
    <path strokeLinecap='round' strokeLinejoin='round' d='M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z' />
    <path strokeLinecap='round' strokeLinejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
  </svg>
);

export const UserIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z' />
  </svg>
);

export const RoleIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M23 19C23 20.0609 22.5786 21.0783 21.8284 21.8284C21.0783 22.5786 20.0609 23 19 23H17V21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19C21 18.4696 20.7893 17.9609 20.4142 17.5858C20.0391 17.2107 19.5304 17 19 17H17V15H19C20.0609 15 21.0783 15.4214 21.8284 16.1716C22.5786 16.9217 23 17.9391 23 19ZM9 19C9 17.9391 9.42143 16.9217 10.1716 16.1716C10.9217 15.4214 11.9391 15 13 15H15V17H13C12.4696 17 11.9609 17.2107 11.5858 17.5858C11.2107 17.9609 11 18.4696 11 19C11 19.5304 11.2107 20.0391 11.5858 20.4142C11.9609 20.7893 12.4696 21 13 21H15V23H13C11.9391 23 10.9217 22.5786 10.1716 21.8284C9.42143 21.0783 9 20.0609 9 19Z' fill='black'/>
    <path d='M14 18H18V20H14V18ZM9 5C8.40666 5 7.82664 5.17595 7.33329 5.50559C6.83994 5.83524 6.45542 6.30377 6.22836 6.85195C6.0013 7.40013 5.94189 8.00333 6.05764 8.58527C6.1734 9.16721 6.45912 9.70176 6.87868 10.1213C7.29824 10.5409 7.83279 10.8266 8.41473 10.9424C8.99667 11.0581 9.59987 10.9987 10.1481 10.7716C10.6962 10.5446 11.1648 10.1601 11.4944 9.66671C11.8241 9.17336 12 8.59334 12 8C11.9976 7.20508 11.6808 6.4434 11.1187 5.8813C10.5566 5.31921 9.79492 5.00237 9 5ZM9 9C8.80222 9 8.60888 8.94135 8.44443 8.83147C8.27998 8.72159 8.15181 8.56541 8.07612 8.38268C8.00043 8.19996 7.98063 7.99889 8.01921 7.80491C8.0578 7.61093 8.15304 7.43275 8.29289 7.29289C8.43275 7.15304 8.61093 7.0578 8.80491 7.01921C8.99889 6.98063 9.19996 7.00043 9.38268 7.07612C9.56541 7.15181 9.72159 7.27998 9.83147 7.44443C9.94135 7.60888 10 7.80222 10 8C9.99921 8.26497 9.8936 8.51887 9.70623 8.70623C9.51887 8.8936 9.26497 8.99921 9 9ZM5.31 15C6.41113 14.2891 7.68949 13.9011 9 13.88C9.2605 13.8833 9.52046 13.9047 9.778 13.944C10.7395 13.3273 11.8578 12.9997 13 13H13.254C11.9437 12.2989 10.4858 11.9185 9 11.89C6.97 11.89 3 12.96 3 15.47V17H7.349C7.61041 16.2621 8.01403 15.5826 8.537 15H5.31Z' fill='black'/>
    <path d='M16 2.0002H11.82C11.6152 1.41564 11.2339 0.909159 10.7287 0.550821C10.2235 0.192483 9.61939 0 9 0C8.38061 0 7.77654 0.192483 7.27133 0.550821C6.76612 0.909159 6.38476 1.41564 6.18 2.0002H2C1.47005 2.00179 0.962265 2.21301 0.587535 2.58774C0.212805 2.96247 0.00158273 3.47026 0 4.0002L0 18.0002C0.00158273 18.5301 0.212805 19.0379 0.587535 19.4127C0.962265 19.7874 1.47005 19.9986 2 20.0002H7.141C6.95242 19.3469 6.95242 18.6535 7.141 18.0002H2V4.0002H16V13.0002H18V4.0002C17.9984 3.47026 17.7872 2.96247 17.4125 2.58774C17.0377 2.21301 16.5299 2.00179 16 2.0002ZM9 3.2502C8.80149 3.24889 8.61149 3.16945 8.47112 3.02908C8.33075 2.88872 8.25131 2.69871 8.25 2.5002C8.25 2.30129 8.32902 2.11052 8.46967 1.96987C8.61032 1.82922 8.80109 1.7502 9 1.7502C9.19891 1.7502 9.38968 1.82922 9.53033 1.96987C9.67098 2.11052 9.75 2.30129 9.75 2.5002C9.74869 2.69871 9.66925 2.88872 9.52888 3.02908C9.38851 3.16945 9.19851 3.24889 9 3.2502Z' fill='black'/>
  </svg>
);

export const RefreshIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99' />
  </svg>
);

export const PlusBox = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M17 9H13V13H11V9H7V7H11V3H13V7H17M18 0H6C5.46957 0 4.96086 0.210714 4.58579 0.585786C4.21071 0.960859 4 1.46957 4 2V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM2 4H0V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16V18H2V4Z' fill='black'/>
  </svg>
);

export const CopyIcon = () => (
  <svg fill='none' height='22' viewBox='2 0 22 22' width='22' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.50678 6.00782C3.60124 6.58285 3 7.59621 3 8.75V15.25C3 18.1495 5.3505 20.5 8.25 20.5H14.75C15.9038 20.5 16.9172 19.8988 17.4939 18.9925L17.3717 18.9982L17.25 19H8.25C6.17893 19 4.5 17.3211 4.5 15.25V6.25C4.5 6.16872 4.50228 6.08798 4.50678 6.00782ZM8.75 3C6.95507 3 5.5 4.45507 5.5 6.25V14.75C5.5 16.5449 6.95507 18 8.75 18H17.25C19.0449 18 20.5 16.5449 20.5 14.75V6.25C20.5 4.45507 19.0449 3 17.25 3H8.75ZM13.4309 12.1373L13.523 12.2137L17.8295 16.4018C17.6481 16.4654 17.4531 16.5 17.25 16.5H8.75C8.54688 16.5 8.35185 16.4654 8.17047 16.4018L12.4774 12.2137C12.7394 11.959 13.141 11.9335 13.4309 12.1373ZM8.75 4.5H17.25C18.2165 4.5 19 5.2835 19 6.25V14.75C19 14.9588 18.9634 15.159 18.8964 15.3447L14.5687 11.1382C13.739 10.3316 12.4428 10.2913 11.5665 11.0172L11.4318 11.1382L7.10399 15.3457C7.03669 15.1597 7 14.9592 7 14.75V6.25C7 5.2835 7.7835 4.5 8.75 4.5ZM10.4996 6.75116C9.80959 6.75116 9.25026 7.31048 9.25026 8.00045C9.25026 8.69041 9.80959 9.24974 10.4996 9.24974C11.1895 9.24974 11.7488 8.69041 11.7488 8.00045C11.7488 7.31048 11.1895 6.75116 10.4996 6.75116Z' fill='#212121'/>
  </svg>
);

export const FullScreenIcon = () => (
  <svg height='30' width='30' viewBox='2 0 22 22' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.93,9.36a.38.38,0,0,0-.37.38V13a.76.76,0,0,1-.75.75H6.19A.76.76,0,0,1,5.44,13V6.89a.75.75,0,0,1,.75-.74h3.94a.38.38,0,0,0,.38-.38.37.37,0,0,0-.38-.37H6.19a1.5,1.5,0,0,0-1.5,1.49V13a1.51,1.51,0,0,0,1.5,1.5h7.62a1.51,1.51,0,0,0,1.5-1.5V9.74A.38.38,0,0,0,14.93,9.36Z'/><path d='M12.06,6.18H14L9.61,9.81a.38.38,0,0,0,.24.67.38.38,0,0,0,.24-.09l4.45-3.7,0,1.56a.38.38,0,0,0,.37.37h0a.38.38,0,0,0,.38-.38l0-2.42a.37.37,0,0,0-.37-.37l-2.85,0h0a.38.38,0,0,0,0,.75Z'/>
  </svg>
);

export const LoadingIcon = () => (
  <svg className='animate-spin feather feather-loader' fill='none' height='24' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><line x1='12' x2='12' y1='2' y2='6'/><line x1='12' x2='12' y1='18' y2='22'/><line x1='4.93' x2='7.76' y1='4.93' y2='7.76'/><line x1='16.24' x2='19.07' y1='16.24' y2='19.07'/><line x1='2' x2='6' y1='12' y2='12'/><line x1='18' x2='22' y1='12' y2='12'/><line x1='4.93' x2='7.76' y1='19.07' y2='16.24'/><line x1='16.24' x2='19.07' y1='7.76' y2='4.93'/></svg>
);

export const RedCross = () => (
  <svg width='14' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.8001 1.66527C14.8635 1.60189 14.9137 1.52664 14.948 1.44382C14.9823 1.361 15 1.27224 15 1.1826C15 1.09296 14.9823 1.0042 14.948 0.921381C14.9137 0.838564 14.8635 0.763314 14.8001 0.699929C14.7367 0.636544 14.6614 0.586264 14.5786 0.55196C14.4958 0.517656 14.407 0.5 14.3174 0.5C14.2278 0.5 14.139 0.517656 14.0562 0.55196C13.9734 0.586264 13.8981 0.636544 13.8347 0.699929L7.5 7.03602L1.16527 0.699929C1.10189 0.636544 1.02664 0.586264 0.943821 0.55196C0.861004 0.517656 0.772241 0.5 0.682601 0.5C0.592961 0.5 0.504198 0.517656 0.421381 0.55196C0.338564 0.586264 0.263314 0.636544 0.199929 0.699929C0.136544 0.763314 0.0862639 0.838564 0.05196 0.921381C0.017656 1.0042 -6.67874e-10 1.09296 0 1.1826C6.67873e-10 1.27224 0.017656 1.361 0.05196 1.44382C0.0862639 1.52664 0.136544 1.60189 0.199929 1.66527L6.53602 8L0.199929 14.3347C0.0719165 14.4627 0 14.6364 0 14.8174C0 14.9984 0.0719165 15.1721 0.199929 15.3001C0.327942 15.4281 0.501564 15.5 0.682601 15.5C0.863638 15.5 1.03726 15.4281 1.16527 15.3001L7.5 8.96398L13.8347 15.3001C13.9627 15.4281 14.1364 15.5 14.3174 15.5C14.4984 15.5 14.6721 15.4281 14.8001 15.3001C14.9281 15.1721 15 14.9984 15 14.8174C15 14.6364 14.9281 14.4627 14.8001 14.3347L8.46398 8L14.8001 1.66527Z' fill='#D72A27'/>
  </svg>
);

export const GreenTick = () => (
  <svg width='18' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M18 2.00009L6 14.0001L0.5 8.50009L1.91 7.09009L6 11.1701L16.59 0.590088L18 2.00009Z' fill='#3BB54A'/>
  </svg>
);

export const SortIcon = ({ className }: IconProps) => (
  <svg width='15' height='15' className={className} xmlns='http://www.w3.org/2000/svg' fill='#6B7280' viewBox='0 0 490 490'>
    <g>
      <polygon points='85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46
		0,194.27 37.087,221.213 	'/>
      <polygon points='404.13,335.988 404.13,61.691 358.301,61.691 358.301,335.99 309.503,268.787 272.416,295.73 381.216,445.54
		490,295.715 452.913,268.802 	'/>
    </g>
  </svg>
);

export const SortDown = ({ className }: IconProps) => (
  <svg width='15' height='15' className={className} xmlns='http://www.w3.org/2000/svg' fill='#6B7280' viewBox='0 0 490 490'>
    <g>
      <polygon points='344.13,335.988 344.13,61.691 298.301,61.691 298.301,335.99 249.503,268.787 212.416,295.73 321.216,445.54
		430,295.715 392.913,268.802  	'/>
    </g>
  </svg>
);

export const SortUp = ({ className }: IconProps) => (
  <svg width='15' height='15' className={className} xmlns='http://www.w3.org/2000/svg' fill='#6B7280' viewBox='0 0 490 490'>
    <g>
      <polygon points='85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46
		0,194.27 37.087,221.213 	'/>
    </g>
  </svg>
);

export const CreateNode = () => (
  <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M2.00693 5.14687C1.96181 5.95402 2.21244 6.74993 2.71189 7.3856C3.21134 8.02126 3.92536 8.45308 4.72027 8.6002L4.1936 8.07354C4.13188 8.01182 4.08292 7.93854 4.04952 7.8579C4.01611 7.77726 3.99892 7.69082 3.99892 7.60354C3.99892 7.42725 4.06895 7.25819 4.1936 7.13354C4.31825 7.00888 4.48732 6.93886 4.6636 6.93886C4.83988 6.93886 5.00895 7.00888 5.1336 7.13354L6.86027 8.8602C7.12027 9.1202 7.12027 9.5402 6.86027 9.8002L5.14027 11.5335C5.07855 11.5953 5.00527 11.6442 4.92463 11.6776C4.84399 11.711 4.75755 11.7282 4.67027 11.7282C4.58298 11.7282 4.49655 11.711 4.4159 11.6776C4.33526 11.6442 4.26199 11.5953 4.20027 11.5335C4.13855 11.4718 4.08959 11.3985 4.05618 11.3179C4.02278 11.2373 4.00559 11.1508 4.00559 11.0635C4.00559 10.9762 4.02278 10.8898 4.05618 10.8092C4.08959 10.7285 4.13855 10.6553 4.20027 10.5935L4.78693 10.0069V9.96687C3.59349 9.82506 2.50087 9.22797 1.73699 8.30012C0.973098 7.37228 0.596926 6.18533 0.686933 4.98687C0.860266 2.5202 3.0336 0.66687 5.50693 0.66687H6.66693C7.0336 0.66687 7.3336 0.96687 7.3336 1.33354C7.3336 1.7002 7.0336 2.0002 6.66693 2.0002H5.48027C3.68027 2.0002 2.10027 3.3602 2.00693 5.14687ZM10.0003 5.33354H13.3336C14.0669 5.33354 14.6669 4.73354 14.6669 4.0002V2.0002C14.6669 1.26687 14.0669 0.66687 13.3336 0.66687H10.0003C9.26693 0.66687 8.66693 1.26687 8.66693 2.0002V4.0002C8.66693 4.73354 9.26693 5.33354 10.0003 5.33354ZM13.3336 4.0002H10.0003V2.0002H13.3336V4.0002ZM13.3336 11.3335H10.0003C9.26693 11.3335 8.66693 10.7335 8.66693 10.0002V8.0002C8.66693 7.26687 9.26693 6.66687 10.0003 6.66687H13.3336C14.0669 6.66687 14.6669 7.26687 14.6669 8.0002V10.0002C14.6669 10.7335 14.0669 11.3335 13.3336 11.3335Z' fill='#3BB54A'/>
  </svg>
);

export const ShowIcon = () => (
  <svg width='22' height='22' xmlns='http://www.w3.org/2000/svg' viewBox='0 -5 22 22'><path d='M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z'/></svg>
);

export const HiddenIcon = () => (
  <svg width='22' height='22' xmlns='http://www.w3.org/2000/svg' viewBox='0 -5 22 22'><path d='M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z'/></svg>
);

export const CardIcon = ({ strokeColor }: { strokeColor?: string }) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke={strokeColor || 'currentColor'} className='w-6 h-6'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z' />
  </svg>
);

export const SearchIconNewUI = ({ className }: IconProps) => (
  <svg width='15' height='15' className={className} viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg' fill='#c0bfbc'>
    <g id='SVGRepo_bgCarrier' strokeWidth='0'/>
    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'/>
    <g id='SVGRepo_iconCarrier'>
      <path fill='#c0bfbc'
          d='m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704z'/>
    </g>
  </svg>
);

export const PicturePlaceHolder = ({ className }: IconProps) => (
  <svg width='24' height='24' className={className} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
        d='M2.58005 19.0098L2.56005 19.0298C2.29005 18.4398 2.12005 17.7698 2.05005 17.0298C2.12005 17.7598 2.31005 18.4198 2.58005 19.0098Z'
        fill='#292D32'/>
    <path
        d='M9 10.3801C10.3144 10.3801 11.38 9.31456 11.38 8.00012C11.38 6.68568 10.3144 5.62012 9 5.62012C7.68556 5.62012 6.62 6.68568 6.62 8.00012C6.62 9.31456 7.68556 10.3801 9 10.3801Z'
        fill='#292D32'/>
    <path
        d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z'
        fill='#292D32'/>
  </svg>
);

export const HappyFace = () => (
  <svg width='125' height='125' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
    <circle cx={24} cy={24} r={23} fill='#ffce52'/>
    <path fill='#273941'
        d='M24 39c-7.168 0-13-4.935-13-11h2c0 4.962 4.935 9 11 9s11-4.038 11-9h2c0 6.065-5.832 11-13 11z'/>
    <path d='M20 21h-2c0-2.206-1.346-4-3-4s-3 1.794-3 4h-2c0-3.309 2.243-6 5-6s5 2.691 5 6z'/>
    <path d='M38 21h-2c0-2.206-1.346-4-3-4s-3 1.794-3 4h-2c0-3.309 2.243-6 5-6s5 2.691 5 6z'/>
    <path d='M24 4c12.15 0 22 8.507 22 19h.975a23 23 0 0 0-45.95 0H2C2 12.507 11.85 4 24 4z' fill='#ffe369'/>
    <path
        d='M46 23c0 10.493-9.85 19-22 19S2 33.493 2 23h-.975c-.014.332-.025.665-.025 1a23 23 0 0 0 46 0c0-.335-.011-.668-.025-1z'
        fill='#ffb32b'/>
    <ellipse cx={37} cy={9} rx='.825' ry='1.148' transform='rotate(-45.02 37 9)' fill='#f6fafd '/>
    <ellipse cx={30.746} cy={4.5} rx='.413' ry='.574' transform='rotate(-45.02 30.745 4.5)' fill='#f6fafd' />
    <ellipse cx={34} cy={7} rx={1.65} ry={2.297} transform='rotate(-45.02 34 7)' fill='#f6fafd' />
  </svg>
);

export const NeutralFace = () => (
  <svg width='125' height='125' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
    <circle cx={24} cy={24} r={23} fill='#ffce52' />
    <ellipse cx={33} cy={18} rx={3} ry={4} fill='#273941' />
    <ellipse cx={15} cy={18} rx={3} ry={4} fill='#273941' />
    <ellipse cx={33} cy={18} rx={2} ry={3} fill='#141e21' />
    <ellipse cx={15} cy={18} rx={2} ry={3} fill='#141e21' />
    <circle cx={34} cy={17} r={1} fill='#f6fafd' />
    <circle cx={16} cy={17} r={1} fill='#f6fafd' />
    <path d='M24 4c12.15 0 22 8.507 22 19h.975a23 23 0 0 0-45.95 0H2C2 12.507 11.85 4 24 4' fill='#ffe369' />
    <path d='M46 23c0 10.493-9.85 19-22 19S2 33.493 2 23h-.975c-.014.332-.025.665-.025 1a23 23 0 0 0 46 0c0-.335-.011-.668-.025-1' fill='#ffb32b' />
    <path d='M16 32h16v2H16' fill='#273941' />
    <ellipse cx={37} cy={9} rx={0.825} ry={1.148} transform='rotate(-45.02 37 9)' fill='#f6fafd' />
    <ellipse cx={30.746} cy={4.5} rx={0.413} ry={0.574} transform='rotate(-45.02 30.745 4.5)' fill='#f6fafd' />
    <ellipse cx={34} cy={7} rx={1.65} ry={2.297} transform='rotate(-45.02 34 7)' fill='#f6fafd' />
  </svg>
);

export const DisgustFace = () => (
  <svg width='125' height='125' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
    <circle cx='24' cy='24' r='23' fill='#ffce52' />
    <path d='M24 4c12.15 0 22 8.507 22 19h.975a23 23 0 0 0-45.95 0H2C2 12.507 11.85 4 24 4' fill='#ffe369' />
    <path d='M46 23c0 10.493-9.85 19-22 19S2 33.493 2 23h-.975c-.014.332-.025.665-.025 1a23 23 0 0 0 46 0c0-.335-.011-.668-.025-1' fill='#ffb32b' />
    <path
        className='cls-4'
        d='M13 32.6v-4.2c0-1.325 1.407-2.4 3.143-2.4h15.714C33.593 26 35 27.075 35 28.4v4.2c0 1.658-4.92 5.4-11 5.4s-11-3.742-11-5.4'
        fill='#88d6f9'
    />
    <path
        className='cls-5'
        d='M9 33v-7a4.151 4.151 0 0 1 4.286-4h21.428A4.151 4.151 0 0 1 39 26v7c0 2.764-6.709 9-15 9S9 35.764 9 33'
        fill='#e5ebf7'
    />
    <path
        d='M12 32.7v-4.9c0-1.546 1.535-2.8 3.429-2.8h17.142C34.465 25 36 26.254 36 27.8v4.9c0 1.935-5.367 6.3-12 6.3s-12-4.365-12-6.3'
        fill='#ccedfc'
    />
    <path
        d='M34.714 22H13.286A4.151 4.151 0 0 0 9 26v2a4.151 4.151 0 0 1 4.286-4h21.428A4.151 4.151 0 0 1 39 28v-2a4.151 4.151 0 0 0-4.286-4'
        fill='#ededed'
    />
    <path
        className='cls-8'
        d='M29 15v2c4.883 0 8 1.776 8 3h2c0-2.851-4.3-5-10-5'
        fill='#273941'
    />
    <path
        className='cls-5'
        d='M39 26v.25l7.727-5.8q-.169-1.1-.443-2.167l-7.8 5.849A3.752 3.752 0 0 1 39 26'
        fill='#e5ebf7'
    />
    <path
        className='cls-9'
        d='M39.153 32v1a1.932 1.932 0 0 1-.392 1h5.956q.472-.975.851-2'
        fill='#3bc5f6'
    />
    <path
        className='cls-4'
        d='M9 20h2c0-1.224 3.117-3 8-3v-2c-5.7 0-10 2.149-10 5'
        fill='#88d6f9'
    />
    <path
        className='cls-5'
        d='M39 26a3.752 3.752 0 0 1 .515-1.864l-7.8-5.849q-.272 1.065-.443 2.167L9 26.25'
        fill='#e5ebf7'
    />
    <path
        className='cls-4'
        d='M39 32.6V32H13v.6c0 .848 1.294 2.241 3.366 3.4h15.268C33.706 34.841 35 33.448 35 32.6'
        fill='#88d6f9'
    />
    <ellipse
        className='cls-10'
        cx='37'
        cy='9'
        rx='.825'
        ry='1.148'
        transform='rotate(-45.02 37 9)'
    />
    <ellipse
        className='cls-10'
        cx='30.746'
        cy='4.5'
        rx='.413'
        ry='.574'
        transform='rotate(-45.02 30.745 4.5)'
    />
    <ellipse
        className='cls-10'
        cx='34'
        cy='7'
        rx='1.65'
        ry='2.297'
        transform='rotate(-45.02 34 7)'
    />
  </svg>
);

export const SurpriseFace = () => (
  <svg width='125' height='125' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
    <g id='_11-wow' data-name='11-wow'>
      <circle cx='24' cy='24' r='23' style={{ fill: '#ffce52' }} />
      <ellipse className='cls-2' cx='33' cy='18' rx='3' ry='4' />
      <ellipse className='cls-2' cx='15' cy='18' rx='3' ry='4' />
      <ellipse className='cls-3' cx='33' cy='18' rx='2' ry='3' />
      <ellipse className='cls-3' cx='15' cy='18' rx='2' ry='3' />
      <circle className='cls-4' cx='34' cy='17' r='1' />
      <circle className='cls-4' cx='16' cy='17' r='1' />
      <path className='cls-2' d='M37 10h-2a2 2 0 0 0-4 0h-2a4 4 0 0 1 8 0zM19 10h-2a2 2 0 0 0-4 0h-2a4 4 0 0 1 8 0z' />
      <path d='M24 4c12.15 0 22 8.507 22 19h.975a23 23 0 0 0-45.95 0H2C2 12.507 11.85 4 24 4z' style={{ fill: '#ffe369' }} />
      <path className='cls-6' d='M46 23c0 10.493-9.85 19-22 19S2 33.493 2 23h-.975c-.014.332-.025.665-.025 1a23 23 0 0 0 46 0c0-.335-.011-.668-.025-1z' />
      <ellipse className='cls-4' cx='34' cy='5' rx='.825' ry='1.148' transform='rotate(-45.02 34 5)' />
      <ellipse className='cls-4' cx='26.746' cy='3.5' rx='.413' ry='.574' transform='rotate(-45.02 26.746 3.5)' />
      <ellipse className='cls-4' cx='30' cy='4' rx='1.65' ry='2.297' transform='rotate(-45.02 30 4)' />
      <ellipse className='cls-2' cx='24' cy='34' rx='6' ry='9' />
      <path d='M24 43c2.542 0 4.71-2.375 5.584-5.723a7.985 7.985 0 0 0-11.168 0C19.29 40.625 21.458 43 24 43z' style={{ fill: '#ae2d4c' }} />
      <path className='cls-6' d='M24 43c-3.088 0-5.629-3.5-5.961-8-.024.329-.039.662-.039 1 0 4.971 2.686 9 6 9s6-4.029 6-9c0-.338-.015-.671-.039-1-.332 4.5-2.873 8-5.961 8z' />
      <path className='cls-3' d='M24 27c3.088 0 5.629 3.5 5.961 8 .024-.329.039-.662.039-1 0-4.971-2.686-9-6-9s-6 4.029-6 9c0 .338.015.671.039 1 .332-4.5 2.873-8 5.961-8z' />
      <path d='M24 41c-2.132 0-3.989-1.682-5.052-4.2a7.95 7.95 0 0 0-.532.473C19.29 40.625 21.458 43 24 43s4.71-2.375 5.584-5.723a7.95 7.95 0 0 0-.532-.473C27.989 39.318 26.132 41 24 41z' style={{ fill: '#8a293d' }} />
    </g>
  </svg>
);

export const FearFace = () => (
  <svg width='125' height='125' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
    <circle cx={24} cy={24} r={23} fill='#ffce52' />
    <ellipse cx={33} cy={18} rx={3} ry={4} fill='#273941' />
    <ellipse cx={15} cy={18} rx={3} ry={4} fill='#273941' />
    <ellipse cx={33} cy={18} rx={2} ry={3} fill='#141e21' />
    <ellipse cx={15} cy={18} rx={2} ry={3} fill='#141e21' />
    <circle cx={34} cy={17} r={1} fill='#f6fafd' />
    <circle cx={16} cy={17} r={1} fill='#f6fafd' />
    <path d='M24 4c12.15 0 22 8.507 22 19h.975a23 23 0 0 0-45.95 0H2C2 12.507 11.85 4 24 4' fill='#ffe369' />
    <path d='M46 23c0 10.493-9.85 19-22 19S2 33.493 2 23h-.975c-.014.332-.025.665-.025 1a23 23 0 0 0 46 0c0-.335-.011-.668-.025-1' fill='#ffb32b' />
    <path d='M16 32h16v2H16' fill='#273941' />
    <ellipse cx={37} cy={9} rx={0.825} ry={1.148} transform='rotate(-45.02 37 9)' fill='#f6fafd' />
    <ellipse cx={30.746} cy={4.5} rx={0.413} ry={0.574} transform='rotate(-45.02 30.745 4.5)' fill='#f6fafd' />
    <ellipse cx={34} cy={7} rx={1.65} ry={2.297} transform='rotate(-45.02 34 7)' fill='#f6fafd' />
  </svg>
);

export const SadFace = () => (
  <svg width='125' height='125' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
    <defs>
      <style>{ '.cls-3{fill:#273941}.cls-4{fill:#141e21}.cls-5{fill:#f6fafd}.cls-7{fill:#ffb32b}' }</style>
    </defs>
    <g id='_23-sad' data-name='23-sad'>
      <circle cx='24' cy='24' r='23' style={{ fill: '#ffce52' }} />
      <path d='M37 35.667A3.179 3.179 0 0 1 34 39a3.179 3.179 0 0 1-3-3.333C31 33.826 33.25 29 34 29s3 4.826 3 6.667z' style={{ fill: '#3bc5f6' }} />
      <path className='cls-3' d='M10 17v-2c3.722 0 6-1.295 6-2h2c0 2.626-4.024 4-8 4zM38 17c-3.976 0-8-1.374-8-4h2c0 .705 2.278 2 6 2zM24 35a10.343 10.343 0 0 0-4 1 4 4 0 0 1 8 0 10.343 10.343 0 0 0-4-1z' />
      <circle className='cls-3' cx='34' cy='22' r='5' />
      <circle className='cls-3' cx='14' cy='22' r='5' />
      <circle className='cls-4' cx='34' cy='22' r='4' />
      <circle className='cls-4' cx='14' cy='22' r='4' />
      <circle className='cls-5' cx='35.5' cy='20.5' r='1.5' />
      <circle className='cls-5' cx='32.5' cy='23.5' r='1.5' />
      <circle className='cls-5' cx='35.5' cy='23.5' r='.5' />
      <circle className='cls-5' cx='12.5' cy='20.5' r='1.5' />
      <circle className='cls-5' cx='15.5' cy='23.5' r='1.5' />
      <circle className='cls-5' cx='12.5' cy='23.5' r='.5' />
      <path d='M24 4c12.15 0 22 8.507 22 19h.975a23 23 0 0 0-45.95 0H2C2 12.507 11.85 4 24 4z' style={{ fill: '#ffe369' }} />
      <path className='cls-7' d='M46 23c0 10.493-9.85 19-22 19S2 33.493 2 23h-.975c-.014.332-.025.665-.025 1a23 23 0 0 0 46 0c0-.335-.011-.668-.025-1z' />
      <ellipse className='cls-5' cx='37' cy='9' rx='.825' ry='1.148' transform='rotate(-45.02 37 9)' />
      <ellipse className='cls-5' cx='30.746' cy='4.5' rx='.413' ry='.574' transform='rotate(-45.02 30.745 4.5)' />
      <ellipse className='cls-5' cx='34' cy='7' rx='1.65' ry='2.297' transform='rotate(-45.02 34 7)' />
      <path d='M34.135 29.047c.723.439 2.365 3.908 2.365 5.286a2.505 2.505 0 1 1-5 0c0-1.378 1.642-4.847 2.365-5.286-.852.469-2.865 4.877-2.865 6.62A3.179 3.179 0 0 0 34 39a3.179 3.179 0 0 0 3-3.333c0-1.743-2.013-6.151-2.865-6.62z' style={{ fill: '#00a3e1' }} />
      <ellipse className='cls-5' cx='35' cy='35' rx='.825' ry='1.148' transform='rotate(-45.02 35 35)' />
      <ellipse className='cls-5' cx='35.746' cy='33.5' rx='.413' ry='.574' transform='rotate(-45.02 35.746 33.5)' />
      <path className='cls-7' d='M34 39a3.048 3.048 0 0 1-2.853-2.354A4.808 4.808 0 0 0 31 37.667 3.179 3.179 0 0 0 34 41a3.179 3.179 0 0 0 3-3.333 4.808 4.808 0 0 0-.147-1.021A3.048 3.048 0 0 1 34 39z' />
    </g>
  </svg>
);

export const ExpandAll = () => (
  <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ width: '100%', height: '100%' }}
      viewBox='0 0 122.88 113.03'
      xmlSpace='preserve'
  >
    <style>
      { '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#29AAE2;}' }
    </style>
    <g>
      <path className='st0' d='M36.9,23.5h71.13c8.17,0,14.85,6.69,14.85,14.85v59.83c0,8.17-6.69,14.85-14.85,14.85H36.9 c-8.17,0-14.85-6.68-14.85-14.85V38.35C22.05,30.19,28.73,23.5,36.9,23.5L36.9,23.5z M10.08,73.96c0,2.78-2.26,5.04-5.04,5.04 C2.26,79,0,76.74,0,73.96V19.89C0,14.42,2.24,9.44,5.84,5.84C9.44,2.24,14.42,0,19.89,0h65.37c2.78,0,5.04,2.26,5.04,5.04 c0,2.78-2.26,5.04-5.04,5.04H19.89c-2.69,0-5.15,1.1-6.93,2.88c-1.78,1.78-2.88,4.23-2.88,6.93V73.96L10.08,73.96z M54.3,74.03 c-3.18,0-5.76-2.58-5.76-5.76s2.58-5.76,5.76-5.76H66.7V50.1c0-3.18,2.58-5.76,5.76-5.76s5.76,2.58,5.76,5.76v12.41h12.41 c3.18,0,5.76,2.58,5.76,5.76s-2.58,5.76-5.76,5.76H78.23v12.41c0,3.18-2.58,5.76-5.76,5.76s-5.76-2.58-5.76-5.76V74.03H54.3 L54.3,74.03z'/>
    </g>
  </svg>
);

export const CollapseAll = () => (
  <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={{ width: '100%', height: '100%' }}
      viewBox='0 0 122.88 113.03'
      xmlSpace='preserve'
  >
    <style>
      { '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#29AAE2;}' }
    </style>
    <g>
      <path className='st0' d='M36.9,23.5h71.13c8.17,0,14.85,6.69,14.85,14.85v59.83c0,8.17-6.69,14.85-14.85,14.85H36.9 c-8.17,0-14.85-6.68-14.85-14.85V38.35C22.05,30.19,28.73,23.5,36.9,23.5L36.9,23.5z M10.08,73.96c0,2.78-2.26,5.04-5.04,5.04 C2.26,79,0,76.74,0,73.96V19.89C0,14.42,2.24,9.44,5.84,5.84C9.44,2.24,14.42,0,19.89,0h65.37c2.78,0,5.04,2.26,5.04,5.04 c0,2.78-2.26,5.04-5.04,5.04H19.89c-2.69,0-5.15,1.1-6.93,2.88c-1.78,1.78-2.88,4.23-2.88,6.93V73.96L10.08,73.96z M54.3,74.03 c-3.18,0-5.76-2.58-5.76-5.76s2.58-5.76,5.76-5.76h36.34c3.18,0,5.76,2.58,5.76,5.76s-2.58,5.76-5.76,5.76H54.3L54.3,74.03z'/>
    </g>
  </svg>
);

export const Refresh = ({ className }: IconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' strokeWidth={1.5} className={className ?? 'h-6 w-6'}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99' />
  </svg>
);
