import classNames from 'classnames';
import React from 'react';
import styles from './result_status_capsule.module.css';

export enum ResultStatus {
    PASS = 'Pass',
    FAIL = 'Fail',
}

const getStatusStyle = (status: ResultStatus) => {
  switch (status) {
    case ResultStatus.PASS:
      return styles.pass;
    case ResultStatus.FAIL:
      return styles.fail;
  }
};

export const ResultStatusCapsule = ({
  status,
} : {
    status: ResultStatus,
}) => (
  <div
      className={classNames(styles.statusCapsule, getStatusStyle(status))}
  >
    • { status.valueOf() }
  </div>
);
