import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSkillList } from '../../api_service/skill_api';
import { Skill } from '../../types/assessment_types';
import { SelectOptions } from '../../types/react_select_types';

export const fetchSkills = createAsyncThunk('skills/fetchSkills', async () => {
  return await getSkillList();
});

export interface SkillLevelState {
    skillsList: SelectOptions[],
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
  skillsList: [],
  loading: 'idle',
} as SkillLevelState;

export const skillSlice = createSlice({
  name: 'skillsList',
  initialState,
  reducers: {
    addNewSkill: (state, action: PayloadAction<SelectOptions>) => {
      let skillsListCopy = [...state.skillsList];
      skillsListCopy.push(action.payload);
      return {
        ...state,
        skillsList: skillsListCopy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkills.pending, (state) => {
        return { ...state, loading: 'pending' };
      })
      .addCase(fetchSkills.fulfilled, (state, action) => {
        let skillArray: SelectOptions[] = [];
        if (action.payload) {
          action.payload.map((item: Skill) => {
            skillArray.push({
              value: item.id,
              label: item.skillName,
            });
          });
        }
        return { ...state, skillsList: skillArray, loading: 'succeeded' };
      });
  },
});

export const { addNewSkill } = skillSlice.actions;
export default skillSlice.reducer;
