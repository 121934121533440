import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import AsyncCustomSelect from '../../../../../ui/custom_react_select/async_select';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { getEmployeeList } from '../../../../../utils/api_service/employees';
import { getPartnerEmployees } from '../../../../../utils/api_service/partner_api';
import { debounce } from '../../../../../utils/helpers/debounce';
import { EmployeeUser, PartnerEntity, PartnerUser } from '../../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';

type AddPartnerModalProps = {
    entityList: PartnerEntity[];
    entity: PartnerEntity | undefined;
    employees: EmployeeUser[];
    partnerEmployees: PartnerUser[];
    addPartnerEntity: (state: PartnerEntity[], parentId: string, newEntity: PartnerEntity, internalManagers: SelectOptions[], externalManagers: SelectOptions[]) => void;
}

export const AddPartnerModal = ({
  entityList,
  entity,
  employees,
  partnerEmployees,
  addPartnerEntity,
}: AddPartnerModalProps) => {
  const [newEntity, setNewEntity] = useState<PartnerEntity>(entity || {
    id: '',
    name: '',
    alias: '',
    parentPartnerId: '',
    organizationId: '',
    partnerManagersInternal: [],
    partnerManagersExternal: [],
    location: {
      city: '',
      state: '',
      country: '',
      region: '',
      continent: '',
    },
    emailSuffix: [],
    subEntities: [],
  });
  const [internalPartnerManagers, setInternalPartnerManagers] = useState<SelectOptions[] | undefined>();
  const [externalPartnerManagers, setExternalPartnerManagers] = useState<SelectOptions[] | undefined>();

  const handleChange = (fieldName: string, value: string) => {
    setNewEntity((prevData) => {
      if (fieldName.includes('.')) {
        const nestedFields = fieldName.split('.');
        return {
          ...prevData,
          location: {
            ...prevData.location,
            [nestedFields[1]]: value,
          },
        };
      }

      return {
        ...prevData,
        [fieldName]: value,
      };
    });
  };

  const handleInternalPartnerChange = (selectedOptions: SelectOptions[]) => {
    setInternalPartnerManagers(selectedOptions);
  };

  const handleExternalPartnerChange = (selectedOptions: SelectOptions[]) => {
    setExternalPartnerManagers(selectedOptions);
  };

  const defaultInternalManagerOptions = employees.map((employee) => ({
    value: employee.id,
    label: employee.user.firstName + ' ' + employee.user.lastName,
  }));

  const defaultExternalManagerOptions = partnerEmployees.map((employee) => ({
    value: employee.id,
    label: employee.user.firstName + ' ' + employee.user.lastName,
  }));

  const handleApiError = async (response: Response) => {setExternalPartnerManagers;
    const errorData = await response.json();
    toast.error(errorData.error, { position: 'bottom-center' });
  };

  const mapEmployeeToSelectOption = (employee: EmployeeUser) => ({
    value: employee.id,
    label: `${employee.user.firstName} ${employee.user.lastName}`,
  });

  const fetchEmployeeList = async (query: string) => {
    try {
      const response = await getEmployeeList(query, 1, 10);
      if (response.ok) {
        const data = await response.json();
        return data.employee.map(mapEmployeeToSelectOption);
      } else {
        handleApiError(response);
        return [];
      }
    } catch (error) {
      toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
      return [];
    }
  };

  const fetchPartnerEmployees = async (query: string) => {
    try {
      const response = await getPartnerEmployees({ page: 1, recordsPerPage: 10, query: query });
      if (response.ok) {
        const data = await response.json();
        return data.employee.map(mapEmployeeToSelectOption);
      } else {
        handleApiError(response);
        return [];
      }
    } catch (error) {
      toast.error('Error fetching partner employees: ' + error, { position: 'bottom-center' });
      return [];
    }
  };

  const loadOptionsDebounced = debounce(
    async (inputValue: string, callback: (options: any) => void, isInternal: boolean) => {
      const options = isInternal ? await fetchEmployeeList(inputValue) : await fetchPartnerEmployees(inputValue);
      callback(options);
    },
    500,
  );

  return (
    <>
      <TextInput
          name='name'
          value={newEntity.name}
          type='text'
          label='Partner Entity Name'
          onChange={(event) => handleChange('name', event.target.value)}
      />
      <TextInput
          name='alias'
          value={newEntity.alias}
          type='text'
          label='Partner Entity Alias'
          onChange={(event) => handleChange('alias', event.target.value)}
      />
      <AsyncCustomSelect
          value={internalPartnerManagers}
          defaultOptions={defaultInternalManagerOptions}
          onChange={handleInternalPartnerChange}
          loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback, true)}
          label={'Internal Partner Manager'}
          isMulti
      />
      <AsyncCustomSelect
          value={externalPartnerManagers}
          defaultOptions={defaultExternalManagerOptions}
          onChange={handleExternalPartnerChange}
          loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback, false)}
          label={'External Partner Manager'}
          isMulti
      />
      <TextInput
          name='location.city'
          value={newEntity.location.city}
          type='text'
          label='City'
          onChange={(event) => handleChange('location.city', event.target.value)}
      />
      <TextInput
          name='location.state'
          value={newEntity.location.state}
          type='text'
          label='Country Region/State'
          onChange={(event) => handleChange('location.state', event.target.value)}
      />
      <TextInput
          name='location.country'
          value={newEntity.location.country}
          type='text'
          label='Country'
          onChange={(event) => handleChange('location.country', event.target.value)}
      />
      <TextInput
          name='location.region'
          value={newEntity.location.region}
          type='text'
          label='Region'
          onChange={(event) => handleChange('location.region', event.target.value)}
      />
      <TextInput
          name='location.continent'
          value={newEntity.location.continent}
          type='text'
          label='Continent'
          onChange={(event) => handleChange('location.continent', event.target.value)}
      />
      <div>
        <Button
            onClick={() => addPartnerEntity(entityList, entity?.id || '', newEntity, internalPartnerManagers || [], externalPartnerManagers || [])}
            variant={'primary'}
            size={'medium'}
        >
          Add Partner Entity
        </Button>
      </div>
    </>
  );
};
