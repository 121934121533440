import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { SecondaryTypography } from '../../../../ui/typography/typography';

type BulkUploadModalProps = {
  inviteEmployeesFromCsv: (csvFile: File) => void,
}

export const BulkUploadModal = ({ inviteEmployeesFromCsv }: BulkUploadModalProps) => {
  const [uploadedFile, setUploadedFile] = useState<File>();

  const addFileViaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!uploadedFile) {
      const files = event.target.files;
      const splitFileString = files && files[0].name.split('.');
      if (files && splitFileString && splitFileString[splitFileString.length - 1] === 'csv') {
        setUploadedFile(files[0]);
      } else {
        toast.error('Invalid File type: CSV needed', { position: 'bottom-center' });
      }
    }
  };

  const handleDownload = () => {
    const fileUrl = process.env.PUBLIC_URL + '/files/employee_bulk_sample.csv';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'employee_bulk_sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
        className='flex flex-col items-center p-5'
    >
      { uploadedFile ?
        <>
          <SecondaryTypography.Small>
            File name: { uploadedFile.name }
          </SecondaryTypography.Small>
          <Button
              variant='primary'
              size='medium'
              onClick={() => inviteEmployeesFromCsv(uploadedFile)}
          >
            Bulk Invite from CSV
          </Button>
        </>
        :
        <>
          <input
              type='file'
              onChange={addFileViaInput}
              id='upload-file-input'
              style={{ display: 'none' }}
          />
          <Button
              variant='primary'
              size='medium'
              onClick={() => document.getElementById('upload-file-input')?.click()}
          >
            Upload File
          </Button>
          <Button
              variant='primary'
              size='medium'
              onClick={handleDownload}
          >
            Download Sample CSV
          </Button>
        </>
      }
    </div>
  );
};
