import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';
import { SelectOptions } from '../../utils/types/react_select_types';
import inputStyles from '../input/input.module.css';
import { SecondaryTypography } from '../typography/typography';
import styles from './custom_select.module.css';
import { customStyles } from './custom_select_styles';

type CustomSelectProps = {
    label?: string,
    name: string,
    options: SelectOptions[] | undefined,
    value?: SelectOptions | SelectOptions[]
    onChange: any,
    disabled?: boolean,
    clearable?: boolean,
    isMulti?: boolean,
    closeMenuOnSelect?: boolean,
    hideSelectedOptions?: boolean,
    components?: any,
};

export const CheckboxMultiSelect = ({
  label,
  name,
  options,
  value,
  onChange,
  disabled,
  clearable = false,
  isMulti,
  closeMenuOnSelect,
  hideSelectedOptions,
  components,
}: CustomSelectProps) => {
  return (
    <div>
      {
        label &&
        <label
            className={styles.selectLabel}
        >
          <SecondaryTypography.XSmall
              className={classNames(inputStyles.labelStyles, styles.infoText)}
              fontWeight='semi-bold'
          >
            { label }
          </SecondaryTypography.XSmall>
        </label>
      }
      <Select
          value={value}
          name={name}
          options={options}
          onChange={onChange}
          styles={customStyles}
          isDisabled={disabled}
          // @ts-ignore
          isMulti={isMulti}
          isClearable={clearable}
          maxMenuHeight={200}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
          components={{ Option: components }}
      />
    </div>
  );
};
