import React, { ChangeEvent } from 'react';
import { Question } from '../../../utils/types/assessment_types';
import { SelectOptions } from '../../../utils/types/react_select_types';
import { Button } from '../../button/button';
import { CustomSelect } from '../../custom_react_select/custom_select';
import { MinuteSecondsInput } from '../../input/minute_seconds_input/minute_seconds_input';
import { TextArea } from '../../input/text_area/text_area';
import { TextInput } from '../../input/text_input/text_input';
import styles from '../collapsible_question.module.css';
import { UploadMedia } from '../upload_media/upload_media';

type ExpandedQuestionProps = {
    toggledQuestion: Question,
    isDisabled: boolean,
    setIsDisabled: (value: boolean) => void,
    isOpen: boolean,
    editQuestion: (question: Question) => void,
    handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    handleSelectChange: (selectedValue: SelectOptions, action: any) => void,
    handleMultiSelectChange: (selectedValue: SelectOptions[], action: any) => void,
    onAddNewSkill: (skill: string, parent?: string | null, level?: string) => void,
    returnSelectedJobTitles: () => SelectOptions[],
    addFileViaInput: (event: React.ChangeEvent<HTMLInputElement>) => void,
    clearMediaFile: () => void,
    toggleCollapsible: (open: boolean) => void,
    skills: SelectOptions[],
    subSkills1: SelectOptions[],
    subSkills2: SelectOptions[],
    subSkills3: SelectOptions[],
    levelList: SelectOptions[] | undefined,
    jobTitleList: SelectOptions[],
    questionTypes: SelectOptions[],
}

export const ExpandedQuestion = ({
  toggledQuestion,
  isDisabled,
  setIsDisabled,
  isOpen,
  editQuestion,
  handleChange,
  handleSelectChange,
  handleMultiSelectChange,
  onAddNewSkill,
  returnSelectedJobTitles,
  addFileViaInput,
  toggleCollapsible,
  clearMediaFile,
  skills,
  subSkills1,
  subSkills2,
  subSkills3,
  levelList,
  jobTitleList,
  questionTypes,
}: ExpandedQuestionProps) => (
  <div
      className={styles.inputContainer}
  >
    <div
        className={styles.largeInputGrid}
    >
      <TextInput
          label='Question'
          name='question'
          value={toggledQuestion.question}
          type='text'
          onChange={handleChange}
          disabled={isDisabled}
      />
      <TextArea
          label='Answer'
          name='answer'
          value={toggledQuestion.answer}
          onChange={handleChange}
          disabled={isDisabled}
      />
    </div>
    <UploadMedia
        question={toggledQuestion}
        addFileViaInput={addFileViaInput}
        clearMediaFile={clearMediaFile}
    />
    <div
        className={styles.smallInputGrid}
    >
      <CustomSelect
          label='Skill'
          name='skillId'
          value={skills?.filter(option => option.value === toggledQuestion.skillId)[0]}
          options={skills}
          onChange={handleSelectChange}
          disabled={isDisabled}
          creatable={true}
          onCreateOption={onAddNewSkill}
      />
      { toggledQuestion.skillId !== null &&
      <CustomSelect
          label='Sub-Skill 1'
          name='subSkill1Id'
          value={subSkills1?.filter(option => option.value === toggledQuestion.subSkill1Id)[0]}
          options={subSkills1}
          onChange={handleSelectChange}
          disabled={isDisabled}
          creatable={true}
          clearable={true}
          onCreateOption={(input) => onAddNewSkill(input, toggledQuestion.skillId, '1')}
      />
      }
      { toggledQuestion.subSkill1Id &&
      <CustomSelect
          label='Sub-Skill 2'
          name='subSkill2Id'
          value={subSkills2?.filter(option => option.value === toggledQuestion.subSkill2Id)[0]}
          options={subSkills2}
          onChange={handleSelectChange}
          disabled={isDisabled}
          creatable={true}
          clearable={true}
          onCreateOption={(input) => onAddNewSkill(input, toggledQuestion.subSkill1Id, '2')}
      />
      }
      { toggledQuestion.subSkill2Id &&
      <CustomSelect
          label='Sub-Skill 3'
          name='subSkill3Id'
          value={subSkills3?.filter(option => option.value === toggledQuestion.subSkill3Id)[0]}
          options={subSkills3}
          onChange={handleSelectChange}
          disabled={isDisabled}
          creatable={true}
          clearable={true}
          onCreateOption={(input) => onAddNewSkill(input, toggledQuestion.subSkill2Id, '3')}
      />
      }
      <CustomSelect
          label='Level'
          name='levelId'
          value={levelList?.filter(option => option.value === toggledQuestion.levelId)[0]}
          options={levelList}
          onChange={handleSelectChange}
          disabled={isDisabled}
      />
      <CustomSelect
          label='Job Title'
          name='jobTitle'
          value={returnSelectedJobTitles()}
          options={jobTitleList}
          onChange={handleMultiSelectChange}
          disabled={isDisabled}
          isMulti={true}
      />
      <CustomSelect
          label='Question Type'
          name='questionType'
          value={questionTypes.filter(option => option.value === toggledQuestion.questionType)[0]}
          options={questionTypes}
          onChange={handleSelectChange}
          disabled={isDisabled}
      />
      <MinuteSecondsInput
          label='Timer (Seconds)'
          name='time'
          value={toggledQuestion.time}
          onChange={handleChange}
          disabled={isDisabled}
      />
      <TextInput
          label='Points for Question'
          name='points'
          value={toggledQuestion.points.toString()}
          type='number'
          onChange={handleChange}
          disabled={isDisabled}
      />
    </div>
    { !isDisabled &&
    <Button
        onClick={() => {
          editQuestion(toggledQuestion);
          toggleCollapsible(isOpen);
          setIsDisabled(true);
        }}
        size='medium'
        variant='primary'
        customClass={styles.addQuestionButton}
    >
      Edit Question
    </Button>
    }
  </div>
);
