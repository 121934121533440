import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartOrderItem, DataType } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { LightColors, MediumColors } from './Colors';
import { DashletIconGroup } from './DashletIconGroup';

const colors = [
  MediumColors.GREEN, MediumColors.BLUE, MediumColors.ORANGE, MediumColors.RED, MediumColors.FUCHSIA,
  MediumColors.PURPLE, MediumColors.CYAN, MediumColors.PINK, MediumColors.YELLOW, MediumColors.LIME,
  LightColors.GREEN, LightColors.BLUE, LightColors.ORANGE, LightColors.RED, LightColors.FUCHSIA,
  LightColors.PURPLE, LightColors.CYAN, LightColors.PINK, LightColors.YELLOW, LightColors.LIME,
];

type TootltipPayload = {
  color: string;
  dataKey: string;
  fill: string;
  value: number;
  name: string;
};

type ColumnChartDashletProps = {
    id:string,
    data: Array<DataType>,
    title: string,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void,
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}
export const ColumnChartDashlet = ({ id, data, title, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, fullScreenDetected, setFullScreenDetected, handleDashletVisibility, editMode }: ColumnChartDashletProps) => {
  const [transformedData, setTransformedData] = useState<{[key:string]: any}[]>([]);
  const [categories, setCategories] = useState<{[key: string]: string}>({});
  const [tooltipPayload, setTooltipPayload] = useState<TootltipPayload|null>(null);

  useEffect(() => {
    // The data we get is in the form [{name: 'category1', value: 10}, {name: 'category2', value: 20}]
    // But we want to convert that to [{name: '$chartTitle', category1: 10, category2: 20}]
    // We do this transformation here
    const d: { [key: string]: any } = {
      name: title,
    };
    const cat:  { [key: string]: any } = {};
    if(data !== undefined){
      for (let item of data) {
        cat[item.name] = item.name;
        d[item.name] = item.value*100;
      }
    }

    setCategories(cat);
    setTransformedData([d]);
  }, [data, title]);

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  const handleMouseEnter = (data: any) => {
    if (data.tooltipPayload && data.tooltipPayload.length > 0) {
      setTooltipPayload(data.tooltipPayload[0]);
    }
  };

  const handleMouseLeave = (data: any) => {
    if (data.tooltipPayload && data.tooltipPayload.length > 0) {
      setTooltipPayload(null);
    }
  };

  /**
   * A custom tooltip to show only the hovered over bar
   * @param props active, payload
   * @returns Custom tooltip or null
   */
  const CustomTooltip = (props: any) => {
    if (props.active && props.payload && props.payload.length && tooltipPayload) {
      let value: string | number = tooltipPayload.value;
      if (typeof value === 'number' && Number.isFinite(value) && !Number.isInteger(value)) {
        value = value.toFixed(3);
      }
      return (
        <div className='bg-white p-2 w-fit h-fit opacity-80 flex items-center justify-center'>
          <p className='' style={{ color: tooltipPayload.color || 'black' }}>
            { tooltipPayload.name }: { value }
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className='group relative w-full col-span-2 rounded border border-gray-200 shadow h-full p-2 overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={classNames(styles.dataContainer, dashletItem.hidden ? 'opacity-40': '')}
      >
        <div className='font-bold text-sm mb-2 text-center'>{ title }</div>
        <div className='flex justify-center'>
          { (!data || data.length === 0) ?
            <div className={'text-md text-gray-700 flex place-content-center place-items-center h-[300px]'}>No data to preview</div> :
            <ResponsiveContainer className='w-full h-full' height={fullScreenDetected? 500 : 300}>
              <BarChart
                  data={transformedData}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 10,
                    bottom: 30,
                  }}
                  barSize={20}
              >
                <XAxis dataKey='name' scale='auto' fontSize={12} />
                <YAxis/>
                <Legend />
                <Tooltip content={<CustomTooltip/>} />
                <CartesianGrid strokeDasharray='3 3' />
                { Object.keys(categories).map((category, index: number) => (
                  <Bar
                      key={index}
                      dataKey={category}
                      fill={colors[index % colors.length]}
                      background={{ fill: '#eee' }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                  />
                )) }
              </BarChart>
            </ResponsiveContainer>
          }
        </div>
      </div>
    </div>
  );
};
