import React, { useMemo, useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { UserRole } from '../../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import styles from '../../modals.module.css';

type AddGroupModalProps = {
    userRoleList: UserRole[],
    addGroup: (groupName: string, roleList: SelectOptions[]) => void,
}

export const AddGroupModal = ({
  userRoleList,
  addGroup,
}: AddGroupModalProps) => {
  const [groupName, setGroupName] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<SelectOptions[]>([]);

  const roleOptions = useMemo(() =>
    userRoleList.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  [userRoleList],
  );

  const handleRoleChange = (selectedOptions: SelectOptions[]) => {
    setSelectedRoles(selectedOptions);
  };

  return (
    <>
      <div
          className={styles.modalInputContainer}
      >
        <TextInput
            name='groupName'
            value={groupName}
            type='text'
            label='Group Name'
            onChange={(event) => setGroupName(event.target.value)}
        />
        <CustomSelect
            name='roles'
            options={roleOptions}
            onChange={handleRoleChange}
            isMulti={true}
            label='Role Membership'
            value={selectedRoles}
        />
      </div>
      <div>
        <Button
            onClick={() => addGroup(groupName, selectedRoles)}
            variant='primary'
            size='medium'
        >
          Add group
        </Button>
      </div>
    </>
  );
};
