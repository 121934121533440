import React, { useCallback, useContext, useState } from 'react';
import {  useLocation } from 'react-router-dom';
import { MenuContext } from '../../../utils/contexts/contexts';
import PermissionGate from '../../../utils/hooks/PermissionGate';
import styles from './sidemenu.module.css';
import { SubMenu } from './submenu/submenu';

type SideBarSubItem = {
    label: string,
    path: string,
    assignedPermissions: string[],
    assignedSubscription?: string,
}

export type SideBarItem = {
    title: string,
    icon: JSX.Element,
    assignedPermissions: string[],
    matchAnyPermission: boolean,
    navLinks: SideBarSubItem[],
}

type SideMenuProps = {
    sidebarData: SideBarItem[],
}

const getSidebarDataFromCurrentPath = (sidebarData: SideBarItem[], currentPath: string): SideBarItem => {
  for (const data of sidebarData) {
    for (const link of data.navLinks) {
      if (link.path === currentPath ) {
        return data;
      }
    }
  }
  return sidebarData[0];
};

export const SideMenu = ({
  sidebarData,
}: SideMenuProps) => {
  const location = useLocation();
  const [selectedSubMenu, setSelectedSubMenu] = useState<SideBarItem>(getSidebarDataFromCurrentPath(sidebarData, location.pathname));
  const { menuOpen, setMenuOpen } = useContext(MenuContext);

  const selectMenu = useCallback((item: SideBarItem) => {
    if (setMenuOpen) {
      if (selectedSubMenu === item && menuOpen) {
        setMenuOpen(false);
      } else {
        setSelectedSubMenu(item);
        setMenuOpen(true);
      }
    }
  }, [setMenuOpen, selectedSubMenu, menuOpen]);

  return (
    <div className='flex'>
      <ul
          className='h-full w-16 list-none bg-secondary-blue-500'
      >
        { sidebarData.map(item => (
          <PermissionGate
              key={item.title}
              requiredPermissions={item.assignedPermissions}
              matchAnyPermission={item.matchAnyPermission}
          >
            <li className='w-full'>
              <a
                  className={`w-12 h-12 my-6 mx-2 flex justify-center items-center cursor-pointer bg-secondary-blue-400 hover:bg-gradient-to-r hover:from-primary-blue-500 hover:to-primary-green-500 hover:from-15% hover:to-75% rounded-full ${styles.tooltip}`}
                  aria-selected={item.title === selectedSubMenu.title && menuOpen}
                  aria-label={item.title}
                  onClick={() => selectMenu(item)}
              >
                { item.icon }
                <span className={`${styles.tooltipText} absolute w-64 z-10 bg-black p-2`}>{ item.title }</span>
              </a>
            </li>
          </PermissionGate>
        )) }
      </ul>
      <SubMenu
          menuOpen={menuOpen}
          items={selectedSubMenu}
      />
    </div>
  );
};
