import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../../../../ui/button/button';
import { TextInput } from '../../../../../../ui/input/text_input/text_input';
import { FilterState, PreScreenQuestion } from '../../../../../../utils/types/assessment_types';

type FilterScreenProps = {
  questions: PreScreenQuestion[]
  filters: FilterState[]
  setFilters: Dispatch<SetStateAction<FilterState[]>>
  applyFilters: () => void
  resetFilters: () => void
}

const FilterPreScreen = ({ questions, setFilters, filters, applyFilters, resetFilters }: FilterScreenProps) => {
  const handleNumericChange = (questionId: string, field: 'min' | 'max', value: number) => {
    setFilters(prevFilters => {
      const existingFilter = prevFilters.find(filter => filter.questionId === questionId);

      if (existingFilter) {
        return prevFilters.map(filter =>
          filter.questionId === questionId
            ? {
              ...filter,
              filterNumber: { ...filter.filterNumber, [field]: value },
            }
            : filter,
        );
      }

      return [
        ...prevFilters,
        {
          questionId,
          questionType: 'numeric',
          filterNumber: { [field]: value },
        },
      ];
    });
  };

  const handleChoiceChange = (questionId: string, choice: string) => {
    setFilters(prevFilters => {
      const existingFilter = prevFilters.find(filter => filter.questionId === questionId);

      if (existingFilter) {
        const updatedFilterChoices = existingFilter.filterChoices?.includes(choice)
          ? existingFilter.filterChoices.filter(c => c !== choice)
          : [...(existingFilter.filterChoices || []), choice];

        if (updatedFilterChoices.length === 0) {
          return prevFilters.filter(filter => filter.questionId !== questionId);
        }

        return prevFilters.map(filter =>
          filter.questionId === questionId
            ? {
              ...filter,
              filterChoices: updatedFilterChoices,
            }
            : filter,
        );
      }

      return [
        ...prevFilters,
        {
          questionId,
          questionType: 'multiple-choice',
          filterChoices: [choice],
        },
      ];
    });
  };

  return (
    <div>
      <div className='mb-5 text-xl'>
        Filter
      </div>
      { questions.map(question => (
        <div key={question.questionId} className='mb-4 p-4 border rounded'>
          <h3 className='text-lg font-semibold'>{ question.question }</h3>
          { question.questionType === 'numeric' && (
            <div className='flex gap-4 mt-2'>
              <div className='w-1/2'>
                <TextInput
                    label='Min:'
                    name={`${question.questionId}-min`}
                    type='number'
                    onChange={(e) => handleNumericChange(question.questionId, 'min', Number(e.target.value))}
                    value={(filters.find(option => option.questionId === question.questionId)?.filterNumber?.min || '').toString()}
                />
              </div>
              <div className='w-1/2'>
                <TextInput
                    label='Max:'
                    name={`${question.questionId}-max`}
                    type='number'
                    onChange={(e) => handleNumericChange(question.questionId, 'max', Number(e.target.value))}
                    value={(filters.find(option => option.questionId === question.questionId)?.filterNumber?.max || '').toString()}
                />
              </div>
            </div>
          ) }

          { question.questionType === 'multiple-choice' && (
            <div className='mt-2'>
              { question.choices?.map(choice => (
                <label key={choice} className='block'>
                  <input
                      type='checkbox'
                      className='mr-2'
                      onChange={() => handleChoiceChange(question.questionId, choice)}
                      checked={filters.find(option => option.questionId === question.questionId)?.filterChoices?.includes(choice)}
                  />
                  { choice }
                </label>
              )) }
            </div>
          ) }
        </div>
      )) }
      <div className='flex'>
        <Button onClick={applyFilters} variant='primary' size='large'>
          Apply
        </Button>
        <Button onClick={resetFilters} variant='secondary' size='large'>
          Reset
        </Button>
      </div>
    </div>
  );
};
export default FilterPreScreen;
