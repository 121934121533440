import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { AddIcon, RefreshIcon } from '../../../../ui/icons/icons';
import { Modal } from '../../../../ui/modal/modal';
import { PaginationController } from '../../../../ui/pagination_controller/pagination_controller';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { addToUserRoleList, deleteFromUserRoleList, editUserRoleList, getPermissionEntities } from '../../../../utils/api_service/user_roles';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import {
  addUserRoleRedux,
  deleteUserRoleRedux,
  editUserRoleRedux,
  fetchUserRoles,
} from '../../../../utils/redux_store/features/user_role_reducer';
import { RootState, store } from '../../../../utils/redux_store/store';
import { UserRole } from '../../../../utils/types/admin_types';
import baseStyles from '../../admin.module.css';
import commonStyles from '../access_management.module.css';
import { AdminSearchBar } from '../admin_search_bar/admin_search_bar';
import AddRoleModal from './modals/add_role_modal';
import EditRoleModal from './modals/edit_role_modal';
import { RoleRow } from './role_row/role_row';
import { useRoleSearch } from './useRoleSearch';

export const RoleManagement = () => {
  const userRoles = useSelector((state: RootState) => state.userRoles.userRoleList);
  const [addRoleModalVisible, setAddRoleModalVisible] = useState<boolean>(false);
  const [editRoleModalVisible, setEditRoleModalVisible] = useState<boolean>(false);
  const [editedRole, setEditedRole] = useState<UserRole>(userRoles[0]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [permissionEntities, setPermissionEntities] = useState<string[]>([]);
  const { paginatedRoles, totalPages } = useRoleSearch(userRoles, searchTerm, pageNumber, 8);

  useEffect(() => {
    const fetchPermissionEntities = async () => {
      try {
        const response = await getPermissionEntities();
        if (response.ok) {
          const data = await response.json();
          setPermissionEntities(data);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching entites: ' + error, { position: 'bottom-center' });
      }
    };

    fetchPermissionEntities();
  }, []);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPageNumber(1);
  };

  const addNewRole = async (roleName: string, permissions: string[]) => {
    try {
      const response = await addToUserRoleList(roleName, permissions);
      if (response.ok) {
        const data = await response.json();
        setAddRoleModalVisible(false);
        store.dispatch(addUserRoleRedux(data));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error adding role: ' + error, { position: 'bottom-center' });
    }
  };

  const deleteRole = async (id: string) => {
    try {
      const response = await deleteFromUserRoleList(id);
      if (response.ok) {
        store.dispatch(deleteUserRoleRedux(id));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error deleting role: ' + error, { position: 'bottom-center' });
    }
  };

  const openEditModal = (role: UserRole) => {
    setEditedRole(role);
    setEditRoleModalVisible(true);
  };

  const editRole = async (role: UserRole, roleName: string, permissions: string[]) => {
    try {
      const response = await editUserRoleList(role, roleName, permissions);
      if (response.ok) {
        const data = await response.json();
        setEditRoleModalVisible(false);
        store.dispatch(editUserRoleRedux(data));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error editing role: ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <>
      <div
          className={commonStyles.searchContainer}
      >
        <AdminSearchBar
            searchTerm={searchTerm}
            handleChange={handleSearchTermChange}
        />
        <div
            className={classNames(commonStyles.flexRowContainer, commonStyles.iconGap, commonStyles.blueText)}
            onClick={() => store.dispatch(fetchUserRoles())}
        >
          <div
              className={commonStyles.icons}
          >
            <RefreshIcon/>
          </div>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
              textCase='capitalize'
          >
            Refresh
          </SecondaryTypography.Small>
        </div>
        <PermissionGate
            requiredPermissions={[Permission.ROLES_CREATE]}
        >
          <Button
              icon={<AddIcon/>}
              onClick={() => setAddRoleModalVisible(true)}
              variant='primary'
              size='medium'
          >
            Add Role
          </Button>
        </PermissionGate>
      </div>
      <table
          className={baseStyles.tableStyle}
      >
        <thead>
          <tr>
            <th
                style={{ width: 0 }}
            >
              <input type='checkbox'/>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='left'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Role Name
              </SecondaryTypography.XSmall>
            </th>
          </tr>
        </thead>
        <tbody>
          { paginatedRoles.map((role: UserRole) => {
            return (
              <RoleRow
                  key={role.id}
                  role={role}
                  deleteRole={deleteRole}
                  onEditClick={openEditModal}
              />
            );
          })
          }
        </tbody>
      </table>
      <PaginationController
          pageNumber={pageNumber}
          maxPageNumber={totalPages}
          navigateNextPage={() => setPageNumber((pageNumber) => pageNumber + 1)}
          navigatePreviousPage={() => setPageNumber((pageNumber) => pageNumber - 1)}
      />
      <Modal
          visible={addRoleModalVisible}
          modalContent={<AddRoleModal entities={permissionEntities} addRole={addNewRole}/>}
          toggle={() => setAddRoleModalVisible(false)}
          headerText='Add Role'
          headerClass={baseStyles.modalHeader}
      />
      <Modal
          visible={editRoleModalVisible}
          modalContent={<EditRoleModal role={editedRole} entities={permissionEntities} editRole={editRole}/>}
          toggle={() => setEditRoleModalVisible(false)}
          headerText='Edit Role'
          headerClass={baseStyles.modalHeader}
      />
    </>
  );
};
