import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { createJobTitle } from '../../../../utils/api_service/job_title';
import { addJobTitleToRedux } from '../../../../utils/redux_store/features/job_title_reducer';
import { SelectOptions } from '../../../../utils/types/react_select_types';

type JobTitleManagementProps = {
  jobTitles: SelectOptions[],
}

export const JobTitleManagement = ({
  jobTitles,
}: JobTitleManagementProps) => {
  const dispatch = useDispatch();
  const [selectedJobTitle, setSelectedJobTitle] = useState<string | null>();

  const onAddNewJobTitle = (title: string) => {
    createJobTitle(title).then(response => {
      if (response.id) {
        dispatch(addJobTitleToRedux(response));
      }
    });
  };

  const handleSelectChange = (selectedValue: SelectOptions) => {
    setSelectedJobTitle(selectedValue.value);
  };

  return (
    <div className='grid grid-cols-2 gap-4 mt-5'>
      <CustomSelect
          label='Job Title'
          name='jobTitleId'
          options={jobTitles}
          value={jobTitles?.filter(option => option.value === selectedJobTitle)[0]}
          onChange={handleSelectChange}
          creatable={true}
          onCreateOption={onAddNewJobTitle}
      />
    </div>
  );
};
