import React, { useState } from 'react';
import { DefaultMenuState, MenuContext } from '../../utils/contexts/contexts';
import { NavBar } from '../NavBar';
import { SideBar } from '../sidebar/sidebar';

type PageWrapperProps = {
    Content: () => JSX.Element,
}

export const PageWrapper = ({
  Content,
}: PageWrapperProps) => {
  const [menuOpen, setMenuOpen] = useState(DefaultMenuState.menuOpen);

  const toggleMenuState = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <MenuContext.Provider
        value={{
          menuOpen: menuOpen,
          setMenuOpen: setMenuOpen,
          toggleMenuState: toggleMenuState,
        }}
    >
      <NavBar/>
      <div className='flex w-full'>
        <SideBar/>
        <div
            className='bg-white w-full p-4'
        >
          <Content/>
        </div>
      </div>
    </MenuContext.Provider>
  );
};
