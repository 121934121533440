import React, { Dispatch, KeyboardEventHandler, SetStateAction, useEffect } from 'react';

import { InputActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from '../../../../ui/custom_react_select/custom_select_styles';

type TopicInputProps = {
  setTopic: Dispatch<SetStateAction<string>>,
  disabled?: boolean
};

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

export const TopicInput = ({
  setTopic,
  disabled,
}: TopicInputProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<readonly Option[]>([]);

  useEffect(() => {
    const newTopic = value.map(option => option.value).join(':');
    setTopic(newTopic);
  }, [value, setTopic]);

  const createOption = (label: string) => {
    let prefix = '';
    switch (value.length) {
      case 0:
        prefix = 'Main Skill - ';
        break;
      case 1:
        prefix = 'Sub Skill 1 - ';
        break;
      case 2:
        prefix = 'Sub Skill 2 - ';
        break;
      case 3:
        prefix = 'Sub Skill 3 - ';
        break;
      default:
        prefix = 'Additional Skill - ';
        break;
    }
    return {
      label: prefix + label,
      value: label,
    };
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    if (value.length >= 4) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const handleInputChange = (newValue: string, event: InputActionMeta) => {
    if (event.action === 'menu-close' && inputValue !== '') {
      setValue((prev) => [...prev, createOption(inputValue)]);
      setInputValue('');
    } else {
      if (!newValue.includes(':')) {
        setInputValue(newValue);
      }
    }
  };

  return (
    <>
      <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          //@ts-ignore
          isMulti
          menuIsOpen={false}
          //@ts-ignore
          onChange={(newValue) => setValue(newValue)}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder='Type a skill and press enter...'
          value={value}
          styles={customStyles}
          isDisabled={disabled}
      />
    </>
  );
};
