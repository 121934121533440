import React from 'react';
import emailErrorImage from '../../../../assets/images/graphics/email-error.svg';
import styles from '../../verify_email.module.css';

export const FailureNotice = () => (
  <div
      className={styles.noticeContainer}
  >
    <img
        src={emailErrorImage}
        alt='email-verify'
        width='250px'
    />
    <h1
        className={styles.verificationNoticeHeader}
    >
      Email Verification Failed!
    </h1>
    <p
        className={styles.verificationNoticeInfo}
    >
      Oops! Something has gone wrong.
    </p>
  </div>
);
