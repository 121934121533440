import { JobOpeningData } from '../../../utils/types/DashboardTypes';

export const dummyData: JobOpeningData[] = [
  {
    'id': '1',
    'Job Title': 'Software Engineer',
    'Applicants': 50,
    'Suitable': 20,
    'Outstanding': 5,
    'Shortlist': 10,
    'Unsuccessful': 15,
    'Start Date': '01/04/2024',
    'End Date': '01/05/2024',
  },
  {
    'id': '2',
    'Job Title': 'Data Analyst',
    'Applicants': 40,
    'Suitable': 15,
    'Outstanding': 3,
    'Shortlist': 8,
    'Unsuccessful': 14,
    'Start Date': '15/04/2024',
    'End Date': '15/05/2024',
  },
  {
    'id': '3',
    'Job Title': 'Marketing Manager',
    'Applicants': 60,
    'Suitable': 25,
    'Outstanding': 7,
    'Shortlist': 12,
    'Unsuccessful': 16,
    'Start Date': '01/05/2024',
    'End Date': '01/06/2024',
  },
  {
    'id': '4',
    'Job Title': 'Product Designer',
    'Applicants': 45,
    'Suitable': 18,
    'Outstanding': 4,
    'Shortlist': 9,
    'Unsuccessful': 14,
    'Start Date': '15/05/2024',
    'End Date': '15/06/2024',
  },
  {
    'id': '5',
    'Job Title': 'HR Coordinator',
    'Applicants': 55,
    'Suitable': 22,
    'Outstanding': 6,
    'Shortlist': 11,
    'Unsuccessful': 16,
    'Start Date': '01/06/2024',
    'End Date': '01/07/2024',
  },
];

