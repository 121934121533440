import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getUserRoleList } from '../../api_service/user_roles';
import { UserRole } from '../../types/admin_types';

export const fetchUserRoles = createAsyncThunk('userRoles/fetchUserRoles', async () => {
  try {
    const response = await getUserRoleList();
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      toast.error(errorData.error, { position: 'bottom-center' });
    }
  } catch (error) {
    toast.error('Error fetching roles: ' + error, { position: 'bottom-center' });
  }
});

export interface UserRoleState {
    userRoleList: UserRole[];
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: UserRoleState = {
  userRoleList: [],
  loading: 'idle',
};

const userRoleSlice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {
    addUserRoleRedux: (state, action: PayloadAction<UserRole>) => {
      state.userRoleList.push(action.payload);
    },
    editUserRoleRedux: (state, action: PayloadAction<UserRole>) => {
      const { id } = action.payload;
      const index = state.userRoleList.findIndex((userRole) => userRole.id === id);
      if (index !== -1) {
        state.userRoleList[index] = action.payload;
      }
    },
    deleteUserRoleRedux: (state, action: PayloadAction<string>) => {
      const id= action.payload;
      state.userRoleList = state.userRoleList.filter((userRole) => userRole.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRoles.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUserRoles.fulfilled, (state, action) => {
        state.userRoleList = action.payload;
        state.loading = 'succeeded';
      });
  },
});

export const { addUserRoleRedux, editUserRoleRedux, deleteUserRoleRedux } = userRoleSlice.actions;
export default userRoleSlice.reducer;
