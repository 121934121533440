import React from 'react';

type TableSkeletonLoaderProps = {
  numRows?: number,
  height?: number,
}

const TableSkeletonLoader = ({ numRows = 10, height = 14 }: TableSkeletonLoaderProps) => {
  const renderRows = () => {
    const rows = [];
    const numRows300 = Math.floor(numRows / 3);
    const numRows200 = Math.floor(numRows / 3);
    const numRows100 = numRows - (numRows300 + numRows200);

    for (let i = 0; i < numRows300; i++) {
      rows.push(<div key={`300${i}`} className={`h-${height} bg-gray-300 rounded`}></div>);
    }
    for (let i = 0; i < numRows200; i++) {
      rows.push(<div key={`200${i}`} className={`h-${height} bg-gray-200 rounded`}></div>);
    }
    for (let i = 0; i < numRows100; i++) {
      rows.push(<div key={`100${i}`} className={`h-${height} bg-gray-100 rounded`}></div>);
    }
    return rows;
  };

  return (
    <div className='flex flex-col animate-pulse gap-y-2 pt-4 w-full'>
      { renderRows() }
    </div>
  );
};

export default TableSkeletonLoader;
