import { createRequest } from '../helpers/api_utils';
import { AnalyticsSearchRequest, AssessmentAnalyticsSearchRequest } from '../types/analytics/analytics_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/analytics';
const assessmentApiEndpoint = apiEndpoint + '/assessment';

export function searchAnalytics(searchObject: AnalyticsSearchRequest) {
  return fetch(createRequest(apiEndpoint + '/data/', 'POST', 'application/json', true, searchObject))
    .then(response => {
      return response.json();
    });
}

export function searchAnalyticsAssessment(searchObject: AssessmentAnalyticsSearchRequest) {
  return fetch(createRequest(apiUrl + '/assessments' + '/search', 'POST', 'application/json', true, searchObject))
    .then(response => {
      return response.json();
    });
}

export function searchAssessmentAnalytics(assessmentId: string | undefined, noOfParticipants: number) {
  return fetch(createRequest(assessmentApiEndpoint + `?assessment_id=${assessmentId}&participants=${noOfParticipants}`, 'POST', 'application/json', true))
    .then(response => {
      return response.json();
    });
}
