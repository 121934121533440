import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/subscription';

export function getSubscriptions() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true));
}

export function addSubscription(priceId: string, trial: boolean) {
  const body = {
    priceId: priceId,
    trial: trial,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export function cancelSubscription(featureType: string, cancel: boolean) {
  const body = {
    cancel: cancel,
    featureType: featureType,
  };
  return fetch(createRequest(apiEndpoint + '/cancel', 'POST', 'application/json', true, body));
}

export function getSubscriptionFeatures() {
  return fetch(createRequest(apiEndpoint + '/features', 'GET', 'application/json', true));
}

export function getSubscriptionProducts() {
  return fetch(createRequest(apiEndpoint + '/products', 'GET', 'application/json', true));
}

export function getStripePortalLink() {
  return fetch(createRequest(apiEndpoint + '/portal', 'GET', 'application/json', true));
}
