import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../../ui/button/button';
import { DraggableQuestions } from '../../../../../ui/draggable_questions/draggable_questions';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import { AssessmentContext } from '../../../../../utils/contexts/contexts';
import { RootState } from '../../../../../utils/redux_store/store';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import styles from './create_questions.module.css';

export const CreateQuestions = () => {
  const { assessmentState, setAssessmentState } = useContext(AssessmentContext);
  const skills = useSelector((state: RootState) => state.skills);
  const levels = useSelector((state: RootState) => state.skillLevels);
  const jobTitles = useSelector((state: RootState) => state.jobTitles);
  const subSkills = useSelector((state: RootState) => state.subSkills);
  const [skillList, setSkillList] = useState<SelectOptions[]>(skills.skillsList);

  useEffect(() => {
    setSkillList(skills.skillsList);
  }, [skills.skillsList]);

  const navigateBackToBasicInfo = () => {
    setAssessmentState({
      ...assessmentState,
      assessmentPageNo: 1,
    });
  };

  const navigateToNextPage = () => {
    setAssessmentState({
      ...assessmentState,
      assessmentPageNo: 3,
    });
  };

  return (
    <>
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Create questions
      </PrimaryTypography.FiveXLarge>
      <SecondaryTypography.Medium
          keepDefaultMargins={true}
      >
        You can set up questions for assessment by searching que. from existing question bank / by
        creating new ones / by uploading a file and generating questions.
      </SecondaryTypography.Medium>
      <div
          className={styles.createQuestionsContainer}
      >
        <DraggableQuestions
            assessmentId={assessmentState.assessmentId}
            skillList={skillList}
            levelList={levels.levelList}
            jobTitleList={jobTitles.jobTitlesList}
            subSkillList={subSkills.subSkillsList}
            setSkillList={setSkillList}
        />
        <div
            className={styles.buttonContainer}
        >
          <Button
              onClick={navigateBackToBasicInfo}
              variant='secondary'
              size='medium'
              customClass={styles.bottomButtons}
          >
            Back
          </Button>
          <Button
              onClick={navigateToNextPage}
              variant='primary'
              size='medium'
              customClass={styles.bottomButtons}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
