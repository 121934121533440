import React from 'react';
import inputStyles from '../../../../ui/input/input.module.css';
import styles from './admin_search_bar.module.css';

type AdminSearchBarProps = {
    searchTerm: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export const AdminSearchBar = ({
  searchTerm,
  handleChange,
}: AdminSearchBarProps) => {
  return (
    <div
        className={styles.searchBarContainer}
    >
      <input
          className={inputStyles.inputStyles}
          type='text'
          placeholder='Search'
          value={searchTerm}
          onChange={handleChange}
      />
    </div>
  );
};
