import { UserGroup } from '../../../../utils/types/admin_types';

export const useGroupSearch = (groups: UserGroup[], searchTerm: string, currentPage: number, resultsPerPage: number): {
    paginatedGroups: UserGroup[];
    totalPages: number;
} => {
  const filteredGroups = groups.filter((group) =>
    group.groupName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const totalPages = Math.ceil(filteredGroups.length / resultsPerPage);

  const paginatedGroups = filteredGroups.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage,
  );

  return { paginatedGroups, totalPages };
};
