import React from 'react';
import { toast } from 'react-toastify';
import styles from '../../../pages/questions/upload_file/upload_document_dropbox/upload_document_dropbox.module.css';
import { Button } from '../../button/button';
import { CloseIcon, UploadIcon } from '../../icons/icons';
import { SecondaryTypography } from '../../typography/typography';

type UploadFileProps = {
  uploadedFile: File[] | null,
  setUploadedFile: (file: File[] | null) => void,
  validTypes?: string[],
  isMultiple?: boolean,
  disabled?: boolean,
};

export const UploadFileComponent = ({
  uploadedFile,
  setUploadedFile,
  validTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword', 'video/mp4'],
  isMultiple = false,
  disabled = false,
}: UploadFileProps) => {
  const fileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length) {
      if (!isMultiple && files.length > 1) {
        toast.error('Only one file allowed', { position: 'bottom-center' });
        return;
      }
      const validFiles = Array.from(files).filter(file => validateFile(file));
      if (validFiles.length > 0) {
        handleNewFiles(Array.from(files));
      }
    }
  };

  const validateFile = (file: File) => {
    return validTypes.includes(file.type);
  };

  const preventDefault = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const removeFile = (index: number) => {
    if (uploadedFile && uploadedFile.length > 0) {
      const updatedFile = [...uploadedFile];
      updatedFile.splice(index, 1);
      setUploadedFile(updatedFile);
    }
  };

  const renderUploadedFiles = () => {
    if (!uploadedFile) return null;
    return (
      <div className='flex gap-3 flex-wrap'>
        { uploadedFile.map((file, index) => (
          <div key={index} className='flex gap-2 w-auto bg-gray-200 rounded-md p-1 px-2'>
            <div className='text-sm'>
              { file.name }
            </div>
            <div className='cursor-pointer' onClick={() => removeFile(index)}>
              <CloseIcon />
            </div>
          </div>
        )) }
      </div>
    );
  };

  const addFileViaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length) {
      if (!isMultiple && files.length > 1) {
        toast.error('Only one file allowed', { position: 'bottom-center' });
        return;
      }
      const validFiles = Array.from(files).filter(file => validateFile(file));
      if (validFiles.length > 0) {
        handleNewFiles(Array.from(files));
      }
    }
  };

  const handleNewFiles = (newFiles: File[]) => {
    const validNewFiles = newFiles.filter(validateFile);
    if (validNewFiles.length === 0) {
      toast.error('No valid files selected.', { position: 'bottom-center' });
      return;
    }

    const uniqueNewFiles = validNewFiles.filter((newFile, index, self) =>
      index === self.findIndex(f => f.name === newFile.name && f.size === newFile.size),
    );

    const nonDuplicateFiles = uploadedFile ? uniqueNewFiles.filter(newFile =>
      !uploadedFile.some(existingFile =>
        existingFile.name === newFile.name && existingFile.size === newFile.size,
      ),
    ) : uniqueNewFiles;

    if (!isMultiple) {
      if (uploadedFile && uploadedFile.length > 0) {
        toast.error('Only one file allowed', { position: 'bottom-center' });
        return;
      }
      setUploadedFile([nonDuplicateFiles[0]]);
    } else {
      const updatedFiles = uploadedFile ? [...uploadedFile, ...nonDuplicateFiles] : nonDuplicateFiles;
      setUploadedFile(updatedFiles);
    }
  };

  return (
    <div>
      <input
          type='file'
          onChange={addFileViaInput}
          id='upload-file-input'
          style={{ display: 'none' }}
          multiple={isMultiple}
      />
      <div
          className='flex flex-col items-center w-full p-4 my-4 bg-white border-2 border-dashed border-primary-blue-500 rounded-lg px-10'
          onDragOver={preventDefault}
          onDragEnter={preventDefault}
          onDragLeave={preventDefault}
          onDrop={fileDrop}
      >
        <div className={styles.uploadIcon}>
          <UploadIcon/>
        </div>
        <SecondaryTypography.XSmall>
          Drag & Drop your files here
        </SecondaryTypography.XSmall>
        <Button
            onClick={() => document.getElementById('upload-file-input')?.click()}
            variant='secondary'
            size='xSmall'
            disabled={disabled || (!isMultiple && (uploadedFile ? uploadedFile.length > 0 : false))}
        >
          Browse Files
        </Button>
      </div>
      { renderUploadedFiles() }
    </div>
  );
};
