import axios from 'axios';
import { useState } from 'react';

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export const createMultiPartRequest = (url: string, requestType: RequestType, auth: boolean,  body?: FormData) => {
  const headers = new Headers();
  if (auth) {
    headers.append('Authorization' , `Bearer ${localStorage.getItem('token')}`);
  }

  const options = {
    method: requestType,
    headers: headers,
    body: body ? body : undefined,
  };
  return new Request(url, options);
};

export const createRequest = (url: string, requestType: RequestType, contentType: string, auth: boolean,  body?: Object) => {
  const headers = new Headers();
  headers.append('accept', contentType);
  headers.append('Content-Type', contentType);
  if (auth) {
    headers.append('Authorization' , `Bearer ${localStorage.getItem('token')}`);
  }

  const options = {
    method: requestType,
    headers: headers,
    body: body ? JSON.stringify(body) : undefined,
  };

  return new Request(url, options);
};

export const useUploadForm = (url: string) => {
  const [uploadStatus, setUploadStatus] = useState('initial');
  const [progress, setProgress] = useState(0);

  const uploadForm = async (formData: FormData) => {
    setUploadStatus('loading');
    try {
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.min((progressEvent.loaded / progressEvent.total) * 100, 92);
          setProgress(progress);
        },
      });
      setUploadStatus('success');
      setProgress(100);
    } catch {
      throw new Error();
    } finally {
      setUploadStatus('initial');
    }
  };

  return { uploadForm, uploadStatus, progress };
};
