import { createRequest } from '../helpers/api_utils';
import { UserRole } from '../types/admin_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/user-role';

export function getUserRoleList() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true));
}

export function addToUserRoleList(roleName: string, permissions: string[]) {
  const body = {
    role_id: '',
    role_name: roleName,
    role_desc: '',
    permissions: permissions,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export function editUserRoleList(role: UserRole, roleName: string, permissions: string[]) {
  const body = {
    role_id: role.id,
    role_name: roleName,
    role_desc: role.roleDesc,
    permissions: permissions,
  };
  return fetch(createRequest(apiEndpoint, 'PUT', 'application/json', true, body));
}

export function deleteFromUserRoleList(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'DELETE', 'application/json', true));
}

export function getPermissionEntities() {
  return fetch(createRequest(apiEndpoint + '/permissions', 'GET', 'application/json', true));
}
