import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { ChevronDown, ChevronRight, CreateNode, PencilIcon, TrashIcon } from '../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import styles from './TreeStructure.module.css';

export interface OrganizationUnit {
  id: string;
  unitName: string;
  organizationId: string;
  parentOrganizationUnitId: string;
  children: OrganizationUnit[];
  isDirty?: boolean;
  isDeleted?: boolean;
}

interface FolderProps {
  organizationUnit: OrganizationUnit;
  onDragStart?: (folder: OrganizationUnit) => void;
  onDragEnter?: (folder: OrganizationUnit) => void;
  onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop?: (event: React.DragEvent<HTMLDivElement>, folder: OrganizationUnit) => void;
  onEditClick: (folder: OrganizationUnit) => void;
  onDeleteClick: (folder: OrganizationUnit) => void;
  onAddClick: (folder: OrganizationUnit) => void;
  dragTarget: OrganizationUnit | null;
}

export const TreeStructure: React.FC<FolderProps> = ({
  organizationUnit,
  onDragStart,
  onDragEnter,
  onDragOver,
  onDrop,
  onEditClick,
  onDeleteClick,
  onAddClick,
  dragTarget,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const folderRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (onDragStart) {
      onDragStart(organizationUnit);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(organizationUnit);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event, organizationUnit);
    }
  };

  const onEdit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onEditClick(organizationUnit);
  };

  const onDelete = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onDeleteClick(organizationUnit);
  };

  const onAdd = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onAddClick(organizationUnit);
  };

  const isDragTarget = dragTarget && dragTarget.id === organizationUnit.id;

  return (
    <div>
      <div
          ref={folderRef}
          draggable
          className={classNames(styles.treeItem, { [styles.dropTarget]: isDragTarget })}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
      >
        <div
            className={`${styles.treeRowContainer} hover:bg-gray-200`}
            onClick={handleToggle}
        >
          <div className={styles.expansionIcons}>
            { isExpanded ? <ChevronDown /> : <ChevronRight /> }
            <SecondaryTypography.XSmall fontWeight='semi-bold'>
              { organizationUnit.unitName }
            </SecondaryTypography.XSmall>
          </div>
          <div className={styles.rightIcons}>
            { organizationUnit.parentOrganizationUnitId && (
              <div
                  onClick={(event) => onEdit(event)}
              >
                <PencilIcon />
              </div>
            ) }
            <div
                onClick={(event) => onAdd(event)}
            >
              <CreateNode />
            </div>
            { organizationUnit.parentOrganizationUnitId && (
              <div
                  onClick={(event) => onDelete(event)}
              >
                <TrashIcon />
              </div>
            ) }
          </div>
        </div>
      </div>
      { isExpanded && (
        <div style={{ marginLeft: '20px' }}>
          { organizationUnit.children?.map((child) => (
            <TreeStructure
                key={child.id}
                organizationUnit={child}
                onDragStart={onDragStart}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                onAddClick={onAddClick}
                dragTarget={dragTarget}
            >
              { child.children }
            </TreeStructure>
          )) }
        </div>
      ) }
    </div>
  );
};
