import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getUserAssessments } from '../../../utils/api_service/dashboard';
import SearchBar from '../ui/SearchBar';
import ParticipantCard from './ParticipantCard';

export type ParticipantCardData = {
  id: string,
  assessmentTitle: string,
  isActive: boolean,
  startDate: number,
  endDate: number,
  status: string,
}

const ParticipantDashboard = () => {
  const [userAssessments, setUserAssessments] = useState<ParticipantCardData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    const debounceFetch = setTimeout(() => {
      getUserAssessments(searchTerm)
        .then((response) => {
          if (!response.ok) {
            throw new Error();
          }
          return response.json();
        })
        .then((data) => {
          setUserAssessments(data);
        })
        .catch(() => {
          toast.error('Error Fetching User Assessment Data', { position: 'bottom-center' });
        }).finally(() => {
          setLoading(false);
        });
    }, 1000);
    return () => {
      clearTimeout(debounceFetch);
    };
  }, [searchTerm]);

  return (
    <div className='flex flex-col gap-8'>
      <div
          className='bg-primary-green-500 text-lg sm:text-2xl text-white text-center sm:text-left sm:px-5 p-6 font-semibold'>
        <span className='block sm:inline'>Welcome { localStorage.getItem('firstname') } to </span>
        <span className='text-blue-600'>HiAcuity’s</span> Automated Assessment Centre
      </div>
      <SearchBar setSearchTerm={setSearchTerm}/>
      { loading ? (
        <div className='flex flex-wrap gap-4'>
          { [...Array(6)].map((_, index) => (
            <div key={index} className='h-60 w-full lg:basis-1/2-gap-4 xl:basis-1/3-gap-4 gap-4 animate-pulse bg-gray-300 rounded-md'></div>
          )) }
        </div>
      ) : (
        <div className='flex flex-wrap gap-4'>
          { userAssessments.map((assessment, index) => (
            <div key={index} className='w-full lg:basis-1/2-gap-4 xl:basis-1/3-gap-4 gap-4 '>
              <ParticipantCard userAssessment={assessment}/>
            </div>
          )) }
        </div>
      ) }
    </div>
  );
};

export default ParticipantDashboard;
