import classNames from 'classnames';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button } from '../../../ui/button/button';
import { TextInput } from '../../../ui/input/text_input/text_input';
import styles from '../login_register.module.css';

type ResetPasswordProps = {
  handleSubmit: (event: FormEvent<HTMLFormElement>, email: string) => void,
}

export const ResetPassword = ({ handleSubmit }: ResetPasswordProps) => {
  const [email, setEmail] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <form
          className={classNames(styles.form, styles.marginClass)}
          onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event, email)}
          data-testid='login-form'
      >
        <TextInput
            type='email'
            label='Email'
            name='email'
            data-testid='email'
            value={email}
            onChange={handleChange}
            required={true}
        />
        <Button
            onClick={() => {}}
            size='medium'
            variant='primary'
            customClass={styles.submitButton}
        >
          Send Reset Mail
        </Button>
      </form>
    </div>
  );
};
