import React, { useMemo, useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { UserGroup, UserRole } from '../../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import styles from '../../modals.module.css';

type InviteUserModalProps = {
    userGroupList: UserGroup[];
    userRoleList: UserRole[],
    inviteUser: (email: string, firstname: string, lastname: string, groups: SelectOptions[], roles: SelectOptions[]) => void,
}

export const InviteUserModal = ({
  userGroupList,
  userRoleList,
  inviteUser,
}: InviteUserModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [selectedGroups, setSelectedGroups] = useState<SelectOptions[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<SelectOptions[]>([]);

  const groupOptions = useMemo(() =>
    userGroupList.map((group) => ({
      value: group.id,
      label: group.groupName,
    })),
  [userGroupList],
  );

  const roleOptions = useMemo(() =>
    userRoleList.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  [userRoleList],
  );

  const handleGroupChange = (selectedOptions: any) => {
    setSelectedGroups(selectedOptions);
  };

  const handleRoleChange = (selectedOptions: any) => {
    setSelectedRoles(selectedOptions);
  };

  return (
    <>
      <div
          className={styles.modalInputContainer}
      >
        <TextInput
            label='Email'
            name='email'
            value={email}
            type='text'
            onChange={(event) => setEmail(event.target.value)}
        />
        <TextInput
            label='Firstname'
            name='firstname'
            value={firstname}
            type='text'
            onChange={(event) => setFirstname(event.target.value)}
        />
        <TextInput
            label='Lastname'
            name='lastname'
            value={lastname}
            type='text'
            onChange={(event) => setLastname(event.target.value)}
        />
        <CustomSelect
            name='groups'
            options={groupOptions}
            onChange={handleGroupChange}
            isMulti={true}
            label={'Group Membership'}
            value={selectedGroups}
        />
        <CustomSelect
            name='roles'
            options={roleOptions}
            onChange={handleRoleChange}
            isMulti={true}
            label={'Role Membership'}
            value={selectedRoles}
        />
      </div>
      <div>
        <Button
            onClick={() => inviteUser(email, firstname, lastname, selectedGroups, selectedRoles)}
            variant={'primary'}
            size={'medium'}
        >
          Invite
        </Button>
      </div>
    </>
  );
};
