import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { Modal } from '../../../../../ui/modal/modal';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { addOrganizationUnit, getOrganizationTree, updateOrganizationTree } from '../../../../../utils/api_service/organization_unit';
import commonStyles from '../../../admin.module.css';
import { CreateUnitModal } from '../../Modals/CreateUnitModal';
import { DeleteUnitModal } from '../../Modals/DeleteUnitModal';
import { EditNameModal } from '../../Modals/EditNameModal';
import { OrganizationUnit, TreeStructure } from '../../TreeStructure';
import styles from './OrganizationHierarchy.module.css';

export const OrganizationHierarchy = () => {
  const [organizationTree, setOrganizationTree] = useState<OrganizationUnit>();
  const [selectedUnit, setSelectedUnit] = useState<OrganizationUnit | null>(null);
  const [targetUnit, setTargetUnit] = useState<OrganizationUnit | null>(null);
  const [editNameModal, setEditNameModal] = useState<boolean>(false);
  const [addUnitModal, setAddUnitModal] = useState<boolean>(false);
  const [deleteUnitModal, setDeleteUnitModal] = useState<boolean>(false);
  const [selectedOrganizationUnit, setSelectedOrganizationUnit] = useState<OrganizationUnit | undefined>();
  const [editedOrgUnits, setEditedOrgUnits] = useState<OrganizationUnit[]>();

  useEffect(() => {
    getOrganizationTree().then(response => {
      setOrganizationTree(response);
    });
  }, []);

  const applyTreeChanges = () => {
    if (editedOrgUnits && editedOrgUnits.length > 0) {
      updateOrganizationTree(editedOrgUnits).then(response => {
        if (response.id) {
          setEditedOrgUnits(undefined);
        }
      });
    }
  };

  const handleDragStart = (folder: OrganizationUnit) => {
    setSelectedUnit(folder);
  };

  const handleDragEnter = (folder: OrganizationUnit) => {
    setTargetUnit(folder);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, folder: OrganizationUnit) => {
    event.preventDefault();
    if (selectedUnit && targetUnit && organizationTree && selectedUnit.parentOrganizationUnitId.length !== 0 &&
      selectedUnit.parentOrganizationUnitId !== targetUnit.id && selectedUnit.id !== targetUnit.id) {
      const updatedFolderData = moveUnit(organizationTree, selectedUnit, targetUnit);
      setOrganizationTree(updatedFolderData);
    }
    setSelectedUnit(null);
    setTargetUnit(null);
  };

  const editOrganizationUnit = (targetUnit: OrganizationUnit, updatedObject: Partial<OrganizationUnit>): void => {
    setEditedOrgUnits((prevEditedOrgUnits) => {
      if (!prevEditedOrgUnits) {
        return [{ ...targetUnit, ...updatedObject }];
      }

      const updatedUnits = prevEditedOrgUnits.map((unit) => {
        if (unit.id === targetUnit.id) {
          return { ...unit, ...updatedObject };
        }
        return unit;
      });

      if (!isOrganizationUnitIncluded(targetUnit, prevEditedOrgUnits)) {
        updatedUnits.push({ ...targetUnit, ...updatedObject });
      }

      return updatedUnits;
    });
  };

  const isOrganizationUnitIncluded = (targetUnit: OrganizationUnit, units: OrganizationUnit[]): boolean => {
    return units.some((unit) => unit.id === targetUnit.id);
  };

  const onEditClick = (selectedOrgUnit: OrganizationUnit) => {
    setSelectedOrganizationUnit(selectedOrgUnit);
    setEditNameModal(true);
  };

  const onAddUnitClick = (selectedOrgUnit: OrganizationUnit) => {
    setSelectedOrganizationUnit(selectedOrgUnit);
    setAddUnitModal(true);
  };

  const onDeleteClick = (selectedOrgUnit: OrganizationUnit) => {
    setSelectedOrganizationUnit(selectedOrgUnit);
    setDeleteUnitModal(true);
  };

  const addNewUnit = (unitName: string) => {
    if (selectedOrganizationUnit) {
      addOrganizationUnit(unitName, selectedOrganizationUnit.id).then(response => {
        if (response.id) {
          let newUnit = response;
          newUnit = { ...newUnit, children: [] };
          setOrganizationTree((prevOrganizationTree) => {
            if (prevOrganizationTree) {
              if (selectedOrganizationUnit.parentOrganizationUnitId.length !== 0) {
                return {
                  ...prevOrganizationTree,
                  children: addNewUnitToSelectedUnit(prevOrganizationTree.children, newUnit),
                };
              } else {
                return {
                  ...prevOrganizationTree,
                  children: [...prevOrganizationTree.children, newUnit],
                };
              }
            }
            return prevOrganizationTree;
          });
        } else {
          toast.error(response.errors, { position: 'bottom-center' });
        }
      });
      setAddUnitModal(false);
    }
  };

  const deleteUnit = () => {
    if (selectedOrganizationUnit) {
      setOrganizationTree((organizationTree) => {
        if (organizationTree) {
          return {
            ...organizationTree,
            children: deleteOrganizationUnitById(organizationTree.children, selectedOrganizationUnit.id),
          };
        }
        return organizationTree;
      });
      editOrganizationUnit(selectedOrganizationUnit, { isDirty: true, isDeleted: true });
      setDeleteUnitModal(false);
    }
  };

  const editName = (unitName: string) => {
    if (selectedOrganizationUnit) {
      setOrganizationTree((organizationTree) => {
        if (organizationTree) {
          return {
            ...organizationTree,
            children: editDataStructure(organizationTree.children, selectedOrganizationUnit.id, { unitName: unitName }),
          };
        }
        return organizationTree;
      });
      if (editedOrgUnits && isOrganizationUnitIncluded(selectedOrganizationUnit, editedOrgUnits)) {
        editOrganizationUnit(selectedOrganizationUnit, { unitName: unitName });
      } else {
        editOrganizationUnit(selectedOrganizationUnit, { unitName: unitName, isDirty: true, isDeleted: false });
      }
      setEditNameModal(false);
    }
  };

  const moveUnit = (
    currentUnitData: OrganizationUnit,
    selectedUnit: OrganizationUnit,
    targetUnit: OrganizationUnit,
  ): OrganizationUnit => {
    const updatedSelectedUnit = {
      ...selectedUnit,
      parentOrganizationUnitId: targetUnit.id,
    };

    if (editedOrgUnits && isOrganizationUnitIncluded(selectedUnit, editedOrgUnits)) {
      editOrganizationUnit(selectedUnit, { parentOrganizationUnitId: targetUnit.id });
    } else {
      editOrganizationUnit(updatedSelectedUnit, { isDirty: true, isDeleted: false });
    }

    if (currentUnitData.id === selectedUnit.id) {
      return {
        ...currentUnitData,
        children: currentUnitData.children?.filter((child) => child.id !== selectedUnit.id),
      };
    }

    const updatedChildren = currentUnitData.children?.map((child) =>
      moveUnit(child, selectedUnit, targetUnit),
    );

    if (currentUnitData.id === targetUnit.id) {
      return {
        ...currentUnitData,
        children: [...(updatedChildren || []), updatedSelectedUnit],
      };
    }

    if (updatedChildren !== currentUnitData.children) {
      return {
        ...currentUnitData,
        children: updatedChildren?.filter((child) => child.id !== selectedUnit.id),
      };
    }

    return currentUnitData;
  };

  const addNewUnitToSelectedUnit = (units: OrganizationUnit[], newUnit: OrganizationUnit): OrganizationUnit[] => {
    return units.map((unit) => {
      if (selectedOrganizationUnit && unit.id === selectedOrganizationUnit.id) {
        return {
          ...unit,
          children: [...unit.children, newUnit],
        };
      } else if (unit.children.length > 0) {
        return {
          ...unit,
          children: addNewUnitToSelectedUnit(unit.children, newUnit),
        };
      }
      return unit;
    });
  };

  const editDataStructure = (
    data: OrganizationUnit[],
    objectId: string,
    updatedObject: Partial<OrganizationUnit>,
  ): OrganizationUnit[] => {
    return data.map((unit) => {
      if (unit.id === objectId) {
        return {
          ...unit,
          ...updatedObject,
        };
      }

      if (unit.children && unit.children.length > 0) {
        return {
          ...unit,
          children: editDataStructure(unit.children, objectId, updatedObject),
        };
      }

      return unit;
    });
  };

  const deleteOrganizationUnitById = (
    data: OrganizationUnit[],
    targetId: string,
  ): OrganizationUnit[] => {
    return data.filter((unit) => {
      if (unit.id === targetId) {
        return false;
      } else if (unit.children && unit.children.length > 0) {
        unit.children = deleteOrganizationUnitById(unit.children, targetId);
      }
      return true;
    });
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <div
          className={styles.treeStructureContainer}
      >
        { organizationTree &&
          <TreeStructure
              organizationUnit={organizationTree}
              onDragStart={handleDragStart}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onAddClick={onAddUnitClick}
              dragTarget={targetUnit}
          />
        }
      </div>
      { editedOrgUnits && editedOrgUnits.length > 0 &&
        <div
            className={styles.changesDetected}
        >
          <SecondaryTypography.XSmall>
            Changes detected within the tree.
          </SecondaryTypography.XSmall>
          <Button
              onClick={applyTreeChanges}
              variant={'primary'}
              size={'medium'}
          >
            Apply
          </Button>
        </div>
      }
      <Modal
          visible={addUnitModal}
          modalContent={
            <CreateUnitModal
                addUnit={addNewUnit}
            />
          }
          toggle={() => setAddUnitModal(false)}
          headerText='Create Organization Unit'
          headerClass={commonStyles.modalHeader}
          customClass={commonStyles.organizationSettingsModal}
      />
      <Modal
          visible={editNameModal}
          modalContent={
            <EditNameModal
                organizationUnit={selectedOrganizationUnit}
                editName={editName}
            />
          }
          toggle={() => setEditNameModal(false)}
          headerText='Edit Name'
          headerClass={commonStyles.modalHeader}
          customClass={commonStyles.organizationSettingsModal}
      />
      <Modal
          visible={deleteUnitModal}
          modalContent={
            <DeleteUnitModal
                deleteUnit={deleteUnit}
                cancelEvent={() => setDeleteUnitModal(false)}
            />
          }
          toggleable={false}
          headerText='Delete Selected Unit and all Branches'
          headerClass={commonStyles.modalHeader}
          customClass={commonStyles.organizationSettingsModal}
      />
    </div>
  );
};

export default OrganizationHierarchy;
