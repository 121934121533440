import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../ui/button/button';
import { SecondaryTypography } from '../../ui/typography/typography';
import { DashletTitles } from '../../utils/analytics_data/chartData';
import { updateDashletOrder } from '../../utils/api_service/organization_users';
import { ChartOrderItem } from '../../utils/types/analytics/analytics_types';
import { CloseIcon, PencilIcon } from '../icons/icons';
import { AssessmentValueDashlet } from './AssessmentValueDashlet';
import { ColumnChartAssessmentDashlet } from './ColumnChartAssessmentDashlet';
import { ColumnChartBlue } from './ColumnChartBlue';
import { ColumnChartDashlet } from './ColumnChartDashlet';
import { EmotionDashlet } from './EmotionDashlet';
import { LineChartDashlet } from './LineChartDashlet';
import { NumericalDashlet } from './NumericalDashlet';
import { PieChartDashlet } from './PieChartDashlet';
import { TableDashlet } from './TableDashlet';
type AnalyticsChartsProps = {
  searchResult: any | undefined,
  chartOrder: ChartOrderItem[];
  setChartOrder: (chartOrder: ChartOrderItem[]) => void;
  searchResultChartOrder?: ChartOrderItem[] | undefined,
  type: string
}

const moveOrder = (chartOrder: Array<ChartOrderItem>, selectedDashlet: ChartOrderItem, targetDashlet: ChartOrderItem): Array<ChartOrderItem> => {
  const updatedOrder: Array<ChartOrderItem> = [];
  for (let i = 0; i < chartOrder.length; i++) {
    const dashlet = chartOrder[i];

    if (dashlet.chartId === selectedDashlet.chartId) {
      continue;
    }

    if(dashlet.chartId === targetDashlet.chartId) {
      const selectedDashletIndex = chartOrder.findIndex(item => item.chartId === selectedDashlet.chartId);
      if (selectedDashletIndex < i) {
        updatedOrder.push(dashlet, selectedDashlet);
      } else {
        updatedOrder.push(selectedDashlet, dashlet);
      }
      continue;
    }

    updatedOrder.push(dashlet);
  }
  return updatedOrder;
};

export const AnalyticsCharts = ({ searchResult, chartOrder, setChartOrder, searchResultChartOrder, type } : AnalyticsChartsProps) => {
  const [changesDetected, setChangesDetected] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedDashlet, setSelectedDashlet] = useState<ChartOrderItem | null>(null);
  const [targetDashlet, setTargetDashlet] = useState<ChartOrderItem | null>(null);
  const [fullScreenDetected, setFullScreenDetected] = useState<boolean>(false);

  const componentMap: any = {
    'ParticipantsInvited': NumericalDashlet,
    'HoursSavedTotal': NumericalDashlet,
    'HoursSavedEmployees': NumericalDashlet,
    'HoursSavedJobCandidates': NumericalDashlet,
    'HoursSavedPartners': NumericalDashlet,
    'MoneySavedTotal': NumericalDashlet,
    'MoneySavedEmployees': NumericalDashlet,
    'MoneySavedJobCandidates': NumericalDashlet,
    'MoneySavedPartners': NumericalDashlet,
    'HoursSaved': ColumnChartDashlet,
    'Assessments': ColumnChartDashlet,
    'AssessmentSessions': ColumnChartDashlet,
    'AssessmentType': PieChartDashlet,
    'TopJobTitle': ColumnChartDashlet,
    'TopSkill': PieChartDashlet,
    'AssessmentSessionsTimeBased': LineChartDashlet,
    'HoursSavedTimeBased': LineChartDashlet,
  };

  const componentAssessmentMap: any = {
    'AssessmentStatus': AssessmentValueDashlet,
    'ParticipantsInvited': AssessmentValueDashlet,
    'ParticipantsQuickestTime': AssessmentValueDashlet,
    'ParticipantCheatRisk': AssessmentValueDashlet,
    'ParticipantsTopScore': AssessmentValueDashlet,
    'AverageResultSkill': ColumnChartDashlet,
    'CountSkills': PieChartDashlet,
    'AverageSpeedSkill': ColumnChartAssessmentDashlet,
    'Top10Participants': TableDashlet,
    'DominantEmotion': EmotionDashlet,
    'SessionDistribution': ColumnChartBlue,
  };

  const handleDragStart = (item: ChartOrderItem) => {
    setSelectedDashlet(item);
  };

  const handleDragEnter = (item: ChartOrderItem) => {
    setTargetDashlet(item);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (selectedDashlet && targetDashlet && selectedDashlet.chartId !== targetDashlet.chartId) {
      const updatedOrder = moveOrder(chartOrder, selectedDashlet, targetDashlet);
      if(updatedOrder !== chartOrder) {
        setChartOrder(updatedOrder);
        setChangesDetected(true);
      }
    }
    setSelectedDashlet(null);
    setTargetDashlet(null);
  };

  const handleDashletVisibility = (editedItem: ChartOrderItem) => {
    const editedOrder = chartOrder.map(item => {
      if (item.chartId === editedItem.chartId) {
        return editedItem;
      }
      return item;
    });
    setChartOrder(editedOrder);
    setChangesDetected(true);
  };

  const applyDashletChanges = async () => {
    setEditMode(false);
    if (changesDetected) {
      // TODO: Change after implementing backend
      try {
        const response = await updateDashletOrder(chartOrder, type);
        if (response.ok) {
          setChangesDetected(false);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error applying changes: ' + error, { position: 'bottom-center' });
      }
    }
  };

  useEffect(() => {
    const exitHandler = () => {
      if (!document.fullscreenElement) {
        setFullScreenDetected(false);
      }
    };

    if (document.addEventListener) {
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
    }

    return () => {
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);
    };
  }, []);

  return (
    <>
      { changesDetected &&
        <div
            className='flex justify-end place-items-center'
        >
          <SecondaryTypography.XSmall>
            Changes detected in the dashlet order.
          </SecondaryTypography.XSmall>
          <Button
              onClick={applyDashletChanges}
              variant={'primary'}
              size={'medium'}
          >
            Apply
          </Button>
        </div>
      }
      <div className={changesDetected ? 'mt-6' : 'mt-10'}>
        { editMode ?
          (
            <div className='mb-6 rounded-full text-gray-700 flex justify-end cursor-pointer'
                onClick={() => {
                  setEditMode(false);
                  if (changesDetected) {
                    setChangesDetected(false);
                    window.location.reload();
                  }
                }}
            >
              <CloseIcon/>
            </div>
          ) :
          (
            <div className='mb-6 rounded-full text-lime-500 flex justify-end'>
              <button className='cursor-pointer' onClick={() => setEditMode(true)}>
                <PencilIcon/>
              </button>
            </div>
          )
        }
        { searchResult && searchResultChartOrder ?
          <div className='grid sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6 gap-4 place-items-start'>
            { searchResultChartOrder.map(item => {
              const id = item.chartId;
              const DashletItem = type==='assessment'? componentAssessmentMap[id] : componentMap[id];
              const returnElement = <DashletItem
                  key={id}
                  id={id}
                  data={searchResult[id]}
                  title={DashletTitles[id]}
                  dashletItem={item}
                  onDragStart={handleDragStart}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  fullScreenDetected={fullScreenDetected}
                  setFullScreenDetected={setFullScreenDetected}
                  handleDashletVisibility={handleDashletVisibility}
                  editMode={editMode}
                  {...searchResult[id]}/>;
              if (editMode) {
                return returnElement;
              } else if (!editMode && !item.hidden) {
                return returnElement;
              }
            }) }
          </div>
          :
          <div className='text-center'>
            No Data - Check Filters
          </div>
        }
      </div>
    </>
  );
};
