import { createRequest } from '../helpers/api_utils';
import { apiUrl, faceUrl } from './api_url';

const apiEndpoint = apiUrl + '/storage';
const faceApiEndpoint = faceUrl + '/detect-face';

export function getStorageLink(filename: string, folder: string) {
  const body = {
    file_name: filename,
    folder: folder,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body))
    .then(response => {
      return response.json();
    });
}

export function detectFace(imageBase64: string) {
  const body = {
    imageBase64: imageBase64,
  };
  return fetch(createRequest(faceApiEndpoint, 'POST', 'application/json', true, body));
}

export function awsUpload(url: string, image: Blob) {
  const response = fetch(url, {
    method: 'PUT',
    body: image,
  });
  return response;
}
