import { toast } from 'react-toastify';
import { searchJobTitleList } from '../../api_service/job_title';
import { JobTitle } from '../../types/admin_types';
import { debounce } from '../debounce';

const mapJobTitleToSelectOption = (jobTitle: JobTitle) => ({
  value: jobTitle.id,
  label: jobTitle.jobTitle,
});

const useDebouncedJobTitleSearch = () => {
  const fetchJobTitleList = async (query: string) => {
    try {
      const data = await searchJobTitleList(query);
      return data.map(mapJobTitleToSelectOption);
    } catch (error) {
      toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
      return [];
    }
  };

  const loadOptionsDebounced = debounce(
    async (inputValue: string, callback: (options: any) => void) => {
      const options = await fetchJobTitleList(inputValue);
      callback(options);
    },
    500,
  );

  return { loadOptionsDebounced };
};

export default useDebouncedJobTitleSearch;
