import { createRequest } from '../helpers/api_utils';
import { ChartOrderItem } from '../types/analytics/analytics_types';
import { QueryFilter } from '../types/assessment_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/organization-user';

export function getOrganizationUserList() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true));
}

export function addOrganizationUser(email: string, firstname: string, lastname: string, groups: string[], roles: string[]) {
  const body = {
    email: email,
    first_name: firstname,
    last_name: lastname,
    roles: roles,
    groups: groups,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export function editOrganizationUser(id: string, groups: string[], roles: string[]) {
  const body = {
    id: id,
    roles: roles,
    groups: groups,
  };

  return fetch(createRequest(apiEndpoint, 'PUT', 'application/json', true, body));
}

export function deleteOrganizationUser(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'DELETE', 'application/json', true));
}

export function getDashletOrder(type: string) {
  return fetch(createRequest(apiEndpoint + `/user_preferences/?chartType=${type}`, 'GET', 'application/json', true))
    .then(response => {
      if(!response.ok) {
        throw new Error();
      }
      return response.json();
    });
}

export function updateDashletOrder(updatedOrder: ChartOrderItem[], type: string) {
  let body = {
    chartType: type,
    chartOrder: updatedOrder,
  };
  return fetch(createRequest(apiEndpoint + '/user_preferences/', 'POST', 'application/json', true, body));
}

export function searchOrganizationUsers(query: QueryFilter) {
  return fetch(createRequest(apiEndpoint + '/search', 'POST', 'application/json', true, query));
}
