import classNames from 'classnames';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TextInput } from '../../../../ui/input/text_input/text_input';
import { ToggleInput } from '../../../../ui/input/toggle_input/toggle_input';
import { PrimaryTypography, SecondaryTypography } from '../../../../ui/typography/typography';
import commonStyles from '../ContextSection/upload-file.module.css';

type SessionTitleProps = {
  sessionTitle: string,
  defaultValue: string,
  setSessionTitle: Dispatch<SetStateAction<string>>,
  disabled?: boolean
}

export const SessionTitle = ({
  sessionTitle,
  defaultValue,
  setSessionTitle,
  disabled,
}: SessionTitleProps) => {
  const [active, setActive] = useState<boolean>(true);

  useEffect(() => {
    if (active){
      setSessionTitle(defaultValue);
    }
  }, [active, defaultValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(!active){
      setSessionTitle(event.target.value);
    }
  };

  const setActiveStatus = () => {
    setActive(!active);
    if (!active) {
      setSessionTitle(defaultValue);
    }
  };

  return (
    <div>
      <PrimaryTypography.TwoXLarge
          className={classNames(commonStyles.headerText, commonStyles.blueText)}
          fontWeight='bold'
      >
        Session Title
      </PrimaryTypography.TwoXLarge>
      <div className='flex gap-4 items-center'>
        <div className='w-1/2'>
          <TextInput
              name='topic'
              value={sessionTitle || ''}
              type='text'
              onChange={handleInputChange}
              placeholder='Add Session Title Here...'
              disabled={active && !disabled}
          />
        </div>
        <div className='flex flex-col'>
          <div>
            <ToggleInput
                toggled={active}
                handleToggle={setActiveStatus}
                name='default'
            />
            <div>
              <SecondaryTypography.XSmall>
                AutoGenerate Title
              </SecondaryTypography.XSmall>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
