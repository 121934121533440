import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationNavigator } from '../../../ui/PaginationNavigator';
import TableSkeletonLoader from '../../../ui/TableSkeletonLoader';
import { getDashboardAssessments } from '../../../utils/api_service/dashboard';
import { AssessmentData } from '../../../utils/types/DashboardTypes';
import SearchBar from '../ui/SearchBar';

export type TableColumn = {
  title: string;
  width: string;
};

export type DashboardAssessmentData = {
  assessments: AssessmentData[],
  totalAssessments: number,
}

const DashboardAssessmentTable = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const columns: TableColumn[] = [{ title: 'Assessment Title', width: 'w-1/12' }, { title: 'Assigned', width: 'w-1/12' }, { title: 'Completed', width: 'w-1/12' }, { title: 'Expired', width: 'w-1/12' }, { title: 'Shortlisted', width: 'w-1/12' }, { title: 'Unsuccessful', width: 'w-1/12' }, { title: 'Start Date', width: 'w-1/12' } , { title: 'End Date', width: 'w-1/12' }  ];
  const [data, setData] = useState<AssessmentData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    const debounceSearchTerm = setTimeout(() => {
      getDashboardAssessments(searchTerm, pageNumber)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch assessments');
          }
        })
        .then((data: DashboardAssessmentData) => {
          setTotalPages(Math.ceil(data.totalAssessments / 5));
          setData(data.assessments);
        })
        .catch(() => {
          toast.error('Error Fetching Assessment Data', { position: 'bottom-center' });
        }).finally(() => setLoading(false));
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchTerm, pageNumber]);

  const handleOnClick = (id : string) => {
    history.push(`/view-assessment/${id}`);
  };

  return (
    <div className='flex flex-col'>
      <div className='flex items-center border-b border-gray-200 pb-4'>
        <SearchBar setSearchTerm={setSearchTerm}/>
      </div>
      <table className='w-full'>
        <thead>
          <tr>
            { columns.map((column, index) => (
              <th key={index} className={`border-b-2 px-4 py-3 ${column.width} font-semibold text-gray-500`}>
                <div className='flex justify-start'>
                  { column.title }
                </div>
              </th>
            )) }
          </tr>
        </thead>
        { !loading && (
          <tbody>
            { data.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => handleOnClick(row['id'])} className='cursor-pointer hover:bg-gray-100'>
                { columns.map((column, colIndex) => (
                  <React.Fragment key={colIndex}>
                    <td className={`border-b-2 px-4 py-3 ${column.width}`}>
                      <div className='flex justify-start text-left'>
                        { row[column.title as keyof AssessmentData] }
                      </div>
                    </td>
                  </React.Fragment>
                )) }
              </tr>
            )) }
          </tbody>
        ) }
      </table>
      { loading && <TableSkeletonLoader numRows={5} height={12}/> }
      { (data.length > 0) && (
        <div className='ml-auto mr-auto mt-5'>
          <PaginationNavigator
              pageNumber={pageNumber}
              maxPageNumber={totalPages}
              setPageNumber={setPageNumber}
          />
        </div>
      ) }
    </div>
  );
};

export default DashboardAssessmentTable;
