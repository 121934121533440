import classNames from 'classnames';
import React from 'react';
import { PencilIcon, TrashIcon } from '../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import { UserRole } from '../../../../../utils/types/admin_types';
import rowStyles from '../../row.module.css';

type RoleRowProp = {
    role: UserRole,
    deleteRole: (id: string) => void,
  onEditClick: (role: UserRole) => void,
}

export const RoleRow = ({
  role,
  deleteRole,
  onEditClick,
}: RoleRowProp) => {
  return (
    <tr
        className={rowStyles.tableRowStyle}
    >
      <td>
        <input type='checkbox'/>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.leftBorder)}
      >
        <SecondaryTypography.XSmall>
          { role.roleName }
        </SecondaryTypography.XSmall>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.rightBorder)}
      >
        <div
            className={rowStyles.iconContainer}
        >
          <PermissionGate
              requiredPermissions={[Permission.ROLES_EDIT]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.editIcon, rowStyles.clickable)}
                onClick={() => onEditClick(role)}
            >
              <PencilIcon/>
            </div>
          </PermissionGate>
          <PermissionGate
              requiredPermissions={[Permission.ROLES_DELETE]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.trashIcon, rowStyles.clickable)}
                onClick={() => deleteRole(role.id)}
            >
              <TrashIcon/>
            </div>
          </PermissionGate>
        </div>
      </td>
    </tr>
  );
};
