import React, { useState } from 'react';
import { Button } from '../../../../ui/button/button';
import { TextInput } from '../../../../ui/input/text_input/text_input';
import styles from './Modals.module.css';

type CreateUnitModalProps = {
   addUnit: (unitName: string) => void,
}

export const CreateUnitModal = ({
  addUnit,
}: CreateUnitModalProps) => {
  const [unitName, setUnitName] = useState<string>('');
  return (
    <div
        className={styles.inputGap}
    >
      <TextInput
          name='unitName'
          value={unitName}
          type='text'
          label='Unit Name'
          onChange={(event) => setUnitName(event.target.value)}
      />
      <div>
        <Button
            onClick={() => addUnit(unitName)}
            variant={'primary'}
            size={'medium'}
            disabled={unitName.length === 0}
        >
          Add New Unit
        </Button>
      </div>
    </div>
  );
};
