import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid,  ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartOrderItem, DataType, SessionDistributionType } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { MediumColors } from './Colors';
import { DashletIconGroup } from './DashletIconGroup';

type ColumnChartBlueProps = {
    id:string,
    data: Array<DataType>,
    title: string,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void,
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}
export const ColumnChartBlue = ({ id, data, title, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, fullScreenDetected, setFullScreenDetected, handleDashletVisibility, editMode }: ColumnChartBlueProps) => {
  const [dataVals, setDataVals] = useState<{[key:string]: any}[]>([]);

  useEffect(() => {
    formatData(data);
  }, [data, title]);

  const formatData = (data: any) => {
    if (data !== undefined) {
      const val = data.map((item: SessionDistributionType) => ({
        name: `${item.day}-${new Date(item.year, item.month - 1, item.day).toLocaleString('default', { month: 'short' })}-${item.year}`,
        value: item.count,
      }));
      setDataVals(val);
    }
  };

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  return (
    <div className='group relative w-full col-span-2 rounded border border-gray-200 shadow h-full p-2 overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={classNames(styles.dataContainer, dashletItem.hidden ? 'opacity-40': '')}
      >
        <div className='font-bold text-sm mb-4 text-center'>{ title }</div>
        <div className='flex justify-center'>
          { (!data || data.length === 0) ?
            <div className={'text-md text-gray-700 flex place-content-center place-items-center h-[300px]'}>No data to preview</div> :
            <ResponsiveContainer className='w-full h-full' height={fullScreenDetected? 500 : 300}>
              <BarChart
                  data={dataVals}
                  margin={{ top: 10, right: 30, left: 20, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' scale='auto' angle={-45} textAnchor='end' interval={0} fontSize={10}  />
                <YAxis/>
                <Tooltip />
                <Bar dataKey='value' fill={MediumColors.BLUE} />
              </BarChart>
            </ResponsiveContainer>
          }
        </div>
      </div>
    </div>
  );
};
