import { createRequest } from '../helpers/api_utils';
import { SingleEmployee } from '../types/admin_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/employee';

export function getEmployeeList(searchTerm?: string, page?: number, recordsPerPage?: number) {
  let url: string = apiEndpoint;
  if (searchTerm !== undefined && page && recordsPerPage) {
    url = apiEndpoint + `?name=${searchTerm}&page=${page}&limit=${recordsPerPage}`;
  }
  return fetch(createRequest(url, 'GET', 'application/json', true));
}

export function getEmployee(orgUserId: string): Promise<SingleEmployee> {
  return fetch(createRequest(apiEndpoint + `?employee_id=${orgUserId}`, 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Response not ok');
      }
    });
}

export function createEmployee(email: string, firstname: string, lastname: string, reportingManager: string | undefined, organizationUnitId: string, jobTitleId: string) {
  let body = {
    email: email,
    firstName: firstname,
    lastName: lastname,
    reportingManager: reportingManager,
    organizationUnitId: organizationUnitId,
    jobTitleId: jobTitleId,
  };

  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export function editEmployee(roles: string[], groups: string[], organizationUserId: string, reportingManager: string | undefined, organizationUnitId: string, jobTitleId: string) {
  let body = {
    roles: roles,
    groups: groups,
    organizationUserId: organizationUserId,
    reportingManager: reportingManager,
    organizationUnitId: organizationUnitId,
    jobTitleId: jobTitleId,
  };

  return fetch(createRequest(apiEndpoint, 'PUT', 'application/json', true, body));
}

export function deleteEmployee(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'DELETE', 'application/json', true));
}

export function addEmployeesWithCsv(csvFile: File) {
  let formData = new FormData();
  formData.append('file', csvFile);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  };

  return fetch(apiEndpoint + '/csv', requestOptions);
}
