import classNames from 'classnames';
import React, { useState } from 'react';
import { CheckCircle, ChevronDown, ChevronUp, XCircle } from '../../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { getDownloadLink } from '../../../../../../utils/api_service/assessment_api';
import { convertSecondsToTimeTaken } from '../../../../../../utils/helpers/time';
import { AssessmentQuestionResult } from '../../../../../../utils/types/assessment_types';
import styles from './collapsible_result.module.css';
import { ResultAnswer } from './result_answer/result_answer';

type CollapsibleResultProps = {
    assessmentQuestionResult: AssessmentQuestionResult,
    updateDetailedQuestionArray: (questionArray: AssessmentQuestionResult, getLinkStatus?: boolean) => void,
}

export const CollapsibleResult = ({
  assessmentQuestionResult,
  updateDetailedQuestionArray,
}: CollapsibleResultProps) => {
  const [resultOpen, setResultOpen] = useState<boolean>(false);

  const getVideoLink = () => {
    if (!assessmentQuestionResult.videoUrl) {
      getDownloadLink(assessmentQuestionResult.id).then(response => {
        assessmentQuestionResult.videoUrl = response.url;
        updateDetailedQuestionArray(assessmentQuestionResult, true);
      });
    }
  };

  return (
    <>
      <div
          className={styles.container}
      >
        <div
            className={classNames(styles.iconClass, {
              [styles.correct]: assessmentQuestionResult.isCorrect,
              [styles.incorrect]: !assessmentQuestionResult.isCorrect,
            })}
            style={{ alignSelf: 'center' }}
        >
          { assessmentQuestionResult.isCorrect ? <CheckCircle/> : <XCircle/> }
        </div>
        <div
            className={classNames(styles.questionResultContainer, { [styles.approved]: assessmentQuestionResult.approved })}
        >
          <SecondaryTypography.Medium
              fontWeight='semi-bold'
          >
            { assessmentQuestionResult.systemQuestion }
          </SecondaryTypography.Medium>
          <div
              className={styles.infoAndToggleContainer}
          >
            <SecondaryTypography.Small
                italicized={true}
            >
              Points - { assessmentQuestionResult.score || 0 } points
            </SecondaryTypography.Small>
            <SecondaryTypography.Small
                italicized={true}
            >
              { assessmentQuestionResult.timeExpired ? 'User ran out of time' : 'Time taken - ' +
                    convertSecondsToTimeTaken(assessmentQuestionResult.timeTaken) || 0 }
            </SecondaryTypography.Small>
            { assessmentQuestionResult.userAnswer !== undefined &&
            <div
                className={classNames(styles.iconClass, styles.chevron)}
                onClick={() => {
                  setResultOpen(!resultOpen);
                  getVideoLink();
                }}
            >
              { resultOpen ? <ChevronUp/> : <ChevronDown/> }
            </div>
            }
          </div>
        </div>
      </div>
      { resultOpen && assessmentQuestionResult.userAnswer !== undefined &&
      <ResultAnswer
          key={assessmentQuestionResult.id}
          assessmentQuestionResult={assessmentQuestionResult}
          updateDetailedQuestionArray={updateDetailedQuestionArray}
          setResultOpen={setResultOpen}
      />
      }
    </>
  );
};
