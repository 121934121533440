import React from 'react';
import { PrimaryTypography } from '../../../../../ui/typography/typography';
import styles from '../view_assessment.module.css';

export const AssessmentEmptyList = () => {
  return (
    <div className={styles.emptySect}>
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        There are currently no candidates
      </PrimaryTypography.FiveXLarge>
    </div>
  );
};
