import React from 'react';

type TabsProp = {
  tabTitles: string[],
  activeTab: string,
  setActiveTab: (value: string) => void,
}

export const TabsLine = ({
  tabTitles,
  activeTab,
  setActiveTab,
}: TabsProp) => {

  return (
    <div className='flex gap-4'>
      { tabTitles.map((item: string, index: number) => (
        <div key={item} className='flex justify-center gap-4 text-lg font-semibold '>
          <div
              className={`cursor-pointer ${activeTab === item ? 'text-primary-green-400' : ''} hover:text-primary-green-400`}
              onClick={() => setActiveTab(item)}
          >
            { item }
          </div>
          { index !== tabTitles.length - 1 && <span>|</span> }
        </div>
      )) }
    </div>
  );
};
