import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { CloseIcon, UploadIcon } from '../../../../ui/icons/icons';
import { PrimaryTypography, SecondaryTypography } from '../../../../ui/typography/typography';
import { uploadFile } from '../../../../utils/api_service/job_api';
import { formatDateTime } from '../../../../utils/helpers/time';
import { ContextBasedGeneratorJob } from '../../../../utils/types/content_manager_types';
import commonStyles from '../ContextSection/upload-file.module.css';
import { SessionTitle } from '../session_title';
import { TopicInput } from '../topic_input';
import styles from './upload_document_dropbox.module.css';

type UploadDocumentDropboxProps = {
  uploadedFile: File | null,
  setUploadedFile: (file: File | null) => void,
  pollJobStatus: (id: string, generateQuestions?: boolean) => void,
  setJob: (job: ContextBasedGeneratorJob) => void,
  setTranscribeLoading: (status: boolean) => void,
  transcribeLoading: boolean
}

export const UploadDocumentDropbox = ({
  uploadedFile,
  setUploadedFile,
  pollJobStatus,
  setJob,
  setTranscribeLoading,
  transcribeLoading,
}: UploadDocumentDropboxProps) => {
  const [topic, setTopic] = useState<string>('');
  const [defaultSessionTitle, setdefaultSessionTitle] = useState<string>(`${topic ? topic : ''}-`);
  const [sessionTitle, setSessionTitle] = useState<string>(defaultSessionTitle);

  useEffect(() => {
    const currentDateTimeString = formatDateTime(new Date());
    setdefaultSessionTitle(`${topic ? topic : ''}-${currentDateTimeString}`);
  }, [topic]);

  const dragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const fileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer && event.dataTransfer.files;
    if (files.length && validateFile(files[0])) {
      handleFiles(files);
    }
  };

  const addFileViaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && validateFile(files[0])) {
      handleFiles(files);
    }
  };

  const validateFile = (file: File | null) => {
    const validTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf',
      'application/msword', 'video/mp4'];
    if (file) {
      return validTypes.indexOf(file.type) !== -1;
    } else {
      return false;
    }
  };

  const fileSize = (size: number) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleFiles = (files: FileList) => {
    let file = files.item(0);
    setUploadedFile(file);
  };

  const doUploadFile = () => {
    setTranscribeLoading(true);
    if (uploadedFile !== null && topic !== undefined) {
      uploadFile(uploadedFile, topic, sessionTitle).then(response => {
        if ((response.transcript !== undefined || response.transcript !== null) && response.transcript.length > 0) {
          setJob(response);
          setTranscribeLoading(false);
        } else {
          pollJobStatus(response.id);
        }
      }).catch(() => {
        toast.error('Error. Please try again', { position: 'bottom-center' });
        setTranscribeLoading(false);
      });
    }
  };

  return (
    <div>
      <SessionTitle
          sessionTitle={sessionTitle}
          setSessionTitle={setSessionTitle}
          defaultValue={defaultSessionTitle}
          disabled={transcribeLoading}
      />
      <PrimaryTypography.TwoXLarge
          className={classNames(commonStyles.headerText, commonStyles.blueText)}
          fontWeight='bold'
      >
        Content Topic
      </PrimaryTypography.TwoXLarge>
      <div className='w-1/2'>
        <TopicInput
            setTopic={setTopic}
            disabled={transcribeLoading}
        />
        <SecondaryTypography.Small className='text-gray-500 pt-1'>
          For sub skill creation press enter after each parent skill (maximum of 1 skill and 3 sub skills)
        </SecondaryTypography.Small>
      </div>
      <input
          type='file'
          onChange={addFileViaInput}
          id='upload-file-input'
          style={{ display: 'none' }}
      />
      <PrimaryTypography.TwoXLarge
          className={classNames(commonStyles.headerText, commonStyles.blueText)}
          fontWeight='bold'
      >
        Upload&nbsp;document
      </PrimaryTypography.TwoXLarge>
      <SecondaryTypography.Small>
        Autogenerate questions by uploading documents <br></br>
        Max file size 2GB <br></br>
        Allowed Formats: Docx, PDF, MP4
      </SecondaryTypography.Small>
      <div className={classNames(styles.mainDivision)}>
        <div className={classNames(styles.fileDivision)}>
          <div
              className={styles.uploadContainer}
          >
            <div
                className={styles.uploadAreaContainer}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
            >
              <div
                  className={styles.uploadIcon}
              >
                <UploadIcon/>
              </div>
              <SecondaryTypography.XSmall>
                Drag & Drop your files here
              </SecondaryTypography.XSmall>
              <Button
                  onClick={() => document.getElementById('upload-file-input')?.click()}
                  variant='primary'
                  size='xSmall'
              >
                Browse Files
              </Button>
            </div>
            {
              uploadedFile &&
              <div
                  className={styles.uploadedFileStatusContainer}
              >
                <div
                    className={styles.uploadedFileNameContainer}
                >
                  <SecondaryTypography.Small
                      className={commonStyles.blueText}
                  >
                    { uploadedFile.name }
                  </SecondaryTypography.Small>
                  <div
                      className={styles.uploadIcon}
                      onClick={() => setUploadedFile(null)}
                  >
                    <CloseIcon/>
                  </div>
                </div>
                <SecondaryTypography.XSmall>
                  File Size: { fileSize(uploadedFile.size) }
                </SecondaryTypography.XSmall>
                <SecondaryTypography.XSmall>
                  Max. file size is 2 GB
                </SecondaryTypography.XSmall>
                <SecondaryTypography.XSmall>
                  Allowed formats: Doc, Docx, PDF, MP4
                </SecondaryTypography.XSmall>
              </div>
            }
          </div>
          <Button
              onClick={() => doUploadFile()}
              variant={'primary'}
              size={'small'}
              disabled={(topic === undefined || topic.length === 0) || (uploadedFile === null || undefined) || (transcribeLoading)}
          >
            Upload & Transcribe
          </Button>
        </div>
        { /* <div className={classNames(styles.statusDivision)}>
          <DisplayJobStatus
              job={job}
          />
        </div> */ }
      </div>
    </div>
  );
};
