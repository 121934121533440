import React, { useMemo, useState } from 'react';
import { Button } from '../../../../ui/button/button';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { TextInput } from '../../../../ui/input/text_input/text_input';
import { EmployeeUser, JobTitle } from '../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import styles from '../../access_management/modals.module.css';
import { OrganizationUnit } from '../../OrganizationSettings/TreeStructure';

type CreateEmployeeModalProps = {
    employeeUsers: EmployeeUser[],
    organizationUnits: OrganizationUnit[],
    jobTitles: JobTitle[],
    addNewJobTitle: (title: string) => void,
    createEmployee: (email: string, firstName: string, lastName: string, selectedJobTitle: string, organizationUnit: string, reportingManager: string | undefined) => void,
}

export const CreateEmployeeModal = ({
  employeeUsers,
  organizationUnits,
  jobTitles,
  addNewJobTitle,
  createEmployee,
}: CreateEmployeeModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [reportingManager, setReportingManager] = useState<SelectOptions>();
  const [organizationUnit, setOrganizationUnit] = useState<SelectOptions>();
  const [selectedJobTitle, setSelectedJobTitle] = useState<SelectOptions>();

  const employeeOptions = useMemo(() =>
    employeeUsers.map((employee) => ({
      value: employee.id,
      label: employee.user.firstName + ' ' + employee.user.lastName,
    })),
  [employeeUsers],
  );

  const organizationUnitOptions = useMemo(() =>
    organizationUnits.map((unit) => ({
      value: unit.id,
      label: unit.unitName,
    })),
  [organizationUnits],
  );

  const jobTitleOptions = useMemo(() =>
    jobTitles.map((title) => ({
      value: title.id,
      label: title.jobTitle,
    })),
  [jobTitles],
  );

  const handleManagerChange = (selectedOptions: SelectOptions) => {
    setReportingManager(selectedOptions);
  };

  const handleUnitChange = (selectedOptions: SelectOptions) => {
    setOrganizationUnit(selectedOptions);
  };

  const handleTitleChange = (selectedOptions: SelectOptions) => {
    setSelectedJobTitle(selectedOptions);
  };

  return (
    <>
      <div
          className={styles.modalInputContainer}
      >
        <TextInput
            name='email'
            value={email}
            type='text'
            label='Email'
            onChange={(event) => setEmail(event.target.value)}
        />
        <TextInput
            name='firstName'
            value={firstName}
            type='text'
            label='First Name'
            onChange={(event) => setFirstName(event.target.value)}
        />
        <TextInput
            name='lastName'
            value={lastName}
            type='text'
            label='Last Name'
            onChange={(event) => setLastName(event.target.value)}
        />
        <CustomSelect
            name='jobTitle'
            options={jobTitleOptions}
            onChange={handleTitleChange}
            label={'Job Title'}
            value={selectedJobTitle}
            creatable
            onCreateOption={addNewJobTitle}
        />
        <CustomSelect
            name='reportingManager'
            options={employeeOptions}
            onChange={handleManagerChange}
            label={'Reporting Manager'}
            value={reportingManager}
            clearable
        />
        <CustomSelect
            name='organizationUnit'
            options={organizationUnitOptions}
            onChange={handleUnitChange}
            label={'Organization Unit'}
            value={organizationUnit}
        />
      </div>
      <div>
        <Button
            onClick={() => organizationUnit && selectedJobTitle &&
              createEmployee(email, firstName, lastName, selectedJobTitle.value, organizationUnit.value,
                reportingManager !== null && reportingManager !== undefined ? reportingManager.value : undefined)}
            variant={'primary'}
            size={'medium'}
            disabled={!(organizationUnit !== undefined && selectedJobTitle !== undefined)}
        >
          Invite Employee
        </Button>
      </div>
    </>
  );
};
