import React from 'react';
import { OptionProps, components } from 'react-select';
import styles from './data_source_filter.module.css';

const INDENT_MULTIPLIER = 20;

export const MultiSelectListItem = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <div style={{ marginLeft: props.data.level ? `${props.data.level * INDENT_MULTIPLIER}px` : 0 }}>
        <input
            type='checkbox'
            checked={props.isSelected}
            onChange={() => null}
        />
        <label className={styles.listItemLabel}>{ props.label }</label>
      </div>
    </components.Option>
  );
};
