import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../ui/button/button';
import { PrimaryTypography, SecondaryTypography } from '../../../../../../ui/typography/typography';
import { InviteUserRequest } from '../../../../../../utils/types/assessment_types';
import { InviteSingleUser } from './invite_single_user/invite_single_user';
import styles from './invite_user_modal.module.css';

type InviteUserModalProps = {
    inviteUserForAssessment: (userRequest: InviteUserRequest) => void,
    inviteUsersFromCSV: (csvFile: File) => void,
}

export const InviteUserModal = ({ inviteUserForAssessment, inviteUsersFromCSV }: InviteUserModalProps) => {
  const [addSingleUser, setAddSingleUser] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [inviteUserDetails, setInviteUserDetails] = useState<InviteUserRequest>({
    firstName: '',
    lastName: '',
    email: '',
    assessmentId: null,
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInviteUserDetails({
      ...inviteUserDetails,
      [event.target.name]: event.target.value,
    });
  };

  const addFileViaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!uploadedFile) {
      const files = event.target.files;
      const splitFileString = files && files[0].name.split('.');
      if (files && splitFileString && splitFileString[splitFileString.length - 1] === 'csv') {
        setUploadedFile(files[0]);
      } else {
        toast.error('Invalid File type: CSV needed', { position: 'bottom-center' });
      }
    }
  };

  const handleDownload = () => {
    const fileUrl = process.env.PUBLIC_URL + '/files/users_invite_sample.csv';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'users_invite_sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderModalContent = () => {
    if (addSingleUser) {
      return (
        <InviteSingleUser
            inviteUserDetails={inviteUserDetails}
            handleInputChange={handleInputChange}
            inviteUserForAssessment={inviteUserForAssessment}
            setAddSingleUser={setAddSingleUser}
        />
      );
    } else if (uploadedFile) {
      return (
        <>
          <PrimaryTypography.TwoXLarge
              fontWeight='bold'
              alignment='center'
          >
            File Selected
          </PrimaryTypography.TwoXLarge>
          <div
              className={styles.fileInfoContainer}
          >
            <SecondaryTypography.Small
                fontWeight='semi-bold'
            >
              { uploadedFile.name }
            </SecondaryTypography.Small>
            <Button
                onClick={() => setUploadedFile(undefined)}
                variant='danger'
                size='xSmall'
            >
              Clear File
            </Button>
          </div>
          <Button
              onClick={() => inviteUsersFromCSV(uploadedFile)}
              variant='primary'
              size='medium'
          >
            Submit File
          </Button>
        </>
      );
    } else {
      return (
        <>
          <PrimaryTypography.TwoXLarge
              fontWeight='bold'
              alignment='center'
          >
            Choose Method
          </PrimaryTypography.TwoXLarge>
          <div
              className={styles.buttonContainer}
          >
            <input
                type='file'
                onChange={addFileViaInput}
                id='upload-file-input'
                style={{ display: 'none' }}
            />
            <Button
                onClick={() => setAddSingleUser(true)}
                variant='primary'
                size='medium'
            >
              Add Single User
            </Button>
            <Button
                onClick={() => document.getElementById('upload-file-input')?.click()}
                variant='primary'
                size='medium'
            >
              Bulk Upload Users
            </Button>
            <Button
                onClick={handleDownload}
                variant='primary'
                size='medium'
            >
              Download Sample CSV
            </Button>
          </div>
        </>
      );
    }
  };

  return renderModalContent();
};
