import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../../ui/button/button';
import { CustomSelect } from '../../../../../../ui/custom_react_select/custom_select';
import { SelectOptions } from '../../../../../../utils/types/react_select_types';
import { InputDetails } from '../../../../../../utils/types/utility_types';
import { IS_IOS } from '../../assigned_assessments';
import commonStyles from '../../assigned_assessments.module.css';
import styles from './input_settings_modal.module.css';

type InputSettingsModalProps = {
  commenceAssessment: () => void,
  inputDetails: InputDetails,
  setInputDetails: (details: InputDetails) => void,
}

const defaultCamera: SelectOptions = { value: 'default_camera_id', label: 'Default Camera' };
const defaultMic: SelectOptions = { value: 'default_mic_id', label: 'Default Mic' };

export const InputSettingsModal = ({
  commenceAssessment,
  inputDetails,
  setInputDetails,
}: InputSettingsModalProps) => {
  const videoEl = useRef<HTMLVideoElement>(null);
  const [cameraList, setCameraList] = useState<SelectOptions[]>([]);
  const [micList, setMicList] = useState<SelectOptions[]>([]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      devices.map((device) => {
        if (device.kind === 'audioinput') {
          let micOption: SelectOptions = {
            value: device.deviceId,
            label: device.label,
          };
          setMicList(micList => [
            ...micList,
            micOption,
          ]);
        } else if (device.kind === 'videoinput'){
          let cameraOption: SelectOptions = {
            value: device.deviceId,
            label: device.label,
          };
          setCameraList(cameraList => [
            ...cameraList,
            cameraOption,
          ]);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (!videoEl) {
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: {
      deviceId: inputDetails.micId,
    },
    video: {
      deviceId: inputDetails.cameraId,
    } }).then((mediaStream) => {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(mediaStream);
      const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;

      microphone.connect(analyser);
      analyser.connect(scriptProcessor);
      scriptProcessor.connect(audioContext.destination);
      scriptProcessor.onaudioprocess = function() {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        const average = arraySum / array.length;
        volumeTracker(average);
      };

      let video = videoEl.current;
      if (video) {
        video.muted = true;
        video.srcObject = mediaStream;
        video.autoplay = true;
      }
    });
  }, [videoEl, inputDetails]);

  const volumeTracker = (volume: number) => {
    if (document.getElementById('mic-volume')) {
      // @ts-ignore
      const pidList = [...document.getElementById('mic-volume').children];
      const pidListToColor = Math.round(volume / 10);
      const pidToColor = pidList.slice(0, pidListToColor);
      for (const pid of pidList) {
        // @ts-ignore
        pid.style.backgroundColor = '#e6e7e8';
      }
      for (const pid of pidToColor) {
        // @ts-ignore
        pid.style.backgroundColor = '#29AAE2';
      }
    }
  };

  return (
    <div
        className={commonStyles.modalContentContainer}
    >
      <div
          className='flex flex-col items-center gap-5 sm:flex-row'
      >
        { IS_IOS ?
          <video
              className={styles.videoStreamContainer}
              ref={videoEl}
              autoPlay={true}
              playsInline={true}
              muted={true}
          /> :
          <video
              className={styles.videoStreamContainer}
              ref={videoEl}
          />
        }
        <div
            className={styles.settingsSelectContainer}
        >
          <CustomSelect
              label='Select Webcam'
              name='camera'
              options={cameraList}
              value={defaultCamera}
              onChange={(value: string) => setInputDetails({ ...inputDetails, cameraId: value })}
          />
          <CustomSelect
              label='Select Audio Input'
              name='microphone'
              options={micList}
              value={defaultMic}
              onChange={(value: string) => setInputDetails({ ...inputDetails, micId: value })}
          />
          <div
              className={styles.pidWrapper}
              id='mic-volume'
          >
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
            <div className={styles.pid}/>
          </div>
        </div>
      </div>
      <Button
          onClick={commenceAssessment}
          variant='primary'
          size='medium'
          customClass={commonStyles.assessmentModalButton}
      >
        Next
      </Button>
    </div>
  );
};
