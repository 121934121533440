import classNames from 'classnames';
import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartOrderItem, LineChartType } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { MediumColors } from './Colors';
import { DashletIconGroup } from './DashletIconGroup';

type LineChartDashletProps = {
    id: string,
    data: Array<LineChartType>,
    title: string,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void,
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}
export const LineChartDashlet = ({ id, data, title, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, fullScreenDetected, setFullScreenDetected, handleDashletVisibility, editMode }: LineChartDashletProps) => {

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  return (
    <div className='group relative w-full col-span-2 lg:col-span-2 rounded border border-gray-200 shadow h-full p-2 overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={classNames(styles.dataContainer, dashletItem.hidden ? 'opacity-40': '')}>
        <div className='font-bold text-sm mb-4 text-center'>{ title }</div>
        <div className='flex justify-center'>
          { (!data || !data.length) ?
            <div className={classNames(styles.noDataContainer, 'text-md text-gray-700 flex place-content-center place-items-center')}>No data to preview</div> :
            <ResponsiveContainer  className='w-full' height={fullScreenDetected? 500 : 300}>
              <LineChart width={500} height={fullScreenDetected? 500 : 300} data={data}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='time' padding={{ left: 30, right: 30 }} />
                <YAxis />
                <Tooltip />
                <Legend iconSize={fullScreenDetected? 15 : 10} wrapperStyle={{ fontSize: fullScreenDetected? '15px' : '12px' }} />
                <Line type='monotone' dataKey='Employees' stroke={MediumColors.BLUE}/>
                <Line type='monotone' dataKey='Job_Candidates' stroke={MediumColors.RED} />
                <Line type='monotone' dataKey='Partners' stroke={MediumColors.ORANGE} />
                <Line type='monotone' dataKey='Total' stroke={MediumColors.GREEN} />
              </LineChart>
            </ResponsiveContainer>
          }
        </div>
      </div>
    </div>
  );
};
