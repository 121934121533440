import classNames from 'classnames';
import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from '../../ui/icons/icons';
import { SecondaryTypography } from '../../ui/typography/typography';
import styles from './CollapsibleDetails.module.css';
import { ExpandedDetails } from './ExpandedDetails/ExpandedDetails';

type CollapsibleDetailsProps = {
    title: String,
    firstName?: String,
    lastName?: String,
    open: boolean,
    privacy: boolean,
    setFirstNameInput?: (content: string) => void,
    setLastNameInput?: (content: string) => void
}

export const CollapsibleDetails = ({
  open,
  firstName,
  lastName,
  title,
  privacy,
  setFirstNameInput,
  setLastNameInput,
}:  CollapsibleDetailsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  const toggleCollapsible = (open: boolean) => {
    setIsOpen(!open);
  };

  return (
    <div>
      <div
          className={classNames(styles.questionBox, { [styles.open]: isOpen })}
      >
        <div
            className={styles.questionHeaderContainer}
        >
          <div
              className={styles.questionHeaderContainer}
          >
            <SecondaryTypography.Medium
                fontWeight='bold'
                className={styles.questionHeader}
            >
              { title }
            </SecondaryTypography.Medium>
          </div>
          <div
              className={styles.iconContainer}
          >
            <div
                className={classNames(styles.icons, styles.clickable)}
                onClick={() => {
                  toggleCollapsible(isOpen);
                }}
            >
              {
                isOpen ? <ChevronUp/> : <ChevronDown/>
              }
            </div>
          </div>
        </div>
      </div>
      {
        isOpen &&
        <ExpandedDetails
            firstName={firstName}
            lastName={lastName}
            privacy={privacy}
            setFirstNameInput={setFirstNameInput}
            setLastNameInput={setLastNameInput}
        />
      }
    </div>
  );
};
