import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Features } from '../helpers/subscriptionEnum';
import { RootState } from '../redux_store/store';
import { SubscriptionFeatureDetails } from '../types/admin_types';

interface PermissionGateProps {
  requiredPermissions: string[];
  requiredFeature?: string;
  matchAnyPermission?: boolean;
  children: ReactNode;
}

function PermissionGate({
  requiredPermissions,
  requiredFeature,
  matchAnyPermission,
  children,
}: PermissionGateProps): JSX.Element | null {
  const permissions = useSelector((state: RootState) => state.permissions.permissions);
  const subscription = useSelector((state: RootState) => state.subscriptions.subscriptions);

  const isFeatureActive = (feature: SubscriptionFeatureDetails | undefined): boolean => {
    return feature?.status === 'active' || feature?.status === 'trialing';
  };

  const areFeaturesValid = useMemo(() => {
    switch (requiredFeature) {
      case Features.EMPLOYEE:
        return isFeatureActive(subscription?.employeeFeature);
      case Features.CANDIDATE:
        return isFeatureActive(subscription?.candidateFeature);
      case Features.PARTNER:
        return isFeatureActive(subscription?.partnerFeature);
      case 'ANY':
        return [isFeatureActive(subscription?.employeeFeature),
          isFeatureActive(subscription?.candidateFeature),
          isFeatureActive(subscription?.partnerFeature)].some((value) => value === true);
      default:
        return true;
    }
  }, [subscription, requiredFeature]);

  if (!areFeaturesValid) {
    return null;
  }

  const hasRequiredPermissions = matchAnyPermission
    ? requiredPermissions.some((permission) => permissions.includes(permission))
    : requiredPermissions.every((permission) => permissions.includes(permission));

  return hasRequiredPermissions ? <>{ children }</> : null;
}

export default PermissionGate;
