import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PicturePlaceHolder } from '../../../ui/icons/icons';
import { PaginationNavigator } from '../../../ui/PaginationNavigator';
import TableSkeletonLoader from '../../../ui/TableSkeletonLoader';
import { getDashboardUsers } from '../../../utils/api_service/dashboard';
import { UserData } from '../../../utils/types/DashboardTypes';
import SearchBar from '../ui/SearchBar';

export type TableColumn = {
  title: string;
  width: string;
};

export type DashboardUserData = {
  users: UserData[],
  totalUsers: number,
}

const DashboardUserTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const columns: TableColumn[] = [{ title: 'Name', width: 'w-1/12' }, { title: 'User Type', width: 'w-1/12' }, { title: 'Roles Assigned', width: 'w-1/12' }, { title: 'Assessments', width: 'w-1/12' }, { title: 'Job Applicants', width: 'w-1/12' }, { title: 'Last Login', width: 'w-1/12' } ];
  const [data, setData] = useState<UserData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    const debounceSearchTerm = setTimeout(() => {
      getDashboardUsers(searchTerm, pageNumber)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch assessments');
          }
        })
        .then((data : DashboardUserData) => {
          setTotalPages(Math.ceil(data.totalUsers / 5));
          setData(data.users);
        })
        .catch(() => {
          toast.error('Error Fetching User Data', { position: 'bottom-center' });
        }).finally(() => setLoading(false));
    }, 1000);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchTerm, pageNumber]);

  return (
    <div className='flex flex-col'>
      <div className='flex items-center border-b border-gray-200 pb-4'>
        <SearchBar setSearchTerm={setSearchTerm}/>
      </div>
      <table className='w-full'>
        <thead>
          <tr>
            { columns.map((column, index) => (
              <th key={index} className={`border-b-2 px-4 py-3 ${column.width} font-semibold text-gray-500`}>
                <div className='flex justify-start'>
                  { column.title }
                </div>
              </th>
            )) }
          </tr>
        </thead>
        { !loading && (
          <tbody>
            { data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                { columns.map((column, colIndex) => (
                  <React.Fragment key={colIndex}>
                    { column.title === 'Name' ? (
                      <td className={`border-b-2 px-4 py-3 ${column.width}`}>
                        <div className='flex items-center justify-start align-middle gap-6'>
                          { row['Profile Picture'] ? (
                            <img src={row['Profile Picture']} className='w-10 h-10 rounded-full' alt='Profile Picture'/>
                          ) : (
                            <div className='rounded-full bg-gray-300 w-10 h-10 flex items-center justify-center'>
                              <PicturePlaceHolder className='w-6 h-6'/>
                            </div>
                          ) }
                          <div className='flex flex-col text-left'>
                            <div className='text-lg'>{ row[column.title as keyof UserData] }</div>
                            <div className='text-sm text-gray-500'>{ row['Email'] }</div>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td className={`border-b-2 px-4 py-4 ${column.width} `}>
                        <div className='flex justify-start'>
                          { row[column.title as keyof UserData] }
                        </div>
                      </td>
                    ) }
                  </React.Fragment>
                )) }
              </tr>
            )) }
          </tbody>
        ) }
      </table>
      { loading && <TableSkeletonLoader numRows={5} height={16}/> }
      { (data.length > 0) && (
        <div className='ml-auto mr-auto mt-5'>
          <PaginationNavigator
              pageNumber={pageNumber}
              maxPageNumber={totalPages}
              setPageNumber={setPageNumber}
          />
        </div>
      ) }
    </div>
  );
};

export default DashboardUserTable;
