import React, { useMemo, useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { OrganizationUser, UserGroup, UserRole } from '../../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import styles from '../../modals.module.css';

type EditUserModalProps = {
    userGroupList: UserGroup[],
    userRoleList: UserRole[],
    user: OrganizationUser,
    editUser: (user: OrganizationUser, groups: SelectOptions[], roles: SelectOptions[]) => void,
}

export const EditUserModal = ({
  userGroupList,
  userRoleList,
  user,
  editUser,
}: EditUserModalProps) => {
  const [selectedGroups, setSelectedGroups] = useState(() =>
    user?.groups.map((group) => ({
      value: group.id,
      label: group.groupName,
    })),
  );
  const [selectedRoles, setSelectedRoles] = useState(() =>
    user?.roles.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  );

  const groupOptions = useMemo(() =>
    userGroupList.map((group) => ({
      value: group.id,
      label: group.groupName,
    })),
  [userGroupList],
  );

  const roleOptions = useMemo(() =>
    userRoleList.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  [userRoleList],
  );

  const handleGroupChange = (selectedOptions: SelectOptions[]) => {
    setSelectedGroups(selectedOptions);
  };

  const handleRoleChange = (selectedOptions: SelectOptions[]) => {
    setSelectedRoles(selectedOptions);
  };

  return (
    <>
      <div
          className={styles.modalInputContainer}
      >
        <CustomSelect
            name='groups'
            options={groupOptions}
            onChange={handleGroupChange}
            isMulti={true}
            label={'Group Membership'}
            value={selectedGroups}
        />
        <CustomSelect
            name='roles'
            options={roleOptions}
            onChange={handleRoleChange}
            isMulti={true}
            label={'Role Membership'}
            value={selectedRoles}
        />
      </div>
      <div>
        <Button
            onClick={() => editUser(user, selectedGroups, selectedRoles)}
            variant={'primary'}
            size={'medium'}
        >
          Edit groups
        </Button>
      </div>
    </>
  );
};
