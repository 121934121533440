import React, { useState } from 'react';
import { Button } from '../../../../ui/button/button';
import { TextInput } from '../../../../ui/input/text_input/text_input';
import { OrganizationUnit } from '../TreeStructure';
import styles from './Modals.module.css';

type EditNameModalProps = {
    organizationUnit: OrganizationUnit | undefined,
    editName: (unitName: string) => void,
}

export const EditNameModal = ({
  organizationUnit,
  editName,
}: EditNameModalProps) => {
  const [newName, setNewName] = useState<string>('');

  return (
    <div
        className={styles.inputGap}
    >
      <TextInput
          name='currentName'
          value={organizationUnit?.unitName || ''}
          type='text'
          label='Current Name'
          disabled={true}
          onChange={() => {}}
      />
      <TextInput
          name='newName'
          value={newName}
          type='text'
          label='New Name'
          onChange={(event) => setNewName(event.target.value)}
      />
      <div>
        <Button
            onClick={() => editName(newName)}
            variant={'primary'}
            size={'medium'}
            disabled={newName.length === 0}
        >
          Edit Name
        </Button>
      </div>
    </div>
  );
};
