import { EmailRegex } from '../../../utils/helpers/regex';
import { Errors, FormInput } from '../../../utils/types/login_register_types';

export function validate(eventTarget: HTMLInputElement, formInputs: FormInput) {
  const { name, value } = eventTarget;
  const { password, confirmPassword, errors } = formInputs;
  let validConfirmPassword = false;

  if (name === 'email') {
    errors.emailError = EmailRegex.test(value) ? '' : 'Email is not valid!';
  } else if (name === 'password' && confirmPassword !== undefined) {
    errors.passwordError = value.length < 8 ? 'Password must be 8 characters long!' : '';
    errors.confirmPasswordError = value !== confirmPassword ? 'Passwords do not match!' : '';
  } else if (name === 'confirmPassword') {
    errors.confirmPasswordError = value.length < 8 ? 'Password must be 8 characters long!' : '';
    validConfirmPassword = value.length >= 8;
    if (validConfirmPassword) {
      errors.confirmPasswordError = value !== password ? 'Passwords do not match!' : '';
    }
  }

  return errors;
}

export function validateForm(formInputs: FormInput) {
  let valid = true;
  const { errors } = formInputs;

  Object.values(errors).forEach((value: string) => {
    if (value !== undefined && value.length > 0) {
      valid = false;
    }
  });

  Object.values(formInputs).forEach((value: string | Errors) => {
    if (typeof value === 'string' && value.length === 0) {
      valid = false;
    }
  });

  return valid;
}
