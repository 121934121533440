import classNames from 'classnames';
import React, { useMemo } from 'react';
import { PencilIcon, TrashIcon } from '../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import { OrganizationUser } from '../../../../../utils/types/admin_types';
import rowStyles from '../../row.module.css';

type UserRowProps = {
  user: OrganizationUser,
  deleteUser: (id: string) => void,
  onEditClick: (user: OrganizationUser) => void,
}

export const UserRow = ({
  user,
  deleteUser,
  onEditClick,
}: UserRowProps) => {
  const groupListString = useMemo(() => {
    return user.groups.map(group => group.groupName).join(', ');
  }, [user.groups]);

  return (
    <tr
        className={rowStyles.tableRowStyle}
    >
      <td>
        <input type='checkbox'/>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.leftBorder)}
      >
        <SecondaryTypography.XSmall>
          { user.user.firstName + ' ' + user.user.lastName }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { user.user.email }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { groupListString || 'Empty' }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { user.lastActive ? new Date(user.lastActive * 1000).toString() : 'Never Logged-In' }
        </SecondaryTypography.XSmall>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.rightBorder)}
      >
        <div
            className={rowStyles.iconContainer}
        >
          <PermissionGate
              requiredPermissions={[Permission.USERS_EDIT]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.editIcon, rowStyles.clickable)}
                onClick={() => onEditClick(user)}
            >
              <PencilIcon/>
            </div>
          </PermissionGate>
          <PermissionGate
              requiredPermissions={[Permission.USERS_DELETE]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.trashIcon, rowStyles.clickable)}
                onClick={() => deleteUser(user.user.id)}
            >
              <TrashIcon/>
            </div>
          </PermissionGate>
        </div>
      </td>
    </tr>
  );
};
