import classNames from 'classnames';
import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ChartOrderItem, DataType } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { LightColors, MediumColors } from './Colors';
import { DashletIconGroup } from './DashletIconGroup';

const colors = [
  MediumColors.RED, MediumColors.BLUE, MediumColors.ORANGE, MediumColors.GREEN, MediumColors.FUCHSIA,
  MediumColors.PURPLE, MediumColors.CYAN, MediumColors.PINK, MediumColors.YELLOW, MediumColors.LIME,
  LightColors.GREEN, LightColors.BLUE, LightColors.ORANGE, LightColors.RED, LightColors.FUCHSIA,
  LightColors.PURPLE, LightColors.CYAN, LightColors.PINK, LightColors.YELLOW, LightColors.LIME,
];
const RADIAN = Math.PI / 180;

type PieChartDashletProps = {
    id: string,
    data: Array<DataType>,
    title: string,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void;
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}
type RenderCustomizedLabelProps = {
  cx: number,
  cy: number,
  midAngle: number,
  innerRadius: number,
  outerRadius: number,
  value: number
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }:RenderCustomizedLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * (midAngle > 340 ? 0.8: 0.6);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y}
        fill='black'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
        style={{
          fontSize: outerRadius===200? '20px':'14px',
          fontWeight: 600,
        }}
    >
      { value }
    </text>
  );
};

export const PieChartDashlet = ({ id, data, title, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, fullScreenDetected, setFullScreenDetected, handleDashletVisibility, editMode }: PieChartDashletProps) => {

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <div className='bg-white p-2 w-fit h-fit opacity-90 flex items-center justify-center'>
          <p className='label' style={{ color: payload[0].payload?.fill || 'black' }}>
            { `${payload[0].name} : ${payload[0].value}` }
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className='group relative w-full col-span-2 rounded border border-gray-200 h-full pt-2 shadow overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={classNames(styles.dataContainer, dashletItem.hidden ? 'opacity-40': '')}>
        <div className='font-bold text-sm mb-1 text-center'>{ title }</div>
        <div className='flex justify-center'>
          { (!data || !data.length) ?
            <div className={classNames(styles.noDataContainer, 'text-md text-gray-700 flex place-content-center place-items-center')}>No data to preview</div> :
            <ResponsiveContainer height={fullScreenDetected? 500 : 300 + data.length*3}>
              <PieChart>
                <Legend iconSize={fullScreenDetected? 20 : 12} wrapperStyle={{ fontSize: fullScreenDetected? '20px' : '12px' }}/>
                <Tooltip content={<CustomTooltip/>} />
                <Pie
                    data={data}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={fullScreenDetected? 200 : 100}
                    fill='#8884d8'
                    dataKey='value'
                >
                  { data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  )) }
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          }
        </div>
      </div>
    </div>
  );
};
