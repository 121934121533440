import React from 'react';

type ContextSearchProps = {
    searchTerm: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export const ContextSearch = ({
  searchTerm,
  handleChange,
}: ContextSearchProps) => {
  return (
    <div>
      <input
          className='block bg-white p-2 border border-gray-300 box-border shadow-sm rounded-md w-full text-base leading-6 focus:outline-none focus:border-primary-blue-500'
          type='text'
          placeholder='Search'
          value={searchTerm}
          onChange={handleChange}
      />
    </div>
  );
};
