import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button } from '../../../../../../ui/button/button';
import { AddIcon, TrashIcon } from '../../../../../../ui/icons/icons';
import inputStyles from '../../../../../../ui/input/input.module.css';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { userSearch } from '../../../../../../utils/api_service/user_api';
import { AssessmentUser, User } from '../../../../../../utils/types/assessment_types';
import styles from './user_search.module.css';

type UserSearchProps = {
  displayInviteModal: (value: boolean) => void,
  assignedUsers: AssessmentUser[],
  addAssessmentUser: (user: User) => void,
}

export const UserSearch = ({
  displayInviteModal,
  assignedUsers,
  addAssessmentUser,
}: UserSearchProps) => {

  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchResults, setSearchResults] = useState<User[]>();
  const [isSearchListOpen, setIsSearchListOpen] = useState<boolean>(false);

  useEffect(() => {
    if (searchTerm) {
      userSearch(searchTerm).then(response => {
        if (response.length > 0) {
          let filteredSearchResult = response;
          assignedUsers.map((user: AssessmentUser) => {
            filteredSearchResult = filteredSearchResult.filter((item: User) => item.email !== user.email);
          });
          setSearchResults(filteredSearchResult);
        } else {
          setSearchResults([]);
        }
      });
    }
  }, [searchTerm]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const removeFromSearchResults = (user: User) => {
    let searchResultsCopy = searchResults;
    if (searchResultsCopy) {
      searchResultsCopy = searchResultsCopy.filter(item => item.email !== user.email);
      setSearchResults(searchResultsCopy);
    }
  };

  return (
    <div
        className={styles.container}
    >
      <div
          className={styles.searchBarContainer}
      >
        <div
            className={styles.searchBar}
        >
          <input
              className={inputStyles.inputStyles}
              type='text'
              placeholder='Search Users'
              onChange={handleChange}
              onClick={() => setIsSearchListOpen(!isSearchListOpen)}
          />
          { isSearchListOpen &&
          <div
              className={styles.searchResults}
          >
            { searchResults && searchResults.length > 0 && searchResults.map((user: User) => {
              return (
                <div
                    className={styles.searchResultItem}
                    onClick={() => {
                      addAssessmentUser(user);
                      removeFromSearchResults(user);
                    }}
                    key={user.email}
                >
                  <SecondaryTypography.Small>
                    { user.firstName }&nbsp;{ user.lastName }
                  </SecondaryTypography.Small>
                </div>
              );
            }) }
          </div>
          }
        </div>
        <div
            className={styles.buttonContainer}
        >
          <Button
              variant='success'
              size='medium'
              icon={<AddIcon/>}
              onClick={() => displayInviteModal(true)}
              customClass={styles.searchRowButtons}
          >
            Invite Candidate
          </Button>
          <Button
              variant='secondary'
              size='medium'
              icon={<TrashIcon/>}
              onClick={() => {}}
              customClass={styles.searchRowButtons}
          >
            Activate Access
          </Button>
          <Button
              variant='secondary'
              size='medium'
              icon={<TrashIcon/>}
              onClick={() => {}}
              customClass={styles.searchRowButtons}
          >
            Disable Access
          </Button>
          <Button
              variant='danger'
              size='medium'
              icon={<TrashIcon/>}
              onClick={() => {}}
              customClass={styles.searchRowButtons}
          >
            Remove Candidate
          </Button>
        </div>
      </div>
    </div>
  );
};
