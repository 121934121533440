import classNames from 'classnames';
import React from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon } from '../icons/icons';
import { PrimaryTypography } from '../typography/typography';
import styles from './modal.module.css';

type ModalProps = {
    visible: boolean,
    toggle?: () => void,
    modalContent: JSX.Element | ((closeModal: () => void) => React.ReactNode),
    toggleable?: boolean,
    customClass?: string,
    headerClass?: string,
    headerText?: string,
}

export const Modal = ({
  visible,
  toggle = () => {},
  modalContent,
  toggleable = true,
  customClass,
  headerClass,
  headerText,
}: ModalProps) => visible ? createPortal(
  <div
      className='fixed top-0 left-0 w-screen h-screen bg-opacity-50 backdrop-blur-md'
  >
    <div
        className={classNames(styles.modal, { [styles.open]: visible }, customClass)}
        role='dialog'
        aria-modal='true'
    >
      <div
          className={headerClass}
      >
        <div
            aria-label='close-modal'
            className={classNames(styles.close, { [styles.none]: !toggleable })}
            onClick={toggle}
        >
          <CloseIcon/>
        </div>
        <PrimaryTypography.SevenXLarge
            fontWeight='bold'
        >
          { headerText }
        </PrimaryTypography.SevenXLarge>
      </div>
      { typeof modalContent === 'function' ? modalContent(toggle) : modalContent }
    </div>
  </div>, document.body,
) : null;
