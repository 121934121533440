import classNames from 'classnames';
import React, { useMemo } from 'react';
import { ChartOrderItem } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { DashletIconGroup } from './DashletIconGroup';

type AssessmentValueDashletProps = {
    id: string,
    name: string,
    value: string | number,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void,
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}

export const AssessmentValueDashlet = ({ id, name, value, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, fullScreenDetected, setFullScreenDetected, handleDashletVisibility, editMode }: AssessmentValueDashletProps) => {

  const FormattedValue = useMemo(() => {
    let formattedValue = '';
    let unit = '';

    if (value !== null && value !== undefined) {
      if (name === 'Quickest Time') {
        formattedValue = String(value);
        unit = 'sec';
      } else if (name === 'Top Score') {
        formattedValue = String(value);
        unit = '%';
      } else {
        formattedValue = String(value);
      }
    } else {
      formattedValue = 'NaN';
    }

    return { formattedValue, unit };
  }, [value, name]);

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  return (
    <div className='flex justify-center items-center group relative w-full col-span-1 pt-2 h-full border border-gray-200 shadow rounded overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={`flex flex-col justify-center bg-white w-full h-full ${dashletItem.hidden ? 'opacity-40' : ''}`}
      >
        <div className={classNames('font-bold text-center', fullScreenDetected? 'text-4xl mb-6' : 'text-sm mb-2')}> { name }</div>
        <div className={classNames(styles.numeriacalContainer, 'font-bold text-green-500 flex place-content-center place-items-center', fullScreenDetected ? 'text-9xl' : typeof value==='string' || value===undefined || value===null? 'text-3xl' : 'text-7xl')}>
          <span className='text-inherit'>{ FormattedValue.formattedValue }</span>
          { FormattedValue.unit && (
            <span className='text-5xl ml-2'>{ FormattedValue.unit }</span>
          ) }
        </div>
      </div>
    </div>
  );
};
