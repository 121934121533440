import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../ui/button/button';
import { PrimaryTypography, SecondaryTypography } from '../../../../../ui/typography/typography';
import commonStyles from '../assigned_assessments.module.css';
import styles from './assessment_complete.module.css';

export const AssessmentComplete = () => {
  const history = useHistory();

  const logOut = () => {
    localStorage.clear();
    history.push('/login');
  };

  return (
    <div
        className={styles.assessmentCompleteContainer}
    >
      <div
          className={styles.messageContainer}
      >
        <PrimaryTypography.SevenXLarge>
          🎉
        </PrimaryTypography.SevenXLarge>
        <PrimaryTypography.SevenXLarge
            fontWeight='bold'
            className={commonStyles.darkBlueText}
        >
          Congratulations, { localStorage.getItem('firstname') }
        </PrimaryTypography.SevenXLarge>
        <SecondaryTypography.Medium
            fontWeight='semi-bold'
            className={commonStyles.greyText}
        >
          You have completed the assessment.
        </SecondaryTypography.Medium>
        <div
            className={styles.buttonContainer}
        >
          <Button
              variant='secondary'
              size='medium'
              onClick={() => logOut()}
          >
            Log-Out
          </Button>
        </div>
      </div>
    </div>
  );
};
