import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { View } from '../../types/assessment_types';

export interface ViewState {
  currentView: View;
  accessibleViews: View[];
}

export const viewSlice = createSlice({
  name: 'views',
  initialState: {
    currentView: 'Participant',
    accessibleViews: ['Participant'] as View[],
  },
  reducers: {
    setCurrentView: (state, action: PayloadAction<View>) => {
      state.currentView = action.payload;
    },
    setAccessibleViews: (state, action: PayloadAction<View[]>) => {
      state.accessibleViews = action.payload;
    },
  },
});

export const { setCurrentView, setAccessibleViews } = viewSlice.actions;
export default viewSlice.reducer;
