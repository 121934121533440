import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/images/logos/base-hiacuity-logo.png';
import { Permission } from '../../../utils/helpers/permissions';
import PermissionGate from '../../../utils/hooks/PermissionGate';
import { ChevronDown, ChevronUp } from '../../icons/icons';
import { SecondaryTypography } from '../../typography/typography';
import styles from './sidebar_profile.module.css';

type SideBarProfileProps = {
  logout: () => void;
  deleteUser: () => void;
}

export const SideBarProfile = ({
  logout,
  deleteUser,
}: SideBarProfileProps) => {
  const history = useHistory();
  const MenuItems = [
    {
      title: 'Profile Settings',
      onClick: () => history.push('/user-profile'),
      requiredPermissions: [],
    },
    {
      title: 'Change Password',
      onClick: () => history.push('/change-password'),
      requiredPermissions: [],
    },
    {
      title: 'Delete User',
      onClick: () => deleteUser(),
      requiredPermissions: [Permission.USERS_DELETE],
    },
    {
      title: 'Sign Out',
      onClick: () => logout(),
      requiredPermissions: [],
    },
  ];

  const [openUserMenu, setOpenUserMenu] = useState<boolean>(false);
  const PROFILE_LINK = String(localStorage.getItem('profilePic'));
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenUserMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div
          className={styles.profileContainer}
      >
        <div>
          { PROFILE_LINK === 'null' ? (
            <img
                src={logo}
                alt='Avatar'
                className={styles.profileAvatar}
            />
          ) : (
            <img
                src={PROFILE_LINK}
                alt='Avatar'
                className={styles.profileAvatar}
            />
          ) }
        </div>
        <div
            className={styles.profileInfo}
        >
          <p
              className={styles.username}
          >
            { localStorage.getItem('firstname') }
          </p>
          <p
              className={styles.userRole}
          >
            { localStorage.getItem('role') ? localStorage.getItem('role') : 'Candidate' }
          </p>
        </div>
        <div
            className={styles.chevronContainer}
            onClick={() => setOpenUserMenu(!openUserMenu)}
        >
          { openUserMenu ? <ChevronUp/> : <ChevronDown/> }
        </div>
        <div
            ref={dropdownRef}
            className={`${classNames(styles.dropdown, { [styles.open]: openUserMenu })} z-40`}
        >
          { MenuItems.map((item) =>
            <PermissionGate
                key={item.title}
                requiredPermissions={item.requiredPermissions}
            >
              <div
                  className={styles.dropdownItem}
                  onClick={item.onClick}
                  key={item.title}
              >
                <SecondaryTypography.XSmall
                    fontWeight='bold'
                >
                  { item.title }
                </SecondaryTypography.XSmall>
              </div>
            </PermissionGate>,
          ) }
        </div>
      </div>
    </>
  );
};
