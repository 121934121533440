import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux_store/store';
import AdminDashboard from './AdminDashboard';
import ParticipantDashboard from './ParticipantDashboard';

export const Home = () => {
  const currentView = useSelector((state: RootState) => state.view.currentView);
  const renderLandingPage = () => {
    if (currentView === 'Participant') {
      return <ParticipantDashboard />;
    } else if (currentView === 'Admin') {
      return <AdminDashboard />;
    } else {
      return null;
    }
  };

  return (
    <div
        className='w-full min-h-screen p-4'
    >
      { renderLandingPage() }
    </div>
  );
};
