import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/candidate';

export function getCandidateList(searchTerm?: string, page?: number, recordsPerPage?: number) {
  let url: string = apiEndpoint;
  if (searchTerm !== undefined && page && recordsPerPage) {
    url = apiEndpoint + `?name=${searchTerm}&page=${page}&limit=${recordsPerPage}`;
  }
  return fetch(createRequest(url, 'GET', 'application/json', true));
}

export function deleteCandidate(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'DELETE', 'application/json', true));
}
