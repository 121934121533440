import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/skill';
const subSkillApiEndpoint = apiUrl + '/skill/subskill';

export function getSkillList() {
  return fetch(createRequest(apiEndpoint + '/get', 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function createSkill(skillName: string) {
  const body = {
    skill_name: skillName,
    skill_description: skillName,
  };
  return fetch(createRequest(apiEndpoint + '/create', 'POST', 'application/json', true, body));
}

export function getSubSkillList(parentSkillId: string) {
  return fetch(createRequest(subSkillApiEndpoint + `/get/parent/${parentSkillId}`, 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function createSubSkill(skillName: string, parentSkillId: string) {
  const body = {
    subskill_name: skillName,
    parent_skill_id: parentSkillId,
  };
  return fetch(createRequest(subSkillApiEndpoint + '/create', 'POST', 'application/json', true, body));
}

export function getAllSubSkills() {
  return fetch(createRequest(subSkillApiEndpoint + '/list', 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}
