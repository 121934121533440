import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../../ui/button/button';
import { TextArea } from '../../../../../../ui/input/text_area/text_area';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { AssessmentQuestionInfo } from '../../../../../../utils/types/assessment_types';
import { IS_IOS } from '../../assigned_assessments';
import styles from './question_area.module.css';

type QuestionAreaProps = {
    questionType: string,
    setCurrentAnswer: (value: string | File | undefined) => void,
    recordedVideo: string | undefined,
    setRecordedVideo: (value: string | undefined) => void,
    currentQuestion: AssessmentQuestionInfo,
    timeRemaining: number | undefined,
}

export const QuestionArea = ({
  questionType,
  setCurrentAnswer,
  recordedVideo,
  setRecordedVideo,
  currentQuestion,
  timeRemaining,
}: QuestionAreaProps) => {
  const videoElement = useRef<HTMLVideoElement>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [videoChunks, setVideoChunks] = useState<Blob[]>([]);
  const [recording, setRecording] = useState<boolean>(false);
  const [textAnswer, setTextAnswer] = useState<string>('');

  useEffect(() => {
    if (!videoElement || recordedVideo || questionType === 'Classic') {
      setTextAnswer('');
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
      setMediaRecorder(new MediaRecorder(mediaStream));
      let video = videoElement.current;
      if (video) {
        video.muted = true;
        video.srcObject = mediaStream;
        video.autoplay = true;
      }
    });
  }, [videoElement, recordedVideo, currentQuestion, questionType]);

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          setVideoChunks((videoChunks) => [
            ...videoChunks,
            event.data,
          ]);
        }
      };
    }
  }, [mediaRecorder]);

  useEffect(() => {
    if (questionType === 'Modern' && timeRemaining === 0 && recording) {
      stopRecording();
    }
  }, [questionType, timeRemaining, recording]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let answer = event.target.value;
    setTextAnswer(answer);
    setCurrentAnswer(answer);
  };

  const startRecording = () => {
    if (mediaRecorder) {
      setVideoChunks([]);
      mediaRecorder.start(10);
      setRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      storeRecording();
      setMediaRecorder(undefined);
    }
  };

  const storeRecording = () => {
    const blob = new Blob(videoChunks, { 'type': 'video/webm; codecs=webm' });
    const videoFile = new File(videoChunks, 'sample.webm');
    setCurrentAnswer(videoFile);
    const videoUrl = URL.createObjectURL(blob);
    setRecordedVideo(videoUrl);
    let video = videoElement.current;
    if (video) {
      video.srcObject = null;
      video.src = videoUrl;
      video.autoplay = true;
    }
  };

  const redoRecording = () => {
    setRecordedVideo(undefined);
    setCurrentAnswer(undefined);
    startRecording();
  };

  const renderRecordButton = () => {
    if (recordedVideo) {
      return (
        <Button
            onClick={() => redoRecording()}
            variant='danger'
            size='xLarge'
            customClass={styles.recordInteractionButton}
        >
          Redo Recording
        </Button>
      );
    } else {
      return (
        <Button
            onClick={() => recording ? stopRecording() : startRecording()}
            variant={recording ? 'success' : 'danger'}
            size='xLarge'
            customClass={styles.recordInteractionButton}
        >
          { recording ? 'Stop Recording' : 'Start Recording' }
        </Button>
      );
    }
  };

  const checkMediaType = () => {
    return !currentQuestion.mediaExtension?.match(/^(mp4|mkv|avi|webm)$/);
  };

  const renderQuestionMedia = () => {
    if (currentQuestion.fileUrl) {
      return (
        <>
          <SecondaryTypography.Medium
              keepDefaultMargins={true}
              fontWeight='semi-bold'
          >
            Use video/image seen below to answer the question.
          </SecondaryTypography.Medium>
          <div
              className={styles.mediaContainer}
          >
            { checkMediaType() ? (
              <img
                  width='400px'
                  src={currentQuestion.fileUrl}
                  alt={currentQuestion.id + 'Media'}
              />
            ) : IS_IOS ? (
              <video
                  width='400px'
                  controls
                  src={currentQuestion.fileUrl}
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
              />
            ) : (
              <video
                  width='400px'
                  controls
                  src={currentQuestion.fileUrl}
              />
            ) }
          </div>
        </>
      );
    }
  };

  const renderQuestionArea = () => {
    if (questionType === 'Classic') {
      return (
        <>
          { renderQuestionMedia() }
          <TextArea
              name='answer'
              value={textAnswer}
              onChange={handleChange}
              customClass={styles.answerAreaHeight}
          />
        </>
      );
    } else {
      return (
        <>
          { renderQuestionMedia() }
          { IS_IOS ?
            <video
                className={styles.videoContainer}
                ref={videoElement}
                controls={!!recordedVideo}
                autoPlay={true}
                playsInline={true}
                muted={true}
            /> :
            <video
                className={styles.videoContainer}
                ref={videoElement}
                controls={!!recordedVideo}
            />
          }
          { renderRecordButton() }
        </>
      );
    }
  };

  return (
    <div
        className={styles.questionAreaContainer}
    >
      { renderQuestionArea() }
    </div>
  );
};
