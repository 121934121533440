import React, { useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { MultiSelectListItem } from '../../../../../ui/data_source_filter/MultiSelectListItem';
import { AddIcon } from '../../../../../ui/icons/icons';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { PartnerEntity } from '../../../../../utils/types/admin_types';
import { FormattedSelectOptions } from '../../../../../utils/types/analytics/analytics_types';

type AddPartnerEmployeeModalProps = {
    partnerEntityList: PartnerEntity[];
    addPartnerEmployee: (email: string, firstName: string, lastName: string, partnerEntityId: FormattedSelectOptions, jobTitle: string) => void;
}

export const getFormattedEntityList = (partnerEntities: PartnerEntity[]): FormattedSelectOptions[] => {
  const formattedOrgList: FormattedSelectOptions[] = [];
  const processPartnerEntity = (level: number, partnerEntity?: PartnerEntity) => {
    if (!partnerEntity) return;
    formattedOrgList.push({ value: partnerEntity.id, label: partnerEntity.name, level });
    for (const org of partnerEntity.subEntities) {
      processPartnerEntity(level + 1, org);
    }
  };
  for (const partnerEntity of partnerEntities) {
    processPartnerEntity(0, partnerEntity);
  }
  return formattedOrgList;
};

export const AddPartnerEmployeeModal = ({ partnerEntityList, addPartnerEmployee }: AddPartnerEmployeeModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [partnerEntityId, setPartnerEntityId] = useState<FormattedSelectOptions>();
  const [jobTitle, setJobTitle] = useState<string>('');
  const formattedOrgList = getFormattedEntityList(partnerEntityList);

  return (
    <>
      <TextInput
          name='email'
          value={email}
          type='text'
          label='Email'
          onChange={(event) => setEmail(event.target.value)}
      />
      <TextInput
          name='firstName'
          value={firstName}
          type='text'
          label='First Name'
          onChange={(event) => setFirstName(event.target.value)}
      />
      <TextInput
          name='lastName'
          value={lastName}
          type='text'
          label='Last Name'
          onChange={(event) => setLastName(event.target.value)}
      />
      <CustomSelect
          label='Select Partner Entity'
          name='partnerEntityId'
          options={formattedOrgList}
          onChange={(option: FormattedSelectOptions) => { setPartnerEntityId(option); }}
          clearable={true}
          components={{ Option: MultiSelectListItem }}
          value={partnerEntityId}
      />
      <TextInput
          name='jobTitle'
          value={jobTitle}
          type='text'
          label='Job Title'
          onChange={(event) => setJobTitle(event.target.value)}
      />
      <Button
          icon={<AddIcon/>}
          onClick={() => partnerEntityId && addPartnerEmployee(email, firstName, lastName, partnerEntityId, jobTitle)}
          variant='primary'
          size='medium'
      >
        Add Partner Employee
      </Button>
    </>
  );
};
