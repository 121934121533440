import { OrganizationUnit } from '../../pages/admin/OrganizationSettings/TreeStructure';
import { createRequest } from '../helpers/api_utils';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/organization-unit';

export function getOrganizationUnitList() {
  return fetch(createRequest(apiEndpoint, 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function getOrganizationTree() {
  return fetch(createRequest(apiEndpoint + '/tree', 'GET', 'application/json', true))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function updateOrganizationTree(updatedTree: OrganizationUnit[]) {
  return fetch(createRequest(apiEndpoint + '/tree', 'PUT', 'application/json', true, updatedTree))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}

export function addOrganizationUnit(unitName: string, parentOrgId: string) {
  let body = {
    organization_unit_id: '',
    unit_name: unitName,
    parent_organization_unit_id: parentOrgId,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body))
    .then(response => {
      if (response.ok) {
        return response.json();
      }
    });
}
