import React, { ReactNode } from 'react';

interface CollapsibleProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  toggleOpen: () => void;
}

const Collapsible = ({ title, children, isOpen, toggleOpen } : CollapsibleProps) => {
  return (
    <div>
      <div className='flex items-center cursor-pointer gap-x-3'>
        <button
            onClick={toggleOpen}
            className=' w-6 h-6 bg-primary-blue-500 text-white font-bold rounded-md text-center flex justify-center items-center'
        >
          { isOpen ? '-' : '+' }
        </button>
        <h2 className='text-gray-800 py-1 text-sm'>{ title }</h2>
      </div>
      { isOpen && (
        <div className='mt-2 pl-9'>
          { children }
        </div>
      ) }
    </div>
  );
};

export default Collapsible;
