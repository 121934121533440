import React from 'react';
import { PicturePlaceHolder } from '../../../../../../ui/icons/icons';
import TableSkeletonLoader from '../../../../../../ui/TableSkeletonLoader';
import { formatDatePretty, formatUnixPretty, unixToCurrentTimezone } from '../../../../../../utils/helpers/time';
import { JobApplicant } from '../../../../../../utils/types/JobOpenings';

export type TableColumn = {
  title: string;
  width: string;
};

export type DashboardAssessmentTableProps = {
  tableData: JobApplicant[];
  loading: boolean;
}

type MappingTitleType = {
  'Document Type': string,
  'Status': string,
  'Date Uploaded': string,
}

const MappingTitle = {
  'Filename': 'baseFilename',
  'Document Type': 'documentType',
  'Status': 'status',
  'Date Uploaded': 'createdAt',
};

const ApplicantTable = ({ tableData, loading }: DashboardAssessmentTableProps) => {
  const columns: TableColumn[] = [{ title: 'Filename', width: 'w-1/12' }, { title: 'Document Type', width: 'w-1/12' }, { title: 'Applicant Details', width: 'w-1/12' }, { title: 'Date Uploaded', width: 'w-2/12' }, { title: 'Status', width: 'w-1/12' }];
  const renderRow = (title: string, row: any, column: any) => {
    switch (title) {
      case 'Applicant Details':
        return (
          <td className={`border-b-2 px-4 py-3 ${column.width}`}>
            <div className='flex gap-3 justify-items-center items-center'>
              { row['profileImageFilename'] ? (
                <img src={row['profileImageFilename']} className='w-12 h-12 rounded-full' alt='Profile Picture'/>
              ) : (
                <div className='rounded-full bg-gray-300 w-12 h-12 flex items-center justify-center'>
                  <PicturePlaceHolder className='w-8 h-8'/>
                </div>
              ) }
              <div className='flex items-center justify-start align-middle gap-6'>
                <div className='flex flex-col text-left'>
                  <div className='text-lg'>{ row['firstName'] } { row['lastName'] }</div>
                  <div className='text-sm text-gray-500'>{ row['email'] }</div>
                </div>
              </div>
            </div>
          </td>
        );
      case 'Date Uploaded':
        return (
          <td className={`border-b-2 px-4 py-4 ${column.width} `}>
            <div className='flex justify-start text-left'>
              { formatUnixPretty(row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant] as number) }
            </div>
          </td>
        );
      case 'Status':
        return (
          <td className={`border-b-2 px-4 py-4 ${column.width} `}>
            <div className='flex justify-start text-left'>
              { row['status'] === 'Error' ? (
                <div className='flex-col'>
                  <div>
                    Error
                  </div>
                  <div className='text-xs text-red-500'>
                    { row['errorMessage'] }
                  </div>
                </div>
              ) : (
                row['status']
              ) }
            </div>
          </td>
        );
      default:
        return (
          <td className={`border-b-2 px-4 py-4 ${column.width} `}>
            <div className='flex justify-start text-left'>
              { row[MappingTitle[column.title as keyof MappingTitleType] as keyof JobApplicant] }
            </div>
          </td>
        );
    }
  };

  return (
    <div className='flex flex-col w-full'>
      <table className='w-full border-t-2'>
        <thead>
          <tr>
            { columns.map((column, index) => (
              <th key={index} className={`border-b-2 px-4 py-3 ${column.width} font-semibold text-gray-500`}>
                <div className='flex justify-start'>
                  { column.title }
                </div>
              </th>
            )) }
          </tr>
        </thead>
        { !loading && (
          <tbody>
            { tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                { columns.map((column, colIndex) => (
                  <React.Fragment key={colIndex}>
                    { renderRow(column.title, row, column) }
                  </React.Fragment>
                )) }
              </tr>
            )) }
          </tbody>
        ) }
      </table>
      { loading && (
        <TableSkeletonLoader/>
      ) }
      { tableData.length === 0 && !loading && (
        <div className='text-center py-10'>
          <div className='text-gray-800 py-1 text-lg'>
            No Applicants
          </div>
        </div>
      ) }
    </div>
  );
};

export default ApplicantTable;
