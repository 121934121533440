import { createRequest } from '../helpers/api_utils';
import { PartnerEntity } from '../types/admin_types';
import { PartnerQueryFilter } from '../types/assessment_types';
import { SelectOptions } from '../types/react_select_types';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/partner';

interface GetPartnerEmployeesOptions {
  page?: number;
  recordsPerPage?: number;
  id?: string;
  query?: string;
}

interface GetPartnerOptions {
  subEntities?: boolean;
  id?: string;
}

const removeObjectKeys = (originalObject: PartnerEntity, keysToRemove: string[]): PartnerEntity => {
  const newObject = Object.fromEntries(
    Object.entries(originalObject).filter(([key]) => !keysToRemove.includes(key)),
  );
  return newObject as PartnerEntity;
};

export function getPartnerList(options: GetPartnerOptions = {}) {
  let url: string = apiEndpoint;
  const { subEntities, id } = options;

  if (id !== undefined) {
    url = apiEndpoint + `?entityId=${id}`;
  } else if (subEntities !== undefined) {
    url = apiEndpoint + `?withSubEntities=${subEntities}`;
  }
  return fetch(createRequest(url, 'GET', 'application/json', true));
}

export function addPartner(entity: PartnerEntity) {
  const body = removeObjectKeys(entity, ['id', 'organizationId', 'subEntities']);
  if (entity.id.length !== 0) {
    body.parentPartnerId = entity.id;
  }
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export function editPartner(entity: PartnerEntity) {
  const body = removeObjectKeys(entity, ['parentPartnerId', 'organizationId', 'subEntities']);
  return fetch(createRequest(apiEndpoint, 'PUT', 'application/json', true, body));
}

export function deletePartner(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'DELETE', 'application/json', true));
}

export function getPartnerEmployees(options: GetPartnerEmployeesOptions = {}) {
  let url: string = apiEndpoint;
  const { page, recordsPerPage, id, query } = options;

  if (query !== undefined && page !== undefined && recordsPerPage !== undefined) {
    url = apiEndpoint + `/employee?name=${query}&page=${page}&limit=${recordsPerPage}`;
  } else if (id !== undefined && page !== undefined && recordsPerPage !== undefined) {
    url = apiEndpoint + `/employee?partner_id=${id}&page=${page}&limit=${recordsPerPage}`;
  } else if (page !== undefined && recordsPerPage !== undefined) {
    url = apiEndpoint + `/employee?page=${page}&limit=${recordsPerPage}`;
  } else {
    url = apiEndpoint + '/employee';
  }
  return fetch(createRequest(url, 'GET', 'application/json', true));
}

export function addPartnerEmployee(email: string, firstName: string, lastName: string, partnerEntityId: SelectOptions, jobTitle: string) {
  const body = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    partnerEntityId: partnerEntityId.value,
    jobTitle: jobTitle,
  };
  return fetch(createRequest(apiEndpoint + '/employee', 'POST', 'application/json', true, body));
}

export function editPartnerEmployee(id: string, partnerEntityId: SelectOptions, jobTitle: string, roles: string[], groups: string[]) {
  const body = {
    id: id,
    partnerEntityId: partnerEntityId.value,
    jobTitle: jobTitle,
    roles: roles,
    groups: groups,
  };
  return fetch(createRequest(apiEndpoint + '/employee', 'PUT', 'application/json', true, body));
}

export function deletePartnerEmployee(id: string) {
  return fetch(createRequest(apiEndpoint + `/employee/${id}`, 'DELETE', 'application/json', true));
}

export function partnerEmployeeSearch(query: PartnerQueryFilter) {
  return fetch(createRequest(apiEndpoint + '/employee/search', 'POST', 'application/json', true, query));
}
