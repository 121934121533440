import React, { Dispatch, SetStateAction } from 'react';
import TableSkeletonLoader from '../../../../ui/TableSkeletonLoader';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { ContextBasedGeneratorJob, QuestionGeneratorJob, TopicBasedGeneratorJob } from '../../../../utils/types/content_manager_types';
import { Document } from './document/document';
import styles from './document_triage.module.css';
import { TriageRow } from './triage_row/triage_row';

export const DocumentTriage = ({
  documents,
  setDocuments,
  loading,
}: {
  documents: (ContextBasedGeneratorJob | TopicBasedGeneratorJob)[],
  setDocuments: Dispatch<SetStateAction<(ContextBasedGeneratorJob | TopicBasedGeneratorJob)[]>>,
  loading: boolean;
}) => {

  const updateJob = (newJobData: QuestionGeneratorJob) => {
    const updatedDocuments = documents.map(doc =>
      doc.id === newJobData.id ? newJobData : doc);
    setDocuments(updatedDocuments);
  };

  return (
    <div
        className={styles.documentTriage}
    >
      <div
          className={styles.header}
      >
        <TriageRow>
          <SecondaryTypography.Small
              textCase='uppercase'
              fontWeight='semi-bold'
          >
            Document Title
          </SecondaryTypography.Small>

          <SecondaryTypography.Small
              textCase='uppercase'
              fontWeight='semi-bold'
          >
            Upload Date
          </SecondaryTypography.Small>

          <SecondaryTypography.Small
              textCase='uppercase'
              fontWeight='semi-bold'
          >
            Status
          </SecondaryTypography.Small>

          <SecondaryTypography.Small
              textCase='uppercase'
              fontWeight='semi-bold'
          >
            Uploaded By
          </SecondaryTypography.Small>
        </TriageRow>
      </div>
      { loading ? (
        <>
          <TableSkeletonLoader />
        </>
      ) : (
        <div className={styles.documents}>
          { documents.map((documentProps) => (
            <Document
                key={documentProps.id}
                job ={documentProps}
                updateJob={updateJob}
            />
          ) ) }
        </div>
      ) }

    </div>
  );};
