import classNames from 'classnames';
import React, { useMemo } from 'react';
import { PencilIcon, TrashIcon } from '../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import { PartnerUser } from '../../../../../utils/types/admin_types';
import rowStyles from '../../../access_management/row.module.css';

type PartnerpartnerProps = {
  partner: PartnerUser,
  deleteUser: (id: string) => void,
  onEditClick: (partner: PartnerUser) => void,
}

export const PartnerEmployeeRow = ({
  partner,
  deleteUser,
  onEditClick,
}: PartnerpartnerProps) => {
  const groupListString = useMemo(() => {
    return partner.groups.map(group => group.groupName).join(', ');
  }, [partner.groups]);

  return (
    <tr
        className={rowStyles.tableRowStyle}
    >
      <td>
        <input type='checkbox'/>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.leftBorder)}
      >
        <SecondaryTypography.XSmall>
          { partner.user.firstName + ' ' + partner.user.lastName }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { partner.user.email }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { groupListString || 'Empty' }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { partner.lastActive ? new Date(partner.lastActive * 1000).toString() : 'Never Logged-In' }
        </SecondaryTypography.XSmall>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.rightBorder)}
      >
        <div
            className={rowStyles.iconContainer}
        >
          <PermissionGate
              requiredPermissions={[Permission.PARTNER_EDIT]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.editIcon, rowStyles.clickable)}
                onClick={() => onEditClick(partner)}
            >
              <PencilIcon/>
            </div>
          </PermissionGate>
          <PermissionGate
              requiredPermissions={[Permission.PARTNER_DELETE]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.trashIcon, rowStyles.clickable)}
                onClick={() => deleteUser(partner.id)}
            >
              <TrashIcon/>
            </div>
          </PermissionGate>
        </div>
      </td>
    </tr>
  );
};
