import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { AddIcon, PlusBox, RefreshIcon } from '../../../../ui/icons/icons';
import { Modal } from '../../../../ui/modal/modal';
import { PaginationController } from '../../../../ui/pagination_controller/pagination_controller';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import {
  addOrganizationUser,
  deleteOrganizationUser,
  editOrganizationUser,
} from '../../../../utils/api_service/organization_users';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import {
  addOrganizationUserRedux,
  deleteOrganizationUserRedux,
  editOrganizationUserRedux, fetchOrganizationUsers,
} from '../../../../utils/redux_store/features/organization_user_reducer';
import { RootState, store } from '../../../../utils/redux_store/store';
import { OrganizationUser } from '../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import baseStyles from '../../admin.module.css';
import commonStyles from '../access_management.module.css';
import { AdminSearchBar } from '../admin_search_bar/admin_search_bar';
import { EditUserModal } from './modals/edit_user_modal';
import { InviteUserModal } from './modals/invite_user_modal';
import { UserRow } from './user_row/user_row';
import { useUserSearch } from './useUserSearch';

export const UserManagement = () => {
  const organizationUserList = useSelector((state: RootState) => state.organizationUsers.organizationUserList);
  const userGroup = useSelector((state: RootState) => state.userGroups.userGroupList);
  const userRole = useSelector((state: RootState) => state.userRoles.userRoleList);
  const [inviteModalVisible, setInviteModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editedUser, setEditedUser] = useState<OrganizationUser>(organizationUserList[0]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { paginatedUsers, totalPages } = useUserSearch(organizationUserList, searchTerm, pageNumber, 8);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPageNumber(1);
  };

  const deleteUser = async (id: string) => {
    try {
      const response = await deleteOrganizationUser(id);
      if (response.ok) {
        store.dispatch(deleteOrganizationUserRedux(id));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error deleting user: ' + error, { position: 'bottom-center' });
    }
  };

  const openEditModal = (user: OrganizationUser) => {
    setEditedUser(user);
    setEditModalVisible(true);
  };

  const inviteSingleUser = async (email: string, firstname: string, lastname: string, groups: SelectOptions[], roles: SelectOptions[]) => {
    const groupList = groups.map(group => group.value);
    const roleList = roles.map(role => role.value);
    try {
      const response = await addOrganizationUser(email, firstname, lastname, groupList, roleList);
      if (response.ok) {
        const data = await response.json();
        setInviteModalVisible(false);
        store.dispatch(addOrganizationUserRedux(data));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error adding user: ' + error, { position: 'bottom-center' });
    }
  };

  const editUser = async (user: OrganizationUser, groups: SelectOptions[], roles: SelectOptions[]) => {
    const groupList = groups.map(group => group.value);
    const roleList = roles.map(role => role.value);
    try {
      const response = await editOrganizationUser(user.id, groupList, roleList);
      if (response.ok) {
        setEditModalVisible(false);
        const updatedUser = {
          ...user,
          groups: userGroup.filter((item) => groupList.includes(item.id)),
          roles: userRole.filter((item) => roleList.includes(item.id)),
        };
        store.dispatch(editOrganizationUserRedux(updatedUser));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error editing user: ' + error, { position: 'bottom-center' });
    }
  };

  return (
    <>
      <div
          className={commonStyles.searchContainer}
      >
        <AdminSearchBar
            searchTerm={searchTerm}
            handleChange={handleSearchTermChange}
        />
        <div
            className={classNames(commonStyles.flexRowContainer, commonStyles.iconGap, commonStyles.blueText)}
            onClick={() => store.dispatch(fetchOrganizationUsers())}
        >
          <div
              className={commonStyles.icons}
          >
            <RefreshIcon/>
          </div>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
              textCase='capitalize'
          >
            Refresh
          </SecondaryTypography.Small>
        </div>
        <div
            className={classNames(commonStyles.flexRowContainer, commonStyles.iconGap, commonStyles.blueText)}
        >
          <div
              className={classNames(commonStyles.icons, commonStyles.plusIconColor)}
          >
            <PlusBox/>
          </div>
          <SecondaryTypography.Small
              fontWeight='semi-bold'
              textCase='capitalize'
          >
            Invite Users in Bulk
          </SecondaryTypography.Small>
        </div>
        <PermissionGate
            requiredPermissions={[Permission.USERS_CREATE]}
        >
          <Button
              icon={<AddIcon/>}
              onClick={() => setInviteModalVisible(true)}
              variant='primary'
              size='medium'
          >
            Invite Users
          </Button>
        </PermissionGate>
      </div>
      <table
          className={baseStyles.tableStyle}
      >
        <thead>
          <tr>
            <th
                style={{ width: 0 }}
            >
              <input type='checkbox'/>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='left'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Name
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='left'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Email
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='left'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Groups
              </SecondaryTypography.XSmall>
            </th>
            <th>
              <SecondaryTypography.XSmall
                  alignment='left'
                  textCase='uppercase'
                  fontWeight='semi-bold'
              >
                Last Active
              </SecondaryTypography.XSmall>
            </th>
          </tr>
        </thead>
        <tbody>
          { paginatedUsers.map((user: OrganizationUser) => {
            return (
              <UserRow
                  key={user.id}
                  user={user}
                  deleteUser={deleteUser}
                  onEditClick={openEditModal}
              />
            );
          })
          }
        </tbody>
      </table>
      <PaginationController
          pageNumber={pageNumber}
          maxPageNumber={totalPages}
          navigateNextPage={() => setPageNumber((pageNumber) => pageNumber + 1)}
          navigatePreviousPage={() => setPageNumber((pageNumber) => pageNumber - 1)}
      />
      <Modal
          visible={inviteModalVisible}
          modalContent={<InviteUserModal userGroupList={userGroup} userRoleList={userRole} inviteUser={inviteSingleUser}/>}
          toggle={() => setInviteModalVisible(false)}
          headerText='Invite Users'
          headerClass={baseStyles.modalHeader}
      />
      <Modal
          visible={editModalVisible}
          modalContent={<EditUserModal userGroupList={userGroup} userRoleList={userRole} user={editedUser} editUser={editUser}/>}
          toggle={() => setEditModalVisible(false)}
          headerText='Add Groups to User'
          headerClass={baseStyles.modalHeader}
      />
    </>
  );
};
