import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { PrimaryTypography, SecondaryTypography } from '../../../../ui/typography/typography';
import { exportQuestions, generateQuestions, getJob } from '../../../../utils/api_service/job_api';
import { fetchSkills } from '../../../../utils/redux_store/features/skill_reducer';
import { fetchSubSkills } from '../../../../utils/redux_store/features/subskill_reducer';
import { store } from '../../../../utils/redux_store/store';
import { QuestionGeneratorJob, Questions } from '../../../../utils/types/content_manager_types';
import styles from '../ContextSection/upload-file.module.css';

type QuestionListProps = {
  job: QuestionGeneratorJob,
  questionsLoading: boolean,
  setJob: (newJobData: QuestionGeneratorJob) => void;
  errorJob: boolean
}

export const QuestionsList = ({
  job,
  questionsLoading,
  setJob,
  errorJob,
}: QuestionListProps) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [generateMoreLoading, setgenerateMoreLoading] = useState<boolean>(false);
  const [oldQuestionsCount, setOldQuestionsCount] = useState<number>(job.questions?.length || 0);
  const [errorGenerateJob, setErrorGenerateJob] = useState<boolean>(false);
  const [questionImportList, setQuestionImportList] = useState<Array<string>>([]);
  const allSelected = job.questions?.filter(question => question.exists === 'False').every(question => questionImportList.includes(question.questionId));

  const handleQuestionClick = (id: string) => {
    if(questionImportList.includes(id)) {
      setQuestionImportList(questionImportList.filter(item => item !== id));
    } else {
      setQuestionImportList([...questionImportList, id]);
    }
  };

  const handleSelectAllClick = () => {
    if(allSelected) {
      setQuestionImportList([]);
    } else {
      const allQuestionIds = job.questions?.filter(question => question.exists === 'False').map(question => question.questionId) || [];
      setQuestionImportList(allQuestionIds);
    }
  };

  const pollJob = (id: string) => {
    getJob(id).then(response => {
      setJob(response);
      if (response.status && response.status.includes('Error')) {
        setErrorGenerateJob(true);
      } else {
        if (response.status !== 'Generation Completed...') {
          setTimeout(() => {
            pollJob(id);
          }, 5000);
        } else {
          setgenerateMoreLoading(false);
        }
      }
    }).catch(() => {
      toast.error('Error. Please try again', { position: 'bottom-center' });
      setErrorGenerateJob(true);
    });
  };

  const generateMore = () => {
    if (job.questions){
      setOldQuestionsCount(job.questions?.length);
    }
    setgenerateMoreLoading(true);
    generateQuestions(job.id).then(response => {
      if (response.ok) {
        pollJob(job.id);
      }
    });
  };

  const exportToQuestionBank = () => {
    setExportLoading(true);
    try {
      exportQuestions(job.id, questionImportList)
        .then(response => {
          setJob(response);
          setQuestionImportList([]);
          store.dispatch(fetchSkills());
          store.dispatch(fetchSubSkills());
        })
        .catch(() => {
          toast.error('Error importing questions', { position: 'bottom-center' });
        });
    } catch (error) {
      toast.error('Error importing questions', { position: 'bottom-center' });
    }
    setExportLoading(false);
  };

  const SkeletonLoader = () => (
    <div className='space-y-2'>
      <div
          className={styles.transcriptHeaderContainer}
      >
        <PrimaryTypography.TwoXLarge
            className={`mt-5 mb-2.5  ${errorJob ? 'text-red-400' : 'text-primary-blue-500'}`}
            fontWeight='bold'
        >
          { generateMoreLoading && job.status === 'Generation Completed...'
            ? 'Generating More Questions...'
            : job.status
          }
        </PrimaryTypography.TwoXLarge>
      </div>
      <div className='animate-pulse'>
        { [...new Array(5)].map((_, index) => (
          <div key={index} className='border-4 mb-5 border-gray-300 p-4 rounded-md'>
            <div className='h-8 p-4 bg-gray-300 rounded mb-2'></div>
            <div className='h-8 p-4 bg-gray-300 rounded w-3/4'></div>
          </div>
        )) }
      </div>
    </div>
  );

  return (
    <div>
      { questionsLoading ? (
        <SkeletonLoader />
      ) : (
        job.questions && job.questions.length > 0 && (
          <>
            <div className='flex justify-end mb-5'>
              <Button
                  onClick={() => handleSelectAllClick()}
                  variant='secondary'
                  size='xSmall'
              >
                { allSelected ? 'Unselect All' : 'Select All' }
              </Button>
            </div>
            { job.questions.map((question: Questions) => {
              const isSelected = questionImportList.includes(question.questionId);
              const newQuestion = parseInt(question.questionId) > oldQuestionsCount;

              return (
                <div
                    className={`border-4 ${isSelected ? 'border-green-500' : 'border-gray-300'} p-1 mb-2.5 rounded-md ${question.exists === 'False' ? 'cursor-pointer' : ''}`}
                    key={question.questionId}
                    onClick={() => {if (question.exists === 'False') {
                      handleQuestionClick(question.questionId);
                    }}}
                >
                  <div>
                    { newQuestion && (
                      <div>
                        <SecondaryTypography.Small className='text-yellow-500'>*New</SecondaryTypography.Small>
                      </div>
                    ) }
                    <div className='p-0.5'>
                      <SecondaryTypography.Small>Question</SecondaryTypography.Small>
                      <div className='border-2 border-gray-300 p-1 rounded-md'>
                        { question.question }
                      </div>
                    </div>
                    <div className='p-1'>
                      <SecondaryTypography.Small>Answer</SecondaryTypography.Small>
                      <div className='border-2 border-gray-300 p-1 rounded-md'>
                        { question.answer }
                      </div>
                    </div>
                    <div className='p-1 flex flex-col sm:flex-row'>
                      <div className='mb-2 sm:mb-0 sm:mr-2'>
                        <SecondaryTypography.Small>Skill</SecondaryTypography.Small>
                        <div className='border-2 border-gray-300 p-1 px-4 rounded-md'>
                          { question.skill }
                        </div>
                      </div>
                      { question.subSkill1 && (
                        <div className='mb-2 sm:mb-0 sm:mr-2'>
                          <SecondaryTypography.Small>Sub Skill 1</SecondaryTypography.Small>
                          <div className='border-2 border-gray-300 p-1 px-4 rounded-md'>
                            { question.subSkill1 }
                          </div>
                        </div>
                      ) }
                      { question.subSkill2 && (
                        <div className='mb-2 sm:mb-0 sm:mr-2'>
                          <SecondaryTypography.Small>Sub Skill 2</SecondaryTypography.Small>
                          <div className='border-2 border-gray-300 p-1 px-4 rounded-md'>
                            { question.subSkill2 }
                          </div>
                        </div>
                      ) }
                      { question.subSkill3 && (
                        <div className='mb-2 sm:mb-0 sm:mr-2'>
                          <SecondaryTypography.Small>Sub Skill 3</SecondaryTypography.Small>
                          <div className='border-2 border-gray-300 p-1 px-4 rounded-md'>
                            { question.subSkill3 }
                          </div>
                        </div>
                      ) }
                    </div>
                  </div>
                  { question.exists === 'Exists' && (
                    <SecondaryTypography.Small className='pb-0.5 pl-1 text-red-500'>Already exist in question bank</SecondaryTypography.Small>
                  ) }
                  { question.exists === 'Added' && (
                    <SecondaryTypography.Small className='pb-0.5 pl-1 text-green-500'>Added to question bank</SecondaryTypography.Small>
                  ) }
                </div>
              );
            }) }
            <div className='flex justify-between items-center w-full'>
              <div className='text-left'>
                <SecondaryTypography.Medium>{ questionImportList.length } Questions Selected</SecondaryTypography.Medium>
              </div>
              <div className='flex items-center'>
                <Button
                    onClick={() => generateMore()}
                    variant='primary'
                    size='large'
                    disabled={job.questions.length > 29 || generateMoreLoading}
                >
                  { generateMoreLoading ? 'Generating...' : 'Generate More' }
                </Button>
                <Button
                    onClick={() => exportToQuestionBank()}
                    variant='primary'
                    size='large'
                    disabled={questionImportList.length === 0 || exportLoading}
                >
                  { exportLoading ? 'Exporting...' : 'Add to Question Bank' }
                </Button>
              </div>
            </div>
            { generateMoreLoading && !errorGenerateJob && <SkeletonLoader /> }
          </>
        )
      ) }
    </div>
  );
};
