import classNames from 'classnames';
import React, { useMemo } from 'react';
import { ChartOrderItem } from '../../utils/types/analytics/analytics_types';
import styles from './analytics.module.css';
import { DashletIconGroup } from './DashletIconGroup';

type NumericalDashletProps = {
    id: string,
    name: string,
    value: number,
    currency?: string,
    dashletItem:ChartOrderItem,
    onDragStart: (item: ChartOrderItem) => void,
    onDragEnter: (item: ChartOrderItem) => void,
    onDragOver: (event: React.DragEvent<HTMLDivElement>) => void,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void,
    fullScreenDetected: boolean,
    setFullScreenDetected: (fullscreen: boolean) => void,
    handleDashletVisibility: (item: ChartOrderItem) => void,
    editMode: boolean,
}

export const NumericalDashlet = ({ id, name, value, currency, dashletItem, onDragStart, onDragEnter, onDragOver, onDrop, fullScreenDetected, setFullScreenDetected, handleDashletVisibility, editMode }: NumericalDashletProps) => {

  const formattedValue = useMemo(() => {
    const roundedValue = Math.round(value);
    let formattedValue = roundedValue.toString();
    if (roundedValue >= 1000000) {
      formattedValue = parseFloat((roundedValue/1000000).toFixed(1)) + 'M';
    } else  if (roundedValue >= 1000) {
      formattedValue = parseFloat((roundedValue/1000).toFixed(1)) + 'K';
    }
    return formattedValue;
  }, [value]);

  const handleDragStart = () => {
    if (onDragStart) {
      onDragStart(dashletItem);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragEnter) {
      onDragEnter(dashletItem);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDragOver) {
      onDragOver(event);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (onDrop) {
      onDrop(event);
    }
  };

  return (
    <div className='flex justify-center items-center group relative w-full col-span-1 pt-2 h-full border border-gray-200 shadow rounded overflow-hidden hover:cursor-pointer'>
      <DashletIconGroup id={id} dashletItem={dashletItem} setFullScreenDetected={setFullScreenDetected} handleDashletVisibility={handleDashletVisibility} editMode={editMode}/>
      <div id={id}
          draggable
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={`flex flex-col justify-center bg-white w-full h-full ${dashletItem.hidden ? 'opacity-40' : ''}`}
      >
        <div className={classNames('font-bold text-center', fullScreenDetected? 'text-4xl mb-6' : 'text-sm mb-2')}>{ currency ? currency : 'Hours' } Saved - { name }</div>
        <div className={classNames(styles.numeriacalContainer, 'font-bold text-green-500 flex place-content-center place-items-center', fullScreenDetected ? 'text-9xl' : (formattedValue.length > 3 ? 'text-5xl' : 'text-7xl'))}>
          { formattedValue }
        </div>
      </div>
    </div>
  );
};
