import React, { useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import styles from '../../modals.module.css';
import { SelectPermissions } from './select_permissions';

type AddRoleModalProps = {
    entities: string[],
    addRole: (roleName: string, permissions: string[]) => void,
}

const actions: string[] = ['create', 'edit', 'delete', 'view'];

function AddRoleModal({ entities, addRole }: AddRoleModalProps): JSX.Element {
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [roleName, setRoleName] = useState<string>('');

  return (
    <>
      <div
          className={styles.modalInputContainer}
      >
        <TextInput
            name='roleName'
            value={roleName}
            type='text'
            label='Role Name'
            onChange={(event) => setRoleName(event.target.value)}
        />
      </div>
      <SelectPermissions
          selectedActions={selectedActions}
          setSelectedActions={setSelectedActions}
          entities={entities}
          actions={actions}
      />
      <Button
          onClick={() => addRole(roleName, selectedActions)}
          variant='primary'
          size='medium'
      >
        Add Role
      </Button>
    </>
  );
}

export default AddRoleModal;
