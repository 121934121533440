import React, { useMemo, useState } from 'react';
import { Button } from '../../../../../ui/button/button';
import { CustomSelect } from '../../../../../ui/custom_react_select/custom_select';
import { MultiSelectListItem } from '../../../../../ui/data_source_filter/MultiSelectListItem';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { PartnerEntity, PartnerUser, UserGroup, UserRole } from '../../../../../utils/types/admin_types';
import { FormattedSelectOptions } from '../../../../../utils/types/analytics/analytics_types';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import { getFormattedEntityList } from './AddPartnerEmployeeModal';

type EditEmployeeModalProps = {
    partnerEntityList: PartnerEntity[];
    partnerEmployee: PartnerUser;
    userGroupList: UserGroup[],
    userRoleList: UserRole[],
    editPartnerEmployee: (id: string, partnerEntityId: FormattedSelectOptions, jobTitle: string, roles: SelectOptions[], groups: SelectOptions[]) => void;
}

const findPartnerEntityById = (entities: PartnerEntity[],targetId: string | null,currentLevel: number): { entity: PartnerEntity, level: number } | undefined => {
  for (const entity of entities) {
    if (entity.id === targetId) {
      return { entity, level: currentLevel };
    }
    const nestedResult = findPartnerEntityById(entity.subEntities, targetId, currentLevel + 1);
    if (nestedResult) {
      return nestedResult;
    }
  }
  return undefined;
};

export const EditPartnerEmployeeModal = ({
  partnerEntityList,
  partnerEmployee,
  userGroupList,
  userRoleList,
  editPartnerEmployee,
}: EditEmployeeModalProps) => {
  const formattedOrgList = getFormattedEntityList(partnerEntityList);
  const [jobTitle, setJobTitle] = useState<string>(partnerEmployee.jobTitle);
  const [selectedGroups, setSelectedGroups] = useState(() =>
    partnerEmployee?.groups.map((group) => ({
      value: group.id,
      label: group.groupName,
    })),
  );
  const [selectedRoles, setSelectedRoles] = useState(() =>
    partnerEmployee?.roles.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  );
  const [selectedEntity, setSelectedEntity] = useState<FormattedSelectOptions | undefined>(() => {
    const result = findPartnerEntityById(partnerEntityList, partnerEmployee.partnerEntityId, 0);
    return result
      ? { value: result.entity.id, label: result.entity.name, level: result.level }
      : undefined;
  });

  const groupOptions = useMemo(() =>
    userGroupList.map((group) => ({
      value: group.id,
      label: group.groupName,
    })),
  [userGroupList],
  );

  const roleOptions = useMemo(() =>
    userRoleList.map((role) => ({
      value: role.id,
      label: role.roleName,
    })),
  [userRoleList],
  );

  const handleGroupChange = (selectedOptions: SelectOptions[]) => {
    setSelectedGroups(selectedOptions);
  };

  const handleRoleChange = (selectedOptions: SelectOptions[]) => {
    setSelectedRoles(selectedOptions);
  };

  return (
    <>
      <CustomSelect
          label='Select Partner Entity'
          name='partnerEntityId'
          options={formattedOrgList}
          onChange={(option: FormattedSelectOptions) => { setSelectedEntity(option); }}
          clearable={true}
          components={{ Option: MultiSelectListItem }}
          value={selectedEntity}
      />
      <TextInput
          name='jobTitle'
          value={jobTitle}
          type='text'
          label='Job Title'
          onChange={(event) => setJobTitle(event.target.value)}
      />
      <CustomSelect
          name='groups'
          options={groupOptions}
          onChange={handleGroupChange}
          isMulti={true}
          label={'Group Membership'}
          value={selectedGroups}
      />
      <CustomSelect
          name='roles'
          options={roleOptions}
          onChange={handleRoleChange}
          isMulti={true}
          label={'Role Membership'}
          value={selectedRoles}
      />
      <Button
          onClick={() => { selectedEntity && editPartnerEmployee(partnerEmployee.id, selectedEntity, jobTitle, selectedRoles, selectedGroups); }}
          variant='primary'
          size='medium'
      >
        Edit Partner Employee
      </Button>
    </>
  );
};
