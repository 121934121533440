import classNames from 'classnames';
import React from 'react';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import styles from './tabs.module.css';

type TabsProp = {
    tabTitles: string[],
    activeTab: number,
    setActiveTab: (value: number) => void,
}

export const Tabs = ({
  tabTitles,
  activeTab,
  setActiveTab,
}: TabsProp) => {

  return (
    <div
        className='flex border-b border-gray-200'
    >
      { tabTitles.map((item: string, index) => {
        return (
          <div
              className={classNames(styles.tabButton, { [styles.selected]: activeTab === index })}
              onClick={() => setActiveTab(index)}
              key={item}
          >
            <SecondaryTypography.Small
                fontWeight='semi-bold'
            >
              { item }
            </SecondaryTypography.Small>
          </div>
        );
      }) }
    </div>
  );
};
