import React, { useState } from 'react';
import { PrimaryTypography, SecondaryTypography } from '../../../ui/typography/typography';
import { Tabs } from '../../assessment/view/tabs/tabs';
import baseStyles from '../admin.module.css';
import { EntityManagement } from './EntityManagement';
import { PartnerEmployeeManagement } from './PartnerEmployeeManagement';

export const PartnerManagement = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabTitles = ['Entities', 'Employees'];

  const renderTabContent = () => {
    if (activeTab === 0) {
      return <EntityManagement/>;
    } else if (activeTab === 1)  {
      return <PartnerEmployeeManagement/>;
    }
  };

  return (
    <div
        className={baseStyles.container}
    >
      <PrimaryTypography.FiveXLarge
          fontWeight='bold'
          keepDefaultMargins={true}
      >
        Admin
      </PrimaryTypography.FiveXLarge>
      <div
          className={baseStyles.contentContainer}
      >
        <SecondaryTypography.Medium
            keepDefaultMargins={true}
            fontWeight='bold'
        >
          Partner Management
        </SecondaryTypography.Medium>
        <Tabs
            tabTitles={tabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
        { renderTabContent() }
      </div>
    </div>
  );
};
