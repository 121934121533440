import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getJobRoleList } from '../../api_service/job_roles';
import { Role } from '../../types/assessment_types';
import { SelectOptions } from '../../types/react_select_types';

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async () => {
  return await getJobRoleList();
});

export interface RolesState {
    rolesList: SelectOptions[],
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
  rolesList: [],
  loading: 'idle',
} as RolesState;

export const rolesSlice = createSlice({
  name: 'rolesList',
  initialState,
  reducers: {
    addRoleToRedux: (state, action: PayloadAction<SelectOptions>) => {
      let rolesListCopy = [...state.rolesList];
      rolesListCopy.push(action.payload);
      return {
        ...state,
        rolesList: rolesListCopy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        return { ...state, loading: 'pending' };
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        let roleArray: SelectOptions[] = [];
        if (action.payload) {
          action.payload.map((item: Role) => {
            roleArray.push({
              value: item.id,
              label: item.roleName,
            });
          });
        }
        return { ...state, rolesList: roleArray, loading: 'succeeded' };
      });
  },
});

export const { addRoleToRedux } = rolesSlice.actions;
export default rolesSlice.reducer;
