import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getSubscriptions } from '../../api_service/subscription_api';
import { SubscriptionDetails } from '../../types/admin_types';

export const fetchSubscriptions = createAsyncThunk('subscription/fetchSubscriptions', async () => {
  try {
    const response = await getSubscriptions();
    if (response.ok) {
      const data: SubscriptionDetails = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      toast.error(errorData.error, { position: 'bottom-center' });
      throw new Error(errorData.error);
    }
  } catch (error) {
    toast.error('Error fetching subscription data: ' + error, { position: 'bottom-center' });
  }
});

interface SubscriptionsState {
  subscriptions: SubscriptionDetails | undefined;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: SubscriptionsState = {
  subscriptions: undefined,
  loading: 'idle',
};

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions: (state, action: PayloadAction<SubscriptionDetails>) => {
      state.subscriptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        return { ...state, loading: 'pending' };
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(fetchSubscriptions.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const { setSubscriptions } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
