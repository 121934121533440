import React, { ChangeEvent, useState } from 'react';
import { Button } from '../../../ui/button/button';
import { TextInput } from '../../../ui/input/text_input/text_input';
import { SecondaryTypography } from '../../../ui/typography/typography';
import { updateUser } from '../../../utils/api_service/user_api';
import styles from './../CollapsibleDetails.module.css';

type ExpandedDetailsProps = {
    firstName?: String,
    lastName?: String,
    privacy: boolean,
    setFirstNameInput?: (content: string) => void | undefined,
    setLastNameInput?: (content: string) => void | undefined,
}

export const ExpandedDetails = ({
  firstName,
  lastName,
  privacy,
  setFirstNameInput,
  setLastNameInput,
}: ExpandedDetailsProps) => {

  const [inputValue, setInputValue] = useState<string>(String(firstName));
  const [inputValueLast, setInputValueLast] = useState<string>(String(lastName));

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputChangeLast = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValueLast(event.target.value);
  };

  const handleUpdate = () => {
    if(setFirstNameInput && setLastNameInput){
      setFirstNameInput(inputValue);
      setLastNameInput(inputValueLast);
      updateUser(inputValue, inputValueLast).then(response => {
        localStorage.setItem('firstname', response.firstName);
        localStorage.setItem('lastname', response.lastName);
      });
    }
  };

  return (
    <div
        className={styles.inputContainer}
    >
      { !privacy &&
        <div
            className={styles.largeInputGrid}
        >
          <TextInput
              label='First name'
              name='firstName'
              value={inputValue}
              type='text'
              onChange={handleInputChange}
          />
          <TextInput
              label='Last name'
              name='lastName'
              value={inputValueLast}
              type='text'
              onChange={handleInputChangeLast}
          />
          <Button
              onClick={handleUpdate}
              variant={'primary'}
              size={'medium'}
              customClass={styles.buttonStyles}
          >
            Update Profile
          </Button>
        </div>
      }
      {
        privacy &&
        <div
            className={styles.privacyInfo}
        >
          <SecondaryTypography.Medium
          >
            Please read the HiAcuity <a href='https://www.hiacuity.com/legal/terms-of-use' rel='noreferrer' target='_blank'>Terms and Conditions</a> and the HiAcuity <a href='https://www.hiacuity.com/legal/privacy-policy' rel='noreferrer' target='_blank'>Privacy Policy</a> for more details on your rights surrounding the use of the HiAcuity Platform
          </SecondaryTypography.Medium>
        </div>
      }
    </div>
  );

};
