import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { SecondaryTypography } from '../../typography/typography';
import styles from '../input.module.css';

type TextInputProps = {
    label?: string,
    name: string,
    value: string,
    type: string,
    placeholder?: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    min?: string,
    max?: string,
    required?: boolean,
    customClass?: string,
}

export const TextInput = ({
  label,
  name,
  value,
  type,
  placeholder = '',
  onChange,
  disabled,
  min,
  max,
  required,
  customClass,
}: TextInputProps) => (
  <div>
    { label &&
      <label
          className={styles.labelStyles}
      >
        <SecondaryTypography.XSmall
            fontWeight='semi-bold'
        >
          { label }
        </SecondaryTypography.XSmall>
      </label>
    }
    <input
        className={classNames(styles.inputStyles, customClass)}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        min={min}
        max={max}
        required={required}
    />
  </div>
);

