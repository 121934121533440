import classNames from 'classnames';
import React, { useMemo } from 'react';
import { PencilIcon, TrashIcon } from '../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import { EmployeeUser } from '../../../../utils/types/admin_types';
import rowStyles from '../../access_management/row.module.css';

type EmployeeRowProps = {
  employee: EmployeeUser,
  deleteUser: (id: string) => void,
  onEditClick: (employee: EmployeeUser) => void,
}

export const EmployeeRow = ({
  employee,
  deleteUser,
  onEditClick,
}: EmployeeRowProps) => {
  const groupListString = useMemo(() => {
    return employee.groups.map(group => group.groupName).join(', ');
  }, [employee.groups]);

  return (
    <tr
        className={rowStyles.tableRowStyle}
    >
      <td>
        <input type='checkbox'/>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.leftBorder)}
      >
        <SecondaryTypography.XSmall>
          { employee.user.firstName + ' ' + employee.user.lastName }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { employee.user.email }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { groupListString || 'Empty' }
        </SecondaryTypography.XSmall>
      </td>
      <td>
        <SecondaryTypography.XSmall>
          { employee.lastActive ? new Date(employee.lastActive * 1000).toString() : 'Never Logged-In' }
        </SecondaryTypography.XSmall>
      </td>
      <td
          className={classNames(rowStyles.tableRowStyle, rowStyles.rightBorder)}
      >
        <div
            className={rowStyles.iconContainer}
        >
          <PermissionGate
              requiredPermissions={[Permission.USERS_EDIT]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.editIcon, rowStyles.clickable)}
                onClick={() => onEditClick(employee)}
            >
              <PencilIcon/>
            </div>
          </PermissionGate>
          <PermissionGate
              requiredPermissions={[Permission.USERS_DELETE]}
          >
            <div
                className={classNames(rowStyles.icons, rowStyles.trashIcon, rowStyles.clickable)}
                onClick={() => deleteUser(employee.id)}
            >
              <TrashIcon/>
            </div>
          </PermissionGate>
        </div>
      </td>
    </tr>
  );
};
