import React from 'react';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { ContextBasedGeneratorJob, QuestionGeneratorJob, TopicBasedGeneratorJob } from '../../../../../../utils/types/content_manager_types';
import { QuestionsList } from '../../../../upload_file/question_list';
import styles from './document_details.module.css';

type DocumentDetailsProps = {
    job: TopicBasedGeneratorJob | ContextBasedGeneratorJob,
    updateJob: (newJobData: QuestionGeneratorJob) => void,
}

export const DocumentDetails = ({
  job,
  updateJob,
}: DocumentDetailsProps) => {

  return (
    <div
        className={styles.documentDetailsContainer}
    >
      <div
          className={styles.transcriptContainer}
      >
        <SecondaryTypography.Small>
          { 'transcript' in job ? job.transcript : job.topic }
        </SecondaryTypography.Small>
      </div>
      <QuestionsList
          job={job}
          setJob={updateJob}
          errorJob={false}
          questionsLoading={false}
      />
    </div>
  );
};
