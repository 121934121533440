import React, { useState } from 'react';

// Define the props type
interface ToggleButtonGroupProps {
  buttons: string[];
  initialActive?: string;
  onActiveChange?: (activeButton: string) => void;
  disabled?: boolean;
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  buttons,
  initialActive = '',
  onActiveChange = () => {},
  disabled = false,
}) => {
  const [activeButton, setActiveButton] = useState(initialActive);

  const handleClick = (button: string) => {
    if (disabled) {
      return;
    }
    setActiveButton(button);
    onActiveChange(button);
  };

  return (
    <div className='flex w-full'>
      { buttons.map((button, index) => (
        <button
            key={button}
            className={`px-4 py-2 font-semibold transition-colors text-white ${
              activeButton === button
                ? (disabled ? 'bg-gray-500' : 'bg-primary-green-500')
                : 'bg-gray-300'
            } ${index === 0 ? 'rounded-l-lg' : ''} ${index === buttons.length - 1 ? 'rounded-r-lg' : ''} ${index > 0 ? '-ml-px' : ''}`} // This handles the rounded corners and removes left margin for all buttons except the first one
            onClick={() => handleClick(button)}
        >
          { button }
        </button>
      )) }
    </div>
  );
};

export default ToggleButtonGroup;
