import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Button } from '../../../../../../../ui/button/button';
import { CustomSelect } from '../../../../../../../ui/custom_react_select/custom_select';
import { TextArea } from '../../../../../../../ui/input/text_area/text_area';
import { SecondaryTypography } from '../../../../../../../ui/typography/typography';
import { getAssessmentSettings, updateAnswerNotesAndStatus } from '../../../../../../../utils/api_service/assessment_api';
import { Permission } from '../../../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../../../utils/hooks/PermissionGate';
import { AssessmentQuestionResult, FacialEmotions } from '../../../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../../../utils/types/react_select_types';
import styles from './result_answer.module.css';

type ResultAnswerProps = {
    assessmentQuestionResult: AssessmentQuestionResult,
    updateDetailedQuestionArray: (questionArray: AssessmentQuestionResult) => void,
    setResultOpen: (value: boolean) => void,
}

export const ResultAnswer = ({
  assessmentQuestionResult,
  updateDetailedQuestionArray,
  setResultOpen,
}: ResultAnswerProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [questionResult, setQuestionResult] = useState<AssessmentQuestionResult>(assessmentQuestionResult);
  const [blurVideo, setBlurVideo] = useState<boolean>(false);

  useEffect(() => {
    getAssessmentSettings(assessmentQuestionResult.assessmentId).then(response => {
      setBlurVideo(response.blurVideo);
    });
  }, [assessmentQuestionResult.assessmentId]);

  const answerSelect: SelectOptions[] = [
    {
      value: 'correct',
      label: 'Answer is Correct',
    },
    {
      value: 'incorrect',
      label: 'Answer is In-Correct',
    },
  ];

  const getHighestFacialEmotionLabel = (facialEmotions: FacialEmotions): string => {
    let highestValue = 0; // Initialize highest value
    let highestLabel: string = ''; // Initialize highest label

    // Iterate over each key-value pair in the facialEmotions object
    Object.entries(facialEmotions).forEach(([key, value]) => {
      if (value !== undefined && value > highestValue) { // Check if the current value is higher than the highestValue
        highestValue = value; // Update highestValue
        highestLabel = key; // Update highestLabel
      }
    });

    return highestLabel; // Return the label with the highest value
  };

  const getHighestFacialEmotionPercentage = (facialEmotions: FacialEmotions): number => {
    let total = 0; // Initialize total sum of all values
    let highestValue = 0; // Initialize highest value

    // Calculate the total and find the highest value
    Object.values(facialEmotions).forEach(value => {
      if (value !== undefined) {
        total += value; // Add each value to the total
        if (value > highestValue) {
          highestValue = value; // Update highestValue if current value is greater
        }
      }
    });

    // If total is 0 to avoid division by zero error
    if (total === 0) return 0;

    // Calculate the percentage of the highest value
    const highestPercentage = (highestValue / total) * 100;

    // Return the percentage as a number rounded to 2 decimal places
    return parseFloat(highestPercentage.toFixed(2));
  };

  const pieChartData = [
    {
      label: 'Accuracy',
      displayValue: Math.round(assessmentQuestionResult.similarityScore * 100) + '%',
      xCoordinate: 90,
      yCoordinate: 60,
      dx: '11.5em',
      dy: '0.5em',
      data: [
        {
          name: 'Accuracy',
          value: Math.round(assessmentQuestionResult.similarityScore * 100),
        },
        {
          name: 'Non-assigned',
          value: 100 - Math.round(assessmentQuestionResult.similarityScore * 100),
        },
      ],
    },
    {
      label: 'Speech Rate',
      displayValue: Math.round(assessmentQuestionResult.speechRate) + 'wpm',
      xCoordinate: 235,
      yCoordinate: 60,
      dx: '29.75em',
      dy: '0.5em',
      data: [
        {
          name: 'Speech Rate',
          value: 100,
        },
        {
          name: 'Non-assigned',
          value: 0,
        },
      ],
    },
    {
      label: 'Dominant Emotion',
      displayValue: getHighestFacialEmotionLabel(assessmentQuestionResult.facialEmotions),
      xCoordinate: 380,
      yCoordinate: 60,
      dx: '48em',
      dy: '0.5em',
      data: [
        {
          name: 'Dominant Emotion',
          value: Math.round(getHighestFacialEmotionPercentage(assessmentQuestionResult.facialEmotions)),
        },
        {
          name: 'Non-assigned',
          value: 100 - Math.round(getHighestFacialEmotionPercentage(assessmentQuestionResult.facialEmotions)),
        },
      ],
    },
    {
      label: 'Filler words usage',
      displayValue: 100 - Math.round(assessmentQuestionResult.fluencyRate) + '%',
      xCoordinate: 90,
      yCoordinate: 200,
      dx: '11.5em',
      dy: '18em',
      data: [
        {
          name: 'Filler words usage',
          value: Math.round(assessmentQuestionResult.fluencyRate),
        },
        {
          name: 'Non-assigned',
          value: 100 - Math.round(assessmentQuestionResult.fluencyRate),
        },
      ],
    },
    {
      label: 'Face Detected',
      displayValue: Math.round(assessmentQuestionResult.faceDetected) + '%',
      xCoordinate: 235,
      yCoordinate: 200,
      dx: '29.75em',
      dy: '18em',
      data: [
        {
          name: 'Face Detected',
          value: Math.round(assessmentQuestionResult.faceDetected),
        },
        {
          name: 'Non-assigned',
          value: 100 - Math.round(assessmentQuestionResult.faceDetected),
        },
      ],
    },
    {
      label: 'Confidence',
      displayValue: 93 + '%',
      xCoordinate: 380,
      yCoordinate: 200,
      dx: '48em',
      dy: '18em',
      data: [
        {
          name: 'Face Detected',
          value: 93,
        },
        {
          name: 'Non-assigned',
          value: 7,
        },
      ],
    },
  ];

  const COLORS = ['#3BB54A', '#202D8A'];

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestionResult({
      ...questionResult,
      personalNotes: event.target.value,
    });
  };

  const handleSelectChange = (option: SelectOptions) => {
    option.value === 'correct' ?
      setQuestionResult({
        ...questionResult,
        score: questionResult.maxScore,
        isCorrect: true,
      })
      :
      setQuestionResult({
        ...questionResult,
        score: 0,
        isCorrect: false,
      });
  };

  const saveUpdatedQuestionResult = () => {
    updateAnswerNotesAndStatus(questionResult.id, questionResult.isCorrect, questionResult.personalNotes).then(response => {
      if (response.ok) {
        updateDetailedQuestionArray(questionResult);
        setResultOpen(false);
      }
    });
  };

  return (
    <div
        className={styles.questionAnswerContainer}
    >
      <div
          className={styles.userAnswerContainer}
      >
        {
          questionResult.questionType === 'Modern' ?
            <div
                className={styles.userVideo}
            >
              {
                blurVideo &&
                  <div
                      className={styles.blurOverlay}>
                  </div>
              }
              <video
                  controlsList={blurVideo? 'nofullscreen': ''}
                  controls
                  disablePictureInPicture={blurVideo? true: false}
                  src={questionResult.videoUrl}
              />
            </div>
            :
            <TextArea
                name='TextAnswer'
                onChange={() => {}}
                disabled={true}
                label='Answer'
                value={questionResult.userAnswer}
                customClass={styles.answerTextArea}
            />
        }
        <div
            className={styles.pointsInfoContainer}
        >
          <SecondaryTypography.Small
              fontWeight='bold'
              keepDefaultMargins={true}
              className={styles.pointsText}
          >
            Total Points: { questionResult.score | 0 }
          </SecondaryTypography.Small>
          <SecondaryTypography.Small
              fontWeight='bold'
              keepDefaultMargins={true}
              className={styles.linkText}
          >
            View potential answers for question
          </SecondaryTypography.Small>
        </div>
      </div>
      <div
          className={styles.tabContainer}
      >
        <div
            className={styles.tabs}
        >
          <div
              className={classNames({ [styles.selectedTab]: activeTab === 0 })}
              onClick={() => setActiveTab(0)}
          >
            <SecondaryTypography.XSmall
                alignment='center'
                fontWeight='semi-bold'
            >
              Feedback
            </SecondaryTypography.XSmall>
          </div>
          <div
              className={classNames({ [styles.selectedTab]: activeTab === 1 })}
              onClick={() => setActiveTab(1)}
          >
            <SecondaryTypography.XSmall
                alignment='center'
                fontWeight='semi-bold'
            >
              Live Video
            </SecondaryTypography.XSmall>
          </div>
        </div>
        <div
            style={{ padding: '20px' }}
        >
          { activeTab === 0 ?
            <div>
              <ResponsiveContainer
                  width='100%'
                  height={300}
              >
                <PieChart>
                  { pieChartData.map((item: any) => {
                    return (
                      <Pie
                          key={item.label}
                          data={item.data}
                          cx={item.xCoordinate}
                          cy={item.yCoordinate}
                          innerRadius='28%'
                          outerRadius='36%'
                          fill='#8884d8'
                          paddingAngle={5}
                          dataKey='value'
                      >
                        { item.data.map((entry: any, index: any) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        )) }
                        <Label
                            position='center'
                            width={5}
                        >
                          { item.displayValue }
                        </Label>
                        <Label
                            position='center'
                            content={() => (
                              <text textAnchor='middle' dominantBaseline='middle' dx={item.dx} dy={item.dy} style={{ fontSize: '0.5em' }}>
                                { item.label }
                              </text>
                            )}
                        />
                      </Pie>
                    );
                  }) }
                </PieChart>
              </ResponsiveContainer>
              <div
                  className={styles.tabTextArea}
              >
                <TextArea
                    name='system-answer'
                    value={questionResult.systemAnswer}
                    onChange={() => {}}
                    label='System Answer'
                    disabled={true}
                />
                <TextArea
                    name='transcription'
                    value={questionResult.userAnswer}
                    onChange={() => {}}
                    label='Transcription'
                    disabled={true}
                />
              </div>
              <SecondaryTypography.XSmall
                  keepDefaultMargins={true}
                  fontWeight='semi-bold'
                  className={styles.greyText}
              >
                Camera Movement detections - 04
              </SecondaryTypography.XSmall>
              <PermissionGate
                  requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
              >
                <TextArea
                    name='personal-notes'
                    value={questionResult.personalNotes}
                    onChange={handleChange}
                    label='Personal Notes'
                />
                <div
                    className={styles.buttonContainer}
                >
                  <CustomSelect
                      name='change'
                      value={questionResult.isCorrect ? answerSelect[0] : answerSelect[1]}
                      options={answerSelect}
                      onChange={(option: SelectOptions) => handleSelectChange(option)}
                  />
                  <Button
                      onClick={saveUpdatedQuestionResult}
                      variant='primary'
                      size='medium'
                      customClass={styles.buttonClass}
                  >
                    Approve
                  </Button>
                </div>
              </PermissionGate>
            </div> :
            <div
                className={styles.userVideoLive}
            >
              {
                blurVideo &&
                  <div
                      className={styles.blurOverlay}>
                  </div>
              }
              <video
                  controlsList={blurVideo? 'nofullscreen': ''}
                  controls
                  disablePictureInPicture={blurVideo? true: false}
                  src={questionResult.videoUrl}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
