import React from 'react';
import { Permission } from '../../../../../utils/helpers/permissions';

interface Entity {
  entityLabel: string;
  entityValue: string;
}

export const getFormattedEntityList = (entities: string[]): Entity[] => {
  const entityPermissionsMap: Record<string, Permission> = {
    Assessments: Permission.ASSESSMENTS_CREATE,
    Job: Permission.JOB_CREATE,
    Questions: Permission.QUESTIONS_CREATE,
    Roles: Permission.ROLES_CREATE,
    Groups: Permission.GROUPS_CREATE,
    Users: Permission.USERS_CREATE,
    'Organization unit': Permission.ORGUNIT_CREATE,
    'Job title': Permission.JOBTITLE_CREATE,
    Employee: Permission.EMPLOYEE_CREATE,
    Analytics: Permission.ANALYTICS_CREATE,
    'Enterprise Analytics': Permission.ENTERPRISE_ANALYTICS_CREATE,
    'Organization Analytics': Permission.ORGANISATION_ANALYTICS_CREATE,
  };

  const formattedEntities: (Entity | null)[] = entities.map((entity: string) => {
    const permission = entityPermissionsMap[entity] as Permission;
    if (permission) {
      const entityName = permission.split(':')[0];
      return { entityLabel: entity, entityValue: entityName };
    }
    return null;
  });

  return formattedEntities.filter((entity): entity is Entity => entity !== null);
};

export const extractEntityValues = (entities: Entity[]): string[] => {
  return entities.map((entity: Entity) => entity.entityValue);
};
