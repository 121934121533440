import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AsyncCustomSelect from '../../../../ui/custom_react_select/async_select';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { MinuteSecondsInput } from '../../../../ui/input/minute_seconds_input/minute_seconds_input';
import { TextInput } from '../../../../ui/input/text_input/text_input';
import { searchJobTitleList } from '../../../../utils/api_service/job_title';
import { debounce } from '../../../../utils/helpers/debounce';
import { RootState } from '../../../../utils/redux_store/store';
import { JobTitle } from '../../../../utils/types/admin_types';
import { QuestionGeneratorJob, Settings } from '../../../../utils/types/content_manager_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';

type AddQuestionModelProps = {
  setEditGenerateProperties: Dispatch<SetStateAction<Settings>>,
  job: QuestionGeneratorJob
}

export const GenerateProperties = ({
  setEditGenerateProperties,
  job,
}:AddQuestionModelProps) => {
  const levels = useSelector((state: RootState) => state.skillLevels);
  const [jobTitleList, setJobTitleList] = useState<JobTitle[]>([]);
  const [time, setTime] = useState<number>(job.settings?.time || 30);
  const [points, setPoints] = useState<number>(job.settings?.points || 1);
  const [jobTitle, setJobTitle] = useState<SelectOptions | undefined>(() => {
    if (job && job.settings && job.settings.jobTitle && typeof job.settings.jobTitle !== 'string') {
      const jobEntity = job.settings.jobTitle;
      return { value: jobEntity?.id, label: jobEntity.jobTitle };
    }
  });

  const [level, setLevel] = useState<SelectOptions | undefined>(() => {
    if (job && job.settings && job.settings.level) {
      const chosenLevel = levels.levelList?.filter(option => option.value === job.settings?.level)[0];
      if (chosenLevel) {
        return { value: chosenLevel.value, label: chosenLevel.label };
      }
    } else {
      const chosenLevel = levels.levelList?.filter(option => option.label === 'Beginner')[0];
      return { value: chosenLevel.value, label: chosenLevel.label };
    }
  });

  const questionTypesList: SelectOptions[] = [
    {
      value: 'Modern',
      label: 'Video',
    },
    {
      value: 'Classic',
      label: 'Text',
    },
  ];

  const [questionType, setQuestionType] = useState<SelectOptions | undefined>(() => {
    if (job && job.settings && job.settings.questionType) {
      const chosenQuestionType = questionTypesList?.filter(option => option.value === job.settings?.questionType)[0];
      if (chosenQuestionType) {
        return { value: chosenQuestionType.value, label: chosenQuestionType.label };
      }
    } else {
      return questionTypesList[0];
    }
  });

  useEffect(() => {
    searchJobTitleList('').then(response => {
      setJobTitleList(response);
    });
    updateProperties(level, jobTitle, questionType, time, points);
  }, []);

  const updateProperties = (currentLevel: SelectOptions | undefined, currentJobTitle: SelectOptions | undefined, currentQuestionType: SelectOptions | undefined, currentTime: number, currentPoints: number) => {
    setEditGenerateProperties(previousState => ({
      ...previousState,
      level: currentLevel?.value || '',
      jobTitle: currentJobTitle?.value || '',
      questionType: currentQuestionType?.value || 'Modern',
      time: currentTime,
      points: currentPoints,
    }));
  };

  const handleLevelSelectChange = (selectedValue: SelectOptions) => {
    setLevel(selectedValue);
    updateProperties(selectedValue, jobTitle, questionType, time, points);
  };

  const handleQuestionTypeSelectChange = (selectedValue: SelectOptions) => {
    setQuestionType(selectedValue);
    updateProperties(level, jobTitle, selectedValue, time, points);
  };

  const mapJobTitleToSelectOption = (jobTitle: JobTitle) => ({
    value: jobTitle.id,
    label: jobTitle.jobTitle,
  });

  const fetchJobTitleList = async (query: string) => {
    try {
      const data = await searchJobTitleList(query);
      return data.map(mapJobTitleToSelectOption);
    } catch (error) {
      toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
      return [];
    }
  };

  const defaultJobTitles = jobTitleList.map((jobTitle) => ({
    value: jobTitle.id,
    label: jobTitle.jobTitle,
  }));

  const handleJobTitleChange = (selectedOptions: SelectOptions) => {
    setJobTitle(selectedOptions);
    updateProperties(level, selectedOptions, questionType, time, points);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>) => {
    const value = +event.target.value;
    if (event.target.name === 'time') {
      setTime(value);
      updateProperties(level, jobTitle, questionType, value, points);
    } else {
      setPoints(value);
      updateProperties(level, jobTitle, questionType, time, value);
    }
  };

  const loadOptionsDebounced = debounce(
    async (inputValue: string, callback: (options: any) => void) => {
      const options = await fetchJobTitleList(inputValue);
      callback(options);
    },
    500,
  );

  return (
    <div className='flex flex-col gap-4'>
      <div
          className='flex gap-4'
      >
        <div className='w-1/2'>
          <CustomSelect
              label='Level'
              name='level'
              value={level}
              options={levels.levelList}
              onChange={handleLevelSelectChange}
              disabled={job.questions && job.questions.length > 0}
          />
        </div>
        <div className='w-1/2'>
          <AsyncCustomSelect
              value={jobTitle}
              defaultOptions={defaultJobTitles}
              onChange={handleJobTitleChange}
              loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
              label={'Job Title'}
              isMulti={false}
              disabled={job.questions && job.questions.length > 0}
          />
        </div>
      </div>
      <div
          className='flex gap-4'
      >
        <div className='w-1/2'>
          <CustomSelect
              label='Question Type'
              name='questionType'
              value={questionType}
              options={questionTypesList}
              onChange={handleQuestionTypeSelectChange}
              disabled={job.questions && job.questions.length > 0}
          />
        </div>
        <div className='w-1/2'>
          <MinuteSecondsInput
              label='Timer (Seconds)'
              name='time'
              value={time}
              onChange={handleChange}
              disabled={job.questions && job.questions.length > 0}
          />
        </div>
      </div>
      <div
          className='flex gap-4'
      >
        <div className='w-1/2'>
          <TextInput
              label='Points for Question'
              name='points'
              value={String(points)}
              type='number'
              onChange={handleChange}
              disabled={job.questions && job.questions.length > 0}
          />
        </div>
        <div className='w-1/2'></div>
      </div>
    </div>
  );
};

